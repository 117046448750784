import React from "react";
import { Helmet } from "react-helmet-async";

import { SelectedFilesProvider } from "@frontend/contexts/selected-files.context";

import { WarningStatusIcon } from "@shared/components/icons";

import { FolderPage } from "./folders/[id]";

export const DashboardPage: React.FC = () => {
  return (
    <SelectedFilesProvider>
      <MobileNotSupportedWarning />
      <Helmet>
        <title>Subly - Dashboard</title>
      </Helmet>
      <FolderPage />
    </SelectedFilesProvider>
  );
};

export const MobileNotSupportedWarning = () => {
  return (
    <div className="tw-flex tw-flex-row tw-items-start tw-gap-2 tw-border-b tw-border-warning-200 tw-bg-warning-100 tw-px-6 tw-py-2.5 tw-text-warning-900 sm:tw-hidden">
      <WarningStatusIcon className="tw-h-5 tw-w-5 tw-text-warning-500" />
      <div>
        <p className="tw-text-sm">Sorry for any inconvenience.</p>
        <p className="tw-text-sm">We're enhancing Subly's mobile experience.</p>
        <p className="tw-text-sm tw-font-semibold">For the best results, use a desktop.</p>
      </div>
    </div>
  );
};
