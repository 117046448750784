import React from "react";

import { AppId, listApps, zapierRegenerateKey } from "@frontend/api/apps.service";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";
import { SETTINGS_APPS_PATH } from "@frontend/routes";

import { Button } from "@shared/primitives/button";
import { Input } from "@shared/primitives/input";

export const ZapierContainer: React.FC = () => {
  const [apiKey, setApiKey] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchAppList = async () => {
      const fetchResp = await listApps();

      if (fetchResp) {
        const zapierApp = fetchResp.find((a) => a.appId === AppId.Zapier);
        setApiKey(zapierApp?.id || "");
      }
      setIsLoading(false);
    };

    fetchAppList();
  }, []);

  const generateApiKey = async () => {
    const key = await zapierRegenerateKey();
    if (!key) return;

    setApiKey(key);
  };

  return (
    <>
      <SettingsPageHeader title="Zapier" backUrl={SETTINGS_APPS_PATH} className="tw-mb-6" />

      <div className="tw-flex tw-flex-col">
        <p>API KEY</p>
        <Input placeholder="API Key" value={isLoading ? "Loading..." : apiKey} readOnly />
        <Button onClick={generateApiKey} size="36">
          Generate API Key
        </Button>
      </div>
    </>
  );
};
