import React from "react";
import classNames from "classnames";

import { Loader } from "@shared/primitives/loader";

interface CheckboxProps {
  id?: string;
  loading?: boolean;
  label?: string;
  large?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  stopPropagation?: boolean;
  disabled?: boolean;
  help?: string;
  className?: string;
  name?: string;
  defaultChecked?: boolean;
  children?: React.ReactNode;
  position?: "left" | "right";
  onChange?: (checked: boolean) => void;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, forwardedRef) => {
  const localRef = React.useRef<HTMLInputElement>(null);
  const ref = (forwardedRef as React.RefObject<HTMLInputElement>) || localRef;

  const {
    id = React.useId(),
    loading = false,
    label,
    checked,
    indeterminate = false,
    stopPropagation = false,
    disabled = false,
    help,
    className,
    name,
    onChange,
    defaultChecked,
    large,
    children,
    position = "left",
    ...restProps
  } = props;

  const [isControlled] = React.useState(checked !== undefined);
  const [localChecked, setLocalChecked] = React.useState(defaultChecked ?? false);
  const isChecked = isControlled ? checked : localChecked;

  React.useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = indeterminate ?? false;
      ref.current.checked = checked ?? false;
    }
  }, [ref, checked, indeterminate]);

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!disabled) {
        const newChecked = e.target.checked;
        if (!isControlled) {
          setLocalChecked(newChecked);
        }
        onChange?.(newChecked);
      }
    },
    [disabled, isControlled, onChange]
  );

  const handleDivClick = React.useCallback(() => {
    if (!disabled) {
      const newChecked = !isChecked;
      if (!isControlled) {
        setLocalChecked(newChecked);
      }
      onChange?.(newChecked);
    }
  }, [disabled, isChecked, isControlled, onChange, stopPropagation]);

  return (
    <div
      className={classNames(
        "tw-relative tw-inline-flex tw-items-start",
        {
          "tw-gap-2": !large,
          "tw-gap-3": large,
          "tw-flex-row-reverse": position === "right",
          "tw-justify-between": position === "right"
        },
        className
      )}
      onClick={(e) => {
        if (stopPropagation) e.stopPropagation();
      }}
    >
      <div
        className={classNames("tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center", { "tw-h-6 tw-w-6": large })}
      >
        {loading && <Loader />}
        {!loading && (
          <>
            <input
              {...restProps}
              type="checkbox"
              id={id}
              ref={ref}
              className="tw-hidden"
              onChange={handleInputChange}
              disabled={disabled}
              name={name}
              checked={isControlled ? isChecked : undefined}
              defaultChecked={!isControlled ? defaultChecked : undefined}
            />
            <div
              className={classNames(
                "tw-absolute tw-flex tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded",
                {
                  "tw-bg-primary-500": (isChecked || indeterminate) && !disabled,
                  "tw-h-4 tw-w-4": !large,
                  "tw-h-5 tw-w-5": large,
                  "tw-border": !(isChecked || indeterminate),
                  "tw-border-neutral-300 tw-bg-white": !(isChecked || indeterminate) && !disabled,
                  "tw-cursor-pointer": !disabled,
                  "tw-cursor-not-allowed tw-border-neutral-200 tw-bg-neutral-50": disabled,
                  "tw-bg-neutral-500/50": (isChecked || indeterminate) && disabled
                }
              )}
              onClick={handleDivClick}
            >
              {isChecked && !indeterminate && (
                <svg
                  className={classNames({
                    "tw-h-[7px]": !large
                  })}
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 1.5L4.63636 7.33333L1 4"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              {indeterminate && (
                <svg width="8" height="2" viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 1H8" stroke="white" strokeWidth="2" />
                </svg>
              )}
            </div>
          </>
        )}
      </div>
      <div className="tw-flex tw-flex-col">
        {!children && label && (
          <label
            htmlFor={id}
            className={classNames(
              "tw-m-0 tw-flex tw-flex-row tw-items-center tw-text-sm tw-font-medium tw-leading-5 tw-text-neutral-900",
              { "!tw-text-[16px] tw-leading-6": large, "tw-cursor-not-allowed tw-opacity-60": disabled }
            )}
          >
            {label}
          </label>
        )}
        {!children && help && (
          <p
            className={classNames("tw-m-0 tw-flex tw-flex-row tw-items-center tw-text-sm tw-text-neutral-700", {
              "!tw-text-[16px] tw-leading-6": large,
              "tw-cursor-not-allowed tw-opacity-60": disabled
            })}
          >
            {help}
          </p>
        )}
        {children}
      </div>
    </div>
  );
});
