import { Observable, of, switchMap } from "rxjs";

import config from "@frontend/config";

import { UserAccount } from "@shared/interfaces/account";
import { User, UserInvitation } from "@shared/interfaces/user";

import { Query } from "@datorama/akita";

import { AuthState, AuthStore, authStore } from "./auth.store";

export class AuthQuery extends Query<AuthState> {
  get accessToken(): string | undefined {
    return this.getValue().accessToken;
  }

  get user(): User | undefined {
    return this.getValue().user;
  }

  get userId(): string {
    return this.user?.id ?? "";
  }

  get accountId(): string {
    return this.getValue().accountId ?? "";
  }

  get accounts(): UserAccount[] {
    return this.getValue().user?.accounts ?? [];
  }

  get invitations(): UserInvitation[] {
    return this.getValue().user?.invitations ?? [];
  }

  constructor(protected store: AuthStore) {
    super(store);
  }

  // TODO: Move this to a new User store
  selectUser(): Observable<User | undefined> {
    return this.select("user");
  }

  selectAccounts(): Observable<UserAccount[]> {
    return this.select(({ user }) => user?.accounts ?? []);
  }

  selectInvitations(): Observable<UserInvitation[]> {
    return this.select(({ user }) => user?.invitations ?? []);
  }

  selectTrialDownloads(): Observable<number> {
    return this.select((s) => s?.user?.settings?.trialDownloads ?? 0);
  }

  selectTrialDownloadsLimit(): Observable<number> {
    return this.select((s) => s?.user?.settings?.trialDownloadsLimit).pipe(
      switchMap((limit) => {
        // if limit was set in the BE, return it
        if (limit != null) {
          return of(limit);
        }

        // or else return the config value
        return of(config.trialDownloadsLimit);
      })
    );
  }
}

export const authQuery = new AuthQuery(authStore);
