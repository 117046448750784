import React from "react";
import { format } from "date-fns";

import { getInitials } from "@shared/components/profile-image-icon/profile-image-icon";
import { useQuery } from "@shared/hooks/use-query";
import { Media } from "@shared/interfaces/media";
import { User } from "@shared/interfaces/user";
import { Avatar } from "@shared/primitives/avatar/avatar";
import { Dropdown, DropdownActionIconButton, DropdownItem, DropdownMenu } from "@shared/primitives/dropdown";
import { NewBadge } from "@shared/primitives/new-badge/new-badge";
import { Table } from "@shared/primitives/table";

import { Order, OrderStatus, ServiceType } from "@getsubly/common";
import { RiMoreLine } from "@remixicon/react";

type SortableCols = "filename" | "createdAt";

enum OrderColumnId {
  FILENAME = "filename",
  USER = "user",
  SERVICES = "services",
  STATUS = "status",
  STATUS_DATE = "status_date",
  MENU = "menu"
}

interface OrdersTableProps {
  ordersList: Order[];
  onSelect: (orderId: string) => void;
  onClickViewMedia: (orderId: string) => void;
  onCancel: (orderId: string) => Promise<unknown>;
}
export const OrdersTable: React.FC<OrdersTableProps> = ({ ordersList, onSelect, onClickViewMedia, onCancel }) => {
  const { queryParams, updateQueryParams } = useQuery();

  const handleClickSort = (clickedSort: SortableCols) => {
    if (clickedSort !== queryParams.sort) {
      updateQueryParams({ sort: clickedSort, order: "desc" });
    } else {
      updateQueryParams({ order: queryParams.order === "asc" ? "desc" : "asc" });
    }
  };

  if (!ordersList.length) return null;

  const columns = [
    { id: OrderColumnId.FILENAME, label: "File name", width: "1fr" },
    { id: OrderColumnId.USER, label: "User", width: "1fr" },
    { id: OrderColumnId.SERVICES, label: "Services", width: "1fr" },
    { id: OrderColumnId.STATUS, label: "Order status", width: "1fr" },
    { id: OrderColumnId.STATUS_DATE, label: "Status date", width: "1fr" },
    { id: OrderColumnId.MENU, label: "", width: "auto" }
  ];

  return (
    <Table columns={columns} items={ordersList} className="tw-flex tw-min-w-min tw-flex-col tw-overflow-auto">
      <Table.Header className="tw-bg-neutral-50">
        {(cols) =>
          cols.map((col) => {
            return (
              <Table.HeaderCell
                key={col.id}
                columnId={col.id}
                className="!tw-pl-4"
                sortable={["filename", "status", "status_date"].includes(col.id.toString())}
                isSorting={col.id === queryParams.sort}
                sortDirection={queryParams.order as "asc" | "desc" | undefined}
                onClickSort={() => handleClickSort(col.id as SortableCols)}
              >
                {col.label}
              </Table.HeaderCell>
            );
          })
        }
      </Table.Header>
      {ordersList.map((order, i) => (
        <OrderRow order={order} key={i} onSelect={onSelect} onClickViewMedia={onClickViewMedia} onCancel={onCancel} />
      ))}
    </Table>
  );
};

interface OrderRowProps {
  order: Order<User, Media>;
  onSelect: (orderId: string) => void;
  onClickViewMedia: (orderId: string) => void;
  onCancel: (orderId: string) => Promise<unknown>;
}
const OrderRow: React.FC<OrderRowProps> = ({ order, onSelect, onClickViewMedia, onCancel }) => {
  const services = React.useMemo(() => {
    return order.details.services.map((service) => {
      const abbreviation = {
        [ServiceType.HumanReviewTranscription]: "TR",
        [ServiceType.HumanReviewSubtitles]: "TR",
        [ServiceType.HumanSubtitleTranslation]: "TL",
        [ServiceType.HumanTranscriptionTranslation]: "TL",
        [ServiceType.HumanAudioDescription]: "AD"
      }[service.type];
      const languages = service.languages ?? [];
      return abbreviation + (languages.length ? ` (${languages?.join(",")})` : "");
    });
  }, []);

  const humanUpdatedAt = React.useMemo(() => {
    return format(new Date(order.updatedAt), "HH:mm d MMMM yyyy");
  }, [order.updatedAt]);

  return (
    <OrderRowInner
      onClick={() => onSelect(order.id)}
      nameCell={order.media.name}
      servicesCell={services}
      userCell={
        <>
          <Avatar
            size="40"
            initials={getInitials(order.createdBy.name, true, false)}
            className="tw-mr-2.5"
            withBorder={false}
            color="#ededed"
          />
          {order.createdBy.name}
        </>
      }
      statusCell={
        <>
          {order.status === OrderStatus.Pending && (
            <NewBadge className="tw-min-w-[94px]" size="28" variant="primary-soft" label="Requested" />
          )}
          {order.status === OrderStatus.InProgress && (
            <NewBadge className="tw-min-w-[94px]" size="28" variant="warning-soft" label="In progress" />
          )}
          {order.status === OrderStatus.Completed && (
            <NewBadge className="tw-min-w-[94px]" size="28" variant="success-soft" label="Completed" />
          )}
          {order.status === OrderStatus.Cancelled && (
            <NewBadge className="tw-min-w-[94px]" size="28" variant="ghost" label="Cancelled" />
          )}
          {order.status === OrderStatus.Rejected && (
            <NewBadge className="tw-min-w-[94px]" size="28" variant="destructive-soft" label="Rejected" />
          )}
        </>
      }
      statusDateCell={humanUpdatedAt}
      menuCell={
        <OrderMenu
          onClickViewDetails={() => onSelect(order.id)}
          onClickViewMedia={() => onClickViewMedia(order.id)}
          onClickCancel={() => onCancel(order.id)}
          isCancellable={order.status === OrderStatus.Pending}
        />
      }
    />
  );
};

interface MediaRowInnerProps {
  nameCell: React.ReactNode;
  userCell: React.ReactNode;
  servicesCell: React.ReactNode;
  statusCell: React.ReactNode;
  statusDateCell: React.ReactNode;
  menuCell: React.ReactNode;
  onClick: () => void;
}
const OrderRowInner: React.FC<MediaRowInnerProps> = ({
  nameCell,
  userCell,
  servicesCell,
  statusCell,
  statusDateCell,
  menuCell,
  onClick
}) => {
  return (
    <Table.Row className="tw-min-h-12 tw-cursor-pointer tw-whitespace-nowrap hover:tw-bg-neutral-50">
      <Table.Cell
        columnId={OrderColumnId.FILENAME}
        className="tw-min-w-[200px] tw-overflow-hidden !tw-pl-4 tw-font-medium"
        onClick={onClick}
      >
        {nameCell}
      </Table.Cell>
      <Table.Cell
        columnId={OrderColumnId.USER}
        className="tw-min-w-[200px] tw-overflow-hidden !tw-pl-4"
        onClick={onClick}
      >
        {userCell}
      </Table.Cell>
      <Table.Cell columnId={OrderColumnId.SERVICES} onClick={onClick} className="!tw-pl-4">
        {servicesCell}
      </Table.Cell>
      <Table.Cell columnId={OrderColumnId.STATUS} onClick={onClick} className="!tw-pl-4">
        {statusCell}
      </Table.Cell>
      <Table.Cell columnId={OrderColumnId.STATUS_DATE} onClick={onClick} className="!tw-pl-4">
        {statusDateCell}
      </Table.Cell>
      <Table.Cell columnId={OrderColumnId.MENU} onClick={onClick} className="tw-justify-end !tw-pl-4 !tw-opacity-100">
        {menuCell}
      </Table.Cell>
    </Table.Row>
  );
};

interface MediaMenuProps {
  onClickCancel: () => Promise<unknown>;
  onClickViewDetails: () => void;
  onClickViewMedia: () => void;
  isCancellable: boolean;
}
const OrderMenu: React.FC<MediaMenuProps> = ({
  onClickCancel,
  onClickViewDetails,
  onClickViewMedia,
  isCancellable
}) => {
  const [isCancelling, setCancelling] = React.useState<boolean>(false);
  const handleClickCancel = async () => {
    setCancelling(true);
    await onClickCancel();
    setCancelling(false);
  };
  return (
    <Dropdown>
      <DropdownActionIconButton size="20" variant="for-white-background" icon={<RiMoreLine />} loading={isCancelling} />
      <DropdownMenu className="tw-min-w-0" placement="bottom-start">
        <DropdownItem onClick={onClickViewDetails} className="tw-flex tw-flex-row tw-items-center tw-gap-2 ">
          View details
        </DropdownItem>
        <DropdownItem onClick={onClickViewMedia} className="tw-flex tw-flex-row tw-items-center tw-gap-2 ">
          Open media
        </DropdownItem>
        {isCancellable && (
          <DropdownItem
            onClick={handleClickCancel}
            className="tw-flex tw-flex-row tw-items-center tw-gap-2 !tw-text-destructive-600"
          >
            Cancel
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
