import React from "react";

import { useAPI } from "@frontend/components/api/api.context";
import { APIPageContainer } from "@frontend/components/api/api-page-container";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";

import { usePlan } from "@shared/hooks/use-plan";

export const APIPage: React.FC = () => {
  const { isEnterprise } = usePlan();
  const { apiAccount } = useAPI();

  const description =
    isEnterprise || apiAccount ? (
      "The API key allows you to access Subly services via the API"
    ) : (
      <>
        Upgrade to the{" "}
        <a href="https://www.getsubly.com/enterprise" target="_blank" rel="noreferrer" className="tw-text-aux-500">
          Enterprise Plan
        </a>{" "}
        to get access to Subly's API.
      </>
    );

  return (
    <>
      <SettingsPageHeader title="API" description={description} className="tw-mb-6" />
      <APIPageContainer />
    </>
  );
};

export default APIPage;
