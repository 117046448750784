import axios from "axios";

import config from "@frontend/config";

import { Asset, AssetType } from "@shared/interfaces/asset";
import { assetsQuery } from "@shared/state/assets/assets.query";
import { assetsStore } from "@shared/state/assets/assets.store";

import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

interface AssetsListResponse {
  assets: Asset[];
}
export const getAssets = async (mediaId: string): Promise<void> => {
  if (assetsQuery.getValue().loading) {
    return;
  }

  try {
    assetsStore.update({ loading: true });

    const { data } = await axios.get<AssetsListResponse>(`${mediaId}/assets`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    assetsStore.set(data.assets);
    assetsStore.update({ loading: false });
  } catch (error) {
    handleError(error);
  }
};

interface AssetResponse {
  asset: Asset;
}
export const uploadAsset = async ({
  mediaId,
  file,
  assetType
}: {
  mediaId?: string;
  file: File;
  assetType: AssetType;
}): Promise<Asset> => {
  try {
    assetsStore.update({ isUploading: true });

    const formData = new FormData();
    formData.append("file", file);
    formData.append("assetType", assetType);

    const { data } = await axios.post<AssetResponse>(`/${mediaId}/assets`, formData, {
      baseURL,
      headers: {
        "Content-Type": "form-data",
        "x-access-token": await getAccessToken()
      }
    });

    assetsStore.add(data.asset);

    return data.asset;
  } catch (error) {
    handleError(error);
    throw new Error(error);
  } finally {
    assetsStore.update({ isUploading: false });
  }
};

export const deleteAsset = async ({ mediaId, assetId }: { mediaId?: string; assetId: string }): Promise<void> => {
  try {
    await axios.delete(`${mediaId}/assets/${assetId}`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    assetsStore.remove(assetId);
  } catch (error) {
    handleError(error);
  }
};
