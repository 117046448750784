import React from "react";
import classNames from "classnames";
import { dropRight } from "lodash-es";

import { XMarkIcon } from "@shared/components/icons";
import { useBlur } from "@shared/hooks/use-blur";
import { Loader } from "@shared/primitives/loader";

export type Tag = {
  leftIcon?: React.ReactNode;
  label: string;
  value: string;
  extra?: { flagCode?: string };
};

type InlineTagsInputSize = "48" | "44" | "40" | "36" | "32" | "28";

interface InlineTagsInputProps {
  value: Tag[];
  placeholder?: string;
  className?: string;
  variant?: "flat" | "outline";
  size: InlineTagsInputSize;
  contentClassName?: string;
  disabled?: boolean;
  hasError?: boolean;
  after?: React.ReactNode;
  searchQuery?: string;
  onChange?: (tag: Tag[]) => void;
  onBeforeAddTag?: (tag: Tag) => void | Tag | boolean;
  onBeforeRemoveTag?: (tag: Tag) => void | boolean;
  onSearch?: (query: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onTabAway?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const InlineTagsInput: React.FC<InlineTagsInputProps> = ({
  value = [],
  placeholder = "Type keywords or phrases here...",
  className,
  variant = "flat",
  size = "40",
  contentClassName,
  disabled,
  hasError,
  after,
  searchQuery,
  onChange,
  onBeforeAddTag,
  onBeforeRemoveTag,
  onSearch,
  onKeyDown,
  onTabAway,
  onFocus,
  onBlur
}) => {
  const [tags, setTags] = React.useState<Tag[]>(value);
  const [loadingTag, setLoadingTag] = React.useState<Tag>();
  const [inputError, setInputError] = React.useState<string>("");
  const [stagedSearchQuery, setStagedSearchQuery] = React.useState<string>("");
  const [isFocused, setFocused] = React.useState<boolean>(false);

  // trigger onTabAway callback when blur caused by tabbing away from the input
  const ref = useBlur<HTMLDivElement>({ onTabAway });

  React.useEffect(() => {
    setTags(value);
  }, [value]);

  React.useEffect(() => {
    if (typeof searchQuery === "string") {
      setStagedSearchQuery(searchQuery);
    }
  }, [searchQuery]);

  const addTag = async (tag: Tag) => {
    if (!tags.find((_tag) => _tag.value === tag.value)) {
      setLoadingTag(tag);
      const response = onBeforeAddTag?.(tag);

      // if the callBack returns `false`, we will not add the tag.
      const isSuccess = response !== false;

      // if the callback returns a `string`, the tag is transformed before it is added.
      if (typeof response === "object" && typeof response?.value === "string" && typeof response?.label === "string") {
        tag = response;
      }

      if (isSuccess) {
        setStagedSearchQuery("");
        const updatedTags = [...tags, tag];
        setTags(updatedTags);
        onChange?.(updatedTags);
      }

      setLoadingTag(undefined);
    } else {
      setInputError("This word already exists");
    }
  };

  const removeTag = async (tag: Tag) => {
    if (tags.find((_tag) => _tag.value === tag.value)) {
      setLoadingTag(tag);
      const success = onBeforeRemoveTag?.(tag);

      // if callBack returns `false`, we will not remove the tag.
      const isSuccess = success !== false;

      if (isSuccess) {
        const updatedTags = [...tags].filter((_tag: Tag) => _tag.value !== tag.value);
        setTags(updatedTags);
        onChange?.(updatedTags);
      }

      setLoadingTag(undefined);
    }
  };

  const handleClick = () => {
    setFocused(true);
  };

  const handleInput = (value: string) => {
    setStagedSearchQuery(value);
    onSearch?.(value);
  };

  const handleDeletePrev = () => {
    const updatedTags = dropRight(tags);
    setTags(updatedTags);
    onChange?.(updatedTags);
  };

  const handleFocus = () => {
    setFocused(true);
    onFocus?.();
  };

  const handleBlur = () => {
    setFocused(false);
    onBlur?.();
  };

  return (
    <div
      className={classNames(
        "tw-flex tw-w-full tw-flex-row tw-items-center tw-gap-2 tw-rounded-lg focus-within:tw-shadow-focus-ring",
        {
          "tw-p-[4px] tw-text-md": ["48", "44", "40"].includes(size),
          "tw-px-[4px] tw-py-[2px] tw-text-sm": ["36", "32", "28"].includes(size),

          // Flat - error
          "tw-bg-destructive-50 focus-within:tw-border-destructive-200": hasError,
          // Flat - not disabled
          "tw-cursor-text tw-text-neutral-500": !disabled,
          // Flat - default
          "tw-bg-neutral-50 focus-within:tw-border-neutral-300": variant === "flat" && !hasError,
          // Flat - disabled
          "tw-text-neutral-300 tw-opacity-80": variant === "flat" && disabled,

          // Outline - default
          "tw-border-1 tw-border !tw-border-neutral-100 tw-bg-white tw-shadow-NEW-xs focus-within:tw-border-neutral-300":
            variant === "outline" && !hasError,
          // Outline - disabled
          "tw-border tw-border-neutral-100 !tw-bg-neutral-50 tw-text-neutral-300": variant === "outline" && disabled
        },
        className
      )}
      onClick={handleClick}
      ref={ref}
    >
      <div
        className={classNames(
          "tw-flex tw-w-full tw-flex-row tw-flex-wrap tw-items-center tw-gap-1",
          {
            // Size
            "tw-min-h-[40px]": size === "48",
            "tw-min-h-[36px]": size === "44",
            "tw-min-h-[32px]": ["36", "40"].includes(size),
            "tw-min-h-[28px]": size === "32",
            "tw-min-h-[24px]": size === "28"
          },
          contentClassName
        )}
      >
        {tags.map((tag: Tag) => (
          <Tag
            key={tag.value}
            tag={tag}
            size={size}
            isLoading={loadingTag === tag}
            isDisabled={disabled}
            onRemove={removeTag}
          />
        ))}
        <TagInput
          className={classNames({
            // Size
            "abc tw-min-h-[40px]": size === "48",
            "def tw-min-h-[40px]": size === "44",
            "ghi tw-min-h-[32px]": size === "40",
            "jkl tw-min-h-[28px]": size === "36",
            "mno tw-min-h-[24px]": size === "32",
            "pqr tw-min-h-[24px]": size === "28",

            "tw-ml-3": tags.length === 0
          })}
          placeholder={placeholder}
          error={inputError}
          hasError={hasError}
          disabled={disabled}
          value={stagedSearchQuery}
          onAdd={addTag}
          isFocused={isFocused}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onDeletePrev={handleDeletePrev}
          onKeyDown={onKeyDown}
          onInput={handleInput}
        />
      </div>
      {after}
    </div>
  );
};

interface TagProps {
  tag: Tag;
  size?: InlineTagsInputSize;
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onRemove: (tag: Tag) => void;
}
export const Tag: React.FC<TagProps> = ({ tag, size = "40", className, isLoading, isDisabled, onRemove }) => {
  const onClickRemove = () => {
    onRemove(tag);
  };

  return (
    <div
      className={classNames(
        "tw-flex tw-cursor-default tw-flex-row tw-items-center tw-gap-1 tw-rounded-lg tw-bg-white tw-shadow-sm",
        {
          "tw-text-neutral-700": !isDisabled,
          "tw-text-neutral-300 tw-opacity-70": isDisabled,
          "tw-p-2": ["48", "44"].includes(size),
          "tw-p-1": ["40", "36"].includes(size),
          "tw-px-1 tw-py-0.5": ["32", "28"].includes(size)
        },
        className
      )}
    >
      {tag.leftIcon && tag.leftIcon}
      <p
        className={classNames("tw-flex tw-flex-grow tw-select-none tw-gap-1 tw-whitespace-nowrap tw-font-medium", {
          // Font size
          "tw-text-md": ["48", "44", "40"].includes(size),
          "tw-text-sm": ["36", "32", "28"].includes(size)
        })}
      >
        {tag?.extra?.flagCode && <span className={`fi fi-${tag.extra.flagCode} tw-shrink-0`} />}
        {tag.label}
      </p>
      {Boolean(!isDisabled && isLoading) && <Loader className="tw-h-4 tw-w-4" />}
      {Boolean(!isDisabled && !isLoading) && (
        <button
          className="tw-flex tw-shrink-0 tw-flex-row tw-items-center hover:tw-text-neutral-800"
          onClick={onClickRemove}
          tabIndex={-1}
          disabled={isLoading}
        >
          <XMarkIcon className="tw-h-4 tw-w-4 tw-stroke-2" />
        </button>
      )}
    </div>
  );
};

interface TextInputProps {
  className?: string;
  placeholder?: string;
  value?: string;
  error?: string;
  hasError?: boolean;
  disabled?: boolean;
  isFocused: boolean;
  onInput?: (value: string) => void;
  onAdd: (tag: Tag) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onDeletePrev: () => void;
  onFocus: () => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}
export const TagInput: React.FC<TextInputProps> = ({
  className,
  placeholder,
  value = "",
  hasError,
  disabled,
  isFocused,
  onInput,
  onAdd,
  onKeyDown,
  onDeletePrev,
  onFocus,
  onBlur
}) => {
  const ref = React.useRef<HTMLInputElement | null>(null);

  // focus the input when `isFocused=true`
  React.useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.focus();
    }
  }, [isFocused]);

  // emit onInput when input value changes
  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    onInput?.(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // call onKeyDown on every key event
    onKeyDown?.(e);

    // call onAdd when hitting enter on a filled input
    if (e.key === "Enter" && value) {
      onAdd({ value: value.trim(), label: value.trim() });
    }
    // call `onDeletePrev` when hitting backspace on empty input
    else if (e.key === "Backspace" && !value) {
      onDeletePrev();
    }
  };

  return (
    <input
      className={classNames(
        "tw-m-0 tw-flex tw-flex-grow tw-bg-transparent tw-p-0 focus:tw-outline-none",
        {
          // Default
          "tw-text-neutral-900 placeholder:tw-text-neutral-400 placeholder:focus:tw-text-neutral-600":
            !disabled && !hasError,
          // Error & not disabled
          "tw-text-destructive-500 placeholder:tw-text-destructive-400 placeholder:focus:tw-text-destructive-600":
            !disabled && hasError,
          // Disabled
          "tw-text-neutral-300": disabled && !hasError
        },
        className
      )}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onInput={handleInput}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
      onFocus={onFocus}
      ref={ref}
    />
  );
};
