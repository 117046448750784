import React from "react";

import { CheckCircleIcon } from "@shared/components/icons";
import { useQuery } from "@shared/hooks/use-query";

interface Params {
  task?: string;
}

export const OneDriveSuccessPage: React.FC = () => {
  const { queryParams } = useQuery<Params>();

  const title = React.useMemo(() => {
    switch (queryParams?.task) {
      case "caption":
        return "Your caption files are ready";
      case "summary":
        return "Your summary files are ready";
      case "transcription":
        return "Your transcription files are ready";
      default:
        return "Your files are ready";
    }
  }, [queryParams?.task]);

  return (
    <div className="tw-flex tw-max-w-[500px] tw-flex-row tw-gap-3 tw-rounded-8 tw-border tw-border-neutral-100 tw-bg-white tw-p-6">
      <div className="tw-flex tw-h-7 tw-items-center">
        <CheckCircleIcon className="tw-h-5 tw-w-5 tw-text-success-500" />
      </div>
      <div className="tw-flex tw-flex-col tw-gap-1">
        <p className="tw-text-lg tw-font-medium">{title}</p>
        <div className="tw-flex tw-flex-col">
          <p className="tw-text-neutral-500">You may now return to OneDrive.</p>
          <p className="tw-text-neutral-500">Feel free to close this window.</p>
        </div>
      </div>
    </div>
  );
};
