import React from "react";
import classNames from "classnames";

import { InformationCircleIcon } from "@shared/components/icons";
import { ToolTip } from "@shared/primitives/tooltip";

interface FieldsetProps {
  title?: string | JSX.Element;
  helper?: string | JSX.Element;
  tooltipText?: string;
  className?: string;
  children?: React.ReactNode;
}

export const Fieldset: React.FC<FieldsetProps> = ({ title, helper, tooltipText, children, className }) => {
  return (
    <div className={classNames("tw-flex tw-flex-col tw-gap-1 tw-text-neutral-900", className)}>
      {Boolean(title) && (
        <div
          className={classNames("tw-flex tw-flex-row tw-gap-2 tw-text-sm tw-font-medium", {
            "tw-mb-0": Boolean(helper)
          })}
        >
          {title}
          {tooltipText && (
            <ToolTip text={tooltipText} place="top">
              <InformationCircleIcon className="tw-h-4 tw-w-4 tw-cursor-pointer tw-stroke-neutral-500" />
            </ToolTip>
          )}
        </div>
      )}
      {Boolean(helper) && <p className="caption tw-mb-2">{helper}</p>}

      {children}
    </div>
  );
};
