import React from "react";
import classNames from "classnames";

import { FolderSearchIcon } from "@shared/components/icons";

export const EmptyView: React.FC = () => {
  return (
    <div
      className={classNames(
        "tw-flex tw-h-full tw-min-h-[240px] tw-cursor-default tw-items-center tw-justify-center tw-space-y-5 tw-rounded-lg tw-border-[2px] tw-border-dashed tw-border-transparent tw-p-5"
      )}
    >
      <div className="tw-mx-auto tw-flex tw-max-w-md tw-flex-col tw-items-center tw-justify-center tw-gap-2">
        <div className="tw-flex tw-h-[98px] tw-w-[98px] tw-items-center tw-justify-center tw-rounded-full tw-bg-neutral-50">
          <FolderSearchIcon />
        </div>

        <h3 className="tw-text-lg tw-font-semibold tw-text-neutral-900">Nothing to see here</h3>

        <div className="tw-mb-6 tw-text-center tw-text-sm tw-text-neutral-600">
          There are no results or data to be displayed here.
        </div>
      </div>
    </div>
  );
};
