import { debounceTime, Subject } from "rxjs";

import { editorStateRepository } from "@shared/state/editor/editor.state";

import { Transcription } from "@getsubly/common";
import {
  AudioTemplate,
  EditorMode,
  EditorPanel,
  MediaSummary,
  ToolSettings,
  TranscriptionMap,
  TranslationTool
} from "@media-editor/types";
import { createStore, withProps } from "@ngneat/elf";

type EditorStoreProps = {
  showLanguagePanel: boolean;
  isHumanTranscription: boolean;
  hasTranslations: boolean;
  loaded: boolean;
  isOwner: boolean;
  activeSelectionId?: string;
  editorMode: EditorMode;
  subtitleTool: ToolSettings;
  editorPanel: EditorPanel;
  translationTool: TranslationTool;
  selectedTitleId?: string;
  autoScroll: boolean;

  transcribeEmpty: boolean;

  accountId?: string;
  audioTemplates?: AudioTemplate[];
  refreshId: number;
  showTranslationTour: boolean;

  transcriptions?: TranscriptionMap;
  currentSubtitlesId: string;
  currentSubtitles?: Transcription;
  currentTranscriptionId: string;
  currentTranscription?: Transcription;
  publicShareId?: string;

  summary?: MediaSummary;
  isGeneratingSummary: boolean;

  mediaTranslations?: string[];

  showLowConfidenceSensitivity: number;
};

const INITIAL_STATE: EditorStoreProps = {
  showLanguagePanel: false,
  isHumanTranscription: false,
  currentSubtitlesId: "",
  currentTranscriptionId: "",
  hasTranslations: false,
  isOwner: false,
  editorMode: EditorMode.Editor,
  editorPanel: EditorPanel.Subtitles,
  translationTool: TranslationTool.None,
  loaded: false,
  activeSelectionId: "",
  showTranslationTour: false,
  subtitleTool: ToolSettings.None,
  refreshId: 0,
  autoScroll: true,
  transcribeEmpty: false,
  isGeneratingSummary: false,
  showLowConfidenceSensitivity: 0.4
};

export const mediaEditorStore = createStore({ name: "media-editor" }, withProps<EditorStoreProps>(INITIAL_STATE));

class MediaEditorStateRepository {
  transcriptionChanged$ = new Subject<true>();
  trackChanges$ = this.transcriptionChanged$.pipe(debounceTime(3000));
  resetTranscriptionEditor$ = new Subject<true>();

  get transcriptions() {
    return mediaEditorStore.getValue().transcriptions;
  }

  get currentSubtitlesId() {
    const activeId = mediaEditorStore.getValue().currentSubtitlesId;
    const originalId = editorStateRepository.originalSubtitlesId;

    return (activeId || originalId) as string;
  }

  get currentTranscriptionId() {
    const activeId = mediaEditorStore.getValue().currentTranscriptionId;
    const originalId = editorStateRepository.originalTranscriptionId;

    return (activeId || originalId) as string;
  }

  get currentTranscription() {
    const id = this.currentTranscriptionId;

    return this.transcriptions?.[id] ?? [];
  }

  get currentSubtitles() {
    const id = this.currentSubtitlesId;

    return this.transcriptions?.[id] ?? [];
  }

  get activeSelectionId() {
    return mediaEditorStore.getValue().activeSelectionId;
  }

  get summaryFileId() {
    const summaryFileId = mediaEditorStore.getValue().summary?.fileId;

    return summaryFileId;
  }

  updateState = (props: Partial<EditorStoreProps>) => {
    mediaEditorStore.update((state) => ({ ...state, ...props }));
  };

  addTranscription = (id: string, transcription: Transcription) => {
    mediaEditorStore.update((state) => ({
      ...state,
      transcriptions: { ...state.transcriptions, [id]: transcription }
    }));
  };

  resetState = () => {
    mediaEditorStore.reset();
  };
}

export const mediaEditorStateRepository = new MediaEditorStateRepository();
