import React from "react";
import { Canceler } from "axios";

import { cancelOrderRequest, getOrder, getOrderList } from "@frontend/api/orders.service";
import { EN } from "@frontend/assets/i18n/en";

import { Media } from "@shared/interfaces/media";
import { User } from "@shared/interfaces/user";
import { notificationError } from "@shared/primitives/notification";
import { ordersQuery } from "@shared/state/orders";

import { Order, OrderStatus, ServiceType } from "@getsubly/common";
import { useObservable } from "@mindspace-io/react";

interface UseOrders {
  orders: Order[];
  isLoading: boolean;
  hasLoaded: boolean;
  count: number;
  canLoadMore: boolean;
  loadMore: (
    options?: Partial<{
      offset: number;
      order: "asc" | "desc";
      sort: "filename" | "status" | "status_date";
      mediaId: string;
      q: string | null;
    }>
  ) => Canceler;
  cancelOrderRequest: (orderId: string) => void;
  getActiveOrderByService: (orders: Order<User, Media>[], serviceType: ServiceType) => Order<User, Media> | undefined;
  reset: () => void;
}
export function useOrders(): UseOrders {
  const [orders] = useObservable<Order[]>(ordersQuery.select("orders"), []);
  const [isLoading] = useObservable<boolean>(ordersQuery.select("isLoading"), false);
  const [hasLoaded] = useObservable<boolean>(ordersQuery.select("hasLoaded"), false);
  const [count] = useObservable<number>(ordersQuery.select("count"), 0);

  const canLoadMore = React.useMemo(() => {
    if (!hasLoaded) return true;
    return orders?.length < count;
  }, [orders, count]);

  const loadMore = (options?: {
    offset?: number;
    order?: "asc" | "desc";
    sort?: "filename" | "status" | "status_date";
    mediaId?: string;
    q?: string | null;
  }) => {
    ordersQuery.updateState({ isLoading: true });

    const { cancel, response } = getOrderList({
      limit: 20,
      skip: options?.offset ?? orders.length,
      order: options?.order,
      sort: options?.sort,
      mediaId: options?.mediaId,
      q: options?.q
    });

    response
      .then(({ orders: pageOrders, count }) => {
        ordersQuery.updateState({ orders: options?.offset === 0 ? pageOrders : [...orders, ...pageOrders], count });
      })
      .finally(() => ordersQuery.updateState({ isLoading: false, hasLoaded: true }));

    return cancel;
  };

  const handleCancelOrderRequest = async (orderId: string) => {
    const { response: cancelResponse } = cancelOrderRequest(orderId);
    const { success } = await cancelResponse;

    if (success) {
      const { response: orderResponse } = getOrder(orderId);
      const updatedOrder = await orderResponse;

      const updatedOrders = orders.map((order) =>
        order.id === updatedOrder.id ? { ...order, status: updatedOrder.status } : order
      );

      ordersQuery.updateState({ orders: updatedOrders, count });
    } else {
      notificationError(EN.error.defaultMessage);
    }
  };

  const reset = () => {
    ordersQuery.updateState({ orders: [], isLoading: false, hasLoaded: false });
  };

  // Utils
  const getActiveOrderByService = (orders: Order<User, Media>[], serviceType: ServiceType) => {
    return orders?.find(({ status, details: { services } }: Order<User, Media>) => {
      const isActive = [OrderStatus.Pending, OrderStatus.InProgress].includes(status);
      if (!isActive) return false;
      const activeOrderHasService = services.some((service) => service.type === serviceType);
      return activeOrderHasService;
    });
  };

  return {
    orders,
    isLoading,
    hasLoaded,
    count,
    canLoadMore,
    cancelOrderRequest: handleCancelOrderRequest,
    getActiveOrderByService,
    loadMore,
    reset
  };
}
