import React from "react";

import { Dialog } from "@shared/primitives/dialog";

import { DownloadMenu } from "@media-editor/components/download-menu/download-menu";
import { DownloadMenuProvider } from "@media-editor/contexts/download-menu.context";

interface DownloadMediaModalProps {
  closeModal: (shouldClear?: boolean) => void;
}
export const DownloadMediaModal: React.FC<DownloadMediaModalProps> = ({ closeModal }) => {
  return (
    <Dialog onDismiss={closeModal} closeOnClickOutside size="930">
      <DownloadMenuProvider>
        <div className="tw-flex tw-flex-col tw-gap-4">
          <DownloadMenu closeModal={closeModal} />
        </div>
      </DownloadMenuProvider>
    </Dialog>
  );
};
