import React, { useEffect, useState } from "react";

import { usePlan } from "@shared/hooks/use-plan";
import { Button } from "@shared/primitives/button";
import { NewBadge } from "@shared/primitives/new-badge/new-badge";
import { SingleLineBanner } from "@shared/primitives/single-line-banner/single-line-banner";

import { RiCheckLine, RiFileCopy2Line, RiNotification3Fill } from "@remixicon/react";

export const BlackFridayBanner: React.FC = () => {
  const { isTrial } = usePlan();
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [copied, setCopied] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const code = "Biz20BF24";

  useEffect(() => {
    const endDate = new Date("2024-12-02T23:59:59");

    const updateTimer = () => {
      const now = new Date();
      const diff = endDate.getTime() - now.getTime();

      if (diff <= 0) {
        setIsExpired(true);
        return;
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    };

    updateTimer();
    const interval = setInterval(updateTimer, 1000);
    return () => clearInterval(interval);
  }, []);

  if (!isTrial || isExpired) return;

  const handleCopy = async () => {
    await navigator.clipboard.writeText(code);
    setCopied(true);
    setIsShaking(true);
    setTimeout(() => {
      setIsShaking(false);
      setTimeout(() => setCopied(false), 300);
    }, 500);
  };

  return (
    <SingleLineBanner
      rounded={false}
      innerClassName="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-relative tw-h-full tw-w-full"
      className="tw-border-0"
      theme="soft-yellow"
      hideClose
    >
      <style>{`
        @keyframes custom-pulse {
          0%,
          100% {
            background-color: #dc2626;
          }
          50% {
            background-color: #b91c1c;
          }
        }
      `}</style>
      <NewBadge
        size="28"
        icon={<RiNotification3Fill />}
        corner="rounded"
        variant="destructive"
        className="tw-z-10 tw-animate-pulse"
        style={{ animation: "custom-pulse 2s ease-in-out infinite" }}
      >
        <div className="tw-sans-serif tw-font-bold tw-tabular-nums !tw-text-white">
          {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
        </div>
      </NewBadge>

      <div>
        <strong>🔥 Black Friday:</strong> Save 20% on Business Plan
      </div>

      <div className="tw-relative tw-ml-auto">
        <Button
          rIcon={copied ? <RiCheckLine /> : <RiFileCopy2Line />}
          size="28"
          variant="warning"
          className={`duration-300 tw-z-10 tw-border-none !tw-bg-[#fbd44c] !tw-text-[#92400d] tw-transition-all
            ${isShaking ? "tw-animate-shake" : ""} 
            ${copied ? "!tw-scale-95 tw-bg-white/30" : "hover:tw-scale-105"}`}
          onClick={handleCopy}
        >
          {code}
        </Button>
        {copied && (
          <div className="tw-absolute -tw-bottom-8 tw-left-1/2 -tw-translate-x-1/2 tw-animate-fade-in tw-rounded tw-bg-black tw-px-2 tw-py-1 tw-text-xs tw-text-white">
            Copied!
          </div>
        )}
      </div>
    </SingleLineBanner>
  );
};
