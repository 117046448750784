import React from "react";

import {
  EyeOutlineIcon,
  EyeSolidIcon as EyeIcon,
  UserOutlineIcon,
  UserSolidIcon as UserIcon
} from "@shared/components/icons";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@shared/primitives/dropdown";

import { RoleName } from "@getsubly/common";

interface RoleNameDropdownProps {
  value?: RoleName;
  buttonClassName?: string;
  bordered?: boolean;
  showIconOnButton?: boolean;
  canSelectEditor?: boolean;
  canSelectManager?: boolean;
  canSelectAdmin?: boolean;
  onChange: (role: RoleName) => void;
}
export const RoleNameDropdown: React.FC<RoleNameDropdownProps> = ({
  value,
  buttonClassName,
  bordered,
  showIconOnButton = true,
  canSelectEditor = false,
  canSelectManager = false,
  canSelectAdmin = false,
  onChange
}) => {
  const labels = {
    [RoleName.Viewer]: "Viewer",
    [RoleName.Admin]: "Admin",
    [RoleName.Manager]: "Manager",
    [RoleName.Editor]: "Editor",
    [RoleName.Reviewer]: "Reviewer",
    [RoleName.Archived]: "Archived"
  };
  const label = (value && labels[value]) || "Select role";

  return (
    <Dropdown>
      <DropdownButton variant={bordered ? "dropdown" : "ghost"} className={buttonClassName}>
        {showIconOnButton && <RoleIcon roleName={value} />}
        {label}
      </DropdownButton>

      <DropdownMenu placement="bottom-start" className="tw-min-w-[160px]">
        <DropdownItem selected={value === RoleName.Viewer} onClick={() => onChange(RoleName.Viewer)}>
          <EyeOutlineIcon className="tw-h-5 tw-w-5" strokeWidth="2.2" />
          Viewer
        </DropdownItem>
        <DropdownItem
          selected={value === RoleName.Editor}
          onClick={() => onChange(RoleName.Editor)}
          disabled={!canSelectEditor}
        >
          <UserOutlineIcon className="tw-h-5 tw-w-5" strokeWidth="2.2" />
          Editor
        </DropdownItem>
        <DropdownItem
          selected={value === RoleName.Manager}
          onClick={() => onChange(RoleName.Manager)}
          disabled={!canSelectManager}
        >
          <UserOutlineIcon className="tw-h-5 tw-w-5" strokeWidth="2.2" />
          Manager
        </DropdownItem>
        <DropdownItem
          selected={value === RoleName.Admin}
          onClick={() => onChange(RoleName.Admin)}
          disabled={!canSelectAdmin}
        >
          <UserOutlineIcon className="tw-h-5 tw-w-5" strokeWidth="2.2" />
          Admin
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const RoleIcon: React.FC<{ roleName?: RoleName }> = ({ roleName }) => {
  switch (roleName) {
    case RoleName.Viewer:
      return <EyeIcon width="16px" />;
    case RoleName.Editor:
    case RoleName.Manager:
    case RoleName.Admin:
      return <UserIcon width="16px" />;
    default:
      return null;
  }
};
