import axios from "axios";

import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { notificationError } from "@shared/primitives/notification";

import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

export enum AppId {
  Dropbox = "DROPBOX",
  GoogleDrive = "GOOGLEDRIVE",
  OneDrive = "ONEDRIVE",
  Zapier = "ZAPIER",
  Zoom = "ZOOM"
}

export interface AppMetadata {
  driveId: string;
  driveType: string;
  email: string; // userId
}

export interface Integration {
  id: string;
  appId: AppId;
  metadata?: AppMetadata;
  createdAt: string;
}

export enum ZoomTabs {
  NotImported,
  Imported,
  Archived
}

export enum ZoomRecordingType {
  ShareScreenSpeakerViewCC = "shared_screen_with_speaker_view(CC)",
  ShareScreenSpeakerView = "shared_screen_with_speaker_view",
  ShareScreenGalleryView = "shared_screen_with_gallery_view",
  SpeakerView = "speaker_view",
  GalleryView = "gallery_view",
  SharedView = "shared_view",
  AudioOnly = "audio_only",
  AudioTranscript = "audio_transcript",
  ChatFile = "chat_file",
  ActiveSpeaker = "active_speaker",
  Poll = "poll",
  Timeline = "timeline",
  ClosedCaption = "closed_caption"
}

export enum ZoomFileType {
  MP4 = "MP4",
  M4A = "M4A",
  TIMELINE = "TIMELINE",
  TRANSCRIPT = "TRANSCRIPT",
  CHAT = "CHAT",
  CC = "CC",
  CSV = "CSV"
}

export interface IZoomRecordingFile {
  id: string;
  fileType: ZoomFileType;
  playUrl: string;
  meetingId: string;
  start: string;
  end: string;
  duration: number;
  size: number;
  status: string;
  recordingType: ZoomRecordingType;
}

export interface IZoomRecording {
  meetingNumber: number;
  topic: string;
  startTime: string;
  timezone: string;
  meetingId: string;
  fileCount: number;
  files: IZoomRecordingFile[];
}

export interface IZoomRecordingSummary {
  from: string;
  to: string;
  count: number;
  items: IZoomRecording[];
}

export interface IZoomFailAuth {
  authFail: true;
}

export const isZoomFailAuth = (result: unknown): result is IZoomFailAuth =>
  Boolean((result as IZoomFailAuth)?.authFail);

const baseURL = `${config.apiUrl}/api/v1`;

export const listApps = async (): Promise<Integration[] | undefined> => {
  try {
    const { data } = await axios.get(`/${getAccountId()}/apps`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    return data.apps;
  } catch (e) {
    handleError(e);
  }
};

export const unlinkApp = async (appId: AppId): Promise<boolean | undefined> => {
  try {
    const { data } = await axios.delete<{ deleted: boolean }>(`/${getAccountId()}/apps/unlink/${appId}`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    return data.deleted;
  } catch (e) {
    handleError(e);
  }
};

export const unlinkAppById = async (id: string): Promise<boolean | undefined> => {
  try {
    const { data } = await axios.delete<{ deleted: boolean }>(`/${getAccountId()}/apps/unlink-by-id/${id}`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    return data.deleted;
  } catch (e) {
    handleError(e);
  }
};

export const authorizeZoom = async (code: string): Promise<void> => {
  try {
    await axios.post(
      `/${getAccountId()}/zoom/auth`,
      { code },
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );
  } catch (e) {
    handleError(e);
  }
};

export const authorizeOneDrive = async (search: string): Promise<string | undefined> => {
  try {
    const { data } = await axios.get<{ url: string; message: string }>(`/${getAccountId()}/onedrive/login${search}`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    return data?.url;
  } catch (e) {
    handleError(e);
  }
};

export const listZoomRecordings = async (): Promise<IZoomRecordingSummary | IZoomFailAuth | undefined> => {
  try {
    const { data: recordings } = await axios.get<IZoomRecordingSummary>(`/${getAccountId()}/zoom/list`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    return recordings;
  } catch (e) {
    if (e.response?.data?.authFail) {
      notificationError("Unable to sign into Zoom,  Please try again, or contact support@getsubly.com");

      return e.response?.data;
    }

    handleError(e);
  }
};

export const zapierRegenerateKey = async (): Promise<string | undefined> => {
  try {
    const { data } = await axios.put<{
      key: string;
    }>(
      `/${getAccountId()}/zapier/connect`,
      {},
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    return data.key;
  } catch (e) {
    handleError(e);
  }
};
