import { Subject } from "rxjs";
import { Path } from "slate";

import settings from "@frontend/config/settings/settings";

import { EnrichedMedia, MediaFile, MediaInvite, MediaJob, MediaTranscriptionMap } from "@shared/interfaces/media";
import { getTranscriptionLanguageProperties } from "@shared/utils/media-functions";

import { Cue, CueWord, FileType, JobType } from "@getsubly/common";
import { createStore, withProps } from "@ngneat/elf";

export type EditorStore = {
  loading: boolean;
  editorLoading: boolean;
  editorLoaded: boolean;
  loadedAt?: Date;
  error?: string;
  media?: EnrichedMedia;
};

const INITIAL_STATE: EditorStore = {
  loading: false,
  editorLoading: false,
  editorLoaded: false
};

export const editorStore = createStore({ name: "editor" }, withProps<EditorStore>(INITIAL_STATE));

class EditorStateRepository {
  updateTranscriptionCue$ = new Subject<{
    transcriptionId: string;
    updatedCue: Cue;
  }>();
  updateTranscriptionWord$ = new Subject<{
    transcriptionId: string;
    path: Path;
    updatedWord: CueWord;
  }>();
  transcriptionReady$ = new Subject<{
    mediaId: string;
    hasTranscription: boolean;
    isHumanTranscription: boolean;
  }>();

  get editorLoading() {
    return editorStore.getValue().editorLoading;
  }

  get mediaId() {
    return editorStore.getValue().media?.mediaId;
  }

  get media() {
    return editorStore.getValue().media;
  }

  get userId() {
    return editorStore.getValue().media?.userId;
  }

  get type() {
    return editorStore.getValue().media?.type;
  }

  get languageCode() {
    return editorStore.getValue().media?.languageCode;
  }

  get fileSize() {
    return editorStore.getValue().media?.fileSize;
  }

  get transcriptions() {
    return editorStore.getValue().media?.transcriptions;
  }

  get originalSubtitlesId() {
    return editorStore.getValue().media?.transcriptions.originalSubtitlesId;
  }

  get originalTranscriptionId() {
    return editorStore.getValue().media?.transcriptions.originalTranscriptionId;
  }

  get subtitlesTranslations() {
    return editorStore.getValue().media?.transcriptions?.subtitlesTranslations;
  }

  get transcriptionTranslations() {
    return editorStore.getValue().media?.transcriptions?.transcriptionTranslations;
  }

  get isReplacing() {
    return editorStore.getValue().media?.isReplacing;
  }

  get assConfig() {
    return editorStore.getValue().media?.assConfig;
  }

  get snippets() {
    return editorStore.getValue().media?.assConfig?.snippets;
  }

  get plan() {
    return editorStore.getValue().media?.plan;
  }

  get loadedAt() {
    return editorStore.getValue().loadedAt;
  }

  updateState = (props: Partial<EditorStore>) => {
    editorStore.update((state) => ({ ...state, ...props }));
  };

  updatePartialMedia = (props: Partial<EnrichedMedia>): void => {
    editorStore.update((state) => {
      if (!state.media) {
        return state;
      }

      return {
        ...state,
        media: {
          ...state.media,
          ...props
        }
      };
    });
  };

  removeTranscription = (transcriptionId: string): void => {
    editorStore.update((state) => {
      if (!state.media) {
        return state;
      }

      const media = state.media;

      let originalSubtitlesId = media.transcriptions?.originalSubtitlesId;
      let originalSubtitles = media.transcriptions?.originalSubtitles;

      if (transcriptionId === originalSubtitlesId) {
        originalSubtitlesId = "";
        originalSubtitles = {
          ...originalSubtitles,
          fileId: ""
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any;
      }

      let originalTranscriptionId = media.transcriptions?.originalTranscriptionId;
      let originalTranscription = media.transcriptions?.originalTranscription;

      if (transcriptionId === originalTranscriptionId) {
        originalTranscriptionId = "";
        originalTranscription = {
          ...originalSubtitles,
          fileId: ""
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any;
      }

      const updatedFiles = media.files.filter((f) => f.id !== transcriptionId);
      const updatedSubtitlesTranslations = media.transcriptions?.subtitlesTranslations.filter(
        (l) => l.fileId !== transcriptionId
      );
      const updatedSubtitlesTranslationsIds = updatedSubtitlesTranslations.map((t) => t.fileId);

      const updatedTranscriptionTranslations = media.transcriptions?.transcriptionTranslations.filter(
        (l) => l.fileId !== transcriptionId
      );
      const updatedTranscriptionTranslationsIds = updatedTranscriptionTranslations.map((t) => t.fileId);

      let updatedAllLanguages = [];

      if (originalSubtitlesId && originalSubtitles?.languageCode) {
        updatedAllLanguages.push(originalSubtitles.languageCode);
      }

      if (originalTranscriptionId && originalTranscription?.languageCode) {
        updatedAllLanguages.push(originalTranscription.languageCode);
      }

      updatedAllLanguages = [
        ...new Set([
          ...updatedAllLanguages,
          ...updatedSubtitlesTranslations.map((t) => t.languageCode).filter((l) => !!l),
          ...updatedTranscriptionTranslations.map((t) => t.languageCode).filter((l) => !!l)
        ])
      ];

      const updatedMedia = {
        ...media,
        files: updatedFiles,
        transcriptions: {
          ...media.transcriptions,
          originalSubtitlesId,
          originalSubtitles,
          originalTranscriptionId,
          originalTranscription,
          subtitlesTranslations: updatedSubtitlesTranslations,
          subtitlesTranslationsIds: updatedSubtitlesTranslationsIds,
          transcriptionTranslations: updatedTranscriptionTranslations,
          transcriptionTranslationsIds: updatedTranscriptionTranslationsIds
        },
        allLanguages: updatedAllLanguages
      };

      return {
        ...state,
        media: updatedMedia
      };
    });
  };

  updatePartialTranscriptions = (props: Partial<MediaTranscriptionMap>): void => {
    editorStore.update((state) => {
      if (!state.media) {
        return state;
      }

      return {
        ...state,
        media: {
          ...state.media,
          transcriptions: {
            ...state.media.transcriptions,
            ...props
          }
        }
      };
    });
  };

  addTranslationLanguage = ({
    transcriptionId,
    languageCode,
    fileType = FileType.Subtitle
  }: {
    transcriptionId: string;
    languageCode: string;
    fileType?: FileType;
  }): void => {
    const transcriptions = editorStore.getValue().media?.transcriptions;

    if (!transcriptions) {
      return;
    }

    const languages = settings.translation.languages;

    const language = {
      ...getTranscriptionLanguageProperties(transcriptionId, languages, languageCode),
      fileType
    };

    if (!language) {
      return;
    }

    let updatedSubtitlesTranslations = transcriptions?.subtitlesTranslations.filter(
      (l) => l.fileId !== transcriptionId
    );

    let updatedTranscriptionTranslations = transcriptions?.transcriptionTranslations.filter(
      (l) => l.fileId !== transcriptionId
    );

    if (fileType === FileType.Subtitle) {
      updatedSubtitlesTranslations = [...updatedSubtitlesTranslations, language];
    }

    if (fileType === FileType.Transcription) {
      updatedTranscriptionTranslations = [...updatedTranscriptionTranslations, language];
    }

    this.updatePartialTranscriptions({
      subtitlesTranslations: updatedSubtitlesTranslations,
      transcriptionTranslations: updatedTranscriptionTranslations
    });
  };

  updateTranscriptionLanguage = ({
    transcriptionId,
    languageCode
  }: {
    transcriptionId: string;
    languageCode: string;
  }): void => {
    const transcriptions = editorStore.getValue().media?.transcriptions;

    if (!transcriptions) {
      return;
    }

    const isSubtitlesTranslationId = transcriptions.subtitlesTranslationsIds.includes(transcriptionId);
    const isTranscriptionTranslationId = transcriptions.transcriptionTranslationsIds.includes(transcriptionId);
    const languages =
      isSubtitlesTranslationId || isTranscriptionTranslationId
        ? settings.translation.languages
        : settings.transcription.languages;

    const language = getTranscriptionLanguageProperties(transcriptionId, languages, languageCode) || undefined;

    if (!language) {
      return;
    }

    if (transcriptions.originalSubtitlesId === transcriptionId) {
      this.updatePartialTranscriptions({
        originalSubtitles: language
      });
    } else if (transcriptions.originalTranscriptionId === transcriptionId) {
      this.updatePartialTranscriptions({
        originalTranscription: language
      });
    } else if (isSubtitlesTranslationId) {
      const translations = transcriptions.subtitlesTranslations.map((t) =>
        t.fileId === transcriptionId ? language : t
      );

      this.updatePartialTranscriptions({
        subtitlesTranslations: translations
      });
    } else if (isTranscriptionTranslationId) {
      const translations = transcriptions.transcriptionTranslations.map((t) =>
        t.fileId === transcriptionId ? language : t
      );

      this.updatePartialTranscriptions({
        transcriptionTranslations: translations
      });
    }
  };

  addMediaFile = (file: MediaFile): void => {
    editorStore.update((state) => {
      if (!state.media) {
        return state;
      }

      const updatedFiles = [...state.media.files, file];
      const subtitleLanguageCodes = updatedFiles.filter((f) => f.type === FileType.Subtitle).map((f) => f.language);
      const transcriptionLanguageCodes = updatedFiles
        .filter((f) => f.type === FileType.Transcription)
        .map((f) => f.language);

      return {
        ...state,
        media: {
          ...state.media,
          files: updatedFiles,
          subtitleLanguageCodes: subtitleLanguageCodes.filter((l) => !!l) as string[],
          transcriptionLanguageCodes: transcriptionLanguageCodes.filter((l) => !!l) as string[]
        }
      };
    });
  };

  deleteMediaFile = (fileId: string): void => {
    editorStore.update((state) => {
      if (!state.media) {
        return state;
      }

      return {
        ...state,
        media: {
          ...state.media,
          files: state.media.files.filter((f) => f.id !== fileId)
        }
      };
    });
  };

  addMediaJob = (job: MediaJob): void => {
    editorStore.update((state) => {
      if (!state.media) {
        return state;
      }

      return {
        ...state,
        media: {
          ...state.media,
          jobs: [...state.media.jobs, job]
        }
      };
    });
  };

  updateMediaJob = (job: MediaJob): void => {
    editorStore.update((state) => {
      if (!state.media) {
        return state;
      }

      return {
        ...state,
        media: {
          ...state.media,
          jobs: [...state.media.jobs.filter((j) => j.id !== job.id), job]
        }
      };
    });
  };

  updateMediaConvertJobProgress = (percentage: number): void => {
    editorStore.update((state) => {
      if (!state.media) {
        return state;
      }
      const m = state.media;
      if (m.latestJob?.type !== JobType.Conversion) {
        return state;
      }

      const jobs = m.jobs.map((j) => {
        if (j.type !== JobType.Conversion) {
          return j;
        }

        return {
          ...j,
          percentage
        };
      });

      const updatedMedia = {
        ...m,
        jobs,
        latestJob: {
          ...m.latestJob,
          percentage
        }
      };

      return {
        ...state,
        media: updatedMedia
      };
    });
  };

  addMediaInvite = (invite: MediaInvite): void => {
    editorStore.update((state) => {
      const media = state?.media;
      if (!media) {
        return state;
      }
      const oldSharedUsers = media?.sharedUsers ?? [];
      const sharedUsers = [...oldSharedUsers, invite];
      return { ...state, media: { ...media, sharedUsers } };
    });
  };

  updateMediaInvite = (invite: MediaInvite): void => {
    editorStore.update((state) => {
      const media = state?.media;
      if (!media) {
        return state;
      }

      const oldSharedUsers = media.sharedUsers ?? [];
      const inviteIndex = oldSharedUsers.findIndex((i) => i.shareId === invite.shareId);

      if (inviteIndex < 0) {
        return state;
      }

      const sharedUsers = [...oldSharedUsers];
      sharedUsers.splice(inviteIndex, 1, invite);

      return { ...state, media: { ...media, sharedUsers } };
    });
  };

  deleteMediaInvite = (inviteId: string): void => {
    editorStore.update((state) => {
      const media = state?.media;
      if (!media) {
        return state;
      }

      const sharedUsers = media.sharedUsers?.filter((i) => i.shareId !== inviteId);

      return { ...state, media: { ...media, sharedUsers } };
    });
  };

  deleteTranslation = (transcriptionId: string): void => {
    editorStore.update((state) => {
      if (!state.media) {
        return state;
      }

      const media = state.media;

      const updatedFiles = media.files.filter((f) => f.id !== transcriptionId);
      const updatedSubtitlesTranslations = media.transcriptions?.subtitlesTranslations.filter(
        (l) => l.fileId !== transcriptionId
      );
      const updatedSubtitlesTranslationsIds = updatedSubtitlesTranslations.map((t) => t.fileId);
      const updatedTranscriptionTranslations = media.transcriptions?.transcriptionTranslations.filter(
        (l) => l.fileId !== transcriptionId
      );
      const updatedTranscriptionTranslationsIds = updatedTranscriptionTranslations.map((t) => t.fileId);
      const updatedAllLanguages = [
        ...new Set([
          ...updatedSubtitlesTranslations.map((t) => t.languageCode),
          ...updatedTranscriptionTranslations.map((t) => t.languageCode)
        ])
      ];

      const updatedMedia = {
        ...media,
        files: updatedFiles,
        transcriptions: {
          ...media.transcriptions,
          subtitlesTranslations: updatedSubtitlesTranslations,
          subtitlesTranslationsIds: updatedSubtitlesTranslationsIds,
          transcriptionTranslations: updatedTranscriptionTranslations,
          transcriptionTranslationsIds: updatedTranscriptionTranslationsIds
        },
        allLanguages: updatedAllLanguages
      };

      return {
        ...state,
        media: updatedMedia
      };
    });
  };

  resetState = () => {
    editorStore.reset();
  };
}

export const editorStateRepository = new EditorStateRepository();
