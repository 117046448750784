import axios, { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import { saveAs } from "file-saver";

import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";
import { rawAnalyticsTrack } from "@frontend/contexts/analytics.context";
import { getWcagContrastRatio } from "@frontend/utils/color.utils";

import { EnrichedMedia, MediaFile } from "@shared/interfaces/media";
import { UnknownObject } from "@shared/interfaces/types";
import { notificationError } from "@shared/primitives/notification";
import { downloadQueueStore, QueueFileStatus } from "@shared/state/download-queue";
import { editorStateRepository } from "@shared/state/editor/editor.state";
import { editorUiStateRepository, SavingFileType, SavingStatus } from "@shared/state/editor-ui";
import { getBurntFileFromJob, getOriginalFile } from "@shared/utils/media-functions";

import { assColorToHex, cleanTranscription, FileType, MediaConfig, Transcription } from "@getsubly/common";
import { TranscriptionMap } from "@media-editor/types";
import * as Sentry from "@sentry/react";

import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

export const saveSubtitles = async (
  mediaId: string,
  transcriptionMap: TranscriptionMap,
  mediaConfig: MediaConfig
): Promise<void> => {
  try {
    editorUiStateRepository.updateState({
      savingStatus: SavingStatus.Saving
    });

    const savingFileTypes = editorUiStateRepository.savingFileTypes;
    const savingIds = editorUiStateRepository.savingIds;

    const savingArray: Promise<unknown>[] = [];

    if (savingFileTypes.includes(SavingFileType.Subtitle)) {
      for (const fileId of savingIds) {
        const transcription = transcriptionMap[fileId];

        if (transcription) {
          savingArray.push(saveTranscription(mediaId, transcription, fileId));
        }
      }
    }

    mediaConfig.textColorContrastRatio =
      getWcagContrastRatio(
        assColorToHex(mediaConfig.colorPrimary)?.slice(0, 7),
        assColorToHex(mediaConfig.colorBack)?.slice(0, 7) || "#000"
      ) ?? undefined;

    if (savingFileTypes.includes(SavingFileType.Config)) {
      savingArray.push(saveMediaConfig(mediaId, mediaConfig));
    }

    await axios.all(savingArray);
  } catch (e) {
    handleError(e);
  } finally {
    editorUiStateRepository.setMediaIsSaved();
  }
};

interface SaveMediaConfigOptions {
  updateState?: boolean;
}
export const saveMediaConfig = async (
  mediaId: string,
  mediaConfig: MediaConfig,
  options?: SaveMediaConfigOptions
): Promise<void> => {
  try {
    await axios.put(`/${getAccountId()}/media/${mediaId}/config`, mediaConfig, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    // Most times the `saveMediaConfig()` is called from the `mediaEditorContext` and it
    // updates the state but in some cases we call it directly (eg. snippets modals)
    // and we need to force the update on the state. This avoids the jumping update
    // in the editor during saving.
    if (options?.updateState) {
      editorStateRepository.updatePartialMedia({ assConfig: mediaConfig });
    }
  } catch (e) {
    handleError(e);
  }
};

export const downloadBurntMediaFromJob = (
  media: EnrichedMedia,
  jobId: string,
  analyticsData: UnknownObject,
  browserTabId?: number
): void => {
  const burntFile = getBurntFileFromJob(media, jobId);

  // Bug fix: Prevent duplicate file downloads in multi-tab scenarios by sending a
  // unique ID with each request. Only allow download trigger if the pusher
  // subscriber's ID matches the request's ID.
  if (Number.isInteger(burntFile?.metadata?.browserTabId) && burntFile?.metadata?.browserTabId !== browserTabId) {
    return;
  }

  if (!burntFile) {
    notificationError("Sorry, we failed to download the file. Please try again or contact support.");
    return;
  }

  downloadBurntMedia({ mediaId: media.id, burntFile, jobId, analyticsData });
};

interface DownloadBurntMediaParams {
  mediaId: string;
  burntFile?: MediaFile;
  showNotification?: boolean;
  jobId?: string;
  analyticsData: UnknownObject;
}

export const downloadBurntMedia = async ({
  mediaId,
  burntFile,
  analyticsData,
  jobId = ""
}: DownloadBurntMediaParams): Promise<void> => {
  const media = editorStateRepository.media;

  if (!media) {
    return;
  }

  if (!burntFile) {
    return;
  }

  downloadQueueStore.updateQueueJob(mediaId, jobId, {
    status: QueueFileStatus.Downloading,
    progress: 0
  });

  try {
    await downloadFile(media.id, jobId, burntFile, analyticsData);

    downloadQueueStore.completeQueueJob(mediaId, jobId);
  } catch (e) {
    Sentry.captureException(e);

    rawAnalyticsTrack("Download burnt media error", { error: e });

    downloadQueueStore.completeQueueJob(mediaId, jobId);

    await downloadModal(media.id, burntFile);
  }
};

const downloadModal = async (mediaId: string, burntFile: MediaFile): Promise<void> => {
  try {
    const downloadUrl = await getDownloadUrl({ mediaId, fileId: burntFile.id }, burntFile.url);

    editorUiStateRepository.updateState({
      download: {
        showDownloadModal: true,
        downloadUrl
      }
    });
  } catch (e) {
    handleError(e);
  }
};

export const downloadOriginalFile = async (
  mediaId: string,
  jobId: string,
  analyticsData: UnknownObject
): Promise<void> => {
  const media = editorStateRepository.media;

  if (!media) {
    return;
  }

  const originalFile = getOriginalFile(media);
  if (!originalFile) {
    return;
  }

  await downloadFile(media.id, jobId, originalFile, analyticsData);
};

export const getFileFromURL = async (url: string, filename: string, mimeType = "video/mp4"): Promise<File> => {
  const axiosRequestConfig: AxiosRequestConfig = {
    responseType: "arraybuffer"
  };

  const { data } = await axios.get(url, axiosRequestConfig);
  const buffer = Buffer.from(data, "binary");

  const blob = new Blob([buffer], { type: mimeType });
  return new File([blob], filename);
};

export const downloadSubtitles = async (
  mediaId: string,
  fileId: string,
  filename: string,
  analyticsData: UnknownObject = {}
): Promise<void> => {
  const url = `/${getAccountId()}/files/${mediaId}/subtitle-download/${fileId}`;
  try {
    const { data } = await axios.get(url, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });
    if (data.url) {
      saveAs(data.url, filename);
      return;
    }

    const blob = new Blob([data.text], { type: "text/plain" });

    rawAnalyticsTrack("Download content", {
      downloadType: "subtitles",
      ...analyticsData
    });

    saveAs(blob, filename);
  } catch (e) {
    handleError(e);
  }
};

export const deleteSubtitleFile = async ({ mediaId, fileId }: { mediaId: string; fileId: string }): Promise<void> => {
  const url = `/${getAccountId()}/files/${mediaId}/delete-file/${fileId}`;

  try {
    await axios.delete(url, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });
  } catch (e) {
    handleError(e);
  }
};

// Private functions

const downloadFile = async (
  mediaId: string,
  jobId: string,
  file: MediaFile,
  analyticsData: UnknownObject = {}
): Promise<void> => {
  const media = editorStateRepository.media;
  const mediaName = file.downloadName ?? media?.name ?? file.original_filename;
  const filename = `${mediaName}.${file.extension}`;

  // Chrome iOS browser it will open the s3 url
  if (/CriOS/.test(navigator.userAgent)) {
    const timestamp = `timestamp=${new Date().getTime()}`;
    const fileUrl = await getDownloadUrl({
      mediaId,
      fileId: file.id,
      timestamp
    });
    window.location.assign(fileUrl);
    return;
  }

  // Android browsers
  if (/Linux; Android/.test(navigator.userAgent)) {
    const timestamp = `timestamp=${new Date().getTime()}`;
    const fileUrl = await getDownloadUrl({
      mediaId,
      fileId: file.id,
      timestamp
    });

    rawAnalyticsTrack("Download content", {
      downloadType: "media",
      ...analyticsData
    });

    saveAs(fileUrl, filename);
    return;
  }

  // Any other browser download the blob
  const data = await fetchFile(mediaId, jobId, file);

  if (!data) {
    return;
  }

  const blob = new Blob([data]);

  rawAnalyticsTrack("Download content", {
    downloadType: "media",
    ...analyticsData
  });

  saveAs(blob, filename);
};

interface IDownloadRequestResponse {
  fileUrl: string;
  fileType: string;
}

const fetchFile = async (mediaId: string, jobId: string, file: MediaFile): Promise<Blob | undefined> => {
  const timestamp = `timestamp=${new Date().getTime()}`;
  // Try download v2 way
  try {
    rawAnalyticsTrack("Download - fetchFileDownloadV2", {
      mediaId,
      fileId: file.id
    });
    return fetchFileDownloadV2(mediaId, jobId, file.id, timestamp);
  } catch (e) {
    console.error(e);
  }

  // Try download v1 way
  try {
    rawAnalyticsTrack("Download - fetchFileLegacy", {
      mediaId,
      fileId: file.id
    });
    return fetchFileLegacy(mediaId, jobId, file.id, timestamp);
  } catch (e) {
    console.error(e);
  }

  // Try by URL
  rawAnalyticsTrack("Download - fetchFileByUrl", { mediaId, fileId: file.id });
  return fetchFileByUrl(mediaId, jobId, file.url);
};

export const getDownloadUrl = async (
  { mediaId, fileId, timestamp }: { mediaId: string; fileId: string; timestamp?: string },
  defaultUrl?: string
): Promise<string> => {
  try {
    const {
      data: { fileUrl }
    } = await axios.get<IDownloadRequestResponse>(
      `/${getAccountId()}/files/${mediaId}/download_request/${fileId}?${timestamp}`,
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    return fileUrl;
  } catch (e) {
    if (defaultUrl) {
      return defaultUrl;
    }

    throw e;
  }
};

const fetchFileDownloadV2 = async (
  mediaId: string,
  jobId: string,
  fileId: string,
  timestamp: string
): Promise<Blob | undefined> => {
  const fileUrl = await getDownloadUrl({ mediaId, fileId, timestamp });

  const { data } = await axios.get<Blob>(fileUrl, {
    responseType: "blob",
    onDownloadProgress: (e) => downloadProgressHandler(mediaId, jobId, e)
  });

  return data;
};

const fetchFileLegacy = async (
  mediaId: string,
  jobId: string,
  fileId: string,
  timestamp: string
): Promise<Blob | undefined> => {
  const { data } = await axios.get<Blob>(`/${getAccountId()}/files/${mediaId}/download/${fileId}?${timestamp}`, {
    baseURL: baseURL,
    headers: { "x-access-token": await getAccessToken() },
    responseType: "blob",
    onDownloadProgress: (e) => downloadProgressHandler(mediaId, jobId, e)
  });

  return data;
};

const fetchFileByUrl = async (mediaId: string, jobId: string, url: string): Promise<Blob | undefined> => {
  const { data } = await axios.get<Blob>(url, {
    responseType: "blob",
    onDownloadProgress: (e) => downloadProgressHandler(mediaId, jobId, e)
  });

  return data;
};

const saveTranscription = async (mediaId: string, transcription: Transcription, fileId: string): Promise<void> => {
  try {
    // Make sure the <mark> highlights are not saved
    const cleanedTranscription = cleanTranscription(transcription, {
      ignoreErrors: true
    });

    await axios.put(`/${getAccountId()}/media/${mediaId}/json/${fileId}`, cleanedTranscription, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });
  } catch (e) {
    handleError(e);
  }
};

// This is used for legacy files that don't have a transcription
// and this transcription gets generated by the .srt
interface SaveLegacySubtitlesResponse {
  file: MediaFile;
}
export const saveLegacySubtitles = async (
  mediaId: string,
  transcription: Transcription,
  {
    type = FileType.Subtitle,
    languageCode,
    isTranslation
  }: {
    type?: FileType.Subtitle | FileType.Transcription;
    languageCode?: string;
    isTranslation?: boolean;
  }
): Promise<MediaFile> => {
  try {
    const {
      data: { file }
    } = await axios.put<SaveLegacySubtitlesResponse>(
      `/${getAccountId()}/media/${mediaId}/json`,
      { content: transcription, type, language: languageCode, isTranslation },
      { baseURL: baseURL, headers: { "x-access-token": await getAccessToken() } }
    );

    return file;
  } catch (e) {
    throw new Error(e);
  }
};

const downloadProgressHandler = (mediaId: string, jobId: string, progressEvent: AxiosProgressEvent) => {
  const progress = Math.round((progressEvent.progress ?? 0) * 100);

  downloadQueueStore.updateQueueJob(mediaId, jobId, {
    status: QueueFileStatus.Downloading,
    progress
  });
};

interface IFetchPublicAssetUrlResponse {
  url: string;
}

export const fetchPublicAssetUrl = async (publicAssetPath: string): Promise<string> => {
  const {
    data: { url }
  } = await axios.get<IFetchPublicAssetUrlResponse>(`/s3/assets/${publicAssetPath}`, {
    baseURL
  });
  return url;
};
