import React from "react";
import classNames from "classnames";

import { getInitials } from "@shared/components/profile-image-icon/profile-image-icon";
import { SymbolGroup } from "@shared/components/symbol-group/symbol-group";
import { AvatarSymbol } from "@shared/components/symbols/avatar-symbol";
import { MoreSymbol } from "@shared/components/symbols/more-symbol";
import { User } from "@shared/interfaces/user";

type UserGroupHeaderProps = {
  users: Pick<User, "id" | "name" | "email" | "picturePublic">[];
  canClick?: boolean;
  onClick?: () => void;
  currentUserId?: string;
};
export const UserGroup: React.FC<UserGroupHeaderProps> = ({ users, canClick, onClick, currentUserId }) => {
  const previewMembers = React.useMemo<Pick<User, "id" | "name" | "email" | "picturePublic">[]>(() => {
    return users.slice(0, 3) ?? [];
  }, [users]);

  const leftoverMembers = React.useMemo<{
    amount: number;
    tooltip: string;
  }>(() => {
    const members = (users ?? []).slice(3);
    return {
      amount: members.length,
      tooltip: members.map((member) => member.name).join(", ")
    };
  }, [users]);

  const isOnlyYou = React.useMemo(() => {
    return currentUserId && users.length === 1 && users[0].id === currentUserId;
  }, [users, currentUserId]);

  return (
    <div className="tw-inline-flex tw-items-center">
      <SymbolGroup
        size="24"
        className={classNames({ "tw-cursor-pointer": canClick })}
        onClick={() => canClick && onClick?.()}
      >
        {previewMembers.map((member, i) => {
          return (
            <SymbolGroup.Item key={i}>
              <AvatarSymbol
                size="24"
                text={getInitials(member.name, true, false)}
                tooltip={member.name}
                tooltipPlacement="bottom"
              >
                {member.picturePublic && <img src={member.picturePublic} />}
              </AvatarSymbol>
            </SymbolGroup.Item>
          );
        })}
        {leftoverMembers.amount > 0 && (
          <SymbolGroup.Item>
            <MoreSymbol
              size="24"
              amount={leftoverMembers.amount}
              tooltip={leftoverMembers.tooltip}
              borderColorClass="tw-border-white"
              tooltipPlacement="bottom"
            />
          </SymbolGroup.Item>
        )}
      </SymbolGroup>
      {isOnlyYou && <span className="tw-ml-1 tw-text-xs tw-font-medium tw-text-neutral-700">Only you</span>}
    </div>
  );
};
