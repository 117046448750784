import React from "react";
import classNames from "classnames";
import { formatDistanceToNowStrict } from "date-fns";
import { Link } from "react-router-dom";

import { loadFolders } from "@frontend/api/dashboard.service";
import { getFolderPath } from "@frontend/routes";

import { DashboardFolderIcon } from "@shared/components/icons/dashboard-folder-icon";
import { DropdownActionIconButton } from "@shared/primitives/dropdown";
import { Folder } from "@shared/state/dashboard/dashboard.store";

import { RiMoreLine } from "@remixicon/react";

import { PrivateFolderMenu } from "../dashboard-sidebar/drives/private-folder-menu";
import { TeamspaceMenu } from "../dashboard-sidebar/drives/teamspace-menu";

export type FoldersGridProps = {
  className?: string;
  folders: Folder[];
};
export const FoldersGrid: React.FC<FoldersGridProps> = ({ className, folders }) => {
  return (
    <div
      className={classNames(
        "tw-grid tw-grid-cols-1 tw-gap-3 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5",
        className
      )}
    >
      {folders.map((folder, i) => (
        <FolderItem key={i} folder={folder} />
      ))}
    </div>
  );
};

export type FolderProps = {
  className?: string;
  folder: Folder;
};
export const FolderItem: React.FC<FolderProps> = ({ className, folder }) => {
  const [isSavingMenu, setIsSavingMenu] = React.useState(false);
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  return (
    <Link
      className={classNames(
        "tw-group tw-flex tw-items-center tw-gap-3 tw-rounded-[6px] tw-border tw-border-neutral-200 tw-px-3 tw-py-2 hover:tw-bg-neutral-50",
        className
      )}
      to={getFolderPath(folder.id)}
    >
      <div className="tw-shrink-0">
        <DashboardFolderIcon folder={folder} className="tw-h-8 tw-w-8 tw-shrink-0" />
      </div>
      <div className="tw-flex-1">
        <div className="tw-flex tw-items-center tw-justify-between tw-text-xs tw-font-semibold">
          <h3 className="tw-neutral-900 tw-w-full tw-overflow-hidden tw-text-ellipsis">{folder.name}</h3>
          {Boolean(folder.isPublic) ? (
            <TeamspaceMenu
              folder={folder}
              onChange={loadFolders}
              onSaving={setIsSavingMenu}
              onToggleDropdown={setMenuIsOpen}
            >
              <DropdownActionIconButton
                size="16"
                variant="for-white-background"
                icon={<RiMoreLine />}
                className={classNames(
                  "tw-ml-auto tw-rounded-4 tw-border-none tw-opacity-0 group-hover:tw-opacity-100",
                  { "tw-opacity-100": menuIsOpen || isSavingMenu }
                )}
                loading={isSavingMenu}
                onClick={(e) => e.preventDefault()}
              />
            </TeamspaceMenu>
          ) : (
            <PrivateFolderMenu
              folder={folder}
              onChange={loadFolders}
              onSaving={setIsSavingMenu}
              onToggleDropdown={setMenuIsOpen}
            >
              <DropdownActionIconButton
                size="16"
                variant="for-white-background"
                icon={<RiMoreLine />}
                className={classNames(
                  "tw-ml-auto tw-rounded-sm tw-border-none tw-bg-transparent tw-opacity-0 group-hover:tw-opacity-100",
                  { "tw-opacity-100": menuIsOpen || isSavingMenu },
                  { "!tw-bg-transparent": isSavingMenu }
                )}
                loading={isSavingMenu}
                onClick={(e) => e.preventDefault()}
              />
            </PrivateFolderMenu>
          )}
        </div>
        <FolderEditedDate folder={folder} />
      </div>
    </Link>
  );
};

export const FolderEditedDate: React.FC<{ folder: Folder }> = ({ folder }) => {
  const timeAgo = formatDistanceToNowStrict(new Date(folder.updatedAt), {
    addSuffix: true
  });
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-text-xs tw-font-medium tw-text-neutral-500">
      <div>Edited {timeAgo}</div>
    </div>
  );
};
