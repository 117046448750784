import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";

import { APIProvider } from "@frontend/components/api/api.context";
import { DevIndicator } from "@frontend/components/dev-indicator";
import { HubspotAnalytics } from "@frontend/components/hubspot-analytics";
import { ModalsContainer } from "@frontend/components/modals-container";
import config from "@frontend/config";
import { AnalyticsProvider } from "@frontend/contexts/analytics.context";
import { AppIntegrationProvider } from "@frontend/contexts/app-integration.context";
import { AuthProvider } from "@frontend/contexts/auth.context";
import { BrowserTabProvider } from "@frontend/contexts/browser-tab.context";
import { PricingIntervalToggleProvider } from "@frontend/contexts/interval-toggle.context";
import { NotificationsProvider } from "@frontend/contexts/notifications.context";
import { SpeakerRecognitionProvider } from "@frontend/contexts/speaker-recognition.context";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { FingerprintProvider } from "./contexts/fingerprint.context";
import { AppRoutes } from "./AppRoutes";

// Make sure to call `loadStripe` outside a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = config.stripe.clientKey ? loadStripe(config.stripe.clientKey) : null;

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <HelmetProvider>
        <BrowserTabProvider>
          <AnalyticsProvider>
            <Elements stripe={stripePromise}>
              <FingerprintProvider>
                <Router>
                  <AuthProvider>
                    <NotificationsProvider>
                      <PricingIntervalToggleProvider>
                        <SpeakerRecognitionProvider>
                          <AppIntegrationProvider>
                            <APIProvider>
                              <AppRoutes />
                              <HubspotAnalytics />
                              <ModalsContainer />
                            </APIProvider>
                          </AppIntegrationProvider>
                        </SpeakerRecognitionProvider>
                      </PricingIntervalToggleProvider>
                    </NotificationsProvider>
                  </AuthProvider>
                </Router>
              </FingerprintProvider>
            </Elements>
          </AnalyticsProvider>
        </BrowserTabProvider>
      </HelmetProvider>

      {/* Dev indicator that shows the app is refreshing */}
      {config.features.showDevIndicator && <DevIndicator />}
    </React.StrictMode>
  );
};

export default App;
