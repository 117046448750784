import React from "react";

import { Button, ButtonProps } from "@shared/primitives/button";

interface FileInputProps {
  accept: string;
  onChange: (file: File) => void;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
}
export const FileInput: React.FC<FileInputProps> = ({ accept, onChange, disabled, className, children }) => {
  const fileRef = React.useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ?? [];

    if (files.length > 0) {
      onChange(files[0]);
    }
  };

  return (
    <div
      onClick={() => fileRef.current?.click()}
      // style={{ width: "100%", height: "100%" }}
      className={className}
    >
      <input
        type="file"
        accept={accept}
        disabled={disabled}
        style={{ display: "none" }}
        ref={fileRef}
        onChange={handleChange}
      />
      {children}
    </div>
  );
};

type FileButtonProps = ButtonProps & FileInputProps;

export const FileButton: React.FC<FileButtonProps> = ({ accept, onChange, ...props }) => {
  return (
    <FileInput accept={accept} onChange={onChange} disabled={props.disabled}>
      <Button {...props} />
    </FileInput>
  );
};
