import React, { useState } from "react";
import classNames from "classnames";

import { getIntent, handlePurchaseSuccess } from "@frontend/api/billing.service";

import { useAccounts } from "@shared/hooks/use-accounts";
import { Button } from "@shared/primitives/button";
import { Input } from "@shared/primitives/input";
import { Modal } from "@shared/primitives/modal";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";

interface OverdueInvoiceModalProps {
  closeAlert: () => void;
}

export const OverdueInvoiceModal: React.FC<OverdueInvoiceModalProps> = ({ closeAlert }) => {
  return (
    <Modal onDismiss={closeAlert} showCloseButton>
      <OverdueInvoiceModalContainer closeAlert={closeAlert} />
    </Modal>
  );
};

const OverdueInvoiceModalContainer: React.FC<OverdueInvoiceModalProps> = ({ closeAlert }) => {
  const { subscription, paymentMethods } = useAccounts();

  const handleClose = (success = false) => {
    if (success) {
      handlePurchaseSuccess();
    }
    closeAlert();
  };

  if (!subscription || !subscription?.latestInvoice) {
    handleClose();
    return null;
  }

  const [useNewPayment, setUseNewPayment] = useState(false);
  const price = (subscription.latestInvoice.total / 100).toFixed(2);

  let payment = subscription.nextAction?.payment;

  if (!payment && paymentMethods && paymentMethods?.length > 0) {
    payment = paymentMethods[0].id;
  }

  const clientSecret = subscription?.nextAction?.clientSecret;

  if (!useNewPayment && clientSecret && payment) {
    return (
      <ConfirmPaymentMethodContainer
        price={price}
        clientSecret={clientSecret}
        payment={payment}
        switchToNew={() => setUseNewPayment(true)}
        closeAlert={handleClose}
      />
    );
  }

  return <NewPaymentMethodContainer price={price} clientSecret={clientSecret} closeAlert={handleClose} />;
};

interface ConfirmPaymentMethodContainerProps {
  price: string;
  clientSecret: string;
  payment: string;
  switchToNew: () => void;
  closeAlert: (success?: boolean) => void;
}
const ConfirmPaymentMethodContainer: React.FC<ConfirmPaymentMethodContainerProps> = ({
  price,
  clientSecret,
  payment,
  switchToNew,
  closeAlert
}) => {
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const stripe = useStripe();

  const handleConfirmPayment = async () => {
    if (!stripe) {
      setError("There is an issue, please contact support@getsubly.com.");
      return;
    }

    setError("");
    setLoading(true);

    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: payment
    });

    setLoading(false);
    if (error?.message) {
      setError(error.message);
    }
    if (!error) {
      closeAlert(true);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <h5 className="tw-text-h5">Confirm ${price} payment</h5>
      <p className="tw-mb-1">You'll be asked to verify your identity with your bank.</p>

      <div className="tw-flex">
        {error && <span className="has-error tw-text-sm tw-text-destructive-500">{error}</span>}
      </div>

      <div>
        <Button variant="primary" className="tw-mb-2" onClick={handleConfirmPayment} loading={isLoading} size="36">
          Confirm payment
        </Button>
      </div>

      <Button
        variant="secondary"
        onClick={() => {
          switchToNew();
          setError("");
        }}
        size="36"
      >
        Select a new payment method
      </Button>
    </div>
  );
};

interface NewPaymentMethodContainerProps {
  price?: string;
  clientSecret?: string;
  closeAlert: (success?: boolean) => void;
}
const NewPaymentMethodContainer: React.FC<NewPaymentMethodContainerProps> = ({ price, clientSecret, closeAlert }) => {
  const [cardholderName, setCardholderName] = React.useState<string>();

  const [dirty, setDirty] = React.useState(false);
  const [error, setError] = useState("");
  const [focus, setFocus] = useState(false);
  const [complete, setComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleCardholderNameChange = (value: string) => {
    setCardholderName(value);
    setDirty(true);
  };

  const getElementCard = (): StripeCardElement | undefined => {
    if (!elements) {
      setError("There is an issue please contact support@getsubly.com");
      return;
    }

    if (!complete) {
      setError("Some information is missing.");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setError("There is an issue, please contact support@getsubly.com.");
      return;
    }

    return cardElement;
  };

  if (price && clientSecret) {
    const handlePurchase = async () => {
      if (!stripe) {
        setError("There is an issue, please contact support@getsubly.com.");
        return;
      }

      const canComplete = Boolean(cardholderName) && Boolean(complete);
      if (!canComplete) {
        setError("Some information is missing.");
        return;
      }

      const cardElement = getElementCard();

      if (!cardElement) {
        return;
      }

      setError("");
      setLoading(true);

      const cardToken = await stripe.createToken(cardElement);

      if (cardToken.token?.card?.funding === "prepaid") {
        setError("We don't accept prepaid cards.");
        return;
      }

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: cardholderName
          }
        }
      });

      setLoading(false);
      if (error?.message) {
        setError(error.message);
      }
      if (!error) {
        closeAlert(true);
      }
    };

    return (
      <div className="tw-flex tw-flex-col tw-gap-2">
        <h5 className="tw-text-h5">Pay with card</h5>
        <p className="tw-mb-1">Card information</p>
        <div className="tw-flex tw-flex-col">
          <label className="tw-font-medium tw-text-neutral-900">Credit / debit card number</label>
          <CardElement
            className={classNames(
              "tw-mb-4 tw-flex tw-h-10 tw-flex-col tw-justify-center tw-rounded-lg tw-border-none tw-bg-neutral-50 tw-px-3 tw-py-2 tw-text-sm tw-leading-7 tw-text-black tw-shadow-none tw-transition-all tw-duration-200 tw-ease-in hover:tw-border-aux-500 focus:tw-ring-2 focus:tw-ring-aux-200 focus:tw-ring-opacity-50",
              {
                focused: focus
              }
            )}
            options={{ hidePostalCode: true }}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
            onChange={(e) => setComplete(e.complete)}
          />
        </div>
        <div className="tw-flex">
          {error && <span className="has-error tw-text-sm tw-text-destructive-500">{error}</span>}
        </div>
        <div>
          <Button variant="secondary" className="tw-mr-2" onClick={handlePurchase} loading={isLoading} size="36">
            Pay ${price}
          </Button>
        </div>
      </div>
    );
  }

  const handleNewPaymentMethod = async () => {
    if (!stripe) {
      setError("There is an issue, please contact support@getsubly.com.");
      return;
    }

    const canComplete = Boolean(cardholderName) && Boolean(complete);
    if (!canComplete) {
      setError("Some information is missing.");
      return;
    }

    const cardElement = getElementCard();

    if (!cardElement) {
      return;
    }

    setError("");
    setLoading(true);

    const cardToken = await stripe.createToken(cardElement);

    if (cardToken.token?.card?.funding === "prepaid") {
      setError("We don't accept prepaid cards.");
      return;
    }

    const {
      data: { intent }
    } = await getIntent();

    const { error } = await stripe.confirmCardSetup(intent, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: cardholderName
        }
      }
    });

    setLoading(false);
    if (error?.message) {
      setError(error.message);
    }
    if (!error) {
      closeAlert();
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <h5 className="tw-text-h5">Add payment method</h5>
      <p className="tw-mb-1">Card information</p>
      <div className="tw-flex tw-flex-col">
        <label className="tw-font-medium tw-text-neutral-900">Name on card</label>
        <Input
          type="text"
          value={cardholderName}
          onChange={({ target }) => {
            handleCardholderNameChange(target.value);
          }}
          helper={dirty && !cardholderName ? "Required" : undefined}
        />
      </div>
      <div className="tw-flex tw-flex-col">
        <label className="tw-font-medium tw-text-neutral-900">Credit / debit card number</label>
        <CardElement
          className={classNames(
            "tw-flex tw-h-10 tw-flex-col tw-justify-center tw-rounded-lg tw-border-none tw-bg-neutral-50 tw-px-3 tw-py-2 tw-text-sm tw-leading-7 tw-text-black tw-shadow-none tw-transition-all tw-duration-200 tw-ease-in hover:tw-border-aux-500 focus:tw-ring-2 focus:tw-ring-aux-200 focus:tw-ring-opacity-50",
            {
              focused: focus
            }
          )}
          options={{ hidePostalCode: true }}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          onChange={(e) => setComplete(e.complete)}
        />
      </div>
      <div className="tw-flex">
        {error && <span className="has-error tw-text-sm tw-text-destructive-500">{error}</span>}
      </div>
      <div>
        <Button variant="secondary" className="tw-mr-2" onClick={handleNewPaymentMethod} loading={isLoading} size="36">
          Setup card
        </Button>
      </div>
    </div>
  );
};
