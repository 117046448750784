import React from "react";
import classNames from "classnames";

import { usePlanPermissions } from "@shared/hooks/use-plan-permissions";
import { SublyPlan } from "@shared/interfaces/billing";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  DropdownMenu
} from "@shared/primitives/dropdown";
import { useActiveMediaTranscriptionsMapState } from "@shared/state/editor/editor.hooks";

import { FileLanguage, Language, translationLanguages } from "@getsubly/common";
import { useDownloadMenu } from "@media-editor/contexts/download-menu.context";
import { RiCheckLine } from "@remixicon/react";

export const LanguageDropdown: React.FC = () => {
  const { languages, toggleLanguage } = useDownloadMenu();
  const { hasPermission } = usePlanPermissions(SublyPlan.Pro);
  const transcriptionsMap = useActiveMediaTranscriptionsMapState();

  const hasSelected = (lang: Language): boolean => {
    return languages.some((l) => l.languageCode === lang.languageCode);
  };

  const originalLanguage = transcriptionsMap?.originalSubtitles;
  const originalTranscription = transcriptionsMap?.originalTranscription;

  const existingFileLanguages: FileLanguage[] = originalLanguage ? [originalLanguage] : [];
  existingFileLanguages.push(
    ...(transcriptionsMap?.subtitlesTranslations ?? []),
    ...(transcriptionsMap?.transcriptionTranslations ?? [])
  );

  if (originalTranscription) {
    existingFileLanguages.push(originalTranscription);
  }
  const existingLanguages: Language[] = existingFileLanguages
    .map(({ language, languageCode, countryCode, flagCode }) => ({
      language,
      languageCode,
      countryCode,
      flagCode
    }))
    .reduce((acc: Language[], current: Language) => {
      current.languageCode && !acc.some((item) => item.languageCode === current.languageCode) && acc.push(current);
      return acc;
    }, []);

  const possibleLanguages = [...translationLanguages]
    .reduce((acc: Language[], current) => {
      !acc.some((item) => item.languageCode === current.languageCode) && acc.push(current);
      return acc;
    }, [])
    .filter((l) => !existingLanguages.some((fl) => fl.languageCode === l.languageCode));

  if (!transcriptionsMap || !transcriptionsMap.originalSubtitles) {
    return null;
  }

  const renderOption = (lang: Language) => {
    const isSelected = hasSelected(lang);

    const isOriginal = lang.languageCode === originalLanguage?.fileId;
    const disabled = !hasPermission && !isOriginal;

    return (
      <DropdownItem
        key={lang.languageCode}
        className={classNames("!tw-p-0", { ["tw-bg-primary-50"]: isSelected })}
        disabled={disabled}
        keepOpenOnClick
        onClick={() => toggleLanguage(lang)}
      >
        <div className="tw-flex tw-w-full tw-justify-between tw-px-4 tw-py-2.5">
          <div className="tw-flex tw-gap-2">
            <span className={`fi fi-${lang.flagCode} tw-shrink-0`} />
            {lang.language}
          </div>
          {isSelected && <RiCheckLine className="tw-ml-2 tw-text-primary-500" />}
        </div>
      </DropdownItem>
    );
  };
  const existingLanguagesOptions = existingLanguages.map(renderOption);
  const possibleLanguageOptions = possibleLanguages.map(renderOption);

  const getLabel = (): React.ReactNode => {
    if (languages.length === 0) {
      return "No language selected";
    } else if (languages.length === 1) {
      const option = languages[0];

      return (
        <>
          <span className={`fi fi-${option.flagCode} tw-shrink-0`} />
          {option.language}
        </>
      );
    }

    return `${languages.length} selected languages`;
  };

  return (
    <Dropdown>
      <DropdownButton className="tw-w-full" variant="secondary">
        {getLabel()}
      </DropdownButton>
      <DropdownMenu className="tw-w-[867px] tw-min-w-[400px]" placement="bottom-start">
        <div className="tw-max-h-[400px] tw-overflow-auto">
          <DropdownHeader>Your media languages</DropdownHeader>
          {existingLanguagesOptions}
          <DropdownDivider />
          <DropdownHeader>Other languages</DropdownHeader>
          {possibleLanguageOptions}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
