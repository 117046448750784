import React from "react";

import { Loader } from "@shared/primitives/loader";
import { Modal } from "@shared/primitives/modal";

export const WorkspaceLoadingModal: React.FC = () => {
  return (
    <Modal disableBackgroundDismiss>
      <h5 className="tw-mb-4 tw-flex tw-items-center tw-justify-center tw-text-center tw-text-h5">
        We are switching your account 😎
      </h5>

      <p className="tw-text-center tw-text-black">
        Please hold tight and take a sip from your caipirinha 🍹 while we load your workspace!
      </p>

      <p className="tw-text-center tw-text-black">
        <Loader className="tw-mr-2" />
      </p>
    </Modal>
  );
};
