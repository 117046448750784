import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { Integration, unlinkAppById } from "@frontend/api/apps.service";

import { useAnalyticsWithAuth } from "@shared/hooks/use-analytics-with-auth";
import { App } from "@shared/interfaces/app";
import { Button } from "@shared/primitives/button";
import { Modal } from "@shared/primitives/modal";
import { getAgoDate } from "@shared/utils/dates";

import { AppModalContainer } from "./app-modal/app-modal";

import styles from "./app-modal/app-modal.module.scss";

interface OneDriveModalProps {
  app: App;
  connections: Integration[];
  closeModal: (reload?: boolean) => void;
}
export const OneDriveModal: React.FC<OneDriveModalProps> = ({ app, connections, closeModal }) => {
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const navigate = useNavigate();

  const onDismiss = () => {
    trackEventWithAuth(`${app.name} / Cancel`);
    closeModal();
  };

  const onMainCta = () => {
    trackEventWithAuth(`${app.name} / Connect`);
    closeModal();
    if (app.modal.openNewTab) {
      window.open(app.modal.link, "_blank");
    } else {
      navigate(app.modal.link);
    }
  };

  const onDisconnect = async (id: string) => {
    trackEventWithAuth(`${app.name} / Disconnect`);
    try {
      const success = await unlinkAppById(id);
      if (success && connections.length === 1) {
        closeModal(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (connections.length > 0) {
    return (
      <Modal onDismiss={onDismiss} className={classNames(styles["modal"], "tw-min-w-[700px]")}>
        <OneDriveConnectionsContainer connections={connections} onDisconnect={onDisconnect} onDismiss={onDismiss} />
      </Modal>
    );
  }

  return (
    <Modal onDismiss={onDismiss} className={styles["modal"]}>
      <AppModalContainer app={app} onConnect={onMainCta} onDismiss={onDismiss} />
    </Modal>
  );
};

interface OneDriveConnectionsContainerProps {
  connections: Integration[];
  onDisconnect: (id: string) => void;
  onDismiss: () => void;
}

export const OneDriveConnectionsContainer: React.FC<OneDriveConnectionsContainerProps> = ({
  connections,
  onDisconnect,
  onDismiss
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h5 className={classNames(styles.title)}>OneDrive connections</h5>
      </div>
      {connections.map((connection) => (
        <div key={connection.id} className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2 tw-p-2">
          <div className="tw-flex tw-flex-1 tw-flex-row tw-justify-between tw-gap-2">
            <div>{connection?.metadata?.email}</div>
            <div>{getAgoDate(connection.createdAt)}</div>
          </div>
          <Button variant="text-plain-destructive" onClick={() => onDisconnect(connection.id)} size="40">
            Disconnect
          </Button>
        </div>
      ))}
      <div className="tw-mt-6 tw-flex tw-w-full tw-justify-end">
        <Button variant="secondary" onClick={onDismiss} className={classNames("tw-mr-4", styles.button)} size="40">
          Cancel
        </Button>
      </div>
    </div>
  );
};
