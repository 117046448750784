import React from "react";
import classNames from "classnames";

import { SettingsCard } from "../../settings-card/settings-card";

const bytesToGB = (bytes: number) => {
  const gb = bytes / 1024 / 1024 / 1024;
  return Math.round(gb * 100) / 100;
};

interface StorageCardProps {
  totalStorage: number;
  usedStorage: number;
  className?: string;
}

export const StorageCard: React.FC<StorageCardProps> = ({ totalStorage, usedStorage, className }) => {
  const humanTotal = bytesToGB(totalStorage) + "GB";
  const humanUsed = bytesToGB(usedStorage) + "GB";
  const usedStoragePercent = (usedStorage / totalStorage) * 100;

  return (
    <SettingsCard className={classNames("tw-w-full tw-max-w-full tw-gap-2", className)}>
      <SettingsCard.Header className="tw-flex tw-w-full tw-items-center">
        <span className="tw-font-medium">Storage used</span>

        <div className="tw-ml-auto tw-text-sm tw-font-thin tw-text-neutral-500">
          {humanUsed}/{humanTotal}
        </div>
      </SettingsCard.Header>
      <SettingsCard.Body>
        <div className="tw-relative tw-h-5 tw-w-full tw-overflow-hidden tw-rounded-4 tw-bg-[#E9EAEC]">
          <div
            className="tw-absolute tw-left-0 tw-h-full tw-bg-primary-500"
            style={{ width: usedStoragePercent + "%" }}
          />
        </div>
      </SettingsCard.Body>
    </SettingsCard>
  );
};
