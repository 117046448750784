import React from "react";

import { ForgotPassword } from "@frontend/components/auth/forgot-password";
import { AuthLayout } from "@frontend/layouts/auth.layout";

export const ForgotPasswordPage: React.FC = () => {
  return (
    <AuthLayout>
      <ForgotPassword />
    </AuthLayout>
  );
};
