import axios from "axios";

import { getAccessToken } from "@frontend/api/auth.service";
import { handleError } from "@frontend/api/handle-error";
import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { Folder } from "@shared/state/dashboard/dashboard.store";

const baseURL = `${config.apiUrl}/api/v1`;

interface CreateFolderOptions {
  isPublic?: boolean;
  name?: string;
  parentId?: string | null;
  groupId?: string | null;
}
interface CreateFolderResponse {
  folder: Folder;
}
export const createFolder = async (options: CreateFolderOptions = {}): Promise<Folder> => {
  const { isPublic, name = "Untitled folder", parentId, groupId } = options;

  try {
    const folder = { name, isPublic, parentId, groupId };

    const {
      data: { folder: newFolder }
    } = await axios.post<CreateFolderResponse>(`/${getAccountId()}/folders`, folder, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    return newFolder;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const updateFolder = async (folder: Folder): Promise<void> => {
  try {
    await axios.put<CreateFolderResponse>(`/${getAccountId()}/folders/${folder.id}`, folder, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const deleteFolder = async (folderId: string): Promise<void> => {
  try {
    await axios.delete(`/${getAccountId()}/folders/${folderId}`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });
  } catch (error) {
    handleError(error);
  }
};

export const moveFolder = async (folderId: string, targetParentId: string): Promise<void> => {
  try {
    await axios.put(
      `/${getAccountId()}/folders/${folderId}/move`,
      { folderId, targetParentId, order: 0 },
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );
  } catch (error) {
    handleError(error);
    throw error;
  }
};
