import React from "react";
import classNames from "classnames";
import { format, intervalToDuration } from "date-fns";

import { IZoomRecording, IZoomRecordingFile } from "@frontend/api/apps.service";

import { EyeOutlineIcon as EyeIcon, MicrophoneIcon, VideoCameraIcon } from "@shared/components/icons";
import { Badge } from "@shared/primitives/badge";
import { Button } from "@shared/primitives/button";
import { Checkbox } from "@shared/primitives/checkbox";
import { durationToString } from "@shared/utils/time";

import styles from "./zoom.module.scss";

interface IZoomMeetingCardProps {
  recording: IZoomRecording;
  selected: string[];
  onChange: (id: string) => void;
}

interface MeetingDetailsProps {
  start: Date;
  end: Date;
  playUrl: string;
}

export const MeetingDetails: React.FC<MeetingDetailsProps> = ({ start, end, playUrl }) => {
  const duration = intervalToDuration({
    start,
    end
  });

  return (
    <div className={styles.box}>
      <div className={classNames(styles.duration, styles.limit)}>
        <div className={styles.label}>Duration</div>
        <div className={styles.value}>{durationToString(duration)}</div>
      </div>
      <div className={classNames(styles.start, styles.limit)}>
        <div className={styles.label}>Start time</div>
        <div className="tw-flex tw-flex-row">
          <div className={styles.value}>{format(start, "hh:mm")}</div>
          <div className={classNames(styles.date, "tw-ml-2")}>{format(start, "dd/MM/yyyy")}</div>
        </div>
      </div>
      <div className={classNames(styles.end, styles.limit)}>
        <div className={styles.label}>End time</div>
        <div className="tw-flex tw-flex-row">
          <div className={styles.value}>{format(end, "hh:mm")}</div>
          <div className={classNames(styles.date, "tw-ml-2")}>{format(end, "dd/MM/yyyy")}</div>
        </div>
      </div>
      <div className={classNames(styles.preview, styles.limit)}>
        <Button variant="secondary" className={styles["preview-button"]} type="link" isBlank to={playUrl} size="36">
          <>
            <EyeIcon />
            Preview
          </>
        </Button>
      </div>
    </div>
  );
};

interface ZoomMeetingTileProps {
  file: IZoomRecordingFile;
  selected: boolean;
  onChange: (checked: boolean) => void;
}

export const ZoomMeetingTile: React.FC<ZoomMeetingTileProps> = ({ file, selected, onChange }) => {
  const isAudio = file.fileType === "M4A";

  return (
    <div
      className={classNames(styles.tile, {
        [styles.tileSelected]: selected
      })}
    >
      <div className="tw-flex tw-flex-row tw-items-center">
        <div className="tw-w-full">
          <div className="tw-flex tw-items-center">
            <Badge
              className={classNames(styles["type-badge"], {
                [styles["type-badge-audio"]]: isAudio
              })}
            >
              {isAudio ? <MicrophoneIcon /> : <VideoCameraIcon />}
              {isAudio ? "Audio only" : "Video"}
            </Badge>

            <Button
              variant="secondary"
              className={classNames(styles["preview-button"], styles["mobile-preview-button"])}
              type="link"
              isBlank
              to={file.playUrl}
              size="36"
            >
              <>
                <EyeIcon />
                Preview
              </>
            </Button>
          </div>

          <MeetingDetails start={new Date(file.start)} end={new Date(file.end)} playUrl={file.playUrl} />
        </div>
        <Checkbox className="tw-ml-12 tw-mr-12" checked={selected} onChange={() => onChange(!selected)} />
      </div>
    </div>
  );
};

export const ZoomMeetingCard: React.FC<IZoomMeetingCardProps> = ({ recording, selected, onChange }) => {
  const renderFiles = recording.files
    .filter((f) => f.fileType !== "M4A")
    .map((f) => (
      <ZoomMeetingTile
        key={f.id}
        file={f}
        selected={selected.some((s) => s === f.id)}
        onChange={() => onChange(f.id)}
      />
    ));

  return (
    <div className={styles.meetingCard}>
      <div className={styles.meetingHeader}>
        <h6 className={styles.topic}>{recording.topic}</h6>
        <span className="tw-ml-auto">
          <span>ID:</span>
          <span className={styles.meetingNumber}>{recording.meetingNumber}</span>
        </span>
      </div>
      {renderFiles}
    </div>
  );
};
