import React from "react";

import { XMarkIcon } from "@shared/components/icons";
import { useQuery } from "@shared/hooks/use-query";
import { Input, InputSize } from "@shared/primitives/input";

import { RiArrowRightLine } from "@remixicon/react";

export type DashboardMediaSearchProps = {
  size?: InputSize;
};

export const DashboardMediaSearch: React.FC<DashboardMediaSearchProps> = ({ size = "32" }) => {
  const { queryParams, updateQueryParams } = useQuery();
  const [searchValue, setSearchValue] = React.useState(queryParams.q || "");

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (queryParams.q && queryParams.q !== searchValue) {
      setSearchValue(queryParams.q);
    }
  }, [queryParams.q]);

  const handleClearSearch = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    updateQueryParams({ q: null });
    setSearchValue("");
  };

  const icon = React.useMemo(() => {
    if (queryParams.q && searchValue === queryParams.q) {
      return <XMarkIcon className="tw-cursor-pointer" onClick={handleClearSearch} />;
    }

    if ((searchValue && searchValue !== queryParams.q) || (queryParams.q && !searchValue)) {
      return <RiArrowRightLine className="tw-cursor-pointer" onClick={() => updateQueryParams({ q: searchValue })} />;
    }

    return undefined;
  }, [searchValue, queryParams.q]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      updateQueryParams({ q: searchValue });
      inputRef?.current?.blur();
    }
  };

  return (
    <Input
      ref={inputRef}
      className="!tw-w-[240px]"
      sizeH={size}
      rightIcon={icon}
      placeholder="Search files..."
      value={searchValue}
      onChange={({ target }) => setSearchValue(target.value)}
      onKeyUp={handleKeyPress}
    />
  );
};
