import React from "react";

import { MediaCard } from "@frontend/components/dashboard/dashboard-explorer/media-card/media-card";

import { EnrichedMediaListItem } from "@shared/interfaces/media";

interface MediaGridProps {
  mediaList: EnrichedMediaListItem[];
}
export const MediaGrid: React.FC<MediaGridProps> = ({ mediaList }) => {
  return (
    <div className="tw-grid tw-grid-cols-1 tw-gap-x-3 tw-gap-y-6 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4">
      {mediaList.map((media) => (
        <MediaCard media={media} key={media.mediaId} />
      ))}
    </div>
  );
};
