"use client";

import React from "react";
import classNames from "classnames";

import {
  FullScreenExitIcon,
  FullScreenIcon,
  PauseIcon,
  PlaybackSpeedIcon,
  PlayIcon,
  VolumeIcon,
  VolumeMutedIcon
} from "@shared/components/icons";
import { Range } from "@shared/primitives/range/range";
import { ToolTip } from "@shared/primitives/tooltip";

import Icon from "@mdi/react";
import { useMediaPlayer } from "@media-player/contexts/media-player.context";
import {
  useMediaPlayerCurrentTimeState,
  useMediaPlayerDurationState,
  useMediaPlayerFullscreenState,
  useMediaPlayerHasHoursState,
  useMediaPlayerMutedState,
  useMediaPlayerPlayingState
} from "@media-player/state/media-player/media-player.hooks";
import { mediaPlayerRepository } from "@media-player/state/media-player/media-player.state";
import { format } from "@media-player/utils/time";

interface MediaPlayerControlsProps {
  className?: string;
  isTimeline?: boolean;
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  rightSlotFullscreen?: React.ReactNode;
  showBorder?: boolean;
}

export interface FSDocs extends HTMLDivElement {
  mozRequestFullScreen(): Promise<void>;
  webkitRequestFullscreen(): Promise<void>;
}

export const MediaPlayerControls: React.FC<MediaPlayerControlsProps> = React.memo(
  ({ className, leftSlot, rightSlot, rightSlotFullscreen }) => {
    const isFullscreen = useMediaPlayerFullscreenState();
    return (
      <div className={classNames("tw-relative tw-top-0 tw-flex tw-h-12 tw-w-full tw-bg-white", className)}>
        <SeekerBar className="tw-absolute tw--top-0.5 tw-left-0 tw-right-0 tw-z-10 tw--translate-y-1/2 tw-transform" />
        <div className="tw-flex tw-w-full tw-items-center tw-px-1">
          <div className="tw-mr-auto tw-flex tw-items-center">
            <PlayPauseButton />
            <VolumeButton className="tw-mr-2" />
            <TimeSignatures />
            {leftSlot}
          </div>

          <div className="tw-ml-auto tw-flex tw-items-center">
            {rightSlot}
            {isFullscreen ? rightSlotFullscreen : null}
            <ToolTip text="Playback speed" place="top">
              <PlaybackSpeedButton />
            </ToolTip>
            <FullScreenButton />
          </div>
        </div>

        <div className="tw-flex tw-flex-row tw-gap-3"></div>
      </div>
    );
  }
);

type SeekerBarProps = {
  className?: string;
};

const SeekerBar: React.FC<SeekerBarProps> = ({ className }) => {
  const { mediaPlayerRef } = useMediaPlayer();
  const durationValue = useMediaPlayerDurationState();
  const currentTime = useMediaPlayerCurrentTimeState();
  const isPlaying = useMediaPlayerPlayingState();

  const wasPlaying = React.useRef(false);
  const [isDragging, setIsDragging] = React.useState(false);
  const [time, setTime] = React.useState(currentTime);

  React.useEffect(() => {
    setTime(currentTime);
  }, [currentTime]);

  // Pause while dragging
  React.useEffect(() => {
    if (!mediaPlayerRef.current) {
      return;
    }

    if (isDragging) {
      wasPlaying.current = isPlaying;
      mediaPlayerRef.current.pause();
    } else if (wasPlaying.current && !isPlaying) {
      mediaPlayerRef.current.play();
      wasPlaying.current = true;
    }
  }, [isDragging]);

  const handleScrub = (value: number) => {
    if (!mediaPlayerRef.current) {
      return;
    }

    mediaPlayerRef.current.setTime(value);
    setTime(value);
  };

  return (
    <div className={className}>
      <Range
        className="tw-flex tw-w-full tw-cursor-pointer"
        value={time}
        max={durationValue}
        onChange={(e) => handleScrub(e)}
        onIsDragging={setIsDragging}
        progressColor="#0472E7"
        handleBgColor="var(--color-primary-500)"
        handleBorderColor="transparent"
        expandOnHover
        revealHandleOnHover
      />
    </div>
  );
};

const TimeSignatures: React.FC = () => {
  const isHourLong = useMediaPlayerHasHoursState();
  const durationValue = useMediaPlayerDurationState();
  const currentTime = useMediaPlayerCurrentTimeState();

  const formattedTimeElapsed =
    currentTime > 0 ? format(currentTime * 1000, isHourLong) : isHourLong ? "00:00:00.00" : "00:00.00";
  const formattedDuration = format(durationValue * 1000, isHourLong);
  const spanWidth = isHourLong ? "tw-w-[80px]" : "tw-w-[57px]";

  return (
    <span className="tw-whitespace-nowrap tw-font-mono tw-text-xs tw-font-light tw-text-neutral-500">
      <span className={classNames(spanWidth, "tw-inline-block tw-text-right")}>{formattedTimeElapsed}</span>
      <span className="tw-mx-0.5">/</span>
      <span className={classNames(spanWidth, "tw-inline-block")}>{formattedDuration}</span>
    </span>
  );
};

const PlayPauseButton: React.FC = () => {
  const { mediaPlayerRef } = useMediaPlayer();
  const isPlaying = useMediaPlayerPlayingState();
  const icon = isPlaying ? <PlayIcon /> : <PauseIcon />;
  return (
    <ToolTip text={isPlaying ? "Pause" : "Play"} place="top">
      <PlayerButton
        onClick={() => mediaPlayerRef.current?.togglePlay()}
        className="tw-text-neutral-500 hover:tw-text-neutral-900"
        onKeyDown={(e) => {
          // Ignore space bar when play button is focused and use the hotkey to play/pause.
          // This avoids double toggling play/pause.
          // https://take1.atlassian.net/browse/TA-2794
          if (e.key === " ") {
            e.preventDefault();
          }
        }}
        size="24px"
      >
        {icon}
      </PlayerButton>
    </ToolTip>
  );
};

interface VolumeButtonProps {
  className?: string;
}
const VolumeButton: React.FC<VolumeButtonProps> = ({ className }) => {
  const { mediaPlayerRef } = useMediaPlayer();
  const { isMuted } = useMediaPlayerMutedState();

  const handleMute = () => {
    if (!mediaPlayerRef.current) {
      return;
    }

    mediaPlayerRef.current.toggleMute();
  };

  return (
    <ToolTip text={isMuted ? "Unmute" : "Mute"} place="top">
      <PlayerButton
        className={classNames("tw-text-neutral-500 hover:tw-text-neutral-900", className)}
        onClick={handleMute}
      >
        {isMuted ? <VolumeMutedIcon className="tw-h-5 tw-w-5" /> : <VolumeIcon className="tw-h-5 tw-w-5" />}
      </PlayerButton>
    </ToolTip>
  );
};

const PLAYBACK_SPEEDS = [0.5, 1, 1.5, 2];

type PlaybackSpeedButtonProps = Omit<PlayerButtonProps, "icon" | "onClick">;
const PlaybackSpeedButton: React.FC<PlaybackSpeedButtonProps> = (props) => {
  const { mediaPlayerRef } = useMediaPlayer();
  const [speed, setSpeed] = React.useState(1);

  const handleSpeed = () => {
    const speeds = [...PLAYBACK_SPEEDS, ...PLAYBACK_SPEEDS];
    const currentSpeed = speeds.findIndex((s) => s === speed);
    const newSpeed = speeds[currentSpeed + 1];
    setSpeed(newSpeed);

    if (mediaPlayerRef.current) {
      mediaPlayerRef.current.setPlaybackRate(newSpeed);
    }
  };

  return (
    <PlayerButton {...props} onClick={handleSpeed} className="tw-text-neutral-500 hover:tw-text-neutral-900">
      <PlaybackSpeedIcon className="tw-min-[8px] tw-w-2" />
      <span className="tw-ml-[2px] tw-font-medium">{speed}</span>
    </PlayerButton>
  );
};

const FullScreenButton: React.FC = () => {
  const { mediaPlayerRef, fullscreenRef } = useMediaPlayer();

  const isFullscreen = useMediaPlayerFullscreenState();

  const fsExit = document as Document & {
    mozCancelFullScreen(): Promise<void>;
    mozFullScreenElement: Element | null;
    webkitExitFullscreen(): Promise<void>;
    webkitFullscreenElement: Element | null;
  };

  React.useEffect(() => {
    const handleFullscreenChange = () => {
      const updateFullScreen = fsExit.fullscreenElement != null || fsExit.webkitFullscreenElement != null;
      mediaPlayerRepository.updateState({ isFullscreen: updateFullScreen });
    };

    ["", "webkit"].forEach((prefix) => window.addEventListener(prefix + "fullscreenchange", handleFullscreenChange));

    return () => {
      ["", "webkit"].forEach((prefix) =>
        window.removeEventListener(prefix + "fullscreenchange", handleFullscreenChange)
      );
    };
  }, []);

  const handleFullscreen = () => {
    if (!mediaPlayerRef.current) {
      return;
    }

    if (isFullscreen) {
      if (fsExit.exitFullscreen) {
        fsExit.exitFullscreen();
      } else if (fsExit.mozCancelFullScreen) {
        /* Firefox */
        fsExit.mozCancelFullScreen();
      } else if (fsExit.webkitExitFullscreen) {
        /* Safari */
        fsExit.webkitExitFullscreen();
      }
    } else {
      if (fullscreenRef?.current?.requestFullscreen) {
        fullscreenRef.current?.requestFullscreen();
      } else if (fullscreenRef?.current?.mozRequestFullScreen) {
        /* Firefox */
        fullscreenRef.current?.mozRequestFullScreen();
      } else if (fullscreenRef?.current?.webkitRequestFullscreen) {
        /* Safari */
        fullscreenRef.current?.webkitRequestFullscreen();
      }
    }

    mediaPlayerRepository.updateState({ isFullscreen: !isFullscreen });
  };

  return (
    <ToolTip text={isFullscreen ? "Exit full screen" : "Full screen"} place="top">
      <PlayerButton onClick={handleFullscreen} className="tw-text-neutral-500 hover:tw-text-neutral-900">
        {isFullscreen ? <FullScreenExitIcon className="tw-h-5 tw-w-5" /> : <FullScreenIcon className="tw-h-5 tw-w-5" />}
      </PlayerButton>
    </ToolTip>
  );
};

interface PlayerButtonProps {
  icon?: string;
  onClick: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  size?: string;
  className?: string;
  shouldPreventDefault?: boolean;
  children?: React.ReactNode;
}
const PlayerButton: React.FC<PlayerButtonProps> = ({
  icon,
  onClick,
  onKeyDown,
  size = "24px",
  className,
  children
}) => {
  return (
    <button
      onClick={onClick}
      onKeyDown={onKeyDown}
      className={classNames(
        "tw-m-0 tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-md tw-border-none tw-bg-white tw-p-0 hover:tw-transition-colors hover:tw-ease-in",
        className
      )}
    >
      {icon ? <Icon path={icon} size={size} /> : children}
    </button>
  );
};

export default MediaPlayerControls;
