import React from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { LongPressDetectEvents, useLongPress } from "use-long-press";

import { deleteMedia } from "@frontend/api/media.service";
import { EN } from "@frontend/assets/i18n/en";
import { useSelectedFiles } from "@frontend/contexts/selected-files.context";
import { MEDIA_PATH, SETTINGS_BILLING_PATH } from "@frontend/routes";

import { FilmSnippetsIcon, OldSubtitleIcon, TranslationsIcon } from "@shared/components/icons";
import { ThumbnailImage } from "@shared/components/thumbnail-image/thumbnail-image";
import { useAccounts } from "@shared/hooks/use-accounts";
import { usePlan } from "@shared/hooks/use-plan";
import { EnrichedMediaListItem, MediaActiveJob } from "@shared/interfaces/media";
import { Button } from "@shared/primitives/button";
import { Checkbox } from "@shared/primitives/checkbox";
import { useDropdown } from "@shared/primitives/dropdown";
import { notificationError } from "@shared/primitives/notification";
import { ProgressBar } from "@shared/primitives/progress-bar";
import { ToolTip } from "@shared/primitives/tooltip";
import { userPresenceQuery } from "@shared/state/user-presence";
import { pluralize } from "@shared/utils/strings";

import { JobType, MediaStatus } from "@getsubly/common";
import { useObservable } from "@mindspace-io/react";
import { RiErrorWarningFill } from "@remixicon/react";

import { MediaCardDropdown } from "./media-card-dropdown";

const JOB_STATUS_LABELS: Partial<Record<JobType, string>> = {
  [JobType.Upload]: "Uploading",
  [JobType.Conversion]: "Preparing your file...",
  [JobType.Transcribe]: "Transcribing subtitles",
  [JobType.HumanTranscribe]: "Human transcribing"
};

interface FigureContainerProps {
  children: React.ReactNode;
}
export const MediaCardTop = (props: FigureContainerProps) => {
  return (
    <div className="tw-relative tw-m-0 tw-block tw-rounded-t-[9px] tw-bg-neutral-100 tw-pt-[56%]">{props.children}</div>
  );
};

interface MediaCardReadyProps {
  media: EnrichedMediaListItem;
}
export const MediaCardReadyView = (props: MediaCardReadyProps) => {
  const [activeUser] = useObservable(userPresenceQuery.selectActiveUser(props.media.mediaId));
  const { isViewer } = useAccounts();
  const { isFree } = usePlan();
  const navigate = useNavigate();
  const { selectedFiles, setSelectedFiles } = useSelectedFiles();

  const isSelected = React.useMemo(
    () => selectedFiles.includes(props.media.mediaId),
    [selectedFiles.length, props.media.mediaId]
  );

  const toggleSelected = React.useCallback(() => {
    if (isSelected) {
      setSelectedFiles((ids) => [...ids].filter((id) => id !== props.media.mediaId));
    } else {
      setSelectedFiles((ids) => [...ids, props.media.mediaId]);
    }
  }, [isSelected, selectedFiles.length]);

  const handleLongPress = useLongPress(toggleSelected, {
    detect: LongPressDetectEvents.TOUCH
  });
  const isMediaReady = props.media.status === MediaStatus.Ready;

  const mediaLink = React.useMemo(() => {
    if (!isMediaReady || selectedFiles.length) {
      return "#";
    }

    return `${MEDIA_PATH}/${props.media.mediaId}`;
  }, [isMediaReady, selectedFiles.length]);

  const hasTranslations = props.media.translationLanguages?.length > 0;
  const snippets = props.media?.assConfig?.snippets?.length ?? 0;
  const hasSnippets = snippets > 0;

  const handleClickCard = () => {
    if (selectedFiles.length) {
      toggleSelected();
    } else {
      navigate(mediaLink);
    }
  };

  const { isOpen } = useDropdown();

  return (
    <div onClick={handleClickCard} {...handleLongPress()}>
      <ThumbnailImage
        src={props.media.thumbnailUrl}
        alt={props.media.name}
        mediaType={props.media.type}
        className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      />
      <div className="tw-absolute tw-left-3 tw-top-[11px]">
        {props.media.languageCode && (
          <MediaBadge type="transcription" text="1 subtitle" icon={<OldSubtitleIcon />} count={1} />
        )}
        {hasTranslations && (
          <MediaBadge
            type="languages"
            text={`${props.media.translationLanguages?.length} ${pluralize(
              props.media.translationLanguages?.length,
              "language"
            )}`}
            icon={<TranslationsIcon />}
            count={props.media.translationLanguages?.length}
          />
        )}
        {hasSnippets && (
          <MediaBadge
            type="snippets"
            text={`${snippets} ${pluralize(snippets, "snippet")}`}
            icon={<FilmSnippetsIcon />}
            count={snippets}
          />
        )}
      </div>

      <MediaCardOverlay isDropdownOpen={isOpen} toolbarSelected={isSelected}>
        <div className="tw-absolute tw-right-4 tw-top-4 tw-flex tw-flex-row tw-items-center tw-gap-4">
          <Checkbox stopPropagation onChange={() => toggleSelected()} checked={isSelected} large />
          {!selectedFiles.length && <MediaCardDropdown media={props.media} />}
        </div>

        <MediaCardActionButton
          isSelected={isSelected}
          selectedFilesCount={selectedFiles.length}
          isViewer={isViewer}
          isFree={isFree}
          hasActiveUser={Boolean(activeUser)}
          mediaLink={mediaLink}
        />
      </MediaCardOverlay>
    </div>
  );
};

interface MediaCardProgressViewProps {
  media: EnrichedMediaListItem;
  job: MediaActiveJob;
}
export const MediaCardProgressView = (props: MediaCardProgressViewProps) => {
  const uploadProgress = props.job.percentage;
  const isMediaReady = MediaStatus.Ready === props.media.status || props.media.isTranscribing;
  const mediaLink = isMediaReady ? `${MEDIA_PATH}/${props.media.mediaId}` : "#";

  return (
    <Link to={mediaLink} className={classNames({ "tw-cursor-default": !isMediaReady })}>
      <ThumbnailImage
        src={props.media.thumbnailUrl}
        alt={props.media.name}
        mediaType={props.media.type}
        className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      />
      <MediaCardOverlay showingProgress>
        <p className="tw-mb-1 tw-text-center tw-text-sm tw-text-black">{JOB_STATUS_LABELS[props.job.type] ?? ""}</p>
        {Boolean(uploadProgress) && <h6 className="tw-mb-2 tw-text-center tw-text-h6">{`${props.job.percentage}%`}</h6>}
        <ProgressBar progress={uploadProgress ?? 0} loading />
      </MediaCardOverlay>
    </Link>
  );
};

interface MediaCardErrorViewProps {
  media: EnrichedMediaListItem;
  errorLabel: string;
  errorMessage: string | JSX.Element;
  showUpgrade?: boolean;
  showDelete?: boolean;
}
export const MediaCardErrorView = (props: MediaCardErrorViewProps) => {
  const [loading, setLoading] = React.useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteMedia(props.media.mediaId);
    } catch (e) {
      notificationError(EN.error.defaultMessage);
    } finally {
      setLoading(false);
    }
  };

  const buttonCount = [props.showUpgrade, props.showDelete].filter(Boolean).length;

  return (
    <div className="tw-absolute tw-bottom-0 tw-top-0 tw-w-full tw-items-center tw-justify-center tw-rounded-t-10 tw-bg-destructive-50 tw-p-4">
      <div className="tw-flex tw-w-full tw-flex-col tw-overflow-auto">
        <div className="tw-mb-4 tw-flex tw-items-center tw-gap-1">
          <RiErrorWarningFill className="tw-h-5 tw-w-5 tw-text-destructive-600" />
          <label className="tw-font-semibold">{props.errorLabel}</label>
        </div>
        <p className="tw-m-0 tw-text-xs tw-font-medium tw-text-neutral-900">{props.errorMessage}</p>
      </div>
      {buttonCount > 0 && (
        <div
          className={classNames("tw-mt-4 tw-flex tw-w-full tw-justify-between", { "tw-items-end": buttonCount > 1 })}
        >
          {props.showUpgrade && (
            <Button
              variant="destructive"
              className="tw-cursor-pointer"
              onClick={handleDelete}
              loading={loading}
              size="36"
            >
              Delete
            </Button>
          )}
          {props.showUpgrade && (
            <Button
              variant="primary"
              type="link"
              to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }}
              size="36"
            >
              Upgrade
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

interface MediaBadgeProps {
  text: string;
  icon: React.ReactNode;
  type: "languages" | "transcription" | "snippets";
  count: number;
}
const MediaBadge = (props: MediaBadgeProps) => {
  return (
    <ToolTip text={props.text} place="top">
      <div className="tw-relative tw-z-[3] tw-mb-2 tw-box-border tw-flex tw-h-[26px]  tw-w-full tw-flex-row tw-items-center tw-rounded-4 tw-bg-neutral-50 tw-p-0">
        <div
          className={classNames(
            "tw-flex tw-h-full tw-w-1/2 tw-flex-[1_1_100%] tw-content-stretch tw-items-center tw-justify-center tw-rounded-l-4 tw-border tw-border-r-0 tw-border-aux-600 tw-bg-aux-500 tw-p-1 tw-text-white",
            { "tw-border-primary-700 tw-bg-primary-500": props.type === "snippets" }
          )}
        >
          {props.icon}
        </div>
        <div
          className={classNames(
            "tw-flex-[1_1_100%] tw-rounded-r-4 tw-border tw-border-l-0 tw-border-aux-600 tw-px-2 tw-py-[3px] tw-text-center tw-text-xs tw-leading-[18px] tw-text-aux-900",
            { "tw-border-primary-700": props.type === "snippets" }
          )}
        >
          {props.count}
        </div>
      </div>
    </ToolTip>
  );
};

interface MediaCardActionButtonProps {
  isSelected: boolean;
  selectedFilesCount: number;
  isViewer: boolean;
  isFree: boolean;
  hasActiveUser: boolean;
  mediaLink: string;
}

const MediaCardActionButton = (props: MediaCardActionButtonProps) => {
  const text = React.useMemo(() => {
    if (props.isSelected && props.selectedFilesCount) return "Deselect";
    if (!props.isSelected && props.selectedFilesCount) return "Select";
    if (props.isViewer || props.isFree) return "View";
    if (props.hasActiveUser) return "View only";
    return "Edit";
  }, [props.isSelected, props.selectedFilesCount, props.isViewer, props.isFree, props.hasActiveUser]);

  const mediaButtonClasses = classNames(
    "tw-border-white !tw-bg-transparent tw-px-[18px] tw-py-2 !tw-text-white hover:!tw-bg-white hover:!tw-text-neutral-600",
    { "tw-hidden": props.selectedFilesCount }
  );

  return (
    <Button variant="secondary" type="link" className={mediaButtonClasses} to={props.mediaLink} size="36">
      {text}
    </Button>
  );
};

interface MediaCardOverlayProps {
  children: React.ReactNode;
  isDropdownOpen?: boolean;
  showingProgress?: boolean;
  toolbarSelected?: boolean;
  hasError?: boolean;
}
const MediaCardOverlay: React.FC<MediaCardOverlayProps> = (props: MediaCardOverlayProps) => {
  const mediaCardToolbarClasses = classNames(
    "tw-absolute tw-bottom-0 tw-top-0 tw-z-[3] tw-hidden tw-w-full tw-items-center tw-justify-center tw-rounded-t-10 tw-bg-transparent tw-p-[18px] tw-py-2 tw-text-white",
    "group-hover/media-card:!tw-flex group-hover/media-card:tw-border-white group-hover/media-card:tw-bg-[rgba(30,30,30,0.6)]",
    {
      "!tw-flex": props.isDropdownOpen || props.toolbarSelected,
      "!tw-flex !tw-flex-col !tw-bg-[rgba(255,255,255,0.8)] !tw-cursor-not": props.showingProgress,
      "tw-static tw-flex tw-h-full tw-flex-col tw-justify-between tw-bg-destructive-100 tw-p-4": props.hasError
    }
  );
  return <div className={mediaCardToolbarClasses}>{props.children}</div>;
};
