import React, { useState } from "react";

import { moveMedia } from "@frontend/api/media.service";

import { MagnifyingGlassIcon } from "@shared/components/icons";
import { useDashboard } from "@shared/hooks/use-dashboard";
import { Button } from "@shared/primitives/button";
import { Input } from "@shared/primitives/input";
import { NewModal } from "@shared/primitives/new-modal";
import { notificationSuccess } from "@shared/primitives/notification";
import { pluralize } from "@shared/utils/strings";

import { FolderTree } from "../dashboard/dashboard-sidebar/drives/folder-tree";

interface MoveMediaModalProps {
  mediaIds: string[];
  closeModal: (shouldClear?: boolean) => void;
  isEditor?: boolean;
}
export const MoveMediaModal: React.FC<MoveMediaModalProps> = ({ mediaIds, closeModal, isEditor = false }) => {
  const title = `Move ${isEditor ? "this" : mediaIds.length} ${pluralize(mediaIds.length, "media")} to folder`;
  return (
    <NewModal onDismiss={closeModal} showCloseButton title={title} disableBackgroundDismiss>
      <MoveMediaModalContainer mediaIds={mediaIds} closeModal={closeModal} isEditor={isEditor} />
    </NewModal>
  );
};

const MoveMediaModalContainer: React.FC<MoveMediaModalProps> = ({ mediaIds, closeModal }) => {
  const { activeFolder, privateDrive, teamspacesDrive, folders } = useDashboard();
  const [selectedFolder, setSelectedFolder] = useState(activeFolder);
  const [isLoading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const searchedFolders = React.useMemo(() => {
    return folders.filter((folder) => {
      if (!searchQuery) return false;
      return folder.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [searchQuery]);

  if (!teamspacesDrive || !privateDrive) return null;

  const handleMove = async () => {
    setLoading(true);
    try {
      await Promise.all(mediaIds.map((mediaId) => moveMedia(mediaId, selectedFolder?.id)));
      notificationSuccess(`Moved to "${selectedFolder?.name}"`);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    closeModal(true);
  };

  const preparedFolders = Boolean(searchQuery) ? searchedFolders : [teamspacesDrive, privateDrive];

  return (
    <div className="tw-flex tw-flex-col">
      <Input
        placeholder="Search folders"
        leftIcon={<MagnifyingGlassIcon className="tw-w-5" />}
        value={searchQuery}
        onChange={({ target }) => setSearchQuery(target.value)}
        wrapperClassName="tw-mb-2"
      />
      {Boolean(preparedFolders.length) && (
        <div className="tw-mb-4 tw-flex tw-flex-col tw-gap-0.25 tw-overflow-hidden">
          {preparedFolders.map((folder, i) => (
            <FolderTree
              folder={folder}
              selectedFolderId={selectedFolder?.id || null}
              onSelectFolder={setSelectedFolder}
              noIndent={searchedFolders.length > 0}
              showOptions={false}
              defaultExpanded
              key={i}
            />
          ))}
        </div>
      )}
      {!Boolean(preparedFolders.length) && (
        <div className="tw-my-4 tw-overflow-hidden tw-rounded-md tw-text-sm tw-text-neutral-700">
          <span className="tw-italic">No folders found</span>
        </div>
      )}

      <MoveButton onClick={handleMove} disabled={!selectedFolder} loading={isLoading} />
    </div>
  );
};

const MoveButton: React.FC<{
  onClick: () => void;
  disabled: boolean;
  loading: boolean;
}> = ({ onClick, disabled, loading }) => (
  <div className="tw-flex tw-justify-end">
    <Button variant="primary" onClick={onClick} disabled={disabled} loading={loading} size="40">
      Move to folder
    </Button>
  </div>
);
