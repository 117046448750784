import React from "react";
import classNames from "classnames";

import { Checkbox } from "@shared/primitives/checkbox/checkbox";

import { Transition } from "@headlessui/react";
import { RiArrowDownLine, RiArrowUpLine } from "@remixicon/react";

import { TableColumn, TableItem, TableProvider, useTableContext } from "./table.context";

type SortDirection = "asc" | "desc";
type ColumnWidth = number | string | undefined | null;

const parseColumnWidth = (width: ColumnWidth): string => {
  if (width === undefined || width === null) return "1fr";
  return typeof width === "number" ? `${width}px` : width;
};

interface ContainerProps {
  columns: TableColumn[];
  items: TableItem[];
  children: React.ReactNode;
  className?: string;
}
const Container: React.FC<ContainerProps> = ({ columns, items, children, className }) => {
  const [hasOverflow, setHasOverflow] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const hasHorizontalOverflow = containerRef.current.scrollWidth > containerRef.current.clientWidth;
        setHasOverflow(hasHorizontalOverflow);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  const columnWidthVar = columns.map((column) => parseColumnWidth(column.width)).join(" ");

  return (
    <TableProvider columns={columns} items={items}>
      <div
        ref={containerRef}
        className={classNames(className, "tw-grid tw-auto-rows-auto tw-rounded-[8px] tw-border tw-border-neutral-200")}
        style={{ gridTemplateColumns: columnWidthVar } as React.CSSProperties}
        data-has-overflow={hasOverflow}
      >
        {children}
      </div>
    </TableProvider>
  );
};

interface HeaderProps {
  children: (column: TableColumn[]) => React.ReactElement[];
  className?: string;
}
const Header: React.FC<HeaderProps> = ({ children, className }) => {
  const { columns } = useTableContext();

  return (
    <div className={classNames("tw-col-span-full tw-grid tw-grid-cols-subgrid", className)}>{children(columns)}</div>
  );
};

interface HeaderCellProps
  extends SortingProps,
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  sortable?: boolean;
  checkable?: boolean;
  checkboxChecked?: boolean;
  checkboxIndeterminate?: boolean;
  checkboxDisabled?: boolean;
  columnId: number | string;
  onClickSort?: () => void;
  onChangeCheckbox?: (checked: boolean) => void;
}
const HeaderCell: React.FC<HeaderCellProps> = ({
  sortable = false,
  checkable = false,
  checkboxChecked = false,
  checkboxIndeterminate = false,
  checkboxDisabled = false,
  isSorting = false,
  onClickSort,
  sortDirection,
  children,
  className,
  columnId,
  onClick,
  onChangeCheckbox,
  ...props
}) => {
  const { columns } = useTableContext();
  const column = columns.find((column) => column.id === columnId);
  const isSticky = column?.sticky === "right";

  return (
    <div
      className={classNames(
        "tw-flex tw-h-12 tw-items-center tw-gap-1 tw-bg-[inherit] tw-p-2 tw-text-sm tw-text-neutral-700",
        {
          "tw-group tw-cursor-pointer": sortable,
          "tw-sticky tw-right-0 tw-z-10 before:tw-absolute before:tw-inset-y-0 before:-tw-left-1.5 before:tw-w-1.5 before:tw-bg-gradient-to-l before:tw-from-black/[0.07] before:tw-to-transparent before:tw-opacity-0 before:tw-transition-opacity [div[data-has-overflow=true]_&]:before:tw-opacity-100":
            isSticky
        },
        className
      )}
      onClick={(e) => {
        if (sortable) onClickSort?.();
        onClick?.(e);
      }}
      {...props}
    >
      {checkable && (
        <Checkbox
          checked={checkboxChecked}
          indeterminate={checkboxIndeterminate}
          disabled={checkboxDisabled}
          onChange={onChangeCheckbox}
          stopPropagation
        />
      )}
      {children}
      {sortable && <Sorting isSorting={isSorting} sortDirection={sortDirection} />}
    </div>
  );
};

interface SortingProps {
  isSorting?: boolean;
  sortDirection?: SortDirection;
}
const Sorting: React.FC<SortingProps> = ({ isSorting, sortDirection }) => {
  if (!isSorting) {
    return <RiArrowDownLine className="tw-h-4 tw-w-4 tw-opacity-0 group-hover:tw-opacity-50" />;
  }

  const Icon = sortDirection === "asc" ? RiArrowUpLine : RiArrowDownLine;
  return <Icon className="tw-h-4 tw-w-4 group-hover:tw-opacity-50" />;
};

interface RowProps {
  children: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  hoverable?: boolean;
}
const Row: React.FC<RowProps> = ({ children, className, onClick, hoverable = false }) => {
  return (
    <div
      className={classNames(
        "tw-col-span-full tw-grid tw-min-h-[68px] tw-grid-cols-subgrid tw-bg-white tw-text-neutral-600 tw-transition-colors",
        { "hover:tw-bg-neutral-50": hoverable, "tw-cursor-pointer": onClick },
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

type CellProps = {
  children?: React.ReactNode;
  columnId: number | string;
  checkable?: boolean;
  checkboxChecked?: boolean;
  checkboxIndeterminate?: boolean;
  checkboxDisabled?: boolean;
  onCheckboxChange?: (checked: boolean) => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
const Cell: React.FC<CellProps> = ({
  className,
  columnId,
  children,
  checkable = false,
  checkboxChecked = false,
  checkboxIndeterminate = false,
  checkboxDisabled = false,
  onCheckboxChange,
  ...props
}) => {
  const { columns } = useTableContext();
  const column = columns.find((column) => column.id === columnId);
  const isSticky = column?.sticky === "right";

  return (
    <div
      className={classNames(
        "tw-flex tw-items-center tw-gap-1 tw-border-t tw-border-neutral-200 tw-bg-[inherit] tw-p-2 tw-text-sm",
        {
          "tw-sticky tw-right-0 tw-z-10 before:tw-absolute before:tw-inset-y-0 before:-tw-left-1.5 before:tw-w-1.5 before:tw-bg-gradient-to-l before:tw-from-black/[0.07] before:tw-to-transparent before:tw-opacity-0 before:tw-transition-opacity [div[data-has-overflow=true]_&]:before:tw-opacity-100":
            isSticky
        },
        className
      )}
      {...props}
    >
      {checkable && (
        <Checkbox
          checked={checkboxChecked}
          indeterminate={checkboxIndeterminate}
          disabled={checkboxDisabled}
          stopPropagation
          onChange={() => onCheckboxChange?.(!checkboxChecked)}
        />
      )}
      {children}
    </div>
  );
};

interface SummaryProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  visible?: boolean;
}

const Summary: React.FC<SummaryProps> = ({ className, onClick, visible, children, ...props }) => {
  return (
    <div
      className={classNames("tw-col-span-full", className)}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      {...props}
    >
      <Transition
        as={React.Fragment}
        show={visible}
        enter="tw-transition tw-ease-out tw-duration-200"
        enterFrom="tw-opacity-0 tw-translate-y-1"
        enterTo="tw-opacity-100 tw-translate-y-0"
        leave="tw-transition tw-ease-in tw-duration-150"
        leaveFrom="tw-opacity-100 tw-translate-y-0"
        leaveTo="tw-opacity-0 tw-translate-y-1"
      >
        <div className="tw-overflow-hidden tw-border-t tw-border-neutral-200">{children}</div>
      </Transition>
    </div>
  );
};

export const Table = Object.assign(Container, { Header, HeaderCell, Row, Cell, Summary });
