import React from "react";

import config from "@frontend/config";

import { SubtitleOrTranscriptionFormat } from "@shared/interfaces/media";
import {
  useActiveMediaAudioState,
  useActiveMediaConfigState,
  useActiveMediaHasOriginalSubtitlesState,
  useActiveMediaHasOriginalTranscriptionState,
  useActiveMediaIdState,
  useActiveMediaTranscriptionsMapState,
  useMediaHasTranslationsState
} from "@shared/state/editor/editor.hooks";
import { editorStateRepository } from "@shared/state/editor/editor.state";

import { AspectRatio, FileLanguage, Language, MediaSnippet } from "@getsubly/common";
import { useEditorLoadedState } from "@media-editor/state/media-editor.hooks";

export enum VideoFormat {
  Original = "Original",
  MP4 = "mp4"
}

export enum AudioFormat {
  MP3 = "mp3"
}

export const MASTER_FILE = {
  id: "master",
  name: "Master file"
};

export type DownloadSnippet = typeof MASTER_FILE | MediaSnippet;

interface DownloadMenuContext {
  hasTranslations: boolean;
  transcriptionFormats: SubtitleOrTranscriptionFormat[];
  setTranscriptionFormats: React.Dispatch<React.SetStateAction<SubtitleOrTranscriptionFormat[]>>;
  subtitlesFormats: SubtitleOrTranscriptionFormat[];
  setSubtitlesFormats: React.Dispatch<React.SetStateAction<SubtitleOrTranscriptionFormat[]>>;
  ratios: AspectRatio[];
  toggleRatio: (ratio: AspectRatio) => void;
  downloadWithSubtitles: boolean;
  setDownloadWithSubtitles: React.Dispatch<React.SetStateAction<boolean>>;
  downloadWithWarning: boolean;
  setDownloadWithWarning: React.Dispatch<React.SetStateAction<boolean>>;
  showDownloadWithDisclaimer: boolean;
  downloadWithoutLineBreaks: boolean;
  setDownloadWithoutLineBreaks: React.Dispatch<React.SetStateAction<boolean>>;
  setDownloadWithAudioDescription: React.Dispatch<React.SetStateAction<boolean>>;
  downloadWithAudioDescription: boolean;
  videoFormats: VideoFormat[];
  toggleVideoFormat: (format: VideoFormat) => void;
  audioFormats: AudioFormat[];
  toggleAudioFormat: (format: AudioFormat) => void;
  languages: Language[];
  setLanguages: React.Dispatch<React.SetStateAction<Language[]>>;
  toggleLanguage: (language: Language) => void;
  subtitlesLanguages: FileLanguage[];
  transcriptionLanguages: FileLanguage[];
  snippets: DownloadSnippet[];
  toggleSnippet: (snippet: DownloadSnippet) => void;
  hasSubtitles: boolean;
  hasTranscription: boolean;
  setSnippets: React.Dispatch<React.SetStateAction<DownloadSnippet[]>>;
  setMasterFile: (master: boolean) => void;
  masterFile: boolean;
  hasSnippets: boolean;
  mediaEditorMode: "subtitles" | "transcription";
}

const DownloadMenuContext = React.createContext<DownloadMenuContext | null>(null);

interface DownloadMenuProviderProps {
  children: React.ReactNode;
}
export const DownloadMenuProvider: React.FC<DownloadMenuProviderProps> = ({ children }) => {
  const [transcriptionFormats, setTranscriptionFormats] = React.useState<SubtitleOrTranscriptionFormat[]>([]);
  const [subtitlesFormats, setSubtitlesFormats] = React.useState<SubtitleOrTranscriptionFormat[]>([]);
  const [ratios, setSelectedRatios] = React.useState<AspectRatio[]>([]);
  const [downloadWithSubtitles, setDownloadWithSubtitles] = React.useState(true);
  const [downloadWithWarning, setDownloadWithWarning] = React.useState(true);
  const [showDownloadWithDisclaimer, setShowDownloadWithDisclaimer] = React.useState(false);
  const [downloadWithoutLineBreaks, setDownloadWithoutLineBreaks] = React.useState(false);
  const [downloadWithAudioDescription, setDownloadWithAudioDescription] = React.useState(false);
  const [videoFormats, setVideoFormats] = React.useState<VideoFormat[]>([]);
  const [audioFormats, setAudioFormats] = React.useState<AudioFormat[]>([]);
  const [languages, setLanguages] = React.useState<Language[]>([]);
  const [subtitlesLanguages, setSubtitleLanguages] = React.useState<FileLanguage[]>([]);
  const [transcriptionLanguages, setTranscriptionLanguages] = React.useState<FileLanguage[]>([]);
  const [snippets, setSnippets] = React.useState<DownloadSnippet[]>([]);
  const [masterFile, setMasterFile] = React.useState<boolean>(true);

  const hasTranslations = useMediaHasTranslationsState();
  const isAudio = useActiveMediaAudioState();

  const mediaConfig = useActiveMediaConfigState();
  const mediaId = useActiveMediaIdState();

  const mediaEditorMode = mediaConfig?.editorMode ?? "subtitles";

  const hasSnippets = React.useMemo(() => {
    return Boolean(mediaConfig?.snippets?.length || false);
  }, [mediaConfig?.snippets]);

  const { loaded } = useEditorLoadedState();
  const transcriptionsMap = useActiveMediaTranscriptionsMapState();

  React.useEffect(() => {
    if (transcriptionsMap?.originalSubtitles) {
      setLanguages([transcriptionsMap.originalSubtitles]);
    }
  }, [transcriptionsMap?.originalSubtitles?.fileId]);

  React.useEffect(() => {
    if (transcriptionsMap?.originalSubtitles) {
      setSubtitleLanguages([transcriptionsMap.originalSubtitles, ...transcriptionsMap?.subtitlesTranslations]);
    }
  }, [transcriptionsMap?.originalSubtitles, transcriptionsMap?.subtitlesTranslations]);

  React.useEffect(() => {
    if (transcriptionsMap?.originalTranscription) {
      setTranscriptionLanguages([
        transcriptionsMap.originalTranscription,
        ...transcriptionsMap?.transcriptionTranslations
      ]);
    }
  }, [transcriptionsMap?.originalTranscription, transcriptionsMap?.transcriptionTranslations]);

  React.useEffect(() => {
    const mediaType = editorStateRepository.type;
    if (mediaType) {
      const defaultVideoFormat = isAudio ? VideoFormat.MP4 : VideoFormat.Original;
      setVideoFormats([defaultVideoFormat]);
    }
  }, [isAudio, hasSnippets]);

  React.useEffect(() => {
    if (!mediaConfig?.disclaimer) {
      return;
    }

    if (!config.features.showDisclaimer) {
      return;
    }

    const showDownloadWithDisclaimer =
      (mediaConfig?.disclaimer?.loudNoise ||
        mediaConfig?.disclaimer?.flashingLights ||
        mediaConfig?.disclaimer?.sensitiveContent) &&
      mediaConfig?.disclaimer?.duration > 0;
    setShowDownloadWithDisclaimer(showDownloadWithDisclaimer);
  }, [
    mediaId,
    loaded,
    config.features.showDisclaimer,
    mediaConfig?.disclaimer?.loudNoise,
    mediaConfig?.disclaimer?.flashingLights,
    mediaConfig?.disclaimer?.sensitiveContent
  ]);

  const toggleRatio = (ratio: AspectRatio) => {
    if (ratios.includes(ratio)) {
      setSelectedRatios((s) => [...s].filter((r) => r !== ratio));
    } else {
      if (languages.length > 0 && !downloadWithSubtitles) {
        setDownloadWithSubtitles(true);
      }
      setSelectedRatios((s) => [...s, ratio]);
    }
  };

  const toggleVideoFormat = (format: VideoFormat) => {
    if (videoFormats.includes(format)) {
      setVideoFormats((s) => [...s].filter((r) => r !== format));
    } else {
      setVideoFormats((s) => [...s, format]);
    }
  };

  const toggleAudioFormat = (format: AudioFormat) => {
    if (audioFormats.includes(format)) {
      setAudioFormats((s) => [...s].filter((r) => r !== format));
    } else {
      setAudioFormats((s) => [...s, format]);
    }
  };

  const toggleLanguage = (lang: Language) => {
    if (languages.some((l) => l.languageCode === lang.languageCode)) {
      if (languages.length === 1) {
        setDownloadWithSubtitles(false);
      }
      setLanguages((langs) => [...langs].filter((l) => l.languageCode !== lang.languageCode));
    } else {
      if (ratios.length > 0 && !downloadWithSubtitles) {
        setDownloadWithSubtitles(true);
      }
      setLanguages((langs) => [...langs, lang]);
    }
  };

  const toggleSnippet = (snippet: DownloadSnippet) => {
    if (snippets.some((s) => s.id === snippet.id)) {
      setSnippets((snipps) => [...snipps].filter((s) => s.id !== snippet.id));
    } else {
      setSnippets((snipps) => [...snipps, snippet]);
    }
  };

  const hasSubtitles = useActiveMediaHasOriginalSubtitlesState();
  const hasTranscription = useActiveMediaHasOriginalTranscriptionState();

  return (
    <DownloadMenuContext.Provider
      value={{
        hasTranslations,
        transcriptionFormats,
        setTranscriptionFormats,
        subtitlesFormats,
        setSubtitlesFormats,
        ratios,
        toggleRatio,
        downloadWithSubtitles,
        setDownloadWithSubtitles,
        downloadWithWarning,
        setDownloadWithWarning,
        showDownloadWithDisclaimer,
        downloadWithoutLineBreaks,
        setDownloadWithoutLineBreaks,
        downloadWithAudioDescription,
        setDownloadWithAudioDescription,
        videoFormats,
        toggleVideoFormat,
        audioFormats,
        toggleAudioFormat,
        languages,
        setLanguages,
        toggleLanguage,
        subtitlesLanguages,
        transcriptionLanguages,
        snippets,
        toggleSnippet,
        hasSubtitles,
        hasTranscription,
        setSnippets,
        setMasterFile,
        masterFile,
        hasSnippets,
        mediaEditorMode
      }}
    >
      {children}
    </DownloadMenuContext.Provider>
  );
};

export const useDownloadMenu = (): DownloadMenuContext => {
  const context = React.useContext(DownloadMenuContext);

  if (context === null) {
    throw new Error("useDownloadMenu must be used within a DownloadMenuProvider");
  }

  return context;
};
