import React from "react";

import { TimeEditorInput } from "@shared/components/time-editor/time-editor-input";
import { Toggle } from "@shared/primitives/toggle";
import { useMediaIsOverHourLongState } from "@shared/state/editor/editor.hooks";
import { msToSec } from "@shared/utils/time";

import { TitleData } from "@media-editor/components/editor-sidebar/text-panel/text-panel";
import { mediaPlayerRepository, useMediaPlayer } from "@media-player";

interface TextTimestampProps {
  start: number;
  end: number;
  showFullTime: boolean;
  onChangeTime: (data: TitleData) => void;
}

export const TextTimestamp: React.FC<TextTimestampProps> = ({ start, end, showFullTime, onChangeTime }) => {
  const { mediaPlayerRef } = useMediaPlayer();
  const isOverHourLong = useMediaIsOverHourLongState();

  const toggleCustomPositioning = (toggle: boolean) => {
    onChangeTime({ showFullTime: toggle });
  };
  const updateCurrentTime = (ms: number) => {
    const sec = msToSec(ms);
    if (mediaPlayerRef.current) {
      mediaPlayerRef.current.setTime(sec);
    }

    mediaPlayerRepository.updateState({
      currentTime: sec
    });
  };
  const setTime = (time: number, isStart = false) => {
    if (isStart) {
      onChangeTime({ start: time });
    } else {
      onChangeTime({ end: time });
    }
    updateCurrentTime(time);
  };

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-gap-5">
      <div className="tw-flex tw-flex-col tw-gap-2">
        <p className="tw-text-sm tw-font-medium">Start and end time</p>
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <TimeEditorInput
            className="!tw-w-[122px]"
            sizeH="36"
            value={start}
            onChange={(time) => setTime(time, true)}
            disabled={showFullTime}
            isOverHourLong={isOverHourLong}
          />
          <div className="tw-h-0.5 tw-w-2 tw-bg-neutral-400" />
          <TimeEditorInput
            className="!tw-w-[122px]"
            sizeH="36"
            value={end}
            onChange={(time) => setTime(time)}
            disabled={showFullTime}
            isOverHourLong={isOverHourLong}
          />
        </div>
      </div>
      <Toggle
        label="Display entire time"
        checked={showFullTime}
        onChange={() => toggleCustomPositioning(!showFullTime)}
      />
    </div>
  );
};
