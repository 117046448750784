import React from "react";

import { toggleFavouriteFolder } from "@frontend/api/dashboard.service";
import { MoveFolderModal } from "@frontend/components/bespoke-modals/move-folder-modal";
import { TeamspaceModal } from "@frontend/components/bespoke-modals/teamspace-modal";

import { UnfavouriteIcon } from "@shared/components/icons";
import { useAccounts } from "@shared/hooks/use-accounts";
import { useDashboard } from "@shared/hooks/use-dashboard";
import { useModal } from "@shared/hooks/use-modal";
import { ModalType } from "@shared/interfaces/modal-type";
import { Dropdown, DropdownDivider, DropdownItem, DropdownMenu } from "@shared/primitives/dropdown";
import { Folder } from "@shared/state/dashboard/dashboard.store";

import { ResourceAction, ResourceType } from "@getsubly/common";
import { useRolePermissions } from "@getsubly/common/dist/permissions/use-role-permissions";
import { RiDeleteBinLine, RiSettings3Line, RiShareForwardLine, RiStarLine } from "@remixicon/react";

import { DeleteFolderModal } from "../modals/delete-folder-modal";

type TeamspaceMenuProps = {
  folder: Folder;
  children: React.ReactNode;
  onToggleDropdown?: (isOpen: boolean) => void;
  onSaving?: (isSaving: boolean) => void;
  onChange?: () => void;
};

export const TeamspaceMenu: React.FC<TeamspaceMenuProps> = ({
  folder,
  children,
  onToggleDropdown,
  onSaving,
  onChange
}) => {
  const { currentAccount } = useAccounts();
  const { permissions: userGroupPermissions } = useRolePermissions(currentAccount?.role, ResourceType.UserGroup);
  const { activeFolder, selectFolder } = useDashboard();

  const canUpdateFolder = userGroupPermissions[ResourceAction.Update];
  const canDeleteFolder = userGroupPermissions[ResourceAction.Delete];
  const canFavourite = userGroupPermissions[ResourceAction.Favourite];

  const { permissions: teamspacePermissions } = useRolePermissions(currentAccount?.role, ResourceType.Teamspace);
  const canMoveFolder = teamspacePermissions[ResourceAction.Move];

  if (!canUpdateFolder && !canDeleteFolder && !canMoveFolder && !canFavourite) {
    return null;
  }

  const handleToggleFavourite = async () => {
    onSaving?.(true);
    try {
      await toggleFavouriteFolder(folder.id);
      await onChange?.();
    } catch (e) {
      console.error(e);
    } finally {
      onSaving?.(false);
    }
  };

  const handleManage = () => {
    const [showTeamspaceModal, hideTeamspaceModal] = useModal(ModalType.ManageTeamspace);
    showTeamspaceModal(<TeamspaceModal closeModal={hideTeamspaceModal} folder={folder} />);
  };

  const handleMove = () => {
    const [showMoveModal, hideMoveModal] = useModal(ModalType.MoveFolder);

    showMoveModal(
      <MoveFolderModal
        folder={folder}
        closeModal={hideMoveModal}
        onBeforeSave={() => onSaving?.(true)}
        onCompleteSave={async () => {
          await onChange?.();
          onSaving?.(false);
        }}
      />
    );
  };

  const handleDelete = () => {
    const [showDeleteFolderModal, hideDeleteFolderModal] = useModal(ModalType.DeleteFolder);

    const isDeletingActiveFolder = activeFolder?.id === folder.id;
    const nextFolderId = isDeletingActiveFolder ? activeFolder?.parentId : null;

    showDeleteFolderModal(
      <DeleteFolderModal
        folder={folder}
        closeModal={hideDeleteFolderModal}
        onBeforeDelete={() => onSaving?.(true)}
        onCompleteDelete={() => {
          if (isDeletingActiveFolder) {
            selectFolder(nextFolderId);
          }
          onChange?.();
        }}
      />
    );
  };

  return (
    <Dropdown>
      {children}

      <DropdownMenu
        placement="right-start"
        showInPortal
        onOpen={() => onToggleDropdown?.(true)}
        onClose={() => onToggleDropdown?.(false)}
      >
        {canFavourite && (
          <DropdownItem
            onClick={handleToggleFavourite}
            lIcon={
              folder.isFavourite ? (
                <UnfavouriteIcon className="tw-h-5 tw-w-5 tw-shrink-0 tw-stroke-2 tw-text-neutral-600" />
              ) : (
                <RiStarLine className="tw-h-5 tw-w-5 tw-shrink-0 tw-stroke-2 tw-text-neutral-600" />
              )
            }
            className="tw-font-medium"
          >
            {folder.isFavourite ? "Remove from favourites" : "Add to favourites"}
          </DropdownItem>
        )}
        {canUpdateFolder && (
          <DropdownItem
            onClick={handleManage}
            lIcon={<RiSettings3Line className="tw-h-5 tw-w-5 tw-shrink-0 tw-stroke-2 tw-text-neutral-600" />}
            className="tw-font-medium"
          >
            Settings
          </DropdownItem>
        )}
        {canMoveFolder && (
          <DropdownItem
            onClick={handleMove}
            lIcon={<RiShareForwardLine className="tw-h-5 tw-w-5 tw-shrink-0 tw-stroke-2 tw-text-neutral-600" />}
            className="tw-font-medium"
          >
            Move
          </DropdownItem>
        )}
        {canDeleteFolder && <DropdownDivider />}
        {canDeleteFolder && (
          <DropdownItem
            onClick={handleDelete}
            lIcon={<RiDeleteBinLine className="tw-h-5 tw-w-5 tw-shrink-0 tw-stroke-2 tw-text-destructive-600" />}
            className="tw-font-medium !tw-text-destructive-600"
          >
            Delete
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
