import axios from "axios";

import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { UserGroup } from "@shared/interfaces/user-group";
import { userGroupsRepository, userGroupsStore } from "@shared/state/user-groups/user-groups.state";

import { deleteEntities, upsertEntities } from "@ngneat/elf-entities";

import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v2`;

export const getUserGroups = async (): Promise<UserGroup[]> => {
  try {
    userGroupsRepository.updateState({ loading: true });

    const { data } = await axios.get(`/${getAccountId()}/user-groups`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    userGroupsStore.update(upsertEntities(data.userGroups));
    userGroupsRepository.updateState({
      loading: false
    });

    return data.userGroups;
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export type ICreateUserGroup = Pick<UserGroup, "name">;
export const createUserGroup = async (userGroup: ICreateUserGroup): Promise<UserGroup> => {
  try {
    const { data } = await axios.post(`/${getAccountId()}/user-groups`, userGroup, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    userGroupsStore.update(upsertEntities(data.userGroup));

    return data.userGroup;
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const updateUserGroup = async (id: string, userGroup: ICreateUserGroup): Promise<UserGroup> => {
  try {
    const { data } = await axios.put(`/${getAccountId()}/user-groups/${id}`, userGroup, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    userGroupsStore.update(upsertEntities(data.userGroup));

    return data.userGroup;
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const deleteUserGroup = async (id: string): Promise<void> => {
  try {
    await axios.delete(`/${getAccountId()}/user-groups/${id}`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    userGroupsStore.update(deleteEntities(id));
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const addMembersToUserGroup = async (id: string, userIds: string[]): Promise<UserGroup> => {
  try {
    const { data } = await axios.put(
      `/${getAccountId()}/user-groups/${id}/users`,
      { userIds },
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    userGroupsStore.update(upsertEntities(data.userGroup));

    return data.userGroup;
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const removeMemberFromUserGroup = async (groupId: string, userId: string): Promise<UserGroup> => {
  try {
    const { data } = await axios.delete(`/${getAccountId()}/user-groups/${groupId}/users/${userId}`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    userGroupsStore.update(upsertEntities(data.userGroup));

    return data.userGroup;
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};
