import React, { useState } from "react";

import { uploadFiles } from "@frontend/api/upload.service";
import { UploadModalAlert } from "@frontend/components/bespoke-modals/upload-modal-alert";
import settings from "@frontend/config/settings/settings";
import { setUserSettings, userLanguage } from "@frontend/config/settings/settings.service";

import { ZoomIcon } from "@shared/components/icons";
import { useAnalyticsWithAuth } from "@shared/hooks/use-analytics-with-auth";
import { useDashboard } from "@shared/hooks/use-dashboard";
import { useMediaInfo } from "@shared/hooks/use-media-info";
import { usePlan } from "@shared/hooks/use-plan";
import { usePlanPermissions } from "@shared/hooks/use-plan-permissions";
import { useUpload } from "@shared/hooks/use-upload";
import { SublyPlan } from "@shared/interfaces/billing";
import { Button } from "@shared/primitives/button";
import { Fieldset } from "@shared/primitives/fieldset";
import { Modal } from "@shared/primitives/modal";
import { ISelectOption, Select } from "@shared/primitives/select";
import { uploadQuery, uploadStore } from "@shared/state/upload";
import { parseCreditToText } from "@shared/utils/plans";

import { FilesList } from "./files-list";
import { ProjectLocationDropdown } from "./project-location-dropdown";

export interface ZoomFile {
  fileId: string;
  meetingId: number;
  name: string;
  type: string;
  duration: number;
  size: number;
}

interface ZoomUploadModalProps {
  initialFiles: ZoomFile[];
  closeModal: (goToDashboard?: boolean) => void;
}

export const ZoomUploadModal: React.FC<ZoomUploadModalProps> = ({ initialFiles = [], closeModal }) => {
  return (
    <Modal
      onDismiss={closeModal}
      closeIconClassName="tw-absolute tw-right-8 tw-top-8 tw-cursor-pointer tw-h-[22px] tw-w-[22px] tw-fill-neutral-600"
    >
      <ZoomUploadModalContainer initialFiles={initialFiles} closeModal={closeModal} />
    </Modal>
  );
};

// Separated the container of the modal to a new component, so it
// doesn't rerender the whole Modal when things change
const ZoomUploadModalContainer: React.FC<ZoomUploadModalProps> = ({ initialFiles = [], closeModal }) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(userLanguage());
  const { plan, isProOrHigher, isPremiumOrHigher, isPayg, availableSeconds, availableStorage } = usePlan();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { loading, convertZoomToUploadFile } = useMediaInfo();

  const { activeFolder } = useDashboard();
  const [folder, setFolder] = useState(activeFolder);

  const { hasPermission } = usePlanPermissions(SublyPlan.Pro);

  const { languages } = settings.transcription;

  const { queue, totalCount, totalDuration, totalSize, isCheckingFile, sortedQueue } = useUpload();
  const totalDurationLabel = parseCreditToText(totalDuration, false, false, true);
  const minutesLeft = parseCreditToText(availableSeconds, false, false);
  const hasInsufficientCredit = totalDuration > availableSeconds;
  const hasInsufficientStorage = totalSize > availableStorage;

  React.useEffect(() => {
    if (initialFiles.length && !queue.length) {
      handleSetFiles(initialFiles);
    }
  }, [initialFiles]);

  const handleSetFiles = async (files: ZoomFile[]) => {
    if (!files) {
      return;
    }

    if (!isPremiumOrHigher && !isPayg) {
      uploadStore.resetUpload();
    }

    // This shows the "checking file" loading spinner.
    uploadStore.setCheckingFile(true);

    for await (const file of files) {
      const uploadFile = await convertZoomToUploadFile({
        meetingId: file.meetingId,
        fileId: file.fileId,
        filename: file.name ?? "Test",
        fileSize: file.size,
        fileType: file.type,
        duration: file.duration,
        isAudio: file.type.includes("audio")
      });
      if (uploadFile) {
        uploadStore.addFilesToQueue([uploadFile], folder?.id);
      }
    }

    uploadStore.setCheckingFile(false);
  };

  const handleSelectedLanguage = (languageCode?: string) => {
    if (languageCode) {
      setSelectedLanguage(languageCode);
      setUserSettings({ language: languageCode });
    }
  };

  const handleUpload = async () => {
    if (!hasPermission) {
      return;
    }

    uploadStore.clearUploadsWithErrors();

    if (!queue) {
      return;
    }

    uploadStore.update({ language: selectedLanguage });

    // Do not put "await" before function because the modal would stay opened
    // noinspection ES6MissingAwait
    uploadFiles({ plan, isProOrHigher, mixpanelTrack: trackEventWithAuth });
    closeModal(true);
  };

  const disableUpload =
    !hasPermission ||
    uploadQuery.state.queue.filter((f) => !f.error).length === 0 ||
    loading ||
    hasInsufficientCredit ||
    hasInsufficientStorage;

  const formatLabel = (option: ISelectOption) => (
    <>
      <span className={`fi fi-${option.extra.flagCode} tw-shrink-0`} />
      {option.label}
    </>
  );

  return (
    <>
      <div className="tw-mb-2.5 tw-flex tw-items-center tw-justify-center">
        <ZoomIcon className="tw-mr-3 tw-h-6 tw-w-6" />
        <h5 className="tw-mb-0 tw-text-lg tw-font-medium">Import from Zoom and transcribe</h5>
      </div>

      <div>
        {/* File upload area */}
        <div className="tw-mb-5 tw-flex tw-flex-wrap">
          <label className="tw-text-sm tw-font-normal tw-text-neutral-900">
            Available credit: <b className="tw-font-semibold">{minutesLeft}</b>
          </label>
          <label className="tw-ml-2 tw-text-sm tw-font-normal tw-text-neutral-900">
            Selected files: <b className="tw-font-semibold">{totalDurationLabel}</b>
          </label>
        </div>
        <FilesList
          className="tw-my-2"
          files={sortedQueue}
          isCheckingFile={isCheckingFile}
          removeFile={(id) => uploadStore.removeFileByUploadId(id)}
        />
        <UploadModalAlert
          count={totalCount}
          credit={hasInsufficientCredit}
          storage={hasInsufficientStorage}
          onUpgrade={closeModal}
        />
        <div className="tw-mt-6 tw-flex tw-max-w-none tw-flex-col tw-justify-between md:tw-max-w-[612px]">
          <div className="tw-flex tw-gap-6">
            <Fieldset className="tw-w-1/2 tw-flex-1" title="File location">
              <ProjectLocationDropdown setFolder={setFolder} folder={folder} />
            </Fieldset>
            <Fieldset className="tw-w-1/2 tw-flex-1" title="Language spoken">
              <Select
                variant="outline"
                placeholder="Select language"
                defaultValue={selectedLanguage}
                options={languages.map((language) => ({
                  label: language.language,
                  value: language.languageCode,
                  extra: { flagCode: language.flagCode }
                }))}
                formatOptionLabel={(option) => formatLabel(option)}
                formatInputSelectedLabel={(option) => formatLabel(option)}
                onChange={handleSelectedLanguage}
                dropdownClassName="!tw-min-w-[280px] !tw-max-h-[300px]"
              />
            </Fieldset>
          </div>

          <div className="tw-mt-4 tw-flex tw-justify-end">
            <Button
              variant="secondary"
              className="tw-text-base tw-mr-2 tw-rounded-md tw-border-neutral-300 tw-font-medium tw-text-neutral-700"
              onClick={closeModal}
              size="36"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={disableUpload}
              className="tw-text-base tw-w-[204px] tw-font-medium"
              onClick={handleUpload}
              size="36"
            >
              Upload and transcribe
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
