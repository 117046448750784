import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { updateAccountName, updateAccountPicture } from "@frontend/api/account.service";
import { getCustomer } from "@frontend/api/billing.service";
import { EN } from "@frontend/assets/i18n/en";
import { SettingsCard } from "@frontend/components/settings-card/settings-card";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { ProfileImageIcon } from "@shared/components/profile-image-icon/profile-image-icon";
import { useAccounts } from "@shared/hooks/use-accounts";
import { Button } from "@shared/primitives/button";
import { Fieldset } from "@shared/primitives/fieldset";
import { FileInput } from "@shared/primitives/file-input";
import { Input } from "@shared/primitives/input";
import { Loader } from "@shared/primitives/loader";
import { notificationError, notificationSuccess } from "@shared/primitives/notification";

type AccountNameForm = {
  accountName: string;
};
export const WorkspaceCard: React.FC = () => {
  const { user, isLoading, currentAccount, isAdmin } = useAccounts();
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<AccountNameForm>({
    defaultValues: {
      accountName: currentAccount?.accountName
    }
  });

  React.useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer(getAccountId(), { force: false });
  }, [user]);

  React.useEffect(() => {
    if (currentAccount?.accountName) {
      setValue("accountName", currentAccount?.accountName);
    }
  }, [currentAccount?.accountName]);

  if (isLoading) {
    return (
      <SettingsCard>
        <SettingsCard.Header>Workspace</SettingsCard.Header>
        <SettingsCard.Body className="!tw-pt-0">
          <Loader className="tw-mr-2" />
        </SettingsCard.Body>
      </SettingsCard>
    );
  }

  const onSubmit = async ({ accountName }: AccountNameForm) => {
    if (!isAdmin) {
      return;
    }

    setLoading(true);

    await updateAccountName(accountName);

    setLoading(false);
  };

  return (
    <SettingsCard>
      <SettingsCard.Header>Workspace</SettingsCard.Header>
      <SettingsCard.Body>
        <div className="tw-mb-6">
          <label className="tw-font-medium">Workspace picture</label>
          <UploadWorkspacePicture />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Fieldset title="Workspace name" className="tw-mb-6">
            <Input
              type="text"
              hasError={!!errors.accountName}
              {...register("accountName", { required: true })}
              disabled={!isAdmin}
              sizeH="32"
            />
          </Fieldset>
          <div className="tw-flex tw-justify-end">
            <Button
              variant="primary"
              type={isAdmin ? "submit" : undefined}
              loading={loading}
              disabled={!isAdmin}
              size="32"
            >
              Save
            </Button>
          </div>
        </form>
      </SettingsCard.Body>
    </SettingsCard>
  );
};

const UploadWorkspacePicture: React.FC = () => {
  const { currentAccount } = useAccounts();
  const [loading, setLoading] = useState(false);

  if (!currentAccount) {
    return null;
  }

  const handleUpload = async (file: File) => {
    // Check image dimensions
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = async () => {
      const { width, height } = img;

      if (width < 200 || height < 200) {
        notificationError(EN.error.AvatarImageSize);
        return;
      }

      try {
        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > 5) {
          notificationError(EN.error.AvatarFileSize);
          return;
        }

        setLoading(true);

        await updateAccountPicture(file);

        notificationSuccess("Workspace has been updated.");
      } catch (e) {
        console.error(e);

        notificationError(EN.error.defaultMessage);
      } finally {
        setLoading(false);
      }
    };
  };

  return (
    <div className="tw-w-fit">
      <FileInput accept="image/*" onChange={handleUpload}>
        <ProfileImageIcon
          name={currentAccount.accountName}
          image={currentAccount.accountPicture}
          label="Upload picture"
          size="100%"
          isUploader
          isUploading={loading}
          twoLetters
          className="tw-text-h1"
        />
      </FileInput>
    </div>
  );
};
