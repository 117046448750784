import { useEffect } from "react";
import { useParams } from "react-router-dom";

import config from "@frontend/config";

import { makeShareUrl } from "@shared/utils/links";

export const PreviewVideoPage: React.FC = () => {
  const { publicShareId = "" } = useParams();

  useEffect(() => {
    window.location.href = makeShareUrl({
      previewBaseUrl: config.previewBaseUrl,
      publicShareId
    });
  }, [publicShareId]);

  return null;
};
