import React from "react";

import { Button } from "@shared/primitives/button";
import { NewModal } from "@shared/primitives/new-modal";

import { MediaConfig, TitleConfig } from "@getsubly/common";

interface TitleDeleteModalProps {
  mediaConfig?: MediaConfig;
  title: TitleConfig;
  onDeleteTitle: () => void;
  closeModal: () => void;
}

export const TitleDeleteModal: React.FC<TitleDeleteModalProps> = ({
  title,
  mediaConfig,
  onDeleteTitle,
  closeModal
}) => {
  return (
    <NewModal onDismiss={closeModal} showCloseButton>
      <TitleDeleteModalContainer
        title={title}
        mediaConfig={mediaConfig}
        closeModal={closeModal}
        onDeleteTitle={onDeleteTitle}
      />
    </NewModal>
  );
};

const TitleDeleteModalContainer: React.FC<TitleDeleteModalProps> = ({
  title,
  mediaConfig,
  onDeleteTitle,
  closeModal
}) => {
  const [isLoading, setLoading] = React.useState(false);

  const handleDelete = async () => {
    if (!mediaConfig) {
      return;
    }
    setLoading(true);

    try {
      onDeleteTitle();
      closeModal();
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };
  const textWithoutLineBreaks = title?.text ? title.text.replace(/(\r\n|\n|\r)/gm, "") : "";
  return (
    <>
      <h5 className="tw-mb-2 tw-truncate tw-whitespace-pre-wrap tw-text-lg tw-font-medium">
        Are you sure you want to delete this text "{textWithoutLineBreaks}"?
      </h5>

      <p className="warning-message">This action will permanently delete this text</p>
      <div className="tw-mt-4 tw-flex">
        <Button variant="secondary" onClick={closeModal} className="tw-ml-auto tw-mr-2" size="36">
          Cancel
        </Button>
        <Button variant="destructive" onClick={handleDelete} loading={isLoading} autoFocus size="36">
          Delete text
        </Button>
      </div>
    </>
  );
};
