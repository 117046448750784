import axios from "axios";
import { Dict } from "mixpanel-browser";

import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { SublyPlan } from "@shared/interfaces/billing";
import { accountStore } from "@shared/state/account";
import {
  isUploadFile,
  isUploadOneDriveFile,
  isUploadUrlFile,
  isUploadZoomFile,
  UploadFile,
  UploadFileType,
  UploadingStatus,
  UploadOneDriveUrlFile,
  uploadQuery,
  uploadStore,
  UploadUrlFile,
  UploadZoomFile
} from "@shared/state/upload";
import { formatMediaName } from "@shared/utils/strings";

import { Transcription } from "@getsubly/common";

import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";
import { IUploadMediaRequestPayload, IUploadMediaRequestResponse, notifyUploadJob } from "./media.service";

const baseURL = `${config.apiUrl}/api/v1`;

export const uploadFiles = async (params: {
  plan: SublyPlan;
  isProOrHigher: boolean;
  mixpanelTrack: (action: string, properties?: Dict) => void;
  skipTranscription?: boolean;
  accountCustomVocabulary?: string[];
  folderId?: string;
  summariseAfterTranscribe?: boolean;
  burnAfterTranscribe?: boolean;
}): Promise<string[]> => {
  const mediaIds: string[] = [];
  const { queue, language, transcription, customVocabulary } = uploadQuery.getValue();

  // Change all `staged` files to `queued`
  for (const file of queue) {
    if (file.uploadingStatus === UploadingStatus.Staged) {
      uploadStore.updateFileInQueue(file.uploadId, {
        uploadingStatus: UploadingStatus.Queued
      });
    }
  }

  // Upload each file in the queue.
  if (!uploadQuery.isUploading) {
    for (let i = 0; i < queue.length; i++) {
      try {
        const file = queue[i];

        const customVocabularyWords = [...customVocabulary, ...(params.accountCustomVocabulary ?? [])];

        const mediaId = await uploadMediaToBucket({
          file,
          language: file.languageCode ?? language,
          folderId: params.folderId,
          transcription: queue.length === 1 ? transcription : undefined,
          skippedTranscription: params.skipTranscription,
          customVocabularyWords,
          summariseAfterTranscribe: params.summariseAfterTranscribe,
          burnAfterTranscribe: params.burnAfterTranscribe
        });

        params.mixpanelTrack("Upload single file", {
          plan: params.plan,
          isProOrHigher: params.isProOrHigher,
          duration: file.mediaInfo?.duration ?? 0,
          language,
          size: file.mediaInfo?.fileSize ?? 0,
          isAudio: Boolean(file.mediaInfo?.isAudio),
          includeSRT: Boolean(transcription),
          isGoogleDrive: false,
          isUrl: Boolean((file as UploadUrlFile)?.isUrl),
          isZoom: Boolean((file as UploadZoomFile)?.isZoom),
          skippedTranscription: params.skipTranscription,
          withCustomGlossary: customVocabularyWords.length > 0,
          customVocabularyWords
        });

        if (mediaId) {
          mediaIds.push(mediaId);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  return mediaIds;
};

export interface IUploadToBucketParams {
  file: UploadFile | UploadUrlFile | UploadOneDriveUrlFile | UploadZoomFile;
  folderId?: string;
  language?: string;
  transcription?: Transcription;
  skippedTranscription?: boolean;
  customVocabularyWords?: string[];
  summariseAfterTranscribe?: boolean;
  burnAfterTranscribe?: boolean;
}

export const uploadMediaToBucket = async (params: IUploadToBucketParams): Promise<string> => {
  const { file } = params;
  let mediaId = "";

  uploadStore.update({
    isUploading: true,
    mediaName: file.mediaName,
    language: params.language
  });

  uploadStore.updateFileInQueue(file.uploadId, {
    uploadingStatus: UploadingStatus.Uploading,
    uploadProgress: 1
  });

  try {
    // If the media is a zoom file, we send it to the back to handle.
    if (isUploadZoomFile(file)) {
      const { data } = await axios.put<{ mediaId: string }>(
        `/${getAccountId()}/zoom/transcribe`,
        {
          meetingId: file.meetingId,
          fileId: file.fileId,
          name: file.mediaName,
          language: params.language,
          folderId: params.folderId,
          transcription: params.transcription,
          settings: { origin: "zoom" }
        },
        { baseURL, headers: { "x-access-token": await getAccessToken() } }
      );

      mediaId = data.mediaId;
      uploadStore.updateFileInQueue(file.uploadId, { mediaId });
    }
    // Otherwise we treat the media as a local or remote file.
    else {
      const payload = prepareUploadPayload(file, {
        language: params.language,
        folderId: params.folderId,
        customVocabularyWords: params.customVocabularyWords,
        transcription: params.transcription,
        skippedTranscription: params.skippedTranscription,
        summariseAfterTranscribe: params.summariseAfterTranscribe,
        burnAfterTranscribe: params.burnAfterTranscribe
      });

      // We add a row to the media table and get the mediaId.
      const { data } = await axios.post<IUploadMediaRequestResponse>(`/${getAccountId()}/media/upload`, payload, {
        baseURL: baseURL,
        headers: {
          "x-access-token": await getAccessToken(),
          "Content-Type": "application/json"
        }
      });

      mediaId = data.mediaId;
      uploadStore.updateFileInQueue(file.uploadId, { mediaId });

      await uploadMedia(data.mediaUrl, mediaId, file, payload);
    }

    uploadStore.updateFileInQueue(file.uploadId, {
      uploadingStatus: UploadingStatus.Uploaded
    });
    accountStore.incrementStorageBytesUsed(file.mediaInfo?.fileSize ?? 0);
  } catch (e) {
    handleError(e);
    uploadStore.removeFileByUploadId(file.uploadId);
  }

  uploadStore.update({ isUploading: false });
  return mediaId;
};

const uploadMedia = async (
  mediaUrl: string,
  mediaId: string,
  file: UploadFileType,
  payload: IUploadMediaRequestPayload
) => {
  const { notifyUser } = uploadQuery.getValue();

  if (mediaId && notifyUser) {
    await notifyUploadJob(mediaId);
  }

  if (isUploadFile(file)) {
    await axios.put(mediaUrl, file.file, {
      headers: { "Content-Type": payload.mimeType },
      onUploadProgress: (progressEvent) => {
        const uploadProgress = Math.round((progressEvent.progress ?? 0) * 100);
        uploadStore.updateFileInQueue(file.uploadId, { uploadProgress });
      }
    });
  }

  if (isUploadUrlFile(file)) {
    await axios.post<IUploadMediaRequestResponse>(
      `/${getAccountId()}/media/url-upload`,
      {
        destUrl: mediaUrl,
        filename: payload.filename,
        sourceUrl: file.url,
        size: file.mediaInfo?.fileSize ?? 0,
        authorization: file.authorization
      },
      {
        baseURL: baseURL,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": await getAccessToken()
        }
      }
    );
  }
};

const prepareUploadPayload = (
  file: UploadFileType,
  extra?: {
    language?: string;
    customVocabularyWords?: string[];
    folderId?: string;
    transcription?: Transcription;
    skippedTranscription?: boolean;
    summariseAfterTranscribe?: boolean;
    burnAfterTranscribe?: boolean;
  }
): IUploadMediaRequestPayload => {
  const base = {
    language: extra?.language ?? "",
    transcription: extra?.transcription,
    filename: file.filename,
    folderId: extra?.folderId,
    sizeBytes: file.mediaInfo?.fileSize ?? 0,
    settings: {
      skippedTranscription: extra?.skippedTranscription ?? true,
      customVocabularyWords: extra?.customVocabularyWords ?? [],
      summariseAfterTranscribe: extra?.summariseAfterTranscribe ?? undefined,
      burnAfterTranscribe: extra?.burnAfterTranscribe ?? undefined
    }
  };

  if (isUploadFile(file)) {
    return {
      ...base,
      name: formatMediaName(file.file.name).slice(0, 129),
      mimeType: file.file.type ?? "video/mp4"
    };
  }

  if (isUploadOneDriveFile(file)) {
    return {
      ...base,
      name: formatMediaName(file.filename).slice(0, 129),
      mimeType: "video/mp4",
      settings: {
        ...base.settings,
        integration: file.integration,
        drive: file.drive,
        path: file.uploadPath
      }
    };
  }

  return {
    ...base,
    name: formatMediaName(file.filename).slice(0, 129),
    mimeType: "video/mp4"
  };
};
