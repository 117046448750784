import React from "react";

import { useModal } from "@shared/hooks/use-modal";
import { ModalType } from "@shared/interfaces/modal-type";

import { RequestSeatModal } from "@media-editor/components/bespoke-modals/request-seat-modal";

import { UpgradeButton } from "./upgrade-button";

interface UpgradeToEditorProps {
  className?: string;
  text?: string;
}

export const UpgradeToEditorButton: React.FC<UpgradeToEditorProps> = ({ className, text = "Become an editor" }) => {
  const [requestSent, setRequestSent] = React.useState(false);
  const [showRequestSeatModal, hideRequestSeatModal] = useModal(ModalType.RequestSeat);

  const handleRequestSent = () => {
    setRequestSent(true);
  };

  const handleRequestSeat = () => {
    showRequestSeatModal(<RequestSeatModal onDismiss={hideRequestSeatModal} onRequestSent={handleRequestSent} />);
  };

  return (
    <UpgradeButton
      className={className}
      text={text}
      onClick={handleRequestSeat}
      successMessage="Request sent"
      showSuccessMessage={requestSent}
    />
  );
};
