import React from "react";
import classNames from "classnames";

import { observeScroll, observeSize } from "@shared/utils/scroll.utils";

type ScrollContainerRef = HTMLDivElement;

type ScrollContainerProps = {
  className?: string;
  children: React.ReactNode;
  onScrollX?: (offset: number) => void;
  onScrollY?: (offset: number) => void;
  onResize?: (size: { width: number; height: number }) => void;
  onResizeHeight?: (height: number) => void;
  onResizeWidth?: (width: number) => void;
};

export const ScrollContainer = React.forwardRef<ScrollContainerRef, ScrollContainerProps>(
  ({ className, children, onScrollX, onScrollY, onResizeHeight, onResizeWidth, onResize }, ref) => {
    const scrollContainerRef = React.useRef(null);
    const lastHeight = React.useRef<number | null>(null);
    const lastWidth = React.useRef<number | null>(null);
    const lastScrollY = React.useRef<number | null>(null);
    const lastScrollX = React.useRef<number | null>(null);

    React.useImperativeHandle<ScrollContainerRef | null, ScrollContainerRef | null>(
      ref,
      () => scrollContainerRef.current
    );

    React.useEffect(() => {
      if (!scrollContainerRef.current) return;

      const removeHeightObserver = observeSize(scrollContainerRef.current, ({ height, width }) => {
        const heightResized = lastHeight.current !== height;
        const widthResized = lastWidth.current !== width;

        if (heightResized) onResizeHeight?.(height);
        if (widthResized) onResizeWidth?.(width);
        if (heightResized || widthResized) onResize?.({ height, width });

        lastHeight.current = height;
        lastWidth.current = width;
      });

      const removeScrollObserver = observeScroll(scrollContainerRef.current, () => {
        if (!scrollContainerRef.current) return;
        const scrollContainer = scrollContainerRef.current as HTMLElement;
        const scrolledX = lastScrollX.current !== scrollContainer.scrollTop;
        const scrolledY = lastScrollY.current !== scrollContainer.scrollLeft;
        if (scrolledX) onScrollX?.(scrollContainer.scrollLeft);
        if (scrolledY) onScrollY?.(scrollContainer.scrollTop);
      });

      return () => {
        removeHeightObserver();
        removeScrollObserver();
      };
    }, []);

    return (
      <div className={classNames(className, "tw-overflow-auto")} ref={scrollContainerRef}>
        {children}
      </div>
    );
  }
);
