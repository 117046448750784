import React from "react";

import { modalQuery } from "@shared/state/modal/modal.query";

import { useObservable } from "@mindspace-io/react";

interface ModalsContainerProps {
  children?: React.ReactNode;
}
export const ModalsContainer: React.FC<ModalsContainerProps> = ({ children }) => {
  const [visibleModals] = useObservable(modalQuery.selectVisibleModals(), []);

  const renderModals = visibleModals.map((m) => {
    return <React.Fragment key={m.type}>{m.component}</React.Fragment>;
  });

  return (
    <>
      {children}
      {renderModals}
    </>
  );
};
