import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuthProvider } from "@frontend/contexts/auth.context";
import { DASHBOARD_PATH, LOGIN_PATH } from "@frontend/routes";

import { useAnalyticsWithAuth } from "@shared/hooks/use-analytics-with-auth";
import { useQuery } from "@shared/hooks/use-query";
import { Alert } from "@shared/primitives/alert";
import { Loader } from "@shared/primitives/loader";
import { SublyCouponCode } from "@shared/utils/plans";

import envelope from "../assets/images/envelope.png";

export interface QueryParams {
  state?: string;
  coupon?: SublyCouponCode;
  alertMessage?: string;
  redirect?: string;
}

export const VerifyMagicLink: React.FC = () => {
  const { loginWithMagicLink } = useAuthProvider();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { queryParams } = useQuery();
  const state = useLocation().state as QueryParams;
  const email = queryParams.email.replaceAll(" ", "+");
  const token = queryParams.token.replaceAll(" ", "+");
  const [alertMessage, setAlertMessage] = React.useState(queryParams.alertMessage || state?.alertMessage);
  const navigate = useNavigate();
  const hasStartedLogin = React.useRef(false);

  const load = async () => {
    if (!email || !token) {
      setAlertMessage("Email and token are required");
      return;
    }
    const { success, alertMessage } = await loginWithMagicLink({ email, token });
    if (!success) {
      setAlertMessage(alertMessage);
      return;
    }
    trackEventWithAuth("Complete Sign in", { method: "magic link" });
    if (queryParams.redirect) {
      navigate(atob(queryParams.redirect));
    } else {
      navigate(DASHBOARD_PATH);
    }
  };

  React.useEffect(() => {
    if (hasStartedLogin.current) {
      return;
    }
    load();
    hasStartedLogin.current = true;
  }, []);

  return (
    <div>
      <Helmet>
        <title>Subly - Link Verification</title>
      </Helmet>
      <div className="tw-my-auto tw-flex tw-h-screen tw-flex-col tw-items-center tw-justify-center">
        {alertMessage && (
          <Alert
            danger
            className="tw-my-2 tw-w-full tw-max-w-sm"
            title="Something went wrong!"
            closable
            onClose={() => navigate(LOGIN_PATH)}
          >
            {alertMessage}
          </Alert>
        )}
        <div className="tw-flex tw-w-full tw-max-w-sm tw-flex-col tw-items-center tw-justify-center">
          <img src={envelope} alt="Letter sent" className="tw-mt-3 tw-h-[98px] tw-w-[98px]" />
          {!alertMessage && (
            <>
              <h3 className=" tw-my-3 tw-text-h3 tw-font-semibold ">Verifying the link</h3>
              <p className="tw-text-centre tw-mb-0 tw-text-md tw-text-neutral-600">Signing you in</p>
              <Loader className="tw-mt-3 tw-h-5 tw-w-5" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
