import React from "react";
import { Link, LinkProps } from "react-router-dom";

import { IconButton } from "@shared/primitives/button";
import { Loader } from "@shared/primitives/loader";
import { ToolTip } from "@shared/primitives/tooltip";

import { RiAddLine } from "@remixicon/react";

type CategoryButtonProps = {
  children: React.ReactNode;
  description: string;
  withPlus?: boolean;
  isLoading?: boolean;
  to?: LinkProps["to"];
  onClick?: () => void;
  onClickToggle?: () => void;
  onClickPlus?: (e: Event) => void;
};

export const CategoryButton: React.FC<CategoryButtonProps> = ({
  children,
  description,
  withPlus,
  isLoading,
  to = "#",
  onClick,
  onClickPlus
}) => {
  return (
    <div className="tw-group tw-flex tw-h-8 tw-w-full tw-items-center tw-py-0.5">
      <ToolTip text={description} place="top-start" delayShow={500} clickable offset={10}>
        <Link
          className="tw-inline-flex tw-h-3 tw-w-fit tw-items-center tw-rounded-sm tw-px-0.5 tw-text-xs tw-font-medium tw-text-neutral-500 hover:tw-bg-neutral-200 hover:tw-text-neutral-700 active:tw-bg-neutral-300 active:tw-text-neutral-900"
          onClick={onClick}
          to={to}
        >
          {children}
        </Link>
      </ToolTip>
      {withPlus && isLoading && <Loader className="tw-ml-auto tw-w-4" />}
      {withPlus && !isLoading && (
        <IconButton
          size="wrap"
          variant="ghost"
          icon={<RiAddLine className="tw-h-4 tw-w-4" />}
          className="tw-ml-auto tw-rounded-sm tw-border-none tw-bg-transparent tw-opacity-0 group-hover:tw-opacity-100"
          onClick={onClickPlus}
        />
      )}
    </div>
  );
};
