import React from "react";

import { EN } from "@frontend/assets/i18n/en";

import { NewModal } from "@shared/primitives/new-modal";
import { isMacOS } from "@shared/utils/browser";

type KeyBinding = string[];

interface ShortcutsModalProps {
  closeModal: () => void;
}
export const ShortcutsModal: React.FC<ShortcutsModalProps> = ({ closeModal }) => {
  const renderShortcutItems = EN.shortcuts.map((shortcut) => {
    return <ShortcutItem key={shortcut.title} {...shortcut} />;
  });

  return (
    <NewModal onDismiss={closeModal} size="930" showCloseButton title="Shortcuts">
      <div className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-4">{renderShortcutItems}</div>
    </NewModal>
  );
};

export interface ShortcutItemProps {
  title?: string;
  keys: KeyBinding[];
  variation?: KeyBinding[];
}
export const ShortcutItem: React.FC<ShortcutItemProps> = ({ title, keys, variation }) => {
  const keyBindings = (isMacOS() && variation) || keys;

  const renderedShortcuts = keyBindings.reduce((acc, keyBinding, index) => {
    acc.push(<KeyBindings keyBinding={keyBinding} key={index} />);

    if (index !== keyBindings.length - 1) {
      acc.push(<Comma key={`${index}-comma`} />);
    }

    return acc;
  }, [] as React.ReactNode[]);

  return (
    <div className="tw-flex tw-items-center tw-justify-between">
      {title && <label className="tw-m-0 tw-block tw-text-sm tw-font-semibold">{title}</label>}
      <div className="tw-flex tw-justify-end">{renderedShortcuts}</div>
    </div>
  );
};

interface KeyBindingsProps {
  keyBinding: KeyBinding;
}
const KeyBindings: React.FC<KeyBindingsProps> = ({ keyBinding }) => {
  const renderedKeys = keyBinding.reduce((acc, key, index) => {
    acc.push(<Key key={key}>{key}</Key>);

    if (index !== keyBinding.length - 1) {
      acc.push(<Plus key={`${key}-plus`} />);
    }

    return acc;
  }, [] as React.ReactNode[]);

  return <div className="tw-flex tw-gap-0.5">{renderedKeys}</div>;
};

interface KeyProps {
  children: React.ReactNode;
}
const Key: React.FC<KeyProps> = ({ children }) => {
  return (
    <div className="tw-inset-0 tw-flex tw-h-6 tw-min-w-[30px] tw-items-center tw-justify-center tw-rounded tw-border tw-border-neutral-200 tw-px-2 tw-py-0 tw-text-sm">
      {children}
    </div>
  );
};

const Comma: React.FC = () => {
  return <span className="tw-mr-2">,</span>;
};

const Plus: React.FC = () => {
  return <span className="tw-px-0.5">+</span>;
};
