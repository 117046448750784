import axios from "axios";

import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { accountQuery, accountStore } from "@shared/state/account";

import { RoleName } from "@getsubly/common";

import { getAccountTeam } from "./account.service";
import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

export const updateMemberRole = async (id: string, role: RoleName): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.put(
      `/${accountId}/members/${id}`,
      { role },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const accountTeam = accountQuery.getValue().accountTeam;
    if (accountTeam) {
      const members = accountTeam.members?.map((m) => {
        if (m.id === id) {
          return { ...m, role };
        }

        return m;
      });

      accountStore.update((s) => ({
        ...s,
        accountTeam: { ...accountTeam, members }
      }));
    } else {
      getAccountTeam();
    }
  } catch (error) {
    handleError(error);
  }
};

export const requestNewRole = async (id: string, roleName: RoleName): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.post(
      `/${accountId}/members/request-role`,
      { roleName },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const accountTeam = accountQuery.getValue().accountTeam;
    if (accountTeam) {
      const members = accountTeam.members?.map((m) => {
        if (m.id === id) {
          return { ...m, requestedRole: roleName };
        }

        return m;
      });

      accountStore.update((s) => ({
        ...s,
        accountTeam: { ...accountTeam, members }
      }));
    } else {
      getAccountTeam();
    }
  } catch (error) {
    handleError(error);
  }
};

export const acceptNewRoleRequest = async (userId: string): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.put(`/${accountId}/members/${userId}/accept`, null, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const accountTeam = accountQuery.getValue().accountTeam;
    if (accountTeam) {
      const members = accountTeam.members?.map((m) => {
        if (m.id === userId) {
          return { ...m, requestedRole: undefined };
        }

        return m;
      });

      accountStore.update((s) => ({
        ...s,
        accountTeam: { ...accountTeam, members }
      }));
    } else {
      getAccountTeam();
    }
  } catch (error) {
    handleError(error);
  }
};

export const declineNewRoleRequest = async (userId: string): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.put(`/${accountId}/members/${userId}/reject`, null, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const accountTeam = accountQuery.getValue().accountTeam;
    if (accountTeam) {
      const members = accountTeam.members?.map((m) => {
        if (m.id === userId) {
          return { ...m, requestedRole: undefined };
        }

        return m;
      });

      accountStore.update((s) => ({
        ...s,
        accountTeam: { ...accountTeam, members }
      }));
    } else {
      getAccountTeam();
    }
  } catch (error) {
    handleError(error);
  }
};

export const deleteMember = async (id: string): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.delete(`/${accountId}/members/${id}`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const accountTeam = accountQuery.getValue().accountTeam;
    if (accountTeam) {
      const members = accountTeam.members?.filter((m) => m.id !== id);

      accountStore.update((s) => ({
        ...s,
        accountTeam: { ...accountTeam, members }
      }));
    } else {
      getAccountTeam();
    }
  } catch (error) {
    handleError(error);
  }
};
