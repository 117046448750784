import React from "react";
import { isEqual, omit, pick } from "lodash-es";
import { v4 } from "uuid";

import { useArtworkUrl } from "@shared/hooks/use-artwork-url";
import { AccountTemplateStyles } from "@shared/interfaces/account";
import { editorStateRepository } from "@shared/state/editor/editor.state";

import {
  AspectFit,
  AspectRatio,
  AspectRatioConfig,
  assColorToHex,
  AssStyle,
  BackgroundImageConfig,
  BackgroundStyle,
  BurnPosition,
  CustomLogoConfig,
  CustomLogoSize,
  MediaConfig,
  TitleConfig
} from "@getsubly/common";
import { AudioTemplate, AudioTemplateStyle } from "@media-editor/types";

export const assStyleToStyleConvert = (style?: AccountTemplateStyles): React.CSSProperties => {
  if (!style) {
    return {};
  }

  const effects = assEffectsToStyle(style);
  const alignment = assAlignmentToStyle(style);

  return {
    fontFamily: style.font,
    // Skip the size to keep it inside the box
    // fontSize: `${style.fontSize}px`,
    lineHeight: 1,
    fontWeight: style.bold === -1 ? "bold" : "normal",
    fontStyle: style.italic === -1 ? "italic" : "",
    textDecoration: style.underline === -1 ? "underline" : "",
    color: assColorToHex(style.colorPrimary),
    ...alignment,
    ...effects
  };
};

const assEffectsToStyle = (style: AssStyle): React.CSSProperties => {
  const color = assColorToHex(style.colorOutline);

  if (style.subtitles?.fullWidth) {
    return {
      width: "100%",
      background: color
    };
  }

  switch (style.backgroundStyle) {
    case BackgroundStyle.Outline:
      return {
        WebkitTextStroke: "0.75px",
        WebkitTextStrokeColor: color
      };
    case BackgroundStyle.DropShadow:
      return {
        textShadow: `${color} 1px 1px`
      };
    case BackgroundStyle.Background:
      return {
        background: color
      };

    default:
      return {};
  }
};

const assAlignmentToStyle = (style: AssStyle): React.CSSProperties => {
  const alignmentValue = style.alignment - Math.floor((style.alignment - 1) / 3) * 3;

  switch (alignmentValue) {
    case 1:
      return {
        justifyContent: "flex-start"
      };
    case 3:
      return {
        justifyContent: "flex-end"
      };

    default:
      return {
        justifyContent: "center"
      };
  }
};

const STYLE_ALL_PROPERTIES: Array<keyof AccountTemplateStyles> = [
  "font",
  "fontSize",
  "colorPrimary",
  "colorSecondary",
  "colorOutline",
  "colorBack",
  "bold",
  "italic",
  "underline",
  "strikeOut",
  "spacing",
  "angle",
  "borderStyle",
  "outline",
  "shadow",
  "alignment",
  "hasDefaultCustomPosition",
  "marginL",
  "marginR",
  "marginV",
  "encoding",
  "hasBackground",
  "backgroundStyle",
  "showWatermark",
  "customLogo",
  "aspectRatio",
  "border",
  "headline",
  "templateId",
  "customFont",
  "subtitles",
  "titles"
];

export const presetHasChanged = (template?: AccountTemplateStyles, style?: AccountTemplateStyles): boolean => {
  if (!template || !style) {
    return false;
  }

  const saved = parseMediaConfig(
    omit(
      template,
      "templateId",
      "customLogo",
      "headline",
      "aspectRatio.ratio",
      "aspectRatio.backgroundImage",
      "aspectRatio.format",
      "titles"
    )
  );
  const current = parseMediaConfig(
    omit(
      style,
      "templateId",
      "customLogo",
      "headline",
      "aspectRatio.ratio",
      "aspectRatio.backgroundImage",
      "aspectRatio.format",
      "titles"
    )
  );

  return !isEqual(saved, current);
};

export const parseMediaConfig = (raw: AccountTemplateStyles): AccountTemplateStyles => {
  return pick(raw, ...STYLE_ALL_PROPERTIES);
};

const AUDIO_TEMPLATE_1: AudioTemplate = {
  templateId: "audiogram-1",
  fontSize: 56,
  colorPrimary: "000B0B0B",
  alignment: 5,
  marginL: 69,
  marginR: 69,
  marginV: 298,
  titles: [
    {
      style: {
        marginL: 69,
        marginR: 69,
        marginV: 69,
        alignment: 7,
        colorPrimary: "000B0B0B",
        fontSize: 56
      }
    }
  ],
  artwork: {
    id: "template-1",
    url: "audio-templates/template-1.png",
    dimensions: {
      width: 1080,
      height: 1080
    },
    sublyArtwork: true
  },
  customLogo: {
    visible: false,
    id: "",
    url: "",
    position: BurnPosition.TopRight,
    opacity: 100,
    size: CustomLogoSize.Small,
    dimensions: {
      width: 0,
      height: 0
    },
    padding: {
      x: 69,
      y: 69
    }
  },
  aspectRatio: {
    color: "#39EFA5"
  }
};

const AUDIO_TEMPLATE_2: AudioTemplate = {
  templateId: "audiogram-2",
  fontSize: 56,
  colorPrimary: "000B0B0B",
  alignment: 8,
  marginL: 100,
  marginR: 100,
  marginV: 280,
  titles: [
    {
      style: {
        alignment: 1,
        colorPrimary: "00FFFFFF",
        fontSize: 56,
        marginL: 230,
        marginR: 230,
        marginV: 64
      }
    }
  ],
  artwork: {
    id: "template-2",
    url: "audio-templates/template-2.png",
    dimensions: {
      width: 1080,
      height: 1080
    },
    sublyArtwork: true
  },
  customLogo: {
    visible: false,
    id: "",
    url: "",
    position: BurnPosition.BottomLeft,
    opacity: 100,
    size: CustomLogoSize.Small,
    dimensions: {
      width: 0,
      height: 0
    },
    padding: {
      x: 59,
      y: 59
    }
  },
  aspectRatio: {
    color: "#8A50F7"
  },
  noLogoHeadline: {
    marginL: 74,
    marginR: 74,
    marginV: 64
  }
};

const AUDIO_TEMPLATE_3: AudioTemplate = {
  templateId: "audiogram-3",
  fontSize: 56,
  colorPrimary: "00FFFFFF",
  alignment: 8,
  marginL: 150,
  marginR: 150,
  marginV: 350,
  titles: [
    {
      style: {
        alignment: 7,
        colorPrimary: "00FFFFFF",
        fontSize: 56,
        marginL: 230,
        marginR: 230,
        marginV: 78
      }
    }
  ],
  artwork: {
    id: "template-3",
    url: "audio-templates/template-3.png",
    dimensions: {
      width: 1080,
      height: 1080
    },
    sublyArtwork: true
  },
  customLogo: {
    visible: false,
    id: "",
    url: "",
    position: BurnPosition.TopLeft,
    opacity: 100,
    size: CustomLogoSize.Small,
    dimensions: {
      width: 0,
      height: 0
    },
    padding: {
      x: 69,
      y: 69
    }
  },
  aspectRatio: {
    color: "#520F9A"
  },
  noLogoHeadline: {
    marginL: 78,
    marginR: 78,
    marginV: 78
  }
};

const AUDIO_TEMPLATE_4: AudioTemplate = {
  templateId: "audiogram-4",
  fontSize: 56,
  colorPrimary: "000B0B0B",
  alignment: 7,
  marginL: 100,
  marginR: 100,
  marginV: 300,
  titles: [
    {
      style: {
        alignment: 3,
        colorPrimary: "000B0B0B",
        fontSize: 56,
        marginL: 230,
        marginR: 230,
        marginV: 64
      }
    }
  ],
  artwork: {
    id: "template-4",
    url: "audio-templates/template-4.png",
    dimensions: {
      width: 1080,
      height: 1080
    },
    sublyArtwork: true
  },
  customLogo: {
    visible: false,
    id: "",
    url: "",
    position: BurnPosition.BottomRight,
    opacity: 100,
    size: CustomLogoSize.Small,
    dimensions: {
      width: 0,
      height: 0
    },
    padding: {
      x: 59,
      y: 59
    }
  },
  aspectRatio: {
    color: "#E2FDF2"
  },
  noLogoHeadline: {
    marginL: 64,
    marginR: 64,
    marginV: 64
  }
};

interface BaseAudiogramTemplate extends Omit<MediaConfig, "playResX" | "playResY"> {
  aspectRatio: AspectRatioConfig;
}

const BASE_AUDIOGRAM_TEMPLATE_TITLE: TitleConfig = {
  id: v4(),
  start: 0,
  end: 5000,
  showFullTime: false,
  text: "Text goes here",
  style: {
    font: "Arial",
    fontSize: 104,
    colorPrimary: "FFFFFF",
    colorSecondary: "0000FF",
    colorOutline: "00000000",
    colorBack: "000000",
    bold: -1,
    italic: 0,
    underline: 0,
    strikeOut: 0,
    scaleX: 100,
    scaleY: 100,
    spacing: 0,
    angle: 0,
    borderStyle: 3,
    outline: 10,
    shadow: 0.5,
    alignment: 5,
    marginL: 100,
    marginR: 100,
    marginV: 200,
    encoding: 0,
    hasBackground: false,
    backgroundStyle: BackgroundStyle.Outline
  }
};

const BASE_AUDIOGRAM_TEMPLATE: BaseAudiogramTemplate = {
  fontSize: 72,
  colorPrimary: "000B0B0B",
  colorOutline: "000000",
  colorBack: "009A0F52",
  alignment: 8,
  font: "Inter",
  colorSecondary: "0000FF",
  bold: 0,
  italic: 0,
  underline: 0,
  strikeOut: 0,
  scaleX: 100,
  scaleY: 100,
  spacing: 0,
  angle: 0,
  borderStyle: "\r",
  outline: 0,
  shadow: 0,
  marginL: 100,
  marginR: 100,
  marginV: 280,
  encoding: 0,
  backgroundStyle: BackgroundStyle.None,
  hasBackground: false,
  showWatermark: false,
  titles: [BASE_AUDIOGRAM_TEMPLATE_TITLE],
  aspectRatio: {
    ratio: AspectRatio.Square,
    fit: AspectFit.Border,
    color: "#8A50F7"
  }
};

export const createAudioTemplateConfig = (template: AudioTemplate, ratio: AspectRatio): BaseAudiogramTemplate => {
  const artworkUrl = getBackgroundImage(template.templateId, ratio);
  return {
    ...BASE_AUDIOGRAM_TEMPLATE,
    ...template,
    titles: [
      {
        ...BASE_AUDIOGRAM_TEMPLATE_TITLE,
        style: {
          ...BASE_AUDIOGRAM_TEMPLATE_TITLE.style,
          ...template.titles?.[0].style
        }
      }
    ],
    headline: undefined,
    aspectRatio: {
      ...BASE_AUDIOGRAM_TEMPLATE.aspectRatio,
      ...template.aspectRatio,
      ratio: ratio
    },
    artwork: {
      ...template.artwork,
      url: artworkUrl
    }
  };
};

export const audioTemplateConfigToStyles = (
  template: AudioTemplate,
  small?: boolean
): {
  cardStyles: React.CSSProperties;
  titleStyles: React.CSSProperties;
  cueStyles: React.CSSProperties;
  logoStyles: React.CSSProperties;
} => {
  const mediaConfig = editorStateRepository.assConfig;
  let aspectRatio = mediaConfig?.aspectRatio?.ratio ?? AspectRatio.Square;

  if (aspectRatio === AspectRatio.Original) {
    aspectRatio = AspectRatio.Square;
  }

  const dimension = small ? 180 : 450;
  const [baseWidth, baseHeight] = getRatioWidth(aspectRatio, dimension, dimension);

  const titleStyles = template.titles?.[0]?.style ?? BASE_AUDIOGRAM_TEMPLATE_TITLE.style;

  const titlePosition = getTextPosition(titleStyles, baseWidth, baseHeight);
  const cuePosition = getTextPosition(template, baseWidth, baseHeight);

  const artwork = getBackgroundImage(template.templateId, aspectRatio);
  const artworkUrl = useArtworkUrl(artwork, true);

  return {
    cardStyles: {
      width: baseWidth,
      height: baseHeight,
      backgroundColor: template.aspectRatio.color,
      backgroundImage: `url(${artworkUrl})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      position: "relative",
      borderRadius: "8px"
    },
    titleStyles: {
      color: assColorToHex(titleStyles.colorPrimary),
      fontWeight: "bold",
      fontSize: "10px",
      textAlign: getTextAlignment(titleStyles.alignment),
      margin: 0,
      ...titlePosition
    },
    cueStyles: {
      color: assColorToHex(template.colorPrimary),
      fontSize: "10px",
      lineHeight: "18px",
      margin: 0,
      textAlign: getTextAlignment(template.alignment),
      ...cuePosition
    },
    logoStyles: getLogoStyles(template.customLogo, baseWidth, baseHeight, aspectRatio)
  };
};

const getTextAlignment = (alignment: number) => {
  switch (alignment) {
    case 1:
    case 4:
    case 7:
      return "left";
    case 2:
    case 5:
    case 8:
      return "center";
    case 3:
    case 6:
    case 9:
      return "right";
  }
};

const getTextPosition = (style: AudioTemplateStyle, width: number, height: number): React.CSSProperties => {
  const { marginL, marginV, marginR, alignment } = style;

  const widthRatio = width / 1080;
  const heightRatio = height / 1080;

  switch (alignment) {
    case 1:
    case 2:
    case 3:
      return {
        position: "absolute",
        bottom: `${marginV * heightRatio + 3}px`,
        left: `${marginL * widthRatio}px`,
        right: `${marginR * widthRatio}px`
      };
    case 4:
    case 5:
    case 6:
      return {
        position: "absolute",
        top: `${height * heightRatio + 30}px`,
        left: `${marginL * widthRatio}px`,
        right: `${marginR * widthRatio}px`
      };

    case 7:
    case 8:
    case 9:
      return {
        position: "absolute",
        top: `${marginV * heightRatio}px`,
        left: `${marginL * widthRatio}px`,
        right: `${marginR * widthRatio}px`
      };
  }

  return {};
};

const getRatioWidth = (ratio: AspectRatio, width: number, height: number): [number, number] => {
  switch (ratio) {
    case AspectRatio.Square:
      break;
    case AspectRatio.Landscape:
      height = height / (16 / 9);
      break;
    case AspectRatio.Portrait:
      width = width / (16 / 9);
      break;
    case AspectRatio.Vertical:
      width = width / (5 / 4);
      break;
  }
  return [width, height];
};

const getLogoStyles = (
  { position, padding }: CustomLogoConfig,
  width: number,
  height: number,
  ratio: AspectRatio
): React.CSSProperties => {
  const widthRatio = width / 1080;
  const heightRatio = height / 1080;

  const size = ratio === AspectRatio.Portrait ? "25px" : "32px";

  switch (position) {
    case BurnPosition.BottomLeft:
      return {
        objectFit: "scale-down",
        position: "absolute",
        bottom: `${padding.y * heightRatio}px`,
        left: `${padding.x * widthRatio}px`,
        height: `${size}`,
        width: `${size}`
      };
    case BurnPosition.BottomRight:
      return {
        objectFit: "scale-down",
        position: "absolute",
        bottom: `${padding.y * heightRatio}px`,
        right: `${padding.x * widthRatio}px`,
        height: `${size}`,
        width: `${size}`
      };
    case BurnPosition.TopLeft:
      return {
        objectFit: "scale-down",
        position: "absolute",
        top: `${padding.y * heightRatio}px`,
        left: `${padding.x * widthRatio}px`,
        height: `${size}`,
        width: `${size}`
      };
    case BurnPosition.TopRight:
      return {
        objectFit: "scale-down",
        position: "absolute",
        top: `${padding.y * heightRatio}px`,
        right: `${padding.x * widthRatio}px`,
        height: `${size}`,
        width: `${size}`
      };
    case BurnPosition.BottomCenter:
      return {};
  }
};

export const getBackgroundImage = (templateId: string, aspectRatio: AspectRatio): string => {
  switch (aspectRatio) {
    case AspectRatio.Landscape:
      if (templateId === AUDIO_TEMPLATE_1.templateId) {
        return "audio-templates/template-1-1920x1080.png";
      }
      if (templateId === AUDIO_TEMPLATE_2.templateId) {
        return "audio-templates/template-2-1920x1080.png";
      }
      if (templateId === AUDIO_TEMPLATE_3.templateId) {
        return "audio-templates/template-3-1920x1080.png";
      }
      if (templateId === AUDIO_TEMPLATE_4.templateId) {
        return "audio-templates/template-4-1920x1080.png";
      }
      break;

    case AspectRatio.Portrait:
      if (templateId === AUDIO_TEMPLATE_1.templateId) {
        return "audio-templates/template-1-1080x1920.png";
      }
      if (templateId === AUDIO_TEMPLATE_2.templateId) {
        return "audio-templates/template-2-1080x1920.png";
      }
      if (templateId === AUDIO_TEMPLATE_3.templateId) {
        return "audio-templates/template-3-1080x1920.png";
      }
      if (templateId === AUDIO_TEMPLATE_4.templateId) {
        return "audio-templates/template-4-1080x1920.png";
      }
      break;

    case AspectRatio.Vertical:
      if (templateId === AUDIO_TEMPLATE_1.templateId) {
        return "audio-templates/template-1-1080x1350.png";
      }
      if (templateId === AUDIO_TEMPLATE_2.templateId) {
        return "audio-templates/template-2-1080x1350.png";
      }
      if (templateId === AUDIO_TEMPLATE_3.templateId) {
        return "audio-templates/template-3-1080x1350.png";
      }
      if (templateId === AUDIO_TEMPLATE_4.templateId) {
        return "audio-templates/template-4-1080x1350.png";
      }
      break;

    case AspectRatio.Square:
      if (templateId === AUDIO_TEMPLATE_1.templateId) {
        return "audio-templates/template-1.png";
      }
      if (templateId === AUDIO_TEMPLATE_2.templateId) {
        return "audio-templates/template-2.png";
      }
      if (templateId === AUDIO_TEMPLATE_3.templateId) {
        return "audio-templates/template-3.png";
      }
      if (templateId === AUDIO_TEMPLATE_4.templateId) {
        return "audio-templates/template-4.png";
      }
      break;
  }

  return "";
};

export const AUDIO_TEMPLATES = [AUDIO_TEMPLATE_1, AUDIO_TEMPLATE_2, AUDIO_TEMPLATE_3, AUDIO_TEMPLATE_4];

export const DEFAULT_BACKGROUND_IMAGE: BackgroundImageConfig = {
  visible: false,
  id: "",
  url: "",
  dimensions: {
    width: 0,
    height: 0
  }
};
