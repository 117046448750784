import React from "react";
import classNames from "classnames";

import { deleteMedia } from "@frontend/api/media.service";
import { DASHBOARD_PATH } from "@frontend/routes";

import { Button } from "@shared/primitives/button";
import { Modal } from "@shared/primitives/modal";
import { pluralize } from "@shared/utils/strings";

import styles from "./delete-media-modal.module.scss";

interface DeleteMediaModalProps {
  mediaIds: string[];
  closeModal: (shouldClear?: boolean) => void;
  onBeforeDelete?: () => void;
  onAfterDelete?: () => void;
  isEditor?: boolean;
}

export const DeleteMediaModal: React.FC<DeleteMediaModalProps> = ({
  mediaIds,
  closeModal,
  onBeforeDelete,
  onAfterDelete,
  isEditor = false
}) => {
  return (
    <Modal onDismiss={closeModal} showCloseButton closeIconClassName={styles["close-icon"]} className={styles.modal}>
      <DeleteMediaModalContainer
        mediaIds={mediaIds}
        closeModal={closeModal}
        onBeforeDelete={onBeforeDelete}
        onAfterDelete={onAfterDelete}
        isEditor={isEditor}
      />
    </Modal>
  );
};

const DeleteMediaModalContainer: React.FC<DeleteMediaModalProps> = ({
  mediaIds,
  closeModal,
  onBeforeDelete,
  onAfterDelete,
  isEditor
}) => {
  const [isLoading, setLoading] = React.useState(false);

  const deleteSingleMedia = async (mediaIds: string[]) => {
    onBeforeDelete?.();
    await deleteMedia(mediaIds[0]);
    onAfterDelete?.();
  };

  const deleteMultipleMediums = async (mediaId: string) => {
    onBeforeDelete?.();
    await deleteMedia(mediaId);
    onAfterDelete?.();
  };

  const handleDelete = (event: Event) => {
    event.preventDefault();
    setLoading(true);
    if (mediaIds.length === 1) {
      deleteSingleMedia(mediaIds);
    } else {
      mediaIds.forEach(async (mediaId) => await deleteMultipleMediums(mediaId));
    }

    setLoading(false);
    closeModal(true);
  };

  return (
    <>
      <h5 className={classNames(styles.header, "tw-text-h5")}>Delete {pluralize(mediaIds.length, "file")}</h5>

      <p>
        Are you sure you would like to permanently delete{" "}
        {isEditor ? "this" : <span className="tw-font-bold tw-text-black">{mediaIds.length}</span>}{" "}
        {pluralize(mediaIds.length, "file")}? This cannot be undone.
      </p>
      <div className={styles["button-container"]}>
        <Button variant="secondary" onClick={closeModal} className={styles["cancel-button"]} size="36">
          Cancel
        </Button>
        <Button
          variant="destructive"
          type="link"
          to={DASHBOARD_PATH}
          onClick={handleDelete}
          loading={isLoading}
          size="36"
        >
          Delete
        </Button>
      </div>
    </>
  );
};
