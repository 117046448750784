import { arrayIncludes } from "@shared/utils/arrays";

import AbhayaLibre from "./AbhayaLibre-Regular.ttf";
import AbrilFatface from "./AbrilFatface.ttf";
import Alegreya from "./Alegreya.ttf";
import Archivo from "./Archivo.ttf";
import Arial from "./Arial.ttf";
import Arvo from "./Arvo.ttf";
import B612 from "./B612.ttf";
import Benne from "./Benne-Regular.ttf";
import BioRhyme from "./BioRhyme.ttf";
import Cairo from "./Cairo.ttf";
import Cardo from "./Cardo.ttf";
import ConcertOne from "./ConcertOne.ttf";
import Cormorant from "./Cormorant.ttf";
import CourierNew from "./CourierNew.ttf";
import CrimsonText from "./CrimsonText.ttf";
import FjallaOne from "./FjallaOne.ttf";
import FrankRuhlLibre from "./FrankRuhlLibre.ttf";
import Gayathri from "./Gayathri-Regular.ttf";
import Georgia from "./Georgia.ttf";
import HelveticaNeue from "./HelveticaNeue.ttf";
import HindMadurai from "./HindMadurai-Regular.ttf";
import HindSiliguri from "./HindSiliguri.ttf";
import HindVadodara from "./HindVadodara-Regular.ttf";
import IBMPlexSans from "./IBMPlexSans.ttf";
import Inter from "./Inter.ttf";
import Kanit from "./Kanit-Regular.ttf";
import Karla from "./Karla.ttf";
import Kosugi from "./Kosugi-Regular.ttf";
import Lato from "./Lato.ttf";
import Lora from "./Lora.ttf";
import Mandali from "./Mandali-Regular.ttf";
import Montserrat from "./Montserrat.ttf";
import Muli from "./Muli.ttf";
import NotoSansArmenian from "./NotoSansArmenian-Regular.ttf";
import NotoSansGurmukhi from "./NotoSansGurmukhi-Regular.ttf";
import NotoSansJP from "./NotoSansJP.otf";
import NotoSansKR from "./NotoSansKR.otf";
import NotoSansSC from "./NotoSansSC.otf";
import NotoSansTC from "./NotoSansTC-Regular.ttf";
import NotoSerifArmenian from "./NotoSerifArmenian-Regular.ttf";
import NotoSerifEthiopic from "./NotoSerifEthiopic-Regular.ttf";
import NotoSerifJP from "./NotoSerifJP-Regular.otf";
import NotoSerifTC from "./NotoSerifTC-Regular.ttf";
import OldStandardTT from "./OldStandardTT.ttf";
import Oswald from "./Oswald.ttf";
import PlayfairDisplay from "./PlayfairDisplay.ttf";
import PTSerif from "./PTSerif.ttf";
import Rakkas from "./Rakkas.ttf";
import Roboto from "./Roboto.ttf";
import Rubik from "./Rubik.ttf";
import SourceSansPro from "./SourceSansPro.ttf";
import Spectral from "./Spectral.ttf";
import Times from "./Times.ttf";
import TitilliumWeb from "./TitilliumWeb.ttf";
import Ubuntu from "./Ubuntu.ttf";
import Varela from "./Varela.ttf";
import Verdana from "./Verdana.ttf";
import Vollkorn from "./Vollkorn.ttf";
import WorkSans from "./WorkSans.ttf";
import YatraOne from "./YatraOne.ttf";

type LatinFont = {
  latin: true;
  codes?: undefined;
};
type NonLatinFont = {
  latin?: undefined;
  codes: string[];
};
type Font = {
  name: string;
  path: string;
  codes?: string[];
  latin?: true;
} & (LatinFont | NonLatinFont);

export const ALL_FONTS: Font[] = [
  { path: AbrilFatface, name: "Abril Fatface", latin: true },
  { path: Alegreya, name: "Alegreya", latin: true },
  { path: Archivo, name: "Archivo", latin: true },
  { path: Arial, name: "Arial", latin: true },
  { path: Arvo, name: "Arvo", latin: true },
  { path: B612, name: "B612", latin: true },
  { path: BioRhyme, name: "BioRhyme", latin: true },
  { path: Cairo, name: "Cairo", latin: true },
  { path: Cardo, name: "Cardo", latin: true },
  { path: ConcertOne, name: "Concert One", latin: true },
  { path: Cormorant, name: "Cormorant", latin: true },
  { path: CourierNew, name: "Courier New", latin: true },
  { path: CrimsonText, name: "Crimson Text", latin: true },
  { path: FjallaOne, name: "Fjalla One", latin: true },
  { path: FrankRuhlLibre, name: "Frank Ruhl Libre", latin: true },
  { path: Georgia, name: "Georgia", latin: true },
  { path: HelveticaNeue, name: "Helvetica Neue", latin: true },
  { path: IBMPlexSans, name: "IBM Plex Sans", latin: true },
  { path: Inter, name: "Inter", latin: true },
  { path: Karla, name: "Karla", latin: true },
  { path: Lato, name: "Lato", latin: true },
  { path: Lora, name: "Lora", latin: true },
  { path: Montserrat, name: "Montserrat", latin: true },
  { path: Muli, name: "Muli", latin: true },
  { path: NotoSansArmenian, name: "Noto Sans Armenian", codes: ["hy"] },
  { path: NotoSansGurmukhi, name: "Noto Sans Gurmukhi", codes: ["pa"] },
  { path: NotoSerifArmenian, name: "Noto Serif Armenian", codes: ["hy"] },
  { path: NotoSerifEthiopic, name: "Noto Serif Ethiopic", codes: ["am"] },
  { path: OldStandardTT, name: "Old Standard TT", latin: true },
  { path: Oswald, name: "Oswald", latin: true },
  { path: PlayfairDisplay, name: "Playfair Display", latin: true },
  { path: PTSerif, name: "PT Serif", latin: true },
  { path: Rakkas, name: "Rakkas", latin: true },
  { path: Roboto, name: "Roboto", latin: true },
  { path: Rubik, name: "Rubik", latin: true },
  { path: SourceSansPro, name: "Source Sans Pro", latin: true },
  { path: Spectral, name: "Spectral", latin: true },
  { path: Times, name: "Times New Roman", latin: true },
  { path: TitilliumWeb, name: "Titillium Web", latin: true },
  { path: Ubuntu, name: "Ubuntu", latin: true },
  { path: Varela, name: "Varela", latin: true },
  { path: Verdana, name: "Verdana", latin: true },
  { path: Vollkorn, name: "Vollkorn", latin: true },
  { path: WorkSans, name: "Work Sans", latin: true },
  { path: YatraOne, name: "Yatra One", latin: true },
  { path: NotoSansSC, name: "Noto Sans SC", codes: ["zh-CN", "zh", "zh-TW"] },
  { path: NotoSansTC, name: "Noto Sans TC", codes: ["zh-CN", "zh", "zh-TW"] },
  { path: NotoSerifTC, name: "Noto Serif TC", codes: ["zh-CN", "zh", "zh-TW"] },
  { path: NotoSansKR, name: "Noto Sans KR", codes: ["ko-KR", "ko"] },
  { path: Kosugi, name: "Kosugi", codes: ["ja-JP", "ja"] },
  { path: NotoSansJP, name: "Noto Sans JP", codes: ["ja-JP", "ja"] },
  { path: NotoSerifJP, name: "Noto Serif JP", codes: ["ja-JP", "ja"] },
  { path: HindSiliguri, name: "Hindi Siliguri", codes: ["bn", "bn"] },
  { path: Benne, name: "Hind Vadodara", codes: ["kn"] },
  { path: HindVadodara, name: "Gayathri", codes: ["gu"] },
  { path: Gayathri, name: "Benne", codes: ["ml"] },
  { path: AbhayaLibre, name: "Abhaya Libre", codes: ["si"] },
  { path: HindMadurai, name: "Hind Madurai Regular", codes: ["ta-IN", "ta"] },
  { path: Mandali, name: "Mandali", codes: ["te-IN", "te"] },
  { path: Kanit, name: "Kanit", codes: ["th-TH", "th"] }
];

const NON_LATIN_LANGUAGES = ALL_FONTS.filter((f) => !f.latin)
  .map((f) => f.codes)
  .flat();

export const checkNonLatinLanguage = (languageCode: string): boolean => {
  return NON_LATIN_LANGUAGES.includes(languageCode);
};

export const fontsFor = (languages: string[]): Font[] => {
  return ALL_FONTS.filter((f) => f.codes && arrayIncludes(f.codes, languages));
};

export const fontsForLatin = (): Font[] => {
  return ALL_FONTS.filter((f) => f.latin);
};

export const fontPathsFor = (languageCodes: string[]): string[] => {
  return [...fontsFor(languageCodes).map((f) => f.path), ...fontsForLatin().map((f) => f.path)];
};

export const fontNamesFor = (languageCode = ""): string[] => {
  if (languageCode) {
    return fontsFor([languageCode]).map((f) => f.name);
  } else {
    return fontsForLatin().map((f) => f.name);
  }
};
