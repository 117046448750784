import React, { useEffect } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";

import { CloseIcon } from "@shared/components/icons";

export enum ModalAlignment {
  Top = "Top",
  Center = "Center"
}

export type ModalSize = "465" | "608" | "705" | "896" | "930";

export interface ModalProps {
  hideBackground?: boolean;
  className?: string;
  modalClassName?: string;
  backgroundClassName?: string;
  onDismiss?: () => void;
  showCloseButton?: boolean;
  closeIconClassName?: string;
  disableBackgroundDismiss?: boolean;
  size?: ModalSize;
  alignment?: ModalAlignment;
  children?: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
  onDismiss,
  hideBackground,
  className,
  modalClassName,
  backgroundClassName,
  showCloseButton,
  closeIconClassName,
  size = "465",
  disableBackgroundDismiss,
  alignment,
  children
}) => {
  const portalElement = document.querySelector("#portal");
  const el = document.createElement("div");

  useEffect(() => {
    if (!portalElement) {
      return;
    }

    portalElement.appendChild(el);

    return () => {
      portalElement.removeChild(el);
    };
  }, [portalElement, el]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onDismiss && onDismiss();
      }
    };

    document.querySelector("body")?.classList.add("tw-overflow-hidden");
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.querySelector("body")?.classList.remove("tw-overflow-hidden");
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [onDismiss]);

  if (!el) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={classNames(
        "tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[1001] tw-flex tw-flex-col tw-items-center tw-overflow-hidden !tw-overflow-y-auto",
        { "tw-static": hideBackground },
        modalClassName
      )}
      onClick={() => !disableBackgroundDismiss && onDismiss && onDismiss()}
    >
      {!hideBackground && (
        <div
          className={classNames(
            "!tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-min-h-full tw-bg-[rgba(30,_30,_30,_0.6)]",
            backgroundClassName
          )}
        />
      )}
      <div
        className={classNames(
          "tw-relative tw-top-0 tw-z-[50] tw-my-[50px] tw-max-w-[calc(100vw_-_48px)] tw-rounded tw-border-neutral-200 tw-bg-white tw-p-8 tw-shadow-[0_0_8px_rgba(0,_0,_0,_0.2)]",
          { "tw-mx-auto": alignment === ModalAlignment.Center },
          { "tw-w-[465px]": size === "465" },
          { "tw-w-[608px]": size === "608" },
          { "tw-w-[705px]": size === "705" },
          { "tw-w-[896px]": size === "896" },
          { "tw-w-[930px]": size === "930" },
          className
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {showCloseButton && (
          <CloseIcon
            className={classNames(
              "tw-absolute tw-right-3 tw-top-3 tw-h-[22px] tw-w-[22px] tw-cursor-pointer",
              closeIconClassName
            )}
            onClick={onDismiss}
          />
        )}
        {children}
      </div>
    </div>,
    el
  ) as React.ReactPortal;
};
