import React from "react";

import {
  Dropdown,
  DropdownIconButton,
  DropdownItem,
  DropdownMenu,
  DropdownToggleType
} from "@shared/primitives/dropdown";

import { RiLayoutGridLine, RiListCheck } from "@remixicon/react";

type ViewTypeDropdownProps = {
  viewType: "list" | "grid";
  onChangeViewType?: (viewType: "list" | "grid") => void;
};

export const ViewTypeDropdown: React.FC<ViewTypeDropdownProps> = ({ viewType, onChangeViewType }) => {
  return (
    <Dropdown>
      <DropdownIconButton
        size="32"
        variant="secondary"
        raised
        icon={viewType === "grid" ? <RiLayoutGridLine /> : <RiListCheck />}
      />
      <DropdownMenu placement="bottom-end" showInPortal className="tw-overflow-hidden">
        <DropdownItem
          onClick={() => onChangeViewType?.("grid")}
          lIcon={<RiLayoutGridLine className="tw-h-4 tw-w-4 tw-shrink-0 tw-text-neutral-700" />}
          showToggle={DropdownToggleType.CHECKMARK}
          checked={viewType === "grid"}
          className="!tw-min-h-[32px] !tw-px-[7px] !tw-py-[3px]"
        >
          <span className="tw-text-xs tw-font-medium">Grid</span>
        </DropdownItem>
        <DropdownItem
          onClick={() => onChangeViewType?.("list")}
          lIcon={<RiListCheck className="tw-h-4 tw-w-4 tw-shrink-0 tw-text-neutral-700" />}
          showToggle={DropdownToggleType.CHECKMARK}
          checked={viewType === "list"}
          className="!tw-min-h-[32px] !tw-px-[7px] !tw-py-[3px]"
        >
          <span className="tw-text-xs tw-font-medium">List</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
