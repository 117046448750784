import React from "react";
import classNames from "classnames";
import { formatDistance, intervalToDuration, subDays } from "date-fns";
import { Link } from "react-router-dom";

import { MEDIA_PATH } from "@frontend/routes";

import { EnrichedMediaListItem, MediaErrorReason, MediaJob } from "@shared/interfaces/media";
import { userPresenceQuery } from "@shared/state/user-presence";
import { durationLessThan1Min } from "@shared/utils/dates";
import { parseCreditToText } from "@shared/utils/plans";
import { formatBytes } from "@shared/utils/storage-size";

import { JobStatus, JobType, MediaStatus } from "@getsubly/common";
import { useObservable } from "@mindspace-io/react";

interface MediaCardBottomProps {
  media: EnrichedMediaListItem;
  isError: boolean;
}

export const MediaCardBottom: React.FC<MediaCardBottomProps> = (props) => {
  const isMediaReady = MediaStatus.Ready === props.media.status;

  const mediaLink = isMediaReady ? `${MEDIA_PATH}/${props.media.mediaId}` : "#";
  const mediaDuration = props.media.duration ?? 0;
  const { size, units } = formatBytes(props.media.storageBytes ?? 0, 2);

  const [activeUser] = useObservable(userPresenceQuery.selectActiveUser(props.media.mediaId));

  return (
    <Link
      to={mediaLink}
      className={classNames("tw-px-[15px] tw-py-[9px] tw-text-inherit tw-no-underline", {
        "tw-cursor-default": !isMediaReady
      })}
    >
      <div className="tw-flex tw-w-full tw-justify-between">
        <h6 className="tw-m-0 tw-truncate tw-text-xs tw-font-semibold tw-leading-5">{props.media.name}</h6>
        <p className="tw-whitespace-nowrap tw-text-xs tw-font-medium tw-leading-5 tw-text-neutral-500">
          {props.media?.storageBytes && `${size} ${units}`}
        </p>
      </div>
      <div className="tw-flex tw-justify-between">
        {!props.isError ? (
          <>
            <div className="tw-text-xs tw-font-medium tw-leading-5 tw-text-neutral-500">
              {parseCreditToText(mediaDuration)}
            </div>
            <div className="tw-text-xs tw-font-medium tw-leading-5 tw-text-neutral-500">
              {getMediaStatus(props.media, Boolean(activeUser))}
            </div>
          </>
        ) : (
          <span className="tw-ml-auto tw-text-xs tw-font-medium tw-text-destructive-600">Error</span>
        )}
      </div>
    </Link>
  );
};
const getStatusForPendingJob = (job?: Pick<MediaJob, "type">): string => {
  switch (job?.type) {
    case JobType.Upload:
      return "Uploading...";
    case JobType.Conversion:
      return "Preparing...";
    case JobType.Transcribe:
      return "Transcribing";
    default:
      return "Queuing";
  }
};

const getMediaStatus = (media: EnrichedMediaListItem, hasActiveUser?: boolean): string => {
  const job = media.latestJob;

  if (media.reason === MediaErrorReason.InsufficientFunds) {
    return "Insufficient credit";
  }

  if (media.status === MediaStatus.Failed) {
    return "Error in processing";
  }

  if (job) {
    switch (job.status) {
      case JobStatus.Uploading:
      case JobStatus.Uploaded:
      case JobStatus.Pending:
      case JobStatus.Converting:
        return getStatusForPendingJob(job);
    }
  }

  if (hasActiveUser) {
    return "Currently being edited";
  }

  const createdDuration = intervalToDuration({
    start: new Date(media.createdAt),
    end: new Date(media.updatedAt)
  });

  if (!durationLessThan1Min(createdDuration)) {
    return "Ready to edit";
  }

  return formatDistance(subDays(new Date(media.updatedAt), 0), new Date(), {
    addSuffix: true
  });
};
