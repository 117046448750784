import React from "react";
import classNames from "classnames";
import { format } from "date-fns";

import { addAccountToStore } from "@frontend/api/account.service";
import { acceptInvitation, deleteInvitation } from "@frontend/api/invitation.service";
import { hideNotification, markAllNotificationRead, markNotificationRead } from "@frontend/api/notifications.service";
import greenBell from "@frontend/assets/images/notifications-menu/green-bell.png";
import { MEDIA_PATH, SETTINGS_BILLING_PATH } from "@frontend/routes";

import { CloseIcon } from "@shared/components/icons";
import { Logo } from "@shared/components/logo";
import { useAccounts } from "@shared/hooks/use-accounts";
import { usePlan } from "@shared/hooks/use-plan";
import { INotification } from "@shared/interfaces/user";
import { Button } from "@shared/primitives/button";
import { notificationSuccess, notificationWarning } from "@shared/primitives/notification";
import { authQuery } from "@shared/state/auth/auth.query";
import { authStore } from "@shared/state/auth/auth.store";
import { parseCreditToText } from "@shared/utils/plans";
import { pluralize } from "@shared/utils/strings";

import { INotificationData, NotificationType } from "@getsubly/common";

import styles from "./notifications-menu.module.scss";

interface MenuWithNotificationsProps {
  unreadNotifications: INotification[];
  recentNotifications: INotification[];
  showUnreadOnly?: boolean;
}

export const MenuWithNotifications: React.FC<MenuWithNotificationsProps> = ({
  unreadNotifications,
  recentNotifications,
  showUnreadOnly
}) => {
  const handleMarkAllNotifications = () => {
    markAllNotificationRead();
  };

  return (
    <>
      <div className="tw-flex tw-justify-between tw-px-5 tw-pb-1">
        <p className="tw-text-sm tw-font-semibold tw-text-neutral-600">Recent</p>
        <p
          className={classNames(
            "tw-cursor-pointer tw-text-sm tw-font-semibold tw-text-neutral-600 hover:tw-text-neutral-900 hover:tw-underline"
          )}
          onClick={handleMarkAllNotifications}
        >
          Mark all as read
        </p>
      </div>

      <div className="tw-flex tw-max-h-[500px] tw-flex-col tw-gap-3 tw-overflow-y-auto tw-overflow-x-hidden">
        {unreadNotifications.length > 0 && <NotificationItems notifications={unreadNotifications} />}
        {!showUnreadOnly && <NotificationItems notifications={recentNotifications} />}
      </div>
    </>
  );
};

interface NotificationItemsProps {
  notifications: INotification[];
}

const NotificationItems: React.FC<NotificationItemsProps> = ({ notifications }) => {
  return notifications.map((notification) => {
    switch (notification.type) {
      case NotificationType.PaymentFailed:
        return (
          <NotificationItem
            key={notification.id}
            text={"Your payment method has failed, please update you payment method."}
            date={new Date(notification.createdAt)}
            notificationId={notification.id}
            read={Boolean(notification?.readAt)}
            showUpdateButton
          />
        );
      case NotificationType.MediaShared:
        return (
          <NotificationItem
            key={notification.id}
            text={`A new file has been shared with you${`${notification.data.name ?? ""}}.`}`}
            date={new Date(notification.createdAt)}
            notificationId={notification.id}
            read={Boolean(notification?.readAt)}
            openMedia={notification?.mediaId}
          />
        );
      case NotificationType.CommentTag:
        return (
          <NotificationItem
            key={notification.id}
            text={`You have been tagged in ${notification.data.name ?? "a media file."}`}
            date={new Date(notification.createdAt)}
            notificationId={notification.id}
            read={Boolean(notification?.readAt)}
            openMedia={notification?.mediaId}
          />
        );
      case NotificationType.LowMinutes:
        const { credit } = usePlan();
        const minutesLeft = credit?.total ?? 0;
        return (
          <NotificationItem
            key={notification.id}
            text={`You only have ${parseCreditToText( minutesLeft )} left in your balance. Buy more minutes now!`} // eslint-disable-line
            date={new Date(notification.createdAt)}
            notificationId={notification.id}
            read={Boolean(notification?.readAt)}
            showBuyMinutesButton
          />
        );
      case NotificationType.CardExpiring:
        return (
          <NotificationItem
            key={notification.id}
            text={`Your credit card is expiring ${  notification.data.timeLeft && notification.data.timeLeftUnits ? `in ${notification.data.timeLeft} ${pluralize(notification.data.timeLeft, notification.data.timeLeftUnits)}` : "soon"}. Please update your card details.`} // eslint-disable-line
            date={new Date(notification.createdAt)}
            notificationId={notification.id}
            read={Boolean(notification?.readAt)}
            showUpdateButton
          />
        );
      case NotificationType.UploadFailed:
        return (
          <NotificationItem
            key={notification.id}
            text={`Your file ${notification.data.name ?? ""} has failed to upload, please retry again.`}
            date={new Date(notification.createdAt)}
            notificationId={notification.id}
            read={Boolean(notification?.readAt)}
          />
        );
      case NotificationType.TeammateAccepted:
        return (
          <NotificationItem
            key={notification.id}
            text={`Your teammate ${notification.data.name ?? ""} has accepted your invite.`}
            date={new Date(notification.createdAt)}
            notificationId={notification.id}
            read={Boolean(notification?.readAt)}
          />
        );
      case NotificationType.TrialEnded:
        const { currentAccount } = useAccounts();
        const accountName = currentAccount?.accountName || "";
        return (
          <NotificationItem
            key={notification.id}
            text={`Your Subly Trial has ended. All users${accountName ? ` in ${accountName}` : ""} were now converted to viewers.\nUpgrade now to start creating, editing, and downloading content again.`} // eslint-disable-line
            date={new Date(notification.createdAt)}
            notificationId={notification.id}
            read={Boolean(notification?.readAt)}
            showUpgradeButton
          />
        );

      case NotificationType.Invitation:
        return (
          <NotificationItem
            key={notification.id}
            text={`You've been invited to join ${notification.data.name}'s workspace.`}
            date={new Date(notification.createdAt)}
            notificationId={notification.id}
            read={Boolean(notification?.readAt)}
            showWorkspaceInvite
            notificationData={notification.data}
          />
        );

      default:
        return <></>;
    }
  });
};

interface NotificationItemProps {
  text: string;
  date: Date;
  read: boolean;
  showUpdateButton?: boolean;
  showUpgradeButton?: boolean;
  showBuyMinutesButton?: boolean;
  showWorkspaceInvite?: boolean;
  openMedia?: string;
  notificationId: string;
  notificationData?: INotificationData;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  text,
  date,
  notificationId,
  read,
  showUpdateButton,
  showUpgradeButton,
  showBuyMinutesButton,
  showWorkspaceInvite,
  openMedia,
  notificationData
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const formattedDate = date ? format(date, "MMM dd, yyyy") : "";

  const invitationId = notificationData?.invitationId;
  const accountName = notificationData?.accountName;

  const handleMarkNotification = async () => {
    if (read) {
      hideNotification(notificationId);
      if (showWorkspaceInvite && invitationId) {
        setLoading(true);
        await deleteInvitation(invitationId);
      }
    } else {
      markNotificationRead(notificationId);
    }
  };

  const handleAcceptInvite = async () => {
    if (invitationId && accountName) {
      setLoading(true);
      const { joinedAccount, hasAvailableSeats, success } = await acceptInvitation(invitationId);

      if (success) {
        addAccountToStore(joinedAccount);

        if (authQuery.user?.invitations?.length) {
          const invitations = authQuery.user.invitations.filter((i) => i.id !== invitationId);
          authStore.update({ user: { ...authQuery.user, invitations } });
        }

        if (!hasAvailableSeats) {
          notificationWarning("You were switched to Viewer mode because there were no more seats available.");
        }

        notificationSuccess(`You now have access to ${accountName} workspace!`);
      }

      hideNotification(notificationId);
      setLoading(false);
    }
  };

  const renderButton = () => {
    if (showUpgradeButton || showUpdateButton || showBuyMinutesButton) {
      return (
        <Button
          variant="primary"
          type="link"
          className="tw-mt-2"
          to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }}
          size="36"
        >
          Upgrade now
        </Button>
      );
    }

    if (openMedia) {
      return (
        <Button
          variant="primary"
          type="link"
          className="tw-mt-2"
          to={{ pathname: `${MEDIA_PATH}/${openMedia}` }}
          size="36"
        >
          Open media
        </Button>
      );
    }

    if (showWorkspaceInvite) {
      return (
        <Button variant="primary" className="tw-mt-2" onClick={handleAcceptInvite} loading={isLoading} size="36">
          Accept
        </Button>
      );
    }
  };

  return (
    <div className="tw-flex tw-gap-2 tw-py-2 tw-pl-5 tw-pr-1">
      <Logo size="sm" color="black" className={styles.logo} />

      <div className="tw-flex tw-flex-1 tw-flex-col tw-items-start">
        <p className="tw-text-xs tw-text-neutral-600">{formattedDate}</p>
        <p className="tw-text-sm tw-text-neutral-900">{text}</p>
        {renderButton()}
      </div>

      <div className="tw-flex tw-flex-col tw-items-center tw-gap-3 tw-p-1">
        {!read && <NotificationAlert />}
        <Button variant="ghost" className={styles["close-button"]} size="36">
          <CloseIcon onClick={handleMarkNotification} />
        </Button>
      </div>
    </div>
  );
};

interface NotificationAlertProps {
  bellIcon?: boolean;
}
const NotificationAlert: React.FC<NotificationAlertProps> = ({ bellIcon = false }) => {
  if (bellIcon) {
    return (
      <div className="tw-absolute tw-right-[1px] tw-top-[-1px] tw-h-[10px] tw-w-[10px] tw-rounded-full tw-bg-aux-2-500" />
    );
  }
  return <div className="tw-h-[10px] tw-w-[10px] tw-rounded-full tw-bg-aux-2-500" />;
};

export const MenuWithoutNotifications: React.FC = () => {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-py-5">
      <img src={greenBell} alt="Notification Bell" />
      <p className="tw-text-md tw-font-medium">You're up to date with everything!</p>
    </div>
  );
};
