import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { getCustomer } from "@frontend/api/billing.service";
import { getAccountId } from "@frontend/config/settings/settings.service";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { CREATE_OR_JOIN_PATH, DASHBOARD_PATH } from "@frontend/routes";

import { useQuery } from "@shared/hooks/use-query";

interface LoggedOutRouteProps {
  children?: React.ReactNode;
}
export const LoggedOutRoute: React.FC<LoggedOutRouteProps> = ({ children }) => {
  const { isAuthenticated, getUser } = useAuthProvider();
  const { queryParams } = useQuery<{ invite?: string }>();

  useEffect(() => {
    const accountId = getAccountId();
    if (isAuthenticated === undefined) getUser(); // Load auth info.
    else if (isAuthenticated && accountId) getCustomer(accountId, { getSettings: true }); // Make sure it always has the latest customer info
  }, [isAuthenticated]);

  // only proceed after the auth check has run.
  if (isAuthenticated === undefined) return;

  // Redirect to /join-or-create if signed in and on invite link
  if (isAuthenticated && queryParams.invite) return <Navigate to={CREATE_OR_JOIN_PATH + location.search} />;

  // Redirect to dashboard if signed in
  if (isAuthenticated) return <Navigate to={DASHBOARD_PATH + location.search} />;

  // Show the page
  return (
    <>
      <Outlet />
      {children}
    </>
  );
};
