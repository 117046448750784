import React from "react";
import { Channel } from "pusher-js";
import { useNavigate } from "react-router-dom";

import { PusherAction, PusherEvent } from "@frontend/components/pusher/pusher";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { CheckCircleIcon } from "@shared/components/icons";
import { useQuery } from "@shared/hooks/use-query";
import { ProgressBar } from "@shared/primitives/progress-bar";

import { useChannel, useEvent } from "@harelpls/use-pusher";

const usePusherEvent = (
  channel: Channel | undefined,
  eventName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (data?: any, metadata?: { user_id: string } | undefined) => void
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEvent(channel, eventName, (data: any) => {
    callback(data);
  });
};

interface Params {
  state?: string;
  task?: "caption" | "transcription" | "summary" | string;
}

interface MediaTaskItem {
  mediaId: string;
  name?: string;
  task?: string;
  progress?: number;
  completed?: boolean;
  error?: boolean;
}

export const OneDriveProcessPage: React.FC = () => {
  const { queryParams } = useQuery<Params>();
  const navigate = useNavigate();
  const [mediaList, setMediaList] = React.useState<MediaTaskItem[]>([]);
  const [hasLoaded, setHasLoaded] = React.useState(false);

  React.useEffect(() => {
    if (queryParams?.state) {
      const state = JSON.parse(atob(queryParams.state));
      const mediaIds = state?.mediaIds || [];
      setMediaList(mediaIds.map((mediaId: string) => ({ mediaId })));
      setHasLoaded(true);
    }
  }, [queryParams?.state]);

  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  const accountChannel = useChannel(accountId);

  const updateMedia = (mediaId: string, data: Partial<MediaTaskItem>) => {
    const newState = mediaList.map((media) => (media.mediaId === mediaId ? { ...media, ...data } : media));
    setMediaList(newState);
    return newState;
  };

  usePusherEvent(accountChannel, PusherEvent.Media, async (data) => {
    const mediaId = data.mediaId || data.media?.mediaId;
    if (!mediaList.find((media) => media.mediaId === mediaId)) {
      return;
    }

    let updateData: Partial<MediaTaskItem> = {};

    if (data.media) {
      updateData = {
        name: data.media.name
      };
    }

    switch (data.action) {
      case PusherAction.UpdateMediaStatus:
        updateData = {
          task: "Transcribing..."
        };
        break;
      case PusherAction.BurnStart:
        updateData = {
          task: "Captioning..."
        };
        break;
      case PusherAction.UpdateMediaJobStatus:
        updateData = {
          progress: data.progress?.overallProgress
        };
        break;
      case PusherAction.MediaTranscribeReady:
        if (queryParams.task === "transcription") {
          updateData = {
            completed: true
          };
        }
        break;
      case PusherAction.MediaSummary:
      case PusherAction.BurnComplete:
        updateData = {
          completed: true
        };
        break;
      case PusherAction.BurnFail:
        updateData = {
          completed: true,
          error: true
        };
        break;
    }

    const updatedList = updateMedia(mediaId, updateData);
    const allCompleted = updatedList.every((m) => m.completed);
    const hasError = updatedList.some((m) => m.error);

    if (hasLoaded && allCompleted) {
      if (hasError) {
        navigate(`/onedrive/error?task=${queryParams?.task}`);
      } else {
        navigate(`/onedrive/success?task=${queryParams?.task}`);
      }
    }
  });

  const title = React.useMemo(() => {
    switch (queryParams?.task) {
      case "caption":
        return "Your caption files are being made";
      case "summary":
        return "Your summary files are being made";
      case "transcription":
        return "Your transcription files are being made";
      default:
        return "Your files are being made";
    }
  }, [queryParams?.task]);

  return (
    <div className="tw-flex tw-min-w-[380px] tw-max-w-[500px] tw-flex-row tw-gap-3 tw-rounded-8 tw-border tw-border-neutral-100 tw-bg-white tw-p-6">
      <div className="tw-flex tw-flex-grow tw-flex-col tw-gap-1">
        <p className="tw-text-lg tw-font-medium">{title}</p>
        <div className="tw-flex tw-flex-col tw-divide-y tw-divide-neutral-300">
          {mediaList.map((media, i) => (
            <div key={i} className="tw-flex tw-flex-col tw-gap-1 tw-p-2">
              <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
                <p className="tw-text-sm tw-text-neutral-500">{media?.name || ""}</p>
                {media.completed && (
                  <div className="tw-flex tw-flex-row tw-gap-1">
                    <CheckCircleIcon className="tw-h-5 tw-w-5 tw-text-success-500" />
                    <p className="tw-text-sm tw-text-neutral-500">Completed</p>
                  </div>
                )}
                {!media.completed && (
                  <p className="tw-text-sm tw-text-neutral-500">{media?.task ? media.task : "Processing..."}</p>
                )}
              </div>
              {!media.completed && <ProgressBar loading={!Boolean(media.progress)} progress={media.progress || 0} />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
