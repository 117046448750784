import { Accept } from "react-dropzone";

// We need to explicit say the MIME type because of a bug in Safari
const VIDEO_DROPZONE_FORMATS: Accept = {
  "video/mp4": [],
  "video/quicktime": []
};

const AUDIO_DROPZONE_FORMATS: Accept = {
  "audio/mpeg": [],
  "audio/m4a": [],
  "audio/mp4": [],
  "audio/ogg": [],
  "audio/wav": []
};

export const ACCEPTED_SUBTITLE_DROPZONE_FORMATS: Accept = {
  "text/plain": [".srt"],
  "text/vtt": [".vtt"]
};

export const ACCEPTED_DROPZONE_FORMATS = {
  ...VIDEO_DROPZONE_FORMATS,
  ...AUDIO_DROPZONE_FORMATS
};

const MIME_EXTENSION_MAP: { [key: string]: string } = {
  "video/mp4": "mp4",
  "video/quicktime": "mov",
  "audio/mpeg": "mp3",
  "audio/m4a": "m4a",
  "audio/ogg": "oga",
  srt: "srt"
};

export const getExtensionFromMimeType = (mimeType: string): string | undefined => MIME_EXTENSION_MAP[mimeType];

export const AUDIO_DROPZONE_FORMATS_WITH_EXTENSION: Accept = Object.keys(AUDIO_DROPZONE_FORMATS).reduce((acc, key) => {
  const ext = getExtensionFromMimeType(key);
  return ext ? { ...acc, [key]: [`.${ext}`] } : acc;
}, {} as Accept);
