import React from "react";
import classNames from "classnames";
import { format } from "date-fns";

import { Invoice } from "@shared/interfaces/billing";
import { Loader } from "@shared/primitives/loader";
import { Table } from "@shared/primitives/table";
import { accountQuery } from "@shared/state/account";

import { useObservable } from "@mindspace-io/react";

enum InvoiceColumnId {
  DATE = "date",
  DESCRIPTION = "description",
  TOTAL = "total",
  BUTTON = "button"
}

interface InvoiceTableProps {
  skipDescription?: boolean;
  limit?: number;
  className?: string;
}
export const InvoiceTable: React.FC<InvoiceTableProps> = ({ skipDescription = false, limit, className }) => {
  const [{ invoices, loading }] = useObservable(accountQuery.select(["loading", "invoices"]), {
    loading: true,
    invoices: []
  });

  const rows = invoices.slice(0, limit).map((invoice) => {
    return (
      <Table.Row key={invoice.id} className="tw-h-[46px] even:tw-bg-neutral-100">
        <Table.Cell columnId={InvoiceColumnId.DATE}>{format(new Date(invoice.created), "dd/MM/yyyy")}</Table.Cell>
        {!skipDescription && (
          <Table.Cell columnId={InvoiceColumnId.DESCRIPTION}>{getInvoiceDescription(invoice)}</Table.Cell>
        )}
        <Table.Cell columnId={InvoiceColumnId.TOTAL} className="tw-uppercase">
          {invoice.total / 100} {invoice.currency}
        </Table.Cell>
        <Table.Cell columnId={InvoiceColumnId.BUTTON}>
          <a href={invoice.hosted_invoice_url} target="_blank" rel="noopener noreferrer">
            View
          </a>
        </Table.Cell>
      </Table.Row>
    );
  });

  let columns = [
    { label: "Date", id: InvoiceColumnId.DATE, width: "2fr" },
    { label: "Description", id: InvoiceColumnId.DESCRIPTION, width: "2fr" },
    { label: "Total", id: InvoiceColumnId.TOTAL, width: "2fr" },
    { label: "", id: InvoiceColumnId.BUTTON, width: "1fr" }
  ];

  if (skipDescription) columns = columns.filter((column) => column.id !== "description");

  return (
    <Table columns={columns} items={rows} className={classNames("tw-rounded-none tw-border-0", className)}>
      <Table.Header>
        {(columns) =>
          columns.map((column, i) => (
            <Table.HeaderCell columnId={column.id} className="!tw-font-bold" key={i}>
              {column.label}
            </Table.HeaderCell>
          ))
        }
      </Table.Header>
      {loading ? (
        <Table.Row className="tw-h-[46px] ">
          <Loader className="tw-mr-2" />
        </Table.Row>
      ) : (
        rows
      )}
    </Table>
  );
};

const getInvoiceDescription = (invoice: Invoice): string => {
  if (invoice.description) {
    return invoice.description;
  }
  if (invoice.lines && invoice.lines[0]?.description) {
    return invoice.lines[0].description;
  }
  return "No description";
};
