import React from "react";

import { NewFieldset } from "@shared/primitives/new-fieldset";
import { Folder } from "@shared/state/dashboard/dashboard.store";

import { ProjectLocationDropdown } from "../upload-modal/project-location-dropdown";

interface UploadModalProps {
  folder?: Folder | null;
  className?: string;
  setFolder: (folder?: Folder | null) => void;
  showInPortal?: boolean;
}

export const SaveLocationSection: React.FC<UploadModalProps> = ({ folder, className, setFolder }) => {
  return (
    <div className={className}>
      <NewFieldset title="Save to folder">
        <ProjectLocationDropdown setFolder={setFolder} folder={folder} />
      </NewFieldset>
    </div>
  );
};
