import React from "react";
import { useNavigate } from "react-router-dom";

import { useAuthProvider } from "@frontend/contexts/auth.context";
import { DASHBOARD_PATH, LOGIN_PATH } from "@frontend/routes";

import { useQuery } from "@shared/hooks/use-query";
import { extractErrorDescription } from "@shared/utils/strings";

import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

import styles from "./auth.module.scss";

/**
 * This component handles the OAuth2 callback from an external login provider (e.g., Google).
 *
 * When the user is redirected back to the application after a successful login with the provider,
 * this component processes the authorization code provided in the URL query parameters.
 *
 * The main steps are:
 * 1. Extract the authorization code from the query parameters.
 * 2. If the code is missing, navigate back to the login page with an error message.
 * 3. Use the authorization code to request an access token from the provider.
 * 4. If the token exchange is successful, navigate to the dashboard.
 * 5. If the token exchange fails, navigate back to the login page with an error message.
 */

type HandleOAuth2CallbackProps = {
  redirectUrl?: string;
};
export const HandleOAuth2Callback: React.FC<HandleOAuth2CallbackProps> = ({ redirectUrl }) => {
  const { loginWithOAuth2 } = useAuthProvider();
  const { queryParams } = useQuery<{
    state?: string; // this is the encoded state field included in the OAuth2 callback. It can be undefined or a stringified object.
    code: string;
    error_description?: string;
  }>();
  const navigate = useNavigate();

  // If no code is present in the query parameters, navigate to the login page with an error message
  if (!queryParams.code) {
    const error = extractErrorDescription(queryParams.error_description);
    navigate(LOGIN_PATH, { state: error ? { alertMessage: error } : undefined });
    return;
  }

  // Handle OAuth2 login on component mount
  const mountedCount = React.useRef(0);
  React.useEffect(() => {
    if (mountedCount.current > 0) {
      // Prevent double token request in React 18+ dev mode, avoiding invalid token error message showing on the form.
      return;
    }
    mountedCount.current += 1;

    loginWithOAuth2({ code: queryParams.code, redirectUrl }).then((response) => {
      if (response.success) {
        // Navigate to the dashboard on successful login
        navigate(DASHBOARD_PATH);
      } else {
        // Navigate to the login page with an error message on failure
        const alertMessage = response.alertMessage || "There was a problem authenticating you. Please try again.";
        navigate(LOGIN_PATH, { state: { alertMessage } });
      }
    });
  }, []);

  return (
    <div className={styles.container}>
      <h4 className="tw-mb-6 tw-text-center tw-text-h4">We are preparing your account</h4>
      <p className="tw-text-center">
        <Icon path={mdiLoading} spin size="1.2rem" className="tw-mr-2" /> Please wait a moment...
      </p>
    </div>
  );
};
