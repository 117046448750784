import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getAccountTeam } from "@frontend/api/account.service";
import { getCustomer } from "@frontend/api/billing.service";
import { getAccountId, setUserSettings } from "@frontend/config/settings/settings.service";
import { DASHBOARD_PATH } from "@frontend/routes";

import { useAccounts } from "@shared/hooks/use-accounts";
import { useQuery } from "@shared/hooks/use-query";
import { notificationError } from "@shared/primitives/notification";
import { authStore } from "@shared/state/auth/auth.store";
import { editorStateRepository } from "@shared/state/editor/editor.state";

export const OpenWorkspacePage: React.FC = () => {
  const { workspaceId = "" } = useParams();
  const { queryParams } = useQuery<{ joined?: "true" /* Used to signal that the user just joined the workspace. */ }>();
  const { accounts } = useAccounts();
  const { currentAccount } = useAccounts();
  const navigate = useNavigate();

  const forwardedQueryParams = [queryParams.joined === "true" ? "joined=true" : undefined].filter(Boolean);

  React.useEffect(() => {
    // Redirect straight to the dashboard if opening active workspace
    if (workspaceId !== currentAccount?.accountId) {
      navigate(DASHBOARD_PATH + forwardedQueryParams ? `?${forwardedQueryParams}` : ""); // redirect
    }

    // Set workspaceId and reload to dashboard if authorized to view workspace
    const workspaceIds = accounts.map((account) => account.accountId);
    const hasAccess = workspaceIds.includes(workspaceId);
    if (hasAccess) {
      setUserSettings({ workspaceId });
      window.location.href = DASHBOARD_PATH + forwardedQueryParams ? `?${forwardedQueryParams}` : ""; // reload and redirect
      return;
    }

    // Redirect to dashboard if unauthorized and current active workspace is first valid workspace
    if (workspaceIds[0] === currentAccount?.accountId) {
      navigate(DASHBOARD_PATH + forwardedQueryParams ? `?${forwardedQueryParams}` : ""); // redirect
      notificationError("You do not have access to this workspace.");
      return;
    }

    // Redirect to first valid workspace and show error if unauthorized
    (async () => {
      editorStateRepository.resetState();
      authStore.setAccountId(workspaceIds[0]);
      setUserSettings({ workspaceId: workspaceIds[0] });

      await getCustomer(getAccountId(), { force: true });
      await getAccountTeam();

      navigate(DASHBOARD_PATH + forwardedQueryParams ? `?${forwardedQueryParams}` : ""); // redirect
      notificationError("You do not have access to this workspace.");
    })();
  }, []);

  return <div></div>;
};
