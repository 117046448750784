import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { isZoomFailAuth, IZoomRecordingSummary, listZoomRecordings, ZoomTabs } from "@frontend/api/apps.service";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";
import { DASHBOARD_PATH, SETTINGS_APPS_PATH } from "@frontend/routes";

import { useAnalyticsWithAuth } from "@shared/hooks/use-analytics-with-auth";
import { useModal } from "@shared/hooks/use-modal";
import { ModalType } from "@shared/interfaces/modal-type";
import { Loader } from "@shared/primitives/loader";
import { uploadQuery, uploadStore } from "@shared/state/upload";

import { mdiAlert } from "@mdi/js";
import Icon from "@mdi/react";

import { ZoomUploadModal } from "../../../dashboard/upload-modal/zoom-upload-modal";

import { ZoomHeader } from "./zoom-header";
import { ZoomRecordingList } from "./zoom-recording-list";

export const ZoomContainer: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [recordingList, setRecordingList] = useState<IZoomRecordingSummary | undefined>();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const navigate = useNavigate();

  const [showModal, hideModal] = useModal(ModalType.ZoomModal); // add another one for storage or share?
  const [tab, setTab] = useState<ZoomTabs>(ZoomTabs.NotImported);
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    const fetchZoomList = async () => {
      const fetchResp = await listZoomRecordings();

      if (isZoomFailAuth(fetchResp)) {
        navigate(SETTINGS_APPS_PATH);
        return;
      }

      setRecordingList(fetchResp);
      setIsLoading(false);
      trackEventWithAuth("Zoom: List Recordings");
    };

    fetchZoomList();
  }, []);

  const handleCloseModal = (goToDashboard?: boolean) => {
    hideModal();

    if (!uploadQuery.isUploading) {
      uploadStore.resetUpload();
    }

    if (goToDashboard) {
      navigate(DASHBOARD_PATH);
    }
  };

  const startImportingFiles = () => {
    const items = recordingList?.items ?? [];
    const files = items
      .flatMap((rec) =>
        rec.files.map((f) => ({
          fileId: f.id,
          meetingId: rec.meetingNumber,
          name: `${rec.startTime.substring(0, 10)} - ${rec.topic}`,
          type: f.fileType.toLowerCase(),
          duration: f.duration,
          size: f.size
        }))
      )
      .filter((f) => selected.includes(f.fileId));

    if (!files) {
      return;
    }

    trackEventWithAuth("Zoom: Import Recording Files");
    showModal(<ZoomUploadModal initialFiles={files} closeModal={handleCloseModal} />);
  };

  const onChange = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  if (isLoading) {
    return (
      <ZoomWrapper>
        <div className="tw-flex tw-items-center">
          <Loader className="tw-mr-2" /> <span>Loading...</span>
        </div>
      </ZoomWrapper>
    );
  }

  if (!recordingList) {
    return (
      <ZoomWrapper>
        <div className="tw-mt-12 tw-text-center">
          <Icon path={mdiAlert} size="3rem" color="var(--color-destructive-600)" />
        </div>
        <div className="tw-mt-1 tw-text-center">Error loading recordings, please contact support</div>
      </ZoomWrapper>
    );
  }

  if (recordingList.count === 0) {
    return (
      <ZoomWrapper>
        <div className="tw-mt-12 tw-text-center">No meeting recordings found</div>
      </ZoomWrapper>
    );
  }

  return (
    <ZoomWrapper>
      <ZoomHeader
        tab={tab}
        setTab={setTab}
        importFiles={startImportingFiles}
        showImport
        disableImport={!selected.length}
        showArchive={false}
        disableArchive
      />
      <ZoomRecordingList recordingSummary={recordingList} selected={selected} onChange={(id) => onChange(id)} />
    </ZoomWrapper>
  );
};

interface ZoomWrapperProps {
  children: React.ReactNode;
}
const ZoomWrapper: React.FC<ZoomWrapperProps> = ({ children }) => {
  return (
    <>
      <SettingsPageHeader title="Zoom" backUrl={SETTINGS_APPS_PATH} className="tw-mb-6" />
      {children}
    </>
  );
};
