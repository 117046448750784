import React from "react";
import classNames from "classnames";
import { Link, LinkProps } from "react-router-dom";

import { loadFolders } from "@frontend/api/dashboard.service";
import { TeamspaceModal } from "@frontend/components/bespoke-modals/teamspace-modal";
import { FOLDERS_PATH } from "@frontend/routes";

import { DashboardFolderIcon } from "@shared/components/icons/dashboard-folder-icon";
import { useModal } from "@shared/hooks/use-modal";
import { ModalType } from "@shared/interfaces/modal-type";
import { IconButton } from "@shared/primitives/button";
import { DropdownActionIconButton } from "@shared/primitives/dropdown";
import { Folder } from "@shared/state/dashboard/dashboard.store";

import { RiAddLine, RiArrowDownSLine, RiArrowRightSLine, RiMoreLine } from "@remixicon/react";

import { CreateFolderModal } from "../../dashboard-nav/create-folder-modal";

import { PrivateFolderMenu } from "./private-folder-menu";
import { TeamspaceMenu } from "./teamspace-menu";

interface FolderTreeProps {
  folder: Folder;
  className?: string;
  noIndent?: boolean;
  selectedFolderId: string | null;
  showOptions?: boolean;
  showCreateFolder?: boolean;
  onChange?: () => void;
  onSelectFolder?: (folder: Folder) => void;
  defaultExpanded?: boolean;
  shouldNavigate?: boolean;
}

export const FolderTree: React.FC<FolderTreeProps> = ({
  folder,
  className,
  noIndent,
  selectedFolderId,
  showOptions = true,
  showCreateFolder = true,
  onChange,
  onSelectFolder,
  defaultExpanded = false,
  shouldNavigate = false
}) => {
  const [isExpanded, setIsExpanded] = React.useState(defaultExpanded);

  const handleClickToggle = () => setIsExpanded(!isExpanded);

  return (
    <ul className={classNames("tw-flex tw-flex-col tw-gap-0.25", className)}>
      <li className="tw-flex tw-flex-col tw-gap-0.25">
        <FolderItem
          folder={folder}
          noIndent={noIndent}
          showOptions={showOptions}
          showCreateFolder={showCreateFolder}
          isSelected={selectedFolderId === folder.id}
          isExpanded={isExpanded}
          hasChildren={Boolean(folder.children?.length)}
          onSelect={() => onSelectFolder?.(folder)}
          onClickToggle={handleClickToggle}
          onChange={onChange}
          shouldNavigate={shouldNavigate}
        />
        {isExpanded && Boolean(folder.children?.length) && (
          <div className="tw-flex tw-flex-col tw-gap-0.25">
            {folder.children?.map((childFolder) => (
              <FolderTree
                key={childFolder.id}
                folder={childFolder}
                showOptions={showOptions}
                onChange={onChange}
                onSelectFolder={onSelectFolder}
                selectedFolderId={selectedFolderId}
                showCreateFolder={showCreateFolder}
                shouldNavigate={shouldNavigate}
              />
            ))}
          </div>
        )}
      </li>
    </ul>
  );
};

interface FolderButtonProps {
  folder: Folder;
  noIndent?: boolean;
  showOptions?: boolean;
  showCreateFolder?: boolean;
  isSelected?: boolean;
  isExpanded?: boolean;
  hasChildren?: boolean;
  shouldNavigate: boolean;
  onChange?: () => void;
  onSelect: () => void;
  onClickToggle: () => void;
}

const FolderItem: React.FC<FolderButtonProps> = ({
  folder,
  noIndent,
  showOptions,
  showCreateFolder,
  isSelected,
  isExpanded,
  hasChildren,
  shouldNavigate,
  onChange,
  onSelect,
  onClickToggle
}) => {
  const [isCreatingNewFolder, setCreatingNewFolder] = React.useState(false);
  const [isSavingMenu, setSavingMenu] = React.useState(false);
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const paddingLeft = noIndent ? 0 : ((folder.customDepth ?? folder.depth) + 1) * 0.75;

  const handleClickItem = () => {
    onSelect();
  };

  const handleClickCreateNewFolder = async (e: React.MouseEvent) => {
    e.preventDefault();

    const [showCreateModal, hideCreateModal] = useModal(ModalType.CreateFolder);

    const parentFolder = folder;

    const handleBeforeSave = () => setCreatingNewFolder(true);
    const handleCompleteSave = async () => {
      await loadFolders();
      setCreatingNewFolder(false);
    };

    showCreateModal(
      <CreateFolderModal
        isPublic={parentFolder.isPublic}
        parentId={parentFolder.id ?? undefined}
        hideModal={hideCreateModal}
        onBeforeSave={handleBeforeSave}
        onCompleteSave={handleCompleteSave}
      />
    );
  };

  const handleClickCreateNewTeamspace = async (e: React.MouseEvent) => {
    e.preventDefault();

    const [showCreateModal, hideCreateModal] = useModal(ModalType.CreateFolder);

    showCreateModal(<TeamspaceModal closeModal={hideCreateModal} />);
  };

  const to = shouldNavigate ? FOLDERS_PATH + "/" + folder.id : null;

  return (
    <FolderButton
      className={classNames(
        "tw-group tw-flex tw-w-full tw-cursor-pointer tw-flex-row tw-items-center tw-gap-1.5 tw-rounded-lg tw-px-3 tw-py-1.5 tw-text-sm tw-font-medium tw-text-neutral-700 hover:tw-bg-neutral-200 focus:!tw-outline focus:!tw-outline-2 focus:!tw-outline-primary-500/30 active:tw-bg-neutral-300 active:!tw-outline-transparent",
        { "tw-bg-neutral-100 tw-font-medium tw-text-neutral-900": isSelected }
      )}
      style={{ paddingLeft: paddingLeft + "rem" }}
      onClick={handleClickItem}
      to={to}
    >
      <ToggleButton
        onClick={onClickToggle}
        loaderClassName="tw-h-4 tw-w-4 tw-shrink-0 tw-p-0.5"
        className={classNames(!hasChildren && "tw-pointer-events-none tw-opacity-0")}
      >
        {isExpanded ? (
          <RiArrowDownSLine className="tw-h-4 tw-w-4 tw-shrink-0" />
        ) : (
          <RiArrowRightSLine className="tw-h-4 tw-w-4 tw-shrink-0" />
        )}
      </ToggleButton>

      <DashboardFolderIcon folder={folder} className="tw-h-5 tw-w-5 tw-shrink-0" />

      <span className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">{folder.name}</span>
      <div
        className={classNames(
          "tw-ml-auto tw-hidden tw-gap-2 tw-opacity-0 group-hover:tw-flex group-hover:tw-opacity-100",
          {
            "!tw-flex !tw-opacity-100": menuIsOpen || isCreatingNewFolder || isSavingMenu
          }
        )}
      >
        {showOptions &&
          folder.depth > 0 &&
          (Boolean(folder.isPublic) ? (
            <TeamspaceMenu
              folder={folder}
              onChange={onChange}
              onToggleDropdown={setMenuIsOpen}
              onSaving={setSavingMenu}
            >
              {/* Toggle button */}
              <DropdownActionIconButton
                size="20"
                variant="for-light-grey-background"
                icon={<RiMoreLine />}
                className="tw-ml-auto"
                loading={isSavingMenu}
                onClick={(e) => e.preventDefault()}
              />
            </TeamspaceMenu>
          ) : (
            <PrivateFolderMenu
              folder={folder}
              onChange={onChange}
              onToggleDropdown={setMenuIsOpen}
              onSaving={setSavingMenu}
            >
              {/* Toggle button */}
              <DropdownActionIconButton
                size="20"
                variant="for-light-grey-background"
                icon={<RiMoreLine />}
                className="tw-ml-auto"
                onClick={(e) => e.preventDefault()}
              />
            </PrivateFolderMenu>
          ))}
        {showOptions && showCreateFolder && (
          <IconButton
            size="wrap"
            variant="ghost"
            icon={<RiAddLine className="tw-h-4 tw-w-4" />}
            className="tw--ml-1 tw-h-5 tw-w-5 tw-rounded-4 tw-border-none !tw-bg-transparent hover:!tw-bg-neutral-300 active:!tw-bg-neutral-400"
            loaderClassName="tw-h-4 tw-w-4 tw-p-0.5"
            onClick={folder.depth === 0 && folder.isPublic ? handleClickCreateNewTeamspace : handleClickCreateNewFolder}
            loading={isCreatingNewFolder}
          />
        )}
      </div>
    </FolderButton>
  );
};

type FolderButtonType = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick: () => void;
  to: LinkProps["to"] | null;
};

const FolderButton: React.FC<FolderButtonType> = ({ children, className, style, to, onClick }) => {
  if (to) {
    return (
      <Link className={className} style={style} onClick={onClick} to={to}>
        {children}
      </Link>
    );
  }
  const handleClickItem = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onClick();
  };
  return (
    <div role="button" tabIndex={0} className={className} style={style} onClick={handleClickItem}>
      {children}
    </div>
  );
};

type ToggleButtonProps = {
  loading?: boolean;
  className?: string;
  loaderClassName?: string;
  children?: React.ReactElement;
  disabled?: boolean;
  onClick?: () => void;
};
const ToggleButton: React.FC<ToggleButtonProps> = ({
  loading,
  loaderClassName,
  className,
  children,
  disabled,
  onClick
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onClick?.();
  };
  return (
    <IconButton
      size="wrap"
      icon={children}
      variant="ghost"
      className={classNames(
        "tw-rounded !tw-border-0 !tw-border-transparent hover:!tw-bg-neutral-300 focus:!tw-bg-neutral-300 focus:!tw-outline focus:!tw-outline-2 focus:!tw-outline-primary-500/30 active:!tw-bg-neutral-400",
        { "!tw-bg-transparent": disabled },
        className
      )}
      loaderClassName={loaderClassName}
      onClick={handleClick}
      loading={loading}
      disabled={disabled}
    />
  );
};
