import React from "react";
import classNames from "classnames";

import { SubtitleOrTranscriptionFormat } from "@shared/interfaces/media";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@shared/primitives/dropdown";
import { getSubtitleFormatLabel } from "@shared/utils/media-functions";

import { AudioFormat, useDownloadMenu } from "@media-editor/contexts/download-menu.context";
import { RiCheckLine, RiClosedCaptioningFill, RiFileMusicFill, RiFileTextFill } from "@remixicon/react";

const SUBTITLE_OR_TRANSCRIPTION_FORMATS: SubtitleOrTranscriptionFormat[] = [
  SubtitleOrTranscriptionFormat.srt,
  SubtitleOrTranscriptionFormat.vtt,
  SubtitleOrTranscriptionFormat.ttml,
  SubtitleOrTranscriptionFormat.xml,
  SubtitleOrTranscriptionFormat.txt,
  SubtitleOrTranscriptionFormat.docx
];

export const TranscriptionSelection = () => {
  const { transcriptionFormats, setTranscriptionFormats, hasTranscription, languages } = useDownloadMenu();

  const disabled = !hasTranscription || languages.length <= 0;

  const hasSelected = (format: SubtitleOrTranscriptionFormat): boolean => {
    return transcriptionFormats.some((s) => s === format);
  };

  const toggleSelected = (format: SubtitleOrTranscriptionFormat) => {
    if (hasSelected(format)) {
      setTranscriptionFormats((subs) => [...subs].filter((s) => s !== format));
    } else {
      setTranscriptionFormats((subs) => [...subs, format]);
    }
  };

  const renderOptions = SUBTITLE_OR_TRANSCRIPTION_FORMATS.map((format) => {
    const isSelected = hasSelected(format);
    const disabled = !hasTranscription;

    return (
      <DropdownItem
        key={format}
        className={classNames("!tw-p-0", { ["tw-bg-primary-50"]: isSelected })}
        disabled={disabled}
        keepOpenOnClick
        onClick={() => toggleSelected(format)}
      >
        <div className="tw-flex tw-w-full tw-justify-between tw-px-4 tw-py-3">
          {getSubtitleFormatLabel(format)}
          {isSelected && <RiCheckLine className="tw-ml-2 tw-text-primary-500" />}
        </div>
      </DropdownItem>
    );
  });
  const getLabel = (): React.ReactNode => {
    if (transcriptionFormats.length === 0) {
      return (
        <>
          <RiFileTextFill className="tw-h-5 tw-w-5 tw-text-neutral-500" />
          Select type of transcriptions
        </>
      );
    } else if (transcriptionFormats.length === 1) {
      const option = transcriptionFormats[0];

      return (
        <>
          <RiFileTextFill className="tw-h-5 tw-w-5 tw-text-primary-500" />
          {option}
        </>
      );
    }

    return (
      <>
        <RiFileTextFill className="tw-h-5 tw-w-5 tw-text-primary-500" />
        {transcriptionFormats.length} selected transcription formats
      </>
    );
  };

  return (
    <Dropdown>
      <DropdownButton className="tw-w-full" variant="secondary" disabled={disabled}>
        {getLabel()}
      </DropdownButton>
      <DropdownMenu className="tw-min-w-[407px]" placement="bottom-start">
        <div className="tw-max-h-[270px] tw-overflow-y-auto">{renderOptions}</div>
      </DropdownMenu>
    </Dropdown>
  );
};

export const SubtitlesSelection = () => {
  const { subtitlesFormats, setSubtitlesFormats, hasSubtitles, languages } = useDownloadMenu();
  const disabled = !hasSubtitles || languages.length <= 0;

  const hasSelected = (format: SubtitleOrTranscriptionFormat): boolean => {
    return subtitlesFormats.some((s) => s === format);
  };

  const toggleSelected = (format: SubtitleOrTranscriptionFormat) => {
    if (hasSelected(format)) {
      setSubtitlesFormats((subs) => [...subs].filter((s) => s !== format));
    } else {
      setSubtitlesFormats((subs) => [...subs, format]);
    }
  };

  const renderOptions = SUBTITLE_OR_TRANSCRIPTION_FORMATS.map((format) => {
    const isSelected = hasSelected(format);
    const disabled = !hasSubtitles;

    return (
      <DropdownItem
        key={format}
        className={classNames("!tw-p-0", { ["tw-bg-primary-50"]: isSelected })}
        disabled={disabled}
        keepOpenOnClick
        onClick={() => toggleSelected(format)}
      >
        <div className="tw-flex tw-w-full tw-justify-between tw-px-4 tw-py-3">
          {getSubtitleFormatLabel(format)}
          {isSelected && <RiCheckLine className="tw-ml-2 tw-text-primary-500" />}
        </div>
      </DropdownItem>
    );
  });

  const getLabel = (): React.ReactNode => {
    if (subtitlesFormats.length === 0) {
      return (
        <>
          <RiClosedCaptioningFill className="tw-h-5 tw-w-5 tw-text-neutral-500" />
          Select type of subtitles
        </>
      );
    } else if (subtitlesFormats.length === 1) {
      const option = subtitlesFormats[0];

      return (
        <>
          <RiClosedCaptioningFill className="tw-h-5 tw-w-5 tw-text-primary-500" />
          {option}
        </>
      );
    }

    return (
      <>
        <RiClosedCaptioningFill className="tw-h-5 tw-w-5 tw-text-primary-500" />
        {subtitlesFormats.length} selected subtitles formats
      </>
    );
  };

  return (
    <Dropdown>
      <DropdownButton className="tw-w-full" variant="secondary" disabled={disabled}>
        {getLabel()}
      </DropdownButton>
      <DropdownMenu className="tw-min-w-[407px] tw-overflow-y-auto" placement="bottom-start">
        <div className="tw-max-h-[264px] tw-overflow-y-auto">{renderOptions}</div>
      </DropdownMenu>
    </Dropdown>
  );
};

type AudioSelectionProps = {
  disabled?: boolean;
};

export const AudioSelection: React.FC<AudioSelectionProps> = ({ disabled }) => {
  const { audioFormats, toggleAudioFormat } = useDownloadMenu();

  const hasSelected = (format: AudioFormat): boolean => {
    return audioFormats.some((s) => s === format);
  };

  const renderOptions = (Object.keys(AudioFormat) as Array<keyof typeof AudioFormat>).map((format) => {
    const isSelected = hasSelected(AudioFormat[format]);

    return (
      <DropdownItem
        key={format}
        className={classNames("!tw-p-0", { ["tw-bg-primary-50"]: isSelected })}
        disabled={disabled}
        onClick={() => toggleAudioFormat(AudioFormat[format])}
      >
        <div className="tw-flex tw-w-full tw-justify-between tw-px-4 tw-py-3">
          {format}
          {isSelected && <RiCheckLine className="tw-ml-2 tw-text-primary-500" />}
        </div>
      </DropdownItem>
    );
  });

  const getLabel = (): React.ReactNode => {
    if (audioFormats.length === 0) {
      return (
        <>
          <RiFileMusicFill className="tw-h-5 tw-w-5 tw-text-neutral-500" />
          Select type of audio formats
        </>
      );
    } else if (audioFormats.length === 1) {
      const option = audioFormats[0];

      return (
        <>
          <RiFileMusicFill className="tw-h-5 tw-w-5 tw-text-primary-500" />
          {option.toUpperCase()}
        </>
      );
    }

    return (
      <>
        <RiFileMusicFill className="tw-h-5 tw-w-5 tw-text-primary-500" />
        {audioFormats.length} selected audio formats
      </>
    );
  };

  return (
    <Dropdown>
      <DropdownButton className="tw-w-full" variant="secondary">
        {getLabel()}
      </DropdownButton>
      <DropdownMenu className="tw-min-w-[407px]" placement="bottom-start">
        <div className="tw-max-h-[200px] tw-overflow-y-auto">{renderOptions}</div>
      </DropdownMenu>
    </Dropdown>
  );
};
