import React from "react";
import classNames from "classnames";

interface MaxLinesInputProp {
  value?: number;
  onChange: (value: number) => void;
}
export const MaxLinesInput: React.FC<MaxLinesInputProp> = ({ value, onChange }) => {
  const [maxLines, setMaxLines] = React.useState<number>();

  React.useEffect(() => {
    if (value) {
      setMaxLines(value);
    }
  }, [value]);

  const handleClick = (value: number) => {
    if (maxLines !== value) {
      setMaxLines(value);
      onChange(value);
    }
  };

  return (
    <div className="tw-grid tw-grid-cols-3 tw-gap-3">
      <MaxLineOption lines={1} selected={maxLines === 1} onClick={handleClick} />
      <MaxLineOption lines={2} selected={maxLines === 2} onClick={handleClick} />
      <MaxLineOption lines={3} selected={maxLines === 3} onClick={handleClick} />
    </div>
  );
};

interface MaxLineOptionProps {
  lines: number;
  selected: boolean;
  onClick: (value: number) => void;
}
const MaxLineOption: React.FC<MaxLineOptionProps> = ({ lines, selected, onClick }) => {
  return (
    <div
      className={classNames(
        "tw-group tw-grid tw-h-16 tw-grow tw-cursor-pointer tw-grid-rows-2 tw-gap-1 tw-rounded-md tw-px-[10px] tw-py-2",
        {
          "tw-bg-primary-400 tw-text-white": selected,
          "tw-bg-neutral-50 tw-text-neutral-500 hover:tw-bg-primary-100": !selected
        }
      )}
      onClick={() => onClick(lines)}
    >
      <p className="tw-flex tw-justify-center tw-text-xs tw-font-medium tw-leading-[20px]">
        {
          {
            1: "One line",
            2: "Two lines",
            3: "Three lines"
          }[lines]
        }
      </p>
      <div className="tw-mx-auto tw-flex tw-w-[117px] tw-flex-col tw-items-center tw-gap-[3px]">
        {lines === 1 && <Line width="81px" isSelected={selected} />}
        {lines === 2 && (
          <>
            <Line width="65px" isSelected={selected} />
            <Line width="81px" isSelected={selected} />
          </>
        )}
        {lines === 3 && (
          <>
            <Line width="81px" isSelected={selected} />
            <Line width="65px" isSelected={selected} />
            <Line width="81px" isSelected={selected} />
          </>
        )}
      </div>
    </div>
  );
};

type LineProps = {
  width: string;
  isSelected: boolean;
};
const Line: React.FC<LineProps> = ({ width, isSelected }) => {
  return (
    <div
      style={{ width }}
      className={classNames("tw-h-1 tw-rounded-xl", { "tw-bg-white": isSelected, "tw-bg-neutral-400": !isSelected })}
    />
  );
};
