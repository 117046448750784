import { Observable } from "rxjs";

import { Asset, AssetType } from "@shared/interfaces/asset";

import { Order, QueryConfig, QueryEntity } from "@datorama/akita";

import { AssetsState, AssetsStore, assetsStore } from "./assets.store";

@QueryConfig({
  sortBy: "uploadedAt",
  sortByOrder: Order.DESC
})
export class AssetsQuery extends QueryEntity<AssetsState> {
  get assets(): Asset[] {
    return this.getAll();
  }

  get artworkAssets(): Asset[] {
    return this.getAll({ filterBy: (a) => a.type === AssetType.ARTWORK });
  }

  get logoAssets(): Asset[] {
    return this.getAll({ filterBy: (a) => a.type === AssetType.LOGO });
  }

  get fontAssets(): Asset[] {
    return this.getAll({ filterBy: (a) => a.type === AssetType.FONT });
  }

  get backgroundAssets(): Asset[] {
    return this.getAll({ filterBy: (a) => a.type === AssetType.BACKGROUND });
  }

  constructor(protected store: AssetsStore) {
    super(store);
  }

  selectAssets(): Observable<Asset[]> {
    return this.selectAll();
  }

  selectArtworkAssets(): Observable<Asset[]> {
    return this.selectAll({ filterBy: (a) => a.type === AssetType.ARTWORK });
  }

  selectLogoAssets(): Observable<Asset[]> {
    return this.selectAll({ filterBy: (a) => a.type === AssetType.LOGO });
  }

  selectFontAssets(): Observable<Asset[]> {
    return this.selectAll({ filterBy: (a) => a.type === AssetType.FONT });
  }

  selectBackgroundAssets(): Observable<Asset[]> {
    return this.selectAll({ filterBy: (a) => a.type === AssetType.BACKGROUND });
  }
}

export const assetsQuery = new AssetsQuery(assetsStore);
