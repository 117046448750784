import React from "react";
import { Link } from "react-router-dom";

import sublyClickDemoImage from "@frontend/assets/images/subly-click-demo.png";

import { Logo } from "@shared/components/logo";
import { Button } from "@shared/primitives/button";

const SUBLY_CLICK_PATH_LOGIN_PATH = "/onedrive/login";

export const SublyClickPage: React.FC = () => {
  const handleInstall = () => {
    window.location.href = SUBLY_CLICK_PATH_LOGIN_PATH;
  };

  return (
    <div className="tw-flex tw-h-screen tw-w-full tw-items-center tw-justify-center">
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-start tw-gap-6">
        <Logo className="tw-h-[62px] tw-w-[62px]" size="sm" color="black" />
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-start tw-gap-2">
          <h4 className="tw-text-h4 tw-font-semibold tw-text-neutral-900">
            Install SublyClick in your Microsoft account.
          </h4>
          <div className="tw-text-md tw-font-normal tw-text-neutral-700">Click ‘Install’ to get started!</div>
          <div>
            <span className="tw-text-sm tw-font-medium tw-text-neutral-500 ">
              A Subly license is required to fully use SublyClick. Don't have one?{" "}
            </span>
            <Link className="tw-text-sm tw-font-medium tw-text-primary-500" to={""}>
              Learn more here
            </Link>
          </div>
        </div>
        <Button className="tw-w-[130px]" size="40" onClick={() => handleInstall()}>
          Install
        </Button>
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-start tw-gap-4 tw-pt-4">
          <h5 className="tw-text-h5 tw-font-semibold tw-text-neutral-900">How does it work?</h5>
          <img className="tw-h-[370px] tw-w-[657px] tw-rounded tw-shadow-lg" src={sublyClickDemoImage} />
          <div className="tw-font-normal tw-italic tw-text-neutral-700">
            Access actions like captioning, transcription, and summarisation from One Drive and Sharepoint.
          </div>
          <div className="tw-font-normal tw-text-neutral-700">
            Make sure you have a Subly account with an active license to unlock all features.
          </div>
          <div className="tw-pt-2">
            <Button size="32" onClick={() => handleInstall()}>
              Get started with SublyClick
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
