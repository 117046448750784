import React from "react";
import { v4 } from "uuid";

import { transcribeMedia } from "@frontend/api/media.service";
import { TranslateMediaParams } from "@frontend/api/translate.service";
import { EN } from "@frontend/assets/i18n/en";
import { TranscriptionOccurrencesProvider } from "@frontend/contexts/transcription-occurrences.context";

import { useAccounts } from "@shared/hooks/use-accounts";
import { usePlan } from "@shared/hooks/use-plan";
import { AccountTemplate, AccountTemplateStyles } from "@shared/interfaces/account";
import { EnrichedMedia, MediaFile } from "@shared/interfaces/media";
import { notificationError } from "@shared/primitives/notification";
import { accountQuery } from "@shared/state/account";
import {
  useActiveMediaIdState,
  useActiveMediaOriginalSubtitlesIdState,
  useActiveMediaOriginalTranscriptionIdState,
  useActiveMediaTranscriptionsMapState
} from "@shared/state/editor/editor.hooks";
import { editorStateRepository } from "@shared/state/editor/editor.state";
import { mediaHistoryRepository } from "@shared/state/editor-history/editor-history.state";
import {
  editorUiStateRepository,
  useActiveMediaSelectionsState,
  useMediaCheckedSnippetsState,
  useMediaSelectedSnippetState
} from "@shared/state/editor-ui";
import { timelineStore } from "@shared/state/timeline/timeline.store";
import { resetTranslationsEditorState } from "@shared/state/translations-editor/translations-editor.state";
import { userPresenceStore } from "@shared/state/user-presence";
import { msToSec, secToMs } from "@shared/utils/time";

import { FileType, MediaConfig, MediaSnippet, Transcription } from "@getsubly/common";
import { TitleConfig } from "@getsubly/common/dist/interfaces/media-config";
import { DEFAULT_TITLE_CONFIG } from "@media-editor/components/editor-sidebar/text-panel/text-panel";
import { CommentsProvider } from "@media-editor/contexts/comments.context";
import { EditorMenubarProvider } from "@media-editor/contexts/editor-menubar.context";
import { UndoRedoProvider } from "@media-editor/contexts/undo-redo.context";
import {
  useEditorActiveSelectionIdState,
  useEditorCurrentSubtitlesIdState,
  useEditorCurrentTranscriptionIdState,
  useEditorPanelState,
  useEditorSelectedTitleIdState,
  useEditorTranscriptionsState
} from "@media-editor/state/media-editor.hooks";
import { mediaEditorStateRepository } from "@media-editor/state/media-editor.state";
import { EditorPanel, TranscriptionMap } from "@media-editor/types";
import { mediaPlayerRepository, useMediaPlayer } from "@media-player";

interface MediaEditorContext
  extends Omit<
    MediaEditorFacadeContext,
    | "onDebouncedSave"
    | "onUpdateConfig"
    | "onCreateTranscription"
    | "onUpdateTranscription"
    | "onDeleteTranscription"
    | "onUpdateAccountPresets"
    | "onLoadEditor"
    | "media"
  > {
  handleUpdateConfig: (media: MediaConfig) => void;
  handleUpdateStyles: (style: AccountTemplateStyles) => void;
  handleUpdateTitle: (title: TitleConfig) => void;
  handleSetSubtitlesOrTranscriptionOutdated: (id?: string) => void;
  handleCreateTranscription: (params: {
    type: FileType.Subtitle | FileType.Transcription;
    languageCode: string;
    transcription?: Transcription;
    isTranslation?: boolean;
  }) => Promise<void>;
  handleUpdateTranscription: (transcription: Transcription, id: string) => void;
  handleDeleteTranscription: (id: string) => void;
  handleRegenerateTranscription: (id: string) => void;
  handleUpdatePresets: (mediaId: string, presets: AccountTemplate[]) => Promise<void>;
  handleUndoRedo: (transcriptions?: TranscriptionMap, mediaConfig?: MediaConfig) => void;
  addSnippet: () => void;
  editSnippet: (snippet: MediaSnippet) => void;
  addTitle: () => void;
  lastChange?: number;
}

export const MediaEditorContext = React.createContext<MediaEditorContext | null>(null);

interface MediaEditorProviderProps {
  children: React.ReactNode;
}
export const MediaEditorProvider: React.FC<MediaEditorProviderProps> = ({ children }) => {
  const {
    onCreateTranscription,
    onUpdateTranscription,
    onDeleteTranscription,
    onUpdateConfig,
    onDebouncedSave,
    onUpdateAccountPresets,
    onTranslate,
    onLoadEditor,
    media,
    settings
  } = useMediaEditorFacade();

  const { mediaPlayerRef } = useMediaPlayer();

  const mediaId = useActiveMediaIdState();

  const { setCurrentSubtitlesId } = useEditorCurrentSubtitlesIdState();
  const { setCurrentTranscriptionId } = useEditorCurrentTranscriptionIdState();
  const { setActiveSelectionId } = useEditorActiveSelectionIdState();
  const selections = useActiveMediaSelectionsState();
  const { checkedSnippetsIds, setCheckedSnippets } = useMediaCheckedSnippetsState();
  const { selectedSnippet, setSelectedSnippet } = useMediaSelectedSnippetState();
  const { setSelectedTitleId } = useEditorSelectedTitleIdState();
  const originalId = useActiveMediaOriginalSubtitlesIdState();
  const originalTranscriptionId = useActiveMediaOriginalTranscriptionIdState();
  const { editorPanel } = useEditorPanelState();
  const isTranscriptionPanel = editorPanel === EditorPanel.Transcription;
  const transcriptionsMap = useActiveMediaTranscriptionsMapState();
  const useGlossary = accountQuery.useGlossary ?? false;
  const ignoreWords = accountQuery.ignoreWordsSettings ?? [];
  const { customVocabularyWords } = useAccounts();
  const { isBusinessOrHigher } = usePlan();
  const { setTranscriptions } = useEditorTranscriptionsState();

  const handleCreateTranscription = async ({
    type,
    languageCode,
    transcription,
    isTranslation
  }: {
    type: FileType.Subtitle | FileType.Transcription;
    languageCode: string;
    transcription?: Transcription;
    isTranslation?: boolean;
  }): Promise<void> => {
    const transcriptions = mediaEditorStateRepository.transcriptions;
    const mediaId = editorStateRepository.mediaId;

    if (!mediaId) {
      return;
    }

    // Pause video when update subtitles
    if (mediaPlayerRef.current) {
      mediaPlayerRef.current.pause();
    }

    const { file, transcription: updatedTranscription } = await onCreateTranscription({
      mediaId,
      type,
      languageCode,
      transcription,
      isTranslation
    });
    const fileId = file.id;

    const updatedTranscriptions = { ...transcriptions, [fileId]: updatedTranscription };

    mediaEditorStateRepository.updateState({
      transcriptions: updatedTranscriptions
    });

    setActiveSelectionId(fileId);
    if (type === FileType.Subtitle) {
      setCurrentSubtitlesId(fileId);
    } else {
      setCurrentTranscriptionId(fileId);
    }

    mediaEditorStateRepository.transcriptionChanged$.next(true);
    mediaHistoryRepository.transcriptionUpdates$.next(updatedTranscriptions);
  };

  const handleUpdateTranscription = (transcription: Transcription, id: string): void => {
    const transcriptions = mediaEditorStateRepository.transcriptions;
    const mediaId = editorStateRepository.mediaId;

    if (!mediaId || !transcriptions) {
      return;
    }

    // Pause video when update subtitles
    if (mediaPlayerRef.current) {
      mediaPlayerRef.current.pause();
    }

    onUpdateTranscription(id, mediaId);

    const updatedTranscriptions = { ...transcriptions, [id]: transcription };

    mediaEditorStateRepository.updateState({
      transcriptions: updatedTranscriptions
    });
    mediaEditorStateRepository.transcriptionChanged$.next(true);
    mediaHistoryRepository.transcriptionUpdates$.next(updatedTranscriptions);
  };

  const handleRegenerateTranscription = async (id: string): Promise<void> => {
    const { mediaId, assConfig } = editorStateRepository;

    if (!mediaId || !transcriptionsMap || !assConfig) {
      return;
    }
    const isTranslation = id !== originalTranscriptionId && id !== originalId;
    const selectedTranscription = isTranscriptionPanel
      ? id === originalTranscriptionId
        ? transcriptionsMap.originalTranscription
        : transcriptionsMap.transcriptionTranslations.find((t) => t.fileId === id)
      : id === originalId
      ? transcriptionsMap.originalSubtitles
      : transcriptionsMap.subtitlesTranslations.find((t) => t.fileId === id);

    if (!selectedTranscription || !selectedTranscription.fileType || !selectedTranscription.languageCode) {
      return;
    }
    const vocabularyWords = isBusinessOrHigher ? customVocabularyWords.map((w) => w.word) : [];
    const fileType = isTranscriptionPanel ? FileType.Transcription : FileType.Subtitle;
    try {
      if (isTranslation) {
        const transcriptions = await onTranslate({
          mediaId,
          targetLanguages: [selectedTranscription.languageCode],
          fileType,
          subtitleFileId: isTranscriptionPanel ? originalTranscriptionId : originalId,
          sourceLanguage: selectedTranscription.languageCode,
          ignoreWords,
          isFormal: true,
          useGlossary
        });
        setTranscriptions(transcriptions);

        // Reset media history after translating
        mediaHistoryRepository.initState(transcriptions, assConfig);
      } else {
        await transcribeMedia({
          mediaId,
          languageCode: selectedTranscription.languageCode,
          customVocabularyWords: vocabularyWords,
          options: { fileType, useGlossary }
        });
      }
    } catch (e) {
      console.error(e);
      notificationError(EN.error.defaultMessage);
    }
  };
  const handleDeleteTranscription = (id: string): void => {
    const transcriptions = mediaEditorStateRepository.transcriptions;
    const mediaId = editorStateRepository.mediaId;

    if (!mediaId || !transcriptions) {
      return;
    }

    onDeleteTranscription(id, mediaId);

    // Pause video when update subtitles
    if (mediaPlayerRef.current) {
      mediaPlayerRef.current.pause();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [id]: _, ...updatedTranscriptions } = transcriptions;

    mediaEditorStateRepository.updateState({
      transcriptions: updatedTranscriptions
    });

    const originalId = isTranscriptionPanel
      ? editorStateRepository.originalTranscriptionId
      : editorStateRepository.originalSubtitlesId;

    if (originalId) {
      if (isTranscriptionPanel) {
        setActiveSelectionId(originalId);
        setCurrentTranscriptionId(originalId);
      } else {
        setActiveSelectionId(originalId);
        setCurrentSubtitlesId(originalId);
      }
    }
  };

  const handleUpdateConfig = (mediaConfig: MediaConfig): void => {
    const mediaId = editorStateRepository.mediaId;

    if (!mediaId) {
      return;
    }

    onUpdateConfig(mediaConfig, mediaId);

    mediaHistoryRepository.configUpdates$.next(mediaConfig);
    mediaEditorStateRepository.transcriptionChanged$.next(true);
  };

  const handleUpdateStyles = (styles: AccountTemplateStyles): void => {
    const mediaId = editorStateRepository.mediaId;
    const mediaConfig = editorStateRepository.assConfig;

    if (!mediaId || !mediaConfig) {
      return;
    }

    handleUpdateConfig({ ...mediaConfig, ...styles });
  };

  const handleUpdateTitle = (title: TitleConfig): void => {
    const mediaId = editorStateRepository.mediaId;
    const mediaConfig = editorStateRepository.assConfig;

    if (!mediaId || !mediaConfig) {
      return;
    }

    const oldTitles = mediaConfig.titles ?? [];
    const isTitleExist = oldTitles.some(({ id }) => id === title.id);

    if (!isTitleExist) {
      handleUpdateConfig({
        ...mediaConfig,
        titles: [...oldTitles, title]
      });
      return;
    }

    const titles = oldTitles.map((oldTitle) => (oldTitle.id === title.id ? title : oldTitle));

    handleUpdateConfig({ ...mediaConfig, titles });
  };

  const handleSetSubtitlesOrTranscriptionOutdated = (id?: string): void => {
    const mediaId = editorStateRepository.mediaId;
    const mediaConfig = editorStateRepository.assConfig;
    if (!mediaId || !mediaConfig) {
      return;
    }

    const subtitlesOutdated = id === originalTranscriptionId ? true : false;
    const transcriptionOutdated = id === originalId ? true : false;
    handleUpdateConfig({ ...mediaConfig, subtitlesOutdated, transcriptionOutdated });
  };

  // When a subscription is ready, pusher will emit a message through this observable
  // that will trigger an update on the media editor.
  React.useEffect(() => {
    const transcriptionReady$ = editorStateRepository.transcriptionReady$.subscribe(async (data) => {
      const mediaId = editorStateRepository.mediaId;

      if (data.mediaId !== mediaId) {
        return;
      }

      if (!data.hasTranscription) {
        mediaEditorStateRepository.updateState({
          transcribeEmpty: true
        });
      }

      await onLoadEditor(mediaId);
    });

    return () => {
      transcriptionReady$.unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    const isLoadingEditor = editorStateRepository.editorLoading;

    if (!mediaId || isLoadingEditor) {
      return;
    }

    onLoadEditor(mediaId).then();
  }, [mediaId]);

  React.useEffect(() => {
    const autoSave$ = mediaEditorStateRepository.trackChanges$.subscribe(async () => {
      const transcriptions = mediaEditorStateRepository.transcriptions;
      const mediaId = editorStateRepository.mediaId;
      const mediaConfig = editorStateRepository.assConfig;

      if (!transcriptions || !mediaConfig || !mediaId) {
        return;
      }

      await onDebouncedSave(transcriptions, mediaConfig, mediaId);

      const activeUsers = userPresenceStore.getValue().users.filter((u) => u?.mediaId === mediaId);
      const hasMultipleUsers = activeUsers.length > 1;
      if (hasMultipleUsers) {
        // TODO: TRIGGER PUSHER HAS_SAVED
      }
    });

    return () => autoSave$.unsubscribe();
  }, []);

  // Set the active media in editorStore
  React.useEffect(() => {
    editorStateRepository.updateState({ loadedAt: new Date() });

    return () => {
      editorStateRepository.resetState();
      editorUiStateRepository.resetState();
      mediaHistoryRepository.resetState();
      mediaEditorStateRepository.resetState();
      mediaPlayerRepository.resetState();
      resetTranslationsEditorState();
    };
  }, [media?.mediaId]);

  const handleUndoRedo = (transcriptions?: TranscriptionMap, mediaConfig?: MediaConfig) => {
    const mediaId = editorStateRepository.mediaId;

    if (!mediaId || !transcriptions || !mediaConfig) {
      return;
    }

    Object.keys(transcriptions).forEach((id) => onUpdateTranscription(id, mediaId));
    onUpdateConfig(mediaConfig, mediaId);

    mediaEditorStateRepository.updateState({ transcriptions });

    const snippets = editorStateRepository.snippets;

    // Add back snippets to the mediaConfig from media history
    const assConfig: MediaConfig = {
      ...mediaConfig,
      snippets
    };
    editorStateRepository.updatePartialMedia({ assConfig });
    mediaEditorStateRepository.transcriptionChanged$.next(true);
  };

  // Snippets tab dependencies
  const addSnippet = (): void => {
    const mediaId = editorStateRepository.mediaId;

    if (!mediaId) {
      return;
    }

    const activeSelectionId = mediaEditorStateRepository.activeSelectionId;

    timelineStore.toggleTimeline$.next(true);

    const selectionLength = 5000;
    const currentTime = mediaPlayerRepository.currentTime;

    let startTime = secToMs(currentTime);

    if (activeSelectionId) {
      const activeSelection = selections[activeSelectionId];

      if (activeSelection) {
        startTime = activeSelection.end;
      }
    }

    // move the scrubber to the end of the newly created selection
    const durationValue = mediaPlayerRef.current?.duration || 0;

    const newUpdatedScrubTime = msToSec(startTime + selectionLength);
    const isOverDuration = newUpdatedScrubTime > durationValue;
    const updatedScrubTime = isOverDuration ? durationValue : newUpdatedScrubTime;
    mediaPlayerRepository.updateState({ currentTime: updatedScrubTime });

    const id = v4();

    editorUiStateRepository.createSelection({
      id,
      startTime,
      length: msToSec(selectionLength)
    });

    setActiveSelectionId(id);

    setCheckedSnippets([...checkedSnippetsIds, id]);

    const activeSnippetId = selectedSnippet?.id;

    if (activeSnippetId) {
      editorUiStateRepository.removeSelection(activeSnippetId);
    }
  };

  const editSnippet = (snippet: MediaSnippet): void => {
    const mediaId = editorStateRepository.mediaId;

    if (!mediaId) {
      return;
    }

    setActiveSelectionId(snippet.id);
    setSelectedSnippet(snippet);

    if (snippet.transcriptionId) {
      setCurrentSubtitlesId(snippet.transcriptionId);
    }
    editorUiStateRepository.createSelectionFromSnippet(snippet);
  };

  const addTitle = () => {
    const durationInMs = mediaPlayerRepository.durationInMs;

    const newId = v4();
    const currentTime = secToMs(mediaPlayerRepository.currentTime);
    const end = Math.min(currentTime + 5000, durationInMs);

    handleUpdateTitle({
      ...DEFAULT_TITLE_CONFIG,
      id: newId,
      start: currentTime,
      end
    });

    setSelectedTitleId(newId);
  };

  return (
    <MediaEditorContext.Provider
      value={{
        handleUpdateConfig,
        handleUpdateStyles,
        handleUpdateTitle,
        handleSetSubtitlesOrTranscriptionOutdated,
        handleCreateTranscription,
        handleUpdateTranscription,
        handleRegenerateTranscription,
        handleDeleteTranscription,
        handleUpdatePresets: onUpdateAccountPresets,
        handleUndoRedo,
        addSnippet,
        editSnippet,
        onTranslate,
        addTitle,
        settings
      }}
    >
      <UndoRedoProvider>
        <TranscriptionOccurrencesProvider>
          <CommentsProvider>
            <EditorMenubarProvider>{children}</EditorMenubarProvider>
          </CommentsProvider>
        </TranscriptionOccurrencesProvider>
      </UndoRedoProvider>
    </MediaEditorContext.Provider>
  );
};

export const useMediaEditor = (): MediaEditorContext => {
  const context = React.useContext(MediaEditorContext);

  if (context === null) {
    throw new Error("useMediaEditor must be used within a MediaEditorProvider");
  }

  return context;
};

type DefaultEditorPanels = {
  [K in EditorPanel.Subtitles | EditorPanel.Style]: true;
};

type OtherEditorPanels = {
  [K in Exclude<EditorPanel, EditorPanel.Subtitles | EditorPanel.Style>]?: boolean;
};

export type MediaEditorSettings = {
  features: {
    isIframe?: boolean;
    hamburger?: boolean;
    home?: boolean;
    topNavbar?: boolean;
    guidelines?: boolean;
    uploadSrt?: boolean;
    uploadBgImage?: boolean;
    panels: DefaultEditorPanels & OtherEditorPanels;
  };
};

/**
 * This Facade Provider only provides a connection with the servers and configurations,
 * passing it to other providers where we can build desired functionality.
 */
interface MediaEditorFacadeContext {
  onDebouncedSave: (transcriptions: TranscriptionMap, mediaConfig: MediaConfig, mediaId: string) => Promise<void>;
  onUpdateConfig: (mediaConfig: MediaConfig, mediaId: string) => void;
  onCreateTranscription: (params: {
    mediaId: string;
    type: FileType.Subtitle | FileType.Transcription;
    languageCode: string;
    transcription?: Transcription;
    isTranslation?: boolean;
  }) => Promise<{ file: MediaFile; transcription: Transcription }>;
  onUpdateTranscription: (id: string, mediaId: string) => void;
  onDeleteTranscription: (id: string, mediaId: string) => Promise<void>;
  onTranslate: ({
    mediaId,
    targetLanguages,
    fileType,
    subtitleFileId,
    sourceLanguage,
    ignoreWords,
    isFormal,
    useGlossary
  }: TranslateMediaParams) => Promise<TranscriptionMap>;
  onUpdateAccountPresets: (mediaId: string, presets: AccountTemplate[]) => Promise<void>;
  onLoadEditor: (mediaId: string) => Promise<void>;
  media?: EnrichedMedia;
  settings: MediaEditorSettings;
}

const MediaEditorFacadeContext = React.createContext<MediaEditorFacadeContext | null>(null);

type MediaEditorFacadeProviderProps = MediaEditorFacadeContext & {
  children: React.ReactNode;
};

export const MediaEditorFacadeProvider: React.FC<MediaEditorFacadeProviderProps> = ({ children, ...props }) => {
  return <MediaEditorFacadeContext.Provider value={props}>{children}</MediaEditorFacadeContext.Provider>;
};

// It is not exported because it should only be used by the Media Editor Provider
const useMediaEditorFacade = (): MediaEditorFacadeContext => {
  const context = React.useContext(MediaEditorFacadeContext);

  if (context === null) {
    throw new Error("useMediaEditorFacade must be used within a MediaEditorFacadeProvider");
  }

  return context;
};
