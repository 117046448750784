import React, { useState } from "react";
import classNames from "classnames";
import { differenceInDays } from "date-fns";

import { scheduleAccountDeletion } from "@frontend/api/account.service";
import { verifyEmailResend } from "@frontend/api/auth.service";
import { EN } from "@frontend/assets/i18n/en";
import { SettingsCard } from "@frontend/components/settings-card/settings-card";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { SETTINGS_BILLING_PATH, SETTINGS_PROFILE_EDIT_PATH } from "@frontend/routes";

import { ProfileImageIcon } from "@shared/components/profile-image-icon/profile-image-icon";
import { useAccounts } from "@shared/hooks/use-accounts";
import { useModal } from "@shared/hooks/use-modal";
import { usePlan } from "@shared/hooks/use-plan";
import { ModalType } from "@shared/interfaces/modal-type";
import { Alert } from "@shared/primitives/alert";
import { Button } from "@shared/primitives/button";
import { FileInput } from "@shared/primitives/file-input";
import { Input } from "@shared/primitives/input";
import { NewModal } from "@shared/primitives/new-modal";
import { notificationError, notificationSuccess } from "@shared/primitives/notification";

export const ProfileCard: React.FC = () => {
  const { user, isGoogleAuth } = useAuthProvider();
  const [sendingConfirmation, setSendingConfirmation] = useState(false);
  const { isOwner } = useAccounts();

  if (!user) {
    return null;
  }

  const handleRequestVerifyEmail = async () => {
    setSendingConfirmation(true);

    try {
      await verifyEmailResend();

      notificationSuccess("Please check your email to confirm your email address.");
    } catch (e) {
      console.error(e);

      notificationError(EN.error.defaultMessage);
    }

    setSendingConfirmation(false);
  };

  return (
    <>
      <SettingsCard>
        <SettingsCard.Header className="tw-flex tw-items-center tw-justify-between">
          Profile
          <Button variant="secondary" to={SETTINGS_PROFILE_EDIT_PATH} type="link" size="32">
            Change details
          </Button>
        </SettingsCard.Header>
        <SettingsCard.Body>
          <div className="tw-mb-6">
            <label className="tw-font-medium">Profile picture</label>
            <UploadProfilePicture />
          </div>

          <div className="tw-mb-6">
            <label className="tw-font-medium">Name</label>
            <p>{user.name}</p>
          </div>

          <div
            className={classNames("tw-mb-6", {
              "tw-italic": !user.emailConfirmed
            })}
          >
            <label className="tw-font-medium">Email</label>
            <p>{user.email}</p>
            {!user.emailConfirmed && (
              <Button
                className="tw-w-1/2"
                variant="primary"
                onClick={handleRequestVerifyEmail}
                loading={sendingConfirmation}
                size="36"
              >
                Confirm Email
              </Button>
            )}
          </div>

          {!isGoogleAuth && (
            <div className="tw-mt-6">
              <label className="tw-font-medium">Password</label>
              <p>••••••••••</p>
            </div>
          )}

          {isOwner && <DeleteAccount />}
        </SettingsCard.Body>
      </SettingsCard>
    </>
  );
};

const UploadProfilePicture: React.FC = () => {
  const { user, uploadProfilePicture } = useAuthProvider();
  const [loading, setLoading] = useState(false);

  if (!user) {
    return null;
  }

  const handleUpload = async (file: File) => {
    // Check image dimensions
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = async () => {
      const { width, height } = img;

      if (width < 200 || height < 200) {
        notificationError(EN.error.AvatarImageSize);
        return;
      }

      try {
        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > 5) {
          notificationError(EN.error.AvatarFileSize);
          return;
        }

        setLoading(true);

        await uploadProfilePicture(file);

        notificationSuccess("Your profile has been updated.");
      } catch (e) {
        console.error(e);

        notificationError(EN.error.defaultMessage);
      } finally {
        setLoading(false);
      }
    };
  };

  return (
    <div className="tw-w-fit tw-cursor-pointer">
      <FileInput accept="image/*" onChange={handleUpload}>
        <ProfileImageIcon
          name={user.name}
          image={user.picturePublic}
          size="100%"
          isUploader
          isUploading={loading}
          twoLetters
          className="tw-text-h1"
        />
      </FileInput>
    </div>
  );
};

const DeleteAccount = () => {
  const [showDeleteModal, hideDeleteModal] = useModal(ModalType.DeleteAccountModal);
  const [showCancelDeletionModal, hideCancelDeletionModal] = useModal(ModalType.CancelDeletionModal);
  const { currentAccount } = useAccounts();
  const { isFree } = usePlan();

  const handleDelete = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    showDeleteModal(<DeleteAccountModal onDismiss={hideDeleteModal} />);
  };

  const handleCancelDeletion = () => {
    showCancelDeletionModal(<CancelDeletionModal onDismiss={hideCancelDeletionModal} />);
  };

  if (!currentAccount?.scheduledDeletionDate) {
    return (
      <>
        <div className="tw-mt-6 tw-text-center tw-text-[10px] tw-font-normal tw-text-neutral-600">
          If you wish to delete your account, please{" "}
          <a
            href="#"
            className="tw-font-normal tw-underline hover:tw-text-destructive-600 hover:tw-underline"
            onClick={handleDelete}
          >
            click here
          </a>
          .
        </div>
      </>
    );
  }

  const deletionDate = new Date(currentAccount?.scheduledDeletionDate);
  const daysLeft = differenceInDays(deletionDate, new Date());

  return (
    <div className="tw-mt-6 tw-flex tw-items-center tw-justify-between tw-gap-2 tw-text-center tw-text-destructive-700">
      <div>This account will be deleted {daysLeft >= 1 ? `in ${daysLeft} days` : "today"}</div>
      {isFree ? (
        <Button variant="secondary" type="link" to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }}>
          Cancel deletion
        </Button>
      ) : (
        <Button variant="secondary" onClick={handleCancelDeletion}>
          Cancel deletion
        </Button>
      )}
    </div>
  );
};

interface DeleteAccountModalProps {
  onDismiss: () => void;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ onDismiss }) => {
  const { user } = useAuthProvider();
  const { upcomingInvoice } = useAccounts();
  const [alert, setAlert] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);
  const [emailInput, setEmailInput] = useState<string>("");

  const enableDeleteButton = emailInput === user?.email;
  const hasSubscription = !!upcomingInvoice;

  const handleCloseAlert = () => {
    setAlert("");
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      await scheduleAccountDeletion(false, user?.name, user?.email);

      setDeletionConfirmed(true);
      setLoading(false);
    } catch (e) {
      setAlert(e?.response?.data?.error?.message || e.message || EN.error.defaultMessage);
      setLoading(false);
    }
  };

  return (
    <NewModal
      title={deletionConfirmed ? "Account deletion confirmation" : "Delete your account and workspace"}
      size={deletionConfirmed ? "465" : "608"}
      showCloseButton
      onDismiss={onDismiss}
    >
      {deletionConfirmed ? (
        <div className="tw-flex tw-flex-col tw-gap-4">
          <div className="tw-text-sm tw-text-neutral-700">
            Your account is set to be deleted {hasSubscription ? "7 days after your subscriptions ends." : "in 7 days."}
            <br />
            You have the opportunity to cancel this action during this period.
          </div>
          <div className="tw-flex tw-justify-end tw-pt-6">
            <Button variant="secondary" onClick={onDismiss} size="32">
              Close
            </Button>
          </div>
        </div>
      ) : (
        <>
          {alert && (
            <Alert danger title="Something went wrong" closable onClose={handleCloseAlert}>
              {alert}
            </Alert>
          )}
          <div className="tw-flex tw-flex-col tw-gap-4">
            <div className="tw-text-sm tw-text-neutral-700">
              This action cannot be undone. This will permanently delete your account and your entire workspace.
              <br />
              <br />
              All the media, users and information will be deleted.
            </div>
            <div>
              <label>Please type in your email to confirm.</label>
              <Input type="email" value={emailInput} onChange={(e) => setEmailInput(e.target.value)} />
            </div>
            <div className="tw-flex tw-justify-between tw-pt-8">
              <Button variant="secondary" onClick={onDismiss}>
                Cancel
              </Button>
              <Button variant="destructive" onClick={handleConfirm} loading={loading} disabled={!enableDeleteButton}>
                Delete account and workspace
              </Button>
            </div>
          </div>
        </>
      )}
    </NewModal>
  );
};

export const CancelDeletionModal: React.FC<DeleteAccountModalProps> = ({ onDismiss }) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    await scheduleAccountDeletion(true);
    setLoading(false);
    onDismiss();
  };

  return (
    <NewModal title="Cancel deletion" size="465" showCloseButton onDismiss={onDismiss}>
      <div className="tw-flex tw-flex-col tw-gap-4">
        <div className="tw-text-sm tw-text-neutral-700">This will stop the deletion of your account.</div>
        <div className="tw-flex tw-justify-between tw-pt-6">
          <Button variant="secondary" onClick={onDismiss}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm} loading={loading}>
            Confirm
          </Button>
        </div>
      </div>
    </NewModal>
  );
};
