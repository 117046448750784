import React from "react";
import classNames from "classnames";
import { Link, LinkProps } from "react-router-dom";

import { AnyOnClickHandler } from "@shared/interfaces/types";

import styles from "./text-button.module.scss";

export type ButtonVariant = "primary" | "secondary";

export type ButtonSize = "2xs" | "xs" | "sm" | "md" | "lg";

export type ButtonType = "submit" | "button" | "link";

export type ButtonState = "default" | "active" | "hover" | "focus" | "disabled";

export type ButtonProps = {
  id?: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  loading?: boolean;
  children?: React.ReactNode;
  className?: string;
  loaderClassName?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  download?: string | boolean;
  form?: string;
  type?: ButtonType;
  tabIndex?: number;
  icon?: JSX.Element;
  rIcon?: JSX.Element;
  isBlank?: boolean;
  state?: ButtonState;
  reactRouterState?: unknown;
  linkState?: unknown;
  role?: string;
  tooltipAnchorId?: string;
  hideTooltip?: boolean;
  style?: React.CSSProperties;
  "aria-controls"?: string;
  "aria-expanded"?: boolean | string;
  "aria-haspopup"?: boolean | string;
  to?: LinkProps["to"] | string;
  onClick?: AnyOnClickHandler;
  onFocus?: AnyOnClickHandler;
  onKeyDown?: AnyOnClickHandler;
  onKeyUp?: AnyOnClickHandler;
  onMouseDown?: AnyOnClickHandler;
  onMouseMove?: AnyOnClickHandler;
  onPointerDown?: AnyOnClickHandler;
  onPointerEnter?: AnyOnClickHandler;
  onPointerLeave?: AnyOnClickHandler;
} & (
  | {
      type?: "submit" | "button";
      to?: never;
      linkState?: never;
      isBlank?: never;
    }
  | {
      type?: "link";
      to?: LinkProps["to"];
      linkState?: unknown;
    }
);

export const TextButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      variant = "primary",
      size = "sm",
      loading,
      children,
      className,
      loaderClassName,
      autoFocus,
      form,
      type = "button",
      tabIndex,
      icon,
      rIcon,
      disabled,
      state,
      tooltipAnchorId,
      hideTooltip,
      style,
      role,
      to = "#",
      download,
      isBlank,
      reactRouterState,
      onClick,
      onFocus,
      onKeyDown,
      onKeyUp,
      onMouseDown,
      onMouseMove,
      onPointerDown,
      onPointerEnter,
      onPointerLeave
    },
    ref
  ) => {
    const isDisabled = disabled || loading;
    const classes = classNames([
      styles["text-button"],
      {
        [styles["size--2xs"]]: size === "2xs", // design only supports icon button atm
        [styles["size--xs"]]: size === "xs", // 28 design only supports icon button atm
        [styles["size--sm"]]: size === "sm", // 32
        [styles["size--md"]]: size === "md", // 40
        [styles["size--lg"]]: size === "lg", // 48

        [styles["type--button"]]: type === "button",
        [styles["type--submit"]]: type === "submit",
        [styles["type--link"]]: type === "link",

        [styles["variant--primary"]]: variant === "primary",
        [styles["variant--secondary"]]: variant === "secondary",

        [styles.disabled]: isDisabled
      },
      state, // assert state
      className
    ]);

    const loader = loading ? <Loader className={classNames(styles["loader"], loaderClassName)} /> : null;

    const renderIcon = (icon: JSX.Element, className: string) => {
      className = classNames(icon.props.className, styles["icon"], className);
      const props = { ...icon.props, className };
      return <icon.type {...props} />;
    };

    const LeftIcon = icon ? renderIcon(icon, styles["icon--left"]) : null;
    const RightIcon = rIcon ? renderIcon(rIcon, styles["icon--right"]) : null;

    if (type === "link") {
      return (
        <LinkButton
          id={id}
          className={classes}
          style={style}
          to={to}
          disabled={isDisabled}
          autoFocus={autoFocus}
          tabIndex={tabIndex}
          download={download}
          reactRouterState={reactRouterState}
          isBlank={isBlank}
          onClick={onClick}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onPointerDown={onPointerDown}
          onPointerEnter={onPointerEnter}
          onPointerLeave={onPointerLeave}
        >
          {loading ? loader : LeftIcon}
          {children}
          {RightIcon}
        </LinkButton>
      );
    }

    return (
      <button
        id={id}
        className={classes}
        data-tooltip-id={tooltipAnchorId}
        data-tooltip-hidden={hideTooltip}
        disabled={isDisabled}
        autoFocus={autoFocus}
        form={form}
        type={type}
        tabIndex={tabIndex}
        onClick={onClick}
        onFocus={onFocus}
        role={role}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onPointerDown={onPointerDown}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        ref={ref}
      >
        {loading ? loader : LeftIcon}
        {children}
        {RightIcon}
      </button>
    );
  }
);

interface LoadingIconProps {
  className?: string;
}
const Loader: React.FC<LoadingIconProps> = ({ className }) => {
  return (
    <div role="status" className={classNames(className, "tw-flex")}>
      <svg className="tw-animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="tw-opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
};

interface LinkButtonProps {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  to?: LinkProps["to"] | string;
  disabled?: boolean;
  autoFocus?: boolean;
  tabIndex?: number;
  children?: React.ReactNode;
  reactRouterState?: unknown;
  download?: string | boolean;
  isBlank?: boolean;
  onClick?: () => void;
  onFocus?: AnyOnClickHandler;
  onKeyDown?: AnyOnClickHandler;
  onKeyUp?: AnyOnClickHandler;
  onMouseDown?: AnyOnClickHandler;
  onMouseMove?: AnyOnClickHandler;
  onPointerDown?: AnyOnClickHandler;
  onPointerEnter?: AnyOnClickHandler;
  onPointerLeave?: AnyOnClickHandler;
}

const LinkButton: React.FC<LinkButtonProps> = ({
  id,
  className,
  style,
  to = "#",
  disabled,
  autoFocus,
  tabIndex,
  children,
  reactRouterState,
  download,
  isBlank,
  onClick,
  onFocus,
  onKeyDown,
  onKeyUp,
  onMouseDown,
  onMouseMove,
  onPointerDown,
  onPointerEnter,
  onPointerLeave
}) => {
  const linkElement = React.useRef<HTMLAnchorElement>(null);

  React.useEffect(() => {
    if (autoFocus && linkElement.current) {
      linkElement.current.focus();
    }
  }, []);

  if (typeof to === "string" && isBlank) {
    return (
      <a
        id={id}
        ref={linkElement}
        className={classNames(className, { disabled })}
        style={style}
        href={to}
        tabIndex={tabIndex}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onPointerDown={onPointerDown}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      ref={linkElement}
      id={id}
      className={classNames(className, { disabled })}
      style={style}
      to={to}
      tabIndex={tabIndex}
      download={download}
      state={reactRouterState}
      onClick={onClick}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onPointerDown={onPointerDown}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    >
      {children}
    </Link>
  );
};
