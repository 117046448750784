import React from "react";

import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { Button } from "@shared/primitives/button";

export const ApiUpgrade: React.FC = () => {
  return (
    <div className="tw-mt-3">
      <Button variant="primary" size="32" type="link" to={SETTINGS_BILLING_PATH} className="tw-mr-2">
        Upgrade
      </Button>
      <Button
        variant="secondary"
        type="link"
        size="32"
        to="https://www.getsubly.com/integrations/transcription-api"
        isBlank
      >
        Learn More
      </Button>
    </div>
  );
};
