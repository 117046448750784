import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet-async";

import { SignUpForm } from "@frontend/components/auth/sign-up-form";
import config from "@frontend/config";
import { useAnalytics } from "@frontend/contexts/analytics.context";

export const SignUpPage: React.FC = () => {
  const { trackEvent } = useAnalytics();

  React.useEffect(() => {
    trackEvent("User in Sign up");
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.reCAPTCHA_V3}>
      <Helmet>
        <title>Subly - Start Your Trial</title>
      </Helmet>
      <SignUpForm />
    </GoogleReCaptchaProvider>
  );
};
