import React from "react";
import classNames from "classnames";

import { TextButton } from "@shared/primitives/text-button";

type CookiePreferencesProps = {
  className?: string;
};

export const CookiePreferences: React.FC<CookiePreferencesProps> = (props) => {
  return (
    <TextButton
      onClick={() => {
        const button = document.querySelector(".cky-btn-revisit-wrapper");
        if (button) (button as HTMLButtonElement)?.click();
      }}
      className={classNames("tw-w-min !tw-text-neutral-600 tw-underline hover:!tw-text-neutral-900", props.className)}
      variant="secondary"
      size="xs"
    >
      Cookie preferences
    </TextButton>
  );
};
