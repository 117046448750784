import { User } from "@shared/interfaces/user";
import { authQuery } from "@shared/state/auth/auth.query";

import { useObservable } from "@mindspace-io/react";

interface UseUser {
  user?: User;
  trialDownloadsLimit: number;
}
export function useUser(): UseUser {
  const [user] = useObservable(authQuery.selectUser(), authQuery.user);
  const [trialDownloadsLimit] = useObservable(authQuery.selectTrialDownloadsLimit(), 0);

  return {
    user,
    trialDownloadsLimit
  };
}
