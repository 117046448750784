import React from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";

import { XMarkIcon } from "@shared/components/icons";
import { Alert } from "@shared/primitives/alert";
import { Button } from "@shared/primitives/button";
import { Input } from "@shared/primitives/input";
import { Loader } from "@shared/primitives/loader";
import { containsPunctuationsOrNumbers, wordCount } from "@shared/utils/strings";

interface TagsInputProps {
  value: string[];
  placeholder?: string;
  className?: string;
  contentClassName?: string;
  disabled?: boolean;
  variant?: "flat" | "outline";
  onChange?: (tag: string[]) => void;
  onAddTag?: (tag: string) => void;
  onRemoveTag?: (tag: string) => void;
}

export const TagsInput: React.FC<TagsInputProps> = ({
  value = [],
  placeholder,
  className,
  contentClassName,
  disabled,
  variant,
  onChange,
  onAddTag,
  onRemoveTag
}) => {
  const [tags, setTags] = React.useState<string[]>(value);
  const [loadingTag, setLoadingTag] = React.useState<string>();
  const [inputValue, setInputValue] = React.useState<string>("");
  const [inputError, setInputError] = React.useState<string>("");

  React.useEffect(() => {
    setTags(value);
  }, [value]);

  const addTag = async (tag: string) => {
    if (!tags.includes(tag)) {
      setInputValue("");
      setInputError("");
      const updatedTags = [...tags, tag.trim()];
      setTags(updatedTags);
      setLoadingTag(tag);
      await onAddTag?.(tag);
      setLoadingTag(undefined);
      onChange?.(updatedTags);
    } else {
      setInputError("This word already exists");
    }
  };

  const removeTag = async (tag: string) => {
    if (tags.includes(tag)) {
      setLoadingTag(tag);
      await onRemoveTag?.(tag);
      setLoadingTag(undefined);
      const updatedTags = [...tags].filter((_tag: string) => _tag !== tag);
      setTags(updatedTags);
      onChange?.(updatedTags);
    }
  };

  const onInput = (text: string) => {
    setInputValue(text);
    setInputError("");
  };

  return (
    <div className={classNames("tw-flex tw-flex-col tw-gap-6", className)}>
      <div className="tw-flex tw-flex-col tw-gap-1">
        <TagInput
          value={inputValue}
          placeholder={placeholder}
          error={inputError}
          disabled={disabled}
          onSubmit={addTag}
          onInput={onInput}
          variant={variant}
        />
        {tags.length > 0 && (
          <div
            className={classNames(
              "tw-flex tw-w-full tw-flex-row tw-flex-wrap tw-items-center tw-gap-x-1 tw-gap-y-1 tw-overflow-y-auto tw-overflow-x-hidden",
              contentClassName
            )}
          >
            {tags.map((tag: string) => (
              <Tag key={tag} text={tag} isLoading={loadingTag === tag} isDisabled={disabled} onRemove={removeTag} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

interface TagProps {
  text: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onRemove: (text: string) => void;
}
export const Tag: React.FC<TagProps> = ({ text, isLoading, isDisabled, onRemove }) => {
  const onClickRemove = () => {
    if (isLoading) return;
    onRemove(text);
  };
  const showButton = !isDisabled;

  return (
    <div className="tw-group tw-flex tw-h-10 tw-flex-row tw-items-center tw-rounded-md tw-border-[1px] tw-border-neutral-200 tw-bg-neutral-50 tw-shadow-xs">
      <div className="tw-w-full tw-select-none tw-px-3">
        <p className="tw-text-sm tw-font-medium tw-text-neutral-700">{text}</p>
      </div>
      {showButton ? (
        <button
          className="tw-flex tw-min-h-full tw-w-11 tw-flex-row tw-items-center tw-rounded-br-md tw-rounded-tr-md tw-border-l-[1px] tw-border-neutral-200 tw-px-3 hover:tw-bg-neutral-100 group-hover:tw-cursor-pointer"
          onClick={onClickRemove}
        >
          {!isLoading ? <XMarkIcon className="tw-stroke-neutral-700 tw-stroke-2" width={16} height={16} /> : <Loader />}
        </button>
      ) : null}
    </div>
  );
};

type TagForm = {
  tag: string;
};

interface TextInputProps {
  value: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  variant?: "flat" | "outline";
  onInput: (text: string) => void;
  onSubmit: (text: string) => void;
}
export const TagInput: React.FC<TextInputProps> = ({
  value,
  placeholder = "Type keywords or phrases here...",
  error,
  disabled,
  variant,
  onSubmit,
  onInput
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = useForm<TagForm>({
    mode: "all"
  });

  React.useEffect(() => {
    setValue("tag", value);
  }, [value]);

  const handleOnSubmit = ({ tag }: TagForm) => {
    let tags = tag.split(",").filter(Boolean);
    tags = [...new Set(tags)];
    for (const tag of tags) {
      onSubmit(tag);
    }
  };

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    onInput?.(value);
  };

  const hasInputValue = Boolean(watch().tag);
  const hasError = (Boolean(errors.tag) || Boolean(error)) && hasInputValue;
  const errorMessage = errors.tag?.message || "";
  const disableSubmit = hasError || !hasInputValue || disabled;

  return (
    <div>
      <form className="tw-mb-1 tw-flex tw-flex-row tw-gap-2" onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="tw-flex-1">
          <Input
            hasError={hasError}
            placeholder={placeholder}
            {...register("tag", {
              required: true,
              validate: {
                sentenceWordLimit: (tag: string) => {
                  return wordCount(tag) <= 6 || "Sentence limit up to 6 words";
                },
                containsPunctuation: (tag: string) => {
                  return (
                    !containsPunctuationsOrNumbers(tag, { allowComma: true }) ||
                    "No punctuations or numbers, except apostrophes"
                  );
                }
              },
              onChange: handleOnChange
            })}
            disabled={disabled}
            variant={variant}
          />
        </div>

        <Button variant="secondary" className="tw-w-16" type="submit" disabled={disableSubmit} size="40">
          Add
        </Button>
      </form>
      {errorMessage && (
        <Alert warning className="tw-mb-1 tw-border-[1px] tw-border-warning-200 tw-bg-warning-50">
          <p className="tw-text-xs tw-font-medium tw-text-warning-800">{errorMessage}</p>
        </Alert>
      )}
    </div>
  );
};
