import React from "react";
import { useForm } from "react-hook-form";

import { updateAPIBucket } from "@frontend/api/api.service";

import { Button } from "@shared/primitives/button";
import { Input } from "@shared/primitives/input";
import { NewModal, NewModalProps } from "@shared/primitives/new-modal";
import { notificationSuccess } from "@shared/primitives/notification";

type UpdateBucketForm = { bucket: string };

interface APIUpdateBucketModalProps extends NewModalProps {
  bucket?: string;
  accountId: string;
  onDismiss: () => void;
}
export const APIUpdateBucketModal: React.FC<APIUpdateBucketModalProps> = ({ bucket = "", accountId, ...props }) => {
  const { handleSubmit, register } = useForm<UpdateBucketForm>({
    defaultValues: { bucket }
  });
  const [isLoading, setLoading] = React.useState(false);

  const updateBucket = async (data: UpdateBucketForm) => {
    setLoading(true);

    await updateAPIBucket(accountId, data.bucket);

    setLoading(false);

    notificationSuccess("Bucket updated successfully");

    props.onDismiss();
  };

  return (
    <NewModal {...props}>
      <form onSubmit={handleSubmit(updateBucket)}>
        <Input label="Your AWS S3 Bucket" wrapperClassName="tw-mb-4 tw-w-full" {...register("bucket")} />

        <div className="tw-flex tw-justify-end tw-gap-3">
          <Button variant="secondary" type="button" onClick={props.onDismiss} disabled={isLoading} size="36">
            Cancel
          </Button>

          <Button type="button" variant="primary" loading={isLoading} onClick={handleSubmit(updateBucket)} size="36">
            Save
          </Button>
        </div>
      </form>
    </NewModal>
  );
};
