import { AccountType } from "@frontend/api/billing.service";

import { BillingDetails } from "@shared/interfaces/billing";
import { ICustomVocabularyWord } from "@shared/interfaces/custom-vocabulary-words";
import { IGlossary } from "@shared/interfaces/glossary";
import { Folder } from "@shared/state/dashboard/dashboard.store";
import { GuidelineTemplate } from "@shared/utils/guidelines";

import { AspectRatio, MediaConfig, RoleName } from "@getsubly/common";
import { AudioTemplate } from "@media-editor/types";

export interface IApiKeyDetails {
  id: string;
  name: string;
  planId: string;
  planName: string;
  apiGatewayId: string;
}

export interface IBucketPolicyDetails {
  id: string;
  name: string;
  roles: string[];
}

export interface IApiAccountDetails {
  id: string;
  accountId: string;
  stage: string;
  apiKeyId?: string;
  apiKeyDetails?: IApiKeyDetails;
  apiBucket?: string;
  bucketPolicyArn?: string;
  bucketPolicyDetails?: IBucketPolicyDetails;
  createdAt: Date;
  updatedAt: Date;
}

export type IFullApiDetails = IApiAccountDetails & { apiKey: string };

export interface UserAccount {
  accountId: string;
  accountName: string;
  accountPicture?: string;
  accountType: AccountType;
  creditFreeSeconds: number;
  creditPaidSeconds: number;
  creditExtraSeconds: number;
  creditPaygSeconds: number;
  postPaidSeconds: number;
  billing?: {
    loading: boolean;
    loaded: boolean;
    details?: BillingDetails;
    stripeId?: string;
    isUpfrontCredit?: boolean;
    pausedAt?: number;
    pausedUntil?: number;
  };
  accountTeam?: {
    loading: boolean;
    loaded: boolean;
    invitations?: Invitation[];
    members?: AccountMember[];
    capacity: number;
    additionalSeats?: number;
  };
  folders: Folder[];
  mediaCount?: number;
  mediaSharedWithMeCount?: number;
  owner: string;
  isOwner?: boolean;
  role?: RoleName;
  settings?: AccountSettings;
  totalFreeSecondsUsed: number;
  totalPaidSecondsUsed: number;
  updatedAt: Date;
  uploadLimit: number; // GB
  planTeamCapacity: number;
  planStorageBytes: number;
  additionalSeats: number;
  isPayg: boolean;
  additionalStorageBytes: number;
  isPostPaid?: boolean;
  customVocabularyWords: ICustomVocabularyWord[];
  glossary: IGlossary[];
  apiAccountDetails?: IApiAccountDetails[];
  scheduledDeletionDate?: Date | null;
  picture: string;
  picturePublic?: string;
}

export interface DomainAccount {
  accountId: string;
  accountName: string;
  memberCount: number;
}

export interface Invitation {
  id: string;
  email: string;
  role: RoleName;
}

export interface AccountMember {
  id: string;
  name: string;
  email: string;
  createdAt: Date;
  role: RoleName;
  requestedRole?: RoleName;
  picturePublic?: string;
}

export enum NewBadgeFeature {
  MediaComments = "MediaComments"
}

export type AccountTemplateStyles = Omit<MediaConfig, "playResX" | "playResY" | "snippets">;
export interface AccountTemplate extends AccountTemplateStyles {
  id: string;
  name: string;
  ratio: AspectRatio;
}

export type NewFeatureBadgeSettings = {
  [key in NewBadgeFeature]: Date;
};
export interface AccountSettings {
  primaryColors?: string[];
  outlineColors?: string[];
  aspectRatioColors?: string[];
  borderColors?: string[];
  ignoreWords?: string[];
  hasSeenMediaEditorTour?: boolean;
  hasSeenSpeakersTour?: boolean;
  hasSeenTranslationTour?: boolean;
  hasSeenAccessibilityModal?: boolean;
  customStorageBytes?: number;
  customUploadLimitBytes?: number;
  templates: AccountTemplate[];
  lastVersionTour: string;
  showReleaseModal: boolean;
  hasTranslated: boolean;
  hidePositioningHint: boolean;
  hideReformattingHint: boolean;
  hideTranslationWarning: boolean;
  beta?: boolean;
  hasAlreadyTrialled?: boolean;
  hasSeenTrialModal: boolean;
  hasSeenTrialWelcome: boolean;
  hasSeenTrialOneDownloadRemain: boolean;
  hasSeenTrialLimitModal: boolean;
  migratedProToPremium?: boolean;
  hasUsedReturnCoupon?: boolean;
  audioTemplates: AudioTemplate[];
  trialDownloads: number;
  hasSeenTemplateDeleteModal: boolean;
  hasSeenDashboard?: boolean;
  newFeatureBadge?: NewFeatureBadgeSettings;
  hasPausedPlan?: boolean;
  resumePlanAt?: number;
  guidelineSettings?: GuidelineTemplate;
  allowHumanTranscription?: boolean;
  recentLanguages?: string[];
  useGlossary?: boolean;
  isDiscoverable?: boolean;
}

export const DEFAULT_PRIMARY_COLORS = [
  "#000000",
  "#7F1D1D",
  "#78350F",
  "#075535",
  "#003A77",
  "#2C0575",
  "#374151",
  "#991B1B",
  "#B45309",
  "#0EB370",
  "#034A96",
  "#3F08A6",
  "#6B7280",
  "#DC2626",
  "#D97706",
  "#12E28E",
  "#0472E7",
  "#691FF5",
  "#9CA3AF",
  "#EF4444",
  "#FFAB00",
  "#39EFA5",
  "#228BFB",
  "#8A50F7",
  "#D1D5DB",
  "#FCA5A5",
  "#FCD34D",
  "#86EFAC",
  "#55A6FC",
  "#B794FA",
  "#E5E7EB",
  "#FEE2E2",
  "#FDE68A",
  "#BBF7D0",
  "#A8D1FF",
  "#D5C6F1",
  "#FFFFFF",
  "#FEF2F2",
  "#FEF3C7",
  "#DCFCE7",
  "#DBECFF",
  "#F3EFFB"
];

export const DEFAULT_PRIMARY_COLORS_V2 = [
  "#000000",
  "#1F2937",
  "#4B5563",
  "#9CA3AF",
  "#D1D5DB",
  "#E5E7EB",
  "#F3F4F6",
  "#FFFFFF",
  "#F43F5E",
  "#EC4899",
  "#FACC15",
  "#8B5CF6",
  "#0EA5E9",
  "#10B981",
  "#84CC16",
  "#D946EF",
  "#F76E86",
  "#F174B2",
  "#FCDE69",
  "#A986F8",
  "#3FBBF3",
  "#30EDAF",
  "#AAEB47",
  "#E272F3",
  "#FCCFD7",
  "#FAD1E5",
  "#FEF4CD",
  "#DDCFFC",
  "#CFEEFC",
  "#D0FBED",
  "#EAFAD1",
  "#F5D0FB"
];

// API interfaces
export enum CreditType {
  Free = "FREE",
  Paid = "PAID",
  Extra = "EXTRA",
  PAYG = "PAYG",
  Trial = "TRIAL",
  PostPaid = "POST_PAID"
}

interface ITransactionMetadata {
  credit_seconds?: string;
  topupYear?: number;
  topupMonth?: number;
  subscriptionId?: string;
  fileId?: string;
  waiver?: number;
  dealId?: string;
  [prop: string]: unknown;
}

export interface ITransaction {
  id: string;
  accountId: string;
  invoiceId?: string;
  creditType: CreditType;
  creditFreeSeconds: number;
  creditPaidSeconds: number;
  creditExtraSeconds: number;
  creditPaygSeconds: number;
  postPaidSeconds: number;
  amount: number;
  type: string;
  description: string;
  reason: string;
  balance: Record<"total" | string, number>;
  metadata?: ITransactionMetadata;
  createdAt: Date;
}
