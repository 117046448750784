import React, { ComponentType } from "react";
import { Link } from "react-router-dom";

import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import {
  AudioDescriptionIcon,
  CommentsIcon,
  DownloadsIcon,
  ElementsIcon,
  SnippetsIcon,
  SocialEffectsIcon,
  SpeakersIcon,
  StyleIcon,
  SummarySparkleIcon,
  TextIcon
} from "@shared/components/icons";
import { SublyPlan } from "@shared/interfaces/billing";
import { useActiveMediaAudioState, useActiveMediaHasArtworkState } from "@shared/state/editor/editor.hooks";

import { useEditorLoadedState, useEditorPanelState } from "@media-editor/state/media-editor.hooks";
import { EditorPanel } from "@media-editor/types";
import { RiClosedCaptioningLine, RiFileTextLine } from "@remixicon/react";

export interface EditorMenubarItem {
  panel: EditorPanel;
  icon: ComponentType<{ className?: string; variant?: "light" | "dark" }>;
  plan: SublyPlan;
  planMessage: () => {
    heading: string;
    description: JSX.Element;
  };
  mixPanelEvent?: string;
}

export const EDITOR_MENUBAR_ITEM: { [key in EditorPanel]: EditorMenubarItem } = {
  [EditorPanel.Subtitles]: {
    panel: EditorPanel.Subtitles,
    icon: RiClosedCaptioningLine,
    plan: SublyPlan.Pro,
    planMessage: () => ({
      heading: "Subtitle is a paid feature",
      description: (
        <>
          <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} className="tw-underline">
            Upgrade now
          </Link>{" "}
          to start using AI transcription.
        </>
      )
    })
  },
  [EditorPanel.Transcription]: {
    panel: EditorPanel.Transcription,
    icon: RiFileTextLine,
    plan: SublyPlan.Pro,
    planMessage: () => ({
      heading: "Transcription is a paid feature",
      description: (
        <>
          <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} className="tw-underline">
            Upgrade now
          </Link>{" "}
          to start using AI transcription.
        </>
      )
    })
  },
  [EditorPanel.AudioDescription]: {
    panel: EditorPanel.AudioDescription,
    icon: AudioDescriptionIcon,
    plan: SublyPlan.Personal,
    planMessage: () => ({
      heading: "Audio Description is a paid feature",
      description: (
        <>
          <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} className="tw-underline">
            Upgrade now
          </Link>{" "}
          to start using Audio transcription.
        </>
      )
    }),
    mixPanelEvent: "Editor / Audio description"
  },
  [EditorPanel.Summary]: {
    panel: EditorPanel.Summary,
    icon: SummarySparkleIcon,
    plan: SublyPlan.Personal,
    planMessage: () => ({
      heading: "Summary is a paid feature",
      description: (
        <>
          <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} className="tw-underline">
            Upgrade now
          </Link>{" "}
          to generate a summary.
        </>
      )
    }),
    mixPanelEvent: "Editor / Summary"
  },
  [EditorPanel.Style]: {
    panel: EditorPanel.Style,
    icon: StyleIcon,
    plan: SublyPlan.Pro,
    planMessage: () => ({
      heading: "Style is a paid feature",
      description: (
        <>
          <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} className="tw-underline">
            Upgrade now
          </Link>{" "}
          to start styling your subtitles and video content.
        </>
      )
    })
  },
  [EditorPanel.Text]: {
    panel: EditorPanel.Text,
    icon: TextIcon,
    plan: SublyPlan.Pro,
    planMessage: () => ({
      heading: "Text is a paid feature",
      description: (
        <>
          <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} className="tw-underline">
            Upgrade now
          </Link>{" "}
          to start adding text to your video.
        </>
      )
    })
  },
  [EditorPanel.Elements]: {
    panel: EditorPanel.Elements,
    icon: ElementsIcon,
    plan: SublyPlan.Premium,
    planMessage: () => ({
      heading: "Elements is a paid feature",
      description: (
        <>
          <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} className="tw-underline">
            Upgrade now
          </Link>{" "}
          to start creating your audiograms.
        </>
      )
    })
  },
  [EditorPanel.Speakers]: {
    panel: EditorPanel.Speakers,
    icon: SpeakersIcon,
    plan: SublyPlan.Premium,
    planMessage: () => ({
      heading: "Speakers is a paid feature",
      description: (
        <>
          <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} className="tw-underline">
            Upgrade now
          </Link>{" "}
          to start using AI speaker recognition.
        </>
      )
    })
  },
  [EditorPanel.Snippets]: {
    panel: EditorPanel.Snippets,
    icon: SnippetsIcon,
    plan: SublyPlan.Premium,
    planMessage: () => ({
      heading: "Snippets is a paid feature",
      description: (
        <>
          <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} className="tw-underline">
            Upgrade now
          </Link>{" "}
          to start creating snippets for YouTube Shorts, Facebook, Instagram, TikTok…
        </>
      )
    })
  },
  [EditorPanel.Social]: {
    panel: EditorPanel.Social,
    icon: SocialEffectsIcon,
    plan: SublyPlan.Personal,
    planMessage: () => ({
      heading: "Social Effects is a Business feature",
      description: (
        <>
          <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} className="tw-underline">
            Upgrade now
          </Link>{" "}
          to create social effects for your video styling.
        </>
      )
    })
  },
  [EditorPanel.Comments]: {
    panel: EditorPanel.Comments,
    icon: CommentsIcon,
    plan: SublyPlan.Pro,
    planMessage: () => ({
      heading: "Comments is a paid feature",
      description: (
        <>
          <Link to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }} className="tw-underline">
            Upgrade now
          </Link>{" "}
          to start commenting and collaborating with your team.
        </>
      )
    })
  },
  [EditorPanel.Downloads]: {
    panel: EditorPanel.Downloads,
    icon: DownloadsIcon,
    plan: SublyPlan.Pro,
    planMessage: () => ({
      heading: "Downloads is a paid feature",
      description: <></>
    })
  }
};

export const EDITOR_MENUBAR_ITEMS: EditorMenubarItem[] = [
  EDITOR_MENUBAR_ITEM[EditorPanel.Subtitles],
  EDITOR_MENUBAR_ITEM[EditorPanel.Transcription],
  EDITOR_MENUBAR_ITEM[EditorPanel.AudioDescription],
  EDITOR_MENUBAR_ITEM[EditorPanel.Summary],
  EDITOR_MENUBAR_ITEM[EditorPanel.Text],
  EDITOR_MENUBAR_ITEM[EditorPanel.Elements],
  EDITOR_MENUBAR_ITEM[EditorPanel.Snippets],
  EDITOR_MENUBAR_ITEM[EditorPanel.Comments],
  EDITOR_MENUBAR_ITEM[EditorPanel.Downloads]
];

const EditorMenubarContext = React.createContext<null>(null);

interface EditorMenubarProviderProps {
  children: React.ReactNode;
}
export const EditorMenubarProvider: React.FC<EditorMenubarProviderProps> = ({ children }) => {
  const { loaded } = useEditorLoadedState();
  const isAudio = useActiveMediaAudioState();
  const hasArtwork = useActiveMediaHasArtworkState();
  const { setEditorPanel } = useEditorPanelState();

  // Audiogram, force elements tab if no artwork
  React.useEffect(() => {
    if (isAudio && !hasArtwork) {
      setEditorPanel(EditorPanel.Elements);
    }
  }, [loaded]);

  return <EditorMenubarContext.Provider value={null}>{children}</EditorMenubarContext.Provider>;
};
