import React from "react";

import config from "@frontend/config";

import { Loader } from "@shared/primitives/loader";

declare global {
  interface NodeModule {
    hot: {
      addStatusHandler: (handler: (status: string) => void) => void;
      status: () => string;
    };
  }
}

export const DevIndicator: React.FC = () => {
  const [isReloading, setIsReloading] = React.useState(false);

  React.useEffect(() => {
    if (!config.isDevelopment || !module?.hot) {
      return;
    }

    // Listen for the webpackHotUpdate event
    // and update the isReloading state when it's emitted
    module?.hot?.addStatusHandler?.((status) => {
      setIsReloading(status !== "idle");
    });
  }, []);

  if (!config.isDevelopment) {
    return null;
  }

  if (!isReloading) {
    return null;
  }

  return (
    <div className="tw-fixed tw-bottom-4 tw-right-4 tw-z-50 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-aux-600 tw-p-1 tw-text-white tw-shadow-md">
      <Loader />
    </div>
  );
};
