import { combineLatest, Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";

import { AccountSettings, AccountTemplate } from "@shared/interfaces/account";
import {
  AdditionalItem,
  Coupon,
  CurrencyCode,
  Interval,
  PlanProductVersion,
  Schedule,
  SublyPlan,
  SubscriptionPrimaryItem,
  SubscriptionStatus
} from "@shared/interfaces/billing";
import { getDateBySeconds } from "@shared/utils/dates";
import { GuidelineTemplate } from "@shared/utils/guidelines";
import {
  isBusinessOrHigherPlan,
  isBusinessPlan,
  isEnterprisePlan,
  isPersonalPlan,
  isPremiumOrHigherPlan,
  isPremiumPlan,
  isProOrHigherPlan,
  isProPlan,
  isTrial
} from "@shared/utils/plans";
import { formatBytes, getBytes } from "@shared/utils/storage-size";

import { Query } from "@datorama/akita";
import { AudioTemplate } from "@media-editor/types";
import { select } from "@ngneat/elf";

import { editorUiStateRepository, editorUiStore } from "../editor-ui";

import { AccountCredit, AccountState, AccountStore, accountStore } from "./account.store";

export class AccountQuery extends Query<AccountState> {
  get isLoaded(): boolean {
    return this.getValue().loaded;
  }

  get hasBillingDetails(): boolean {
    return Boolean(this.getValue().billing?.details?.address);
  }

  get credit(): AccountCredit {
    return this.getValue().credit;
  }

  get currentPlan(): SublyPlan {
    if (this.isPaused) {
      return SublyPlan.Free;
    }

    if (this.getValue().deal) {
      return SublyPlan.Business;
    }

    // Force Premium plan to Pay as you go users, this way
    // unlocks all premium features to PayG users.
    if (this.getValue().isPayg) {
      return SublyPlan.Premium;
    }

    const primaryItem = this.primaryItem;

    return primaryItem?.plan ?? SublyPlan.Free;
  }

  get primaryItem(): SubscriptionPrimaryItem | undefined {
    const subscription = this.getValue().subscription;

    if (!subscription) {
      return;
    }

    return subscription.items.find((i) => i.isPrimary) as SubscriptionPrimaryItem | undefined;
  }

  get currentStatus(): SubscriptionStatus | undefined {
    return this.getValue().subscription?.status;
  }

  get interval(): Interval | undefined {
    const subscription = this.getValue().subscription;
    if (!subscription) {
      return;
    }

    const primaryItem = subscription.items.find((i) => i.isPrimary) as SubscriptionPrimaryItem | undefined;

    return primaryItem?.interval;
  }

  get isYearly(): boolean {
    return this.interval === "year";
  }

  get isMonthly(): boolean {
    return this.interval === "month";
  }

  get currency(): CurrencyCode {
    return this.getValue().currency;
  }

  get hadBilling(): boolean {
    return this.getValue().hadBilling;
  }

  get hasPaymentOverdue(): boolean {
    const status = this.getValue().subscription?.status;

    if (!status) {
      return false;
    }

    return status === SubscriptionStatus.PastDue || status === SubscriptionStatus.Unpaid;
  }

  get currentTrialEndDate(): Date | undefined {
    return this.getValue().subscription?.trialEndDate;
  }

  get isPro(): boolean {
    return isProPlan(this.currentPlan);
  }

  get isProOrHigher(): boolean {
    return isProOrHigherPlan(this.currentPlan);
  }

  get isPremiumOrHigher(): boolean {
    return isPremiumOrHigherPlan(this.currentPlan);
  }

  get isBusinessOrHigher(): boolean {
    return isBusinessOrHigherPlan(this.currentPlan);
  }

  get isPremium(): boolean {
    return isPremiumPlan(this.currentPlan);
  }

  get isPersonal(): boolean {
    return isPersonalPlan(this.currentPlan);
  }
  get isBusiness(): boolean {
    return isBusinessPlan(this.currentPlan);
  }

  get isEnterprise(): boolean {
    return isEnterprisePlan(this.currentPlan);
  }

  get isTrial(): boolean {
    return isTrial(this.currentStatus);
  }

  get isUpfrontCredit(): boolean {
    return Boolean(this.getValue().billing?.isUpfrontCredit);
  }

  get trialEndDate(): Date | undefined {
    return this.getValue().subscription?.trialEndDate ?? undefined;
  }

  get isPayg(): boolean {
    return this.getValue().isPayg;
  }

  get settings(): AccountSettings {
    const activeMediaSettings = editorUiStateRepository.accountSettings;

    if (activeMediaSettings) {
      return activeMediaSettings;
    }

    return this.getValue().settings;
  }

  get templates(): AccountTemplate[] {
    return this.getValue().settings.templates ?? [];
  }

  get lastVersionTour(): string {
    return this.getValue().settings.lastVersionTour;
  }

  get hasTranslated(): boolean {
    return Boolean(this.getValue().settings?.hasTranslated);
  }

  get storageBytesUsed(): number {
    return this.getValue().storageBytesUsed;
  }

  get customStorageBytes(): number {
    return this.getValue().settings?.customStorageBytes ?? 0;
  }

  get customUploadLimitBytes(): number {
    return this.getValue().settings?.customUploadLimitBytes ?? 0;
  }

  get uploadLimitBytes(): number {
    if (this.customUploadLimitBytes) {
      return this.customUploadLimitBytes;
    }

    return getBytes(this.getValue().uploadLimit, "GB") ?? 0;
  }

  get planStorageBytes(): number {
    return this.getValue().planStorageBytes ?? 0;
  }

  get additionalStorageBytes(): number {
    return this.getValue().additionalStorageBytes ?? 0;
  }

  get totalStorage(): number {
    if (this.customStorageBytes) {
      return this.customStorageBytes;
    }

    if (this.isPayg) {
      return getBytes(20, "GB");
    }

    if (this.getValue().deal) {
      const bytes = getBytes(100, "GB");

      return bytes + this.additionalStorageBytes ?? 0;
    }

    return this.planStorageBytes + this.additionalStorageBytes;
  }

  get availableStorage(): number {
    const { totalStorage = 0, storageBytesUsed = 0 } = this;
    return Math.max(totalStorage - storageBytesUsed, 0);
  }

  get storageUnits(): number {
    const subscription = this.getValue().subscription;

    if (!subscription) {
      return 0;
    }

    const storageItem = subscription.items.find((i) => !i.isPrimary && i?.product === AdditionalItem.Storage);

    return storageItem?.units ?? 0;
  }

  get pausedAt(): Date | undefined {
    const pausedAt = this.getValue().billing?.pausedAt;
    if (!pausedAt) return undefined;

    return getDateBySeconds(pausedAt);
  }

  get pausedUntil(): Date | undefined {
    const pausedUntil = this.getValue().billing?.pausedUntil;
    if (!pausedUntil) return undefined;

    return getDateBySeconds(pausedUntil);
  }

  get isPaused(): boolean {
    const billing = this.getValue().billing;

    if (!billing?.pausedAt || !billing?.pausedUntil) {
      return false;
    }

    const now = Math.round(Date.now() / 1000);

    if (billing.pausedAt > now) {
      return false;
    }

    if (billing.pausedUntil < now) {
      return false;
    }

    return true;
  }

  get discountCoupon(): Coupon | undefined {
    return this.getValue().subscription?.coupon ?? undefined;
  }

  get scheduledPlan(): Schedule | undefined {
    return this.getValue().subscription?.schedule ?? undefined;
  }

  get planVersion(): PlanProductVersion {
    return this.primaryItem?.version || "2024-01-02";
  }

  get guidelineSettings(): GuidelineTemplate | undefined {
    return this.getValue().settings?.guidelineSettings;
  }

  get ignoreWordsSettings(): string[] | undefined {
    return this.getValue().settings?.ignoreWords;
  }

  get audioTemplates(): AudioTemplate[] {
    return this.getValue().settings?.audioTemplates ?? [];
  }

  get recentLanguagesSettings(): string[] | undefined {
    return this.getValue().settings?.recentLanguages;
  }

  get useGlossary(): boolean | undefined {
    return this.getValue().settings?.useGlossary;
  }

  constructor(protected store: AccountStore) {
    super(store);
  }

  selectIsLoaded(): Observable<boolean> {
    return this.select("loaded");
  }

  selectCredit(): Observable<AccountCredit> {
    return this.select("credit");
  }

  selectPrimaryColors(): Observable<string[] | undefined> {
    return this.selectSettings().pipe(switchMap((s) => of(s.primaryColors)));
  }

  selectOutlineColors(): Observable<string[] | undefined> {
    return this.selectSettings().pipe(switchMap((s) => of(s.outlineColors)));
  }

  selectAspectRatioColors(): Observable<string[] | undefined> {
    return this.selectSettings().pipe(switchMap((s) => of(s.aspectRatioColors)));
  }

  selectBorderColors(): Observable<string[] | undefined> {
    return this.selectSettings().pipe(switchMap((s) => of(s.borderColors)));
  }

  selectTemplates(): Observable<AccountTemplate[]> {
    return this.selectSettings().pipe(switchMap((s) => of(s.templates ?? [])));
  }

  selectTrialDownloads(): Observable<number> {
    return this.selectSettings().pipe(switchMap((s) => of(s?.trialDownloads ?? 0)));
  }

  selectHasTranslated(): Observable<boolean> {
    return this.selectSettings().pipe(switchMap((s) => of(s?.hasTranslated)));
  }

  selectTrialEndDate(): Observable<Date | undefined> {
    return this.select((s) => s?.subscription?.trialEndDate ?? undefined);
  }

  selectCurrentPlan(): Observable<SublyPlan> {
    return combineLatest([this.select(["deal", "isPayg"]), this.selectPrimaryItem(), this.selectIsPaused()]).pipe(
      switchMap(([{ deal, isPayg }, primaryItem, isPaused]) => {
        if (isPaused) {
          return of(SublyPlan.Free);
        }
        if (deal) {
          return of(SublyPlan.Business);
        }

        // Force Premium plan to Pay as you go users, this way
        // unlocks all premium features to PayG users.
        if (isPayg) {
          return of(SublyPlan.Premium);
        }

        if (!primaryItem) {
          return of(SublyPlan.Free);
        }

        const name = primaryItem?.plan ?? SublyPlan.Free;

        return of(name);
      })
    );
  }

  selectPrimaryItem(): Observable<SubscriptionPrimaryItem | undefined> {
    return this.select((s) => s.subscription).pipe(
      switchMap((subscription) => {
        if (!subscription) {
          return of(undefined);
        }

        const primaryItem = subscription.items.find((i) => i.isPrimary) as SubscriptionPrimaryItem | undefined;

        return of(primaryItem);
      })
    );
  }

  selectCurrentStatus(): Observable<SubscriptionStatus | undefined> {
    return this.select(["subscription"]).pipe(
      switchMap(({ subscription: s }) => {
        if (s) {
          return of(s.status);
        }

        return of(undefined);
      })
    );
  }

  selectInterval(): Observable<Interval | undefined> {
    return this.selectPrimaryItem().pipe(
      switchMap((primaryItem) => {
        if (!primaryItem) {
          return of(undefined);
        }

        return of(primaryItem?.interval);
      })
    );
  }

  selectIsYearly(): Observable<boolean> {
    return this.selectInterval().pipe(
      switchMap((interval) => {
        return of(interval === "year");
      })
    );
  }

  selectIsMonthly(): Observable<boolean> {
    return this.selectInterval().pipe(
      switchMap((interval) => {
        return of(interval === "month");
      })
    );
  }

  selectCurrency(): Observable<CurrencyCode> {
    return this.select((s) => s.currency);
  }

  selectHasPaymentOverdue(): Observable<boolean> {
    return this.select(["subscription"]).pipe(
      switchMap(({ subscription: s }) => {
        if (s) {
          return of(s.status === SubscriptionStatus.PastDue || s.status === SubscriptionStatus.Unpaid);
        }

        return of(false);
      })
    );
  }

  selectIsPro(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(isProPlan(v));
      })
    );
  }

  selectIsProOrHigher(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(isProOrHigherPlan(v));
      })
    );
  }

  selectIsPremiumOrHigher(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(isPremiumOrHigherPlan(v));
      })
    );
  }

  selectIsBusinessOrHigher(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(isBusinessOrHigherPlan(v));
      })
    );
  }

  selectIsPremium(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(isPremiumPlan(v));
      })
    );
  }

  selectIsPersonal(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(isPersonalPlan(v));
      })
    );
  }

  selectIsBusiness(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(isBusinessPlan(v));
      })
    );
  }

  selectIsEnterprise(): Observable<boolean> {
    return this.selectCurrentPlan().pipe(
      switchMap((v) => {
        return of(isEnterprisePlan(v));
      })
    );
  }

  selectIsTrial(): Observable<boolean> {
    return this.selectCurrentStatus().pipe(
      switchMap((s) => {
        return of(isTrial(s));
      })
    );
  }

  selectIsUpfrontCredit(): Observable<boolean> {
    return this.select((s) => Boolean(s?.billing?.isUpfrontCredit));
  }

  selectIsPayg(): Observable<boolean> {
    return this.select((s) => Boolean(s.isPayg));
  }

  selectStorageBytesUsed(): Observable<number> {
    return this.select("storageBytesUsed").pipe(
      switchMap((storageBytesUsed) => {
        return of(storageBytesUsed || 0);
      })
    );
  }

  selectCustomStorageBytes(): Observable<number | undefined> {
    return this.select((s) => s?.settings?.customStorageBytes);
  }

  selectPlanStorageBytes(): Observable<number> {
    return this.select((s) => s?.planStorageBytes ?? 0);
  }

  selectAdditionalStorageBytes(): Observable<number> {
    return this.select((s) => s?.additionalStorageBytes ?? 0);
  }

  selectTotalStorage(): Observable<number> {
    return combineLatest([
      this.selectCustomStorageBytes(),
      this.selectPlanStorageBytes(),
      this.selectAdditionalStorageBytes(),
      this.select(["deal", "isPayg"])
    ]).pipe(
      switchMap(([customStorageBytes, planStorageBytes, additionalStorageBytes, { isPayg, deal }]) => {
        const add = additionalStorageBytes ?? 0;
        if (customStorageBytes) {
          return of(customStorageBytes + add);
        }

        if (isPayg) {
          const bytes = getBytes(20, "GB");

          return of(bytes + add);
        }

        if (deal) {
          const bytes = getBytes(100, "GB");

          return of(bytes + add);
        }

        return of(planStorageBytes + add);
      })
    );
  }

  selectTotalStorageLabel(): Observable<string> {
    return this.selectTotalStorage().pipe(
      switchMap((bytes) => {
        const { size, units } = formatBytes(bytes);

        return of(`${size} ${units}`);
      })
    );
  }

  selectAvailableStorage(): Observable<number> {
    return combineLatest([this.selectTotalStorage(), this.selectStorageBytesUsed()]).pipe(
      switchMap(([total, used]) => {
        return of(Math.max(total - used, 0));
      })
    );
  }

  selectStorageUnits(): Observable<number> {
    return this.select((s) => s.subscription).pipe(
      switchMap((subscription) => {
        if (!subscription) {
          return of(0);
        }

        const storageItem = subscription.items.find((i) => !i.isPrimary && i?.product === AdditionalItem.Storage);

        const units = storageItem?.units ?? 0;

        return of(units);
      })
    );
  }

  selectPausedAt(): Observable<Date | undefined> {
    return this.select((s) => {
      const pausedAt = s?.billing?.pausedAt;
      if (!pausedAt) return undefined;

      return getDateBySeconds(pausedAt);
    });
  }

  selectPausedUntil(): Observable<Date | undefined> {
    return this.select((s) => {
      const pausedUntil = s?.billing?.pausedUntil;
      if (!pausedUntil) return undefined;

      return getDateBySeconds(pausedUntil);
    });
  }

  selectIsPaused(): Observable<boolean> {
    return this.select((s) => {
      if (!s?.billing?.pausedAt || !s?.billing?.pausedUntil) {
        return false;
      }

      const now = Math.round(Date.now() / 1000);
      if (s.billing.pausedAt > now) {
        return false;
      }

      if (s.billing.pausedUntil < now) {
        return false;
      }

      return true;
    });
  }

  selectPlanVersion(): Observable<PlanProductVersion> {
    return this.selectPrimaryItem().pipe(
      switchMap((primaryItem) => {
        return of(primaryItem?.version || "2024-01-02");
      })
    );
  }

  selectDiscountCoupon(): Observable<Coupon | undefined> {
    return this.select((s) => s?.subscription?.coupon ?? undefined);
  }

  selectScheduledPlan(): Observable<Schedule | undefined> {
    return this.select((s) => s?.subscription?.schedule ?? undefined);
  }

  selectUploadLimitBytes(): Observable<number> {
    return this.select((s) => {
      if (s?.settings.customUploadLimitBytes) {
        return s?.settings.customUploadLimitBytes;
      }
      return getBytes(s?.uploadLimit || 0, "GB");
    });
  }

  selectCustomUploadLimitBytes(): Observable<number> {
    return this.select((s) => s?.settings?.customUploadLimitBytes ?? 0);
  }

  selectSettings(): Observable<AccountSettings> {
    return combineLatest([this.select("settings"), editorUiStore.pipe(select((state) => state?.accountSettings))]).pipe(
      switchMap(([accountSettings, mediaAccountSettings]) => {
        if (mediaAccountSettings) {
          return of(mediaAccountSettings);
        }
        return of(accountSettings);
      })
    );
  }
}

export const accountQuery = new AccountQuery(accountStore);
