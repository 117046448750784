import { Folder } from "@shared/state/dashboard/dashboard.store";

export function buildTree(folders: Folder[], parentId: string | null = null, depth = 0): Folder[] {
  return folders
    .filter((folder) => depth === 0 || folder.parentId === parentId)
    .map((folder) => ({
      ...folder,
      children: buildTree(folders, folder.id, depth + 1)
    }));
}

export function isTeamspaceWithGroup(folder: Folder): boolean {
  return Boolean(folder.isPublic) && Boolean(folder.groupId);
}
