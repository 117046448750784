import React from "react";
import classNames from "classnames";

import { EN } from "@frontend/assets/i18n/en";

import { EnrichedMediaListItem, MediaActiveJob, MediaErrorReason } from "@shared/interfaces/media";
import { Dropdown } from "@shared/primitives/dropdown";

import { JobStatus } from "@getsubly/common";

import { MediaCardBottom } from "./media-card-bottom";
import { MediaCardErrorView, MediaCardProgressView, MediaCardReadyView, MediaCardTop } from "./media-card-top";

export type MediaCardStatus = "error" | "processing" | "ok";

export type MediaCardStatusDefs =
  | { status: "ok"; media: EnrichedMediaListItem }
  | { status: "processing"; media: EnrichedMediaListItem; job: MediaActiveJob }
  | {
      status: "error";
      media: EnrichedMediaListItem;
      errorLabel: string;
      errorMessage: string | JSX.Element;
      showUpgrade?: boolean;
      showDelete?: boolean;
    };

interface MediaCardProps {
  media: EnrichedMediaListItem;
}

export const MediaCard = (props: MediaCardProps) => {
  const defs = React.useMemo<MediaCardStatusDefs>(() => {
    if (props.media.s3_file_is_missing) {
      return {
        status: "error",
        media: props.media,
        errorLabel: "Error",
        errorMessage: EN.media.MediaLost
      };
    }

    if (!props.media.latestJob) {
      return {
        status: "ok",
        media: props.media
      };
    }

    const latestJob = props.media.latestJob;
    if (
      latestJob.status === JobStatus.Uploading ||
      latestJob.status === JobStatus.Uploaded ||
      latestJob.status === JobStatus.Pending ||
      latestJob.status === JobStatus.Converting
    ) {
      return { status: "processing", media: props.media, job: props.media.latestJob! };
    }

    if (latestJob.status === JobStatus.Failed) {
      const isInsufficientFunds = props.media.reason === MediaErrorReason.InsufficientFunds;
      const errorLabel = isInsufficientFunds ? "Insufficient Funds" : "Error";
      const errorMessage = isInsufficientFunds
        ? EN.media[MediaErrorReason.InsufficientFunds]
        : EN.media[
            props.media.reason === MediaErrorReason.UploadCancelled
              ? MediaErrorReason.UploadCancelled
              : MediaErrorReason.ProcessingCancelled
          ];

      return {
        status: "error",
        media: props.media,
        errorLabel,
        errorMessage,
        showUpgrade: isInsufficientFunds,
        showDelete: true
      };
    }

    return { status: "ok", media: props.media };
  }, []);

  const mediaCardClassNames = classNames(
    "tw-group/media-card tw-flex tw-flex-col tw-rounded-10 tw-border tw-border-solid tw-border-neutral-200 tw-bg-white tw-transition-all tw-duration-200 hover:tw-overflow-visible",
    { "tw-cursor-not-allowed": defs.status === "error", "tw-cursor-pointer": defs.status !== "error" }
  );

  return (
    <Dropdown>
      <div className={mediaCardClassNames}>
        <MediaCardTop>
          {defs.status === "ok" && <MediaCardReadyView media={defs.media} />}
          {defs.status === "processing" && <MediaCardProgressView media={defs.media} job={defs.media.latestJob!} />}
          {defs.status === "error" && (
            <MediaCardErrorView
              media={defs.media}
              errorLabel={defs.errorLabel}
              errorMessage={defs.errorMessage}
              showUpgrade={defs.showUpgrade}
              showDelete={defs.showDelete}
            />
          )}
        </MediaCardTop>
        <MediaCardBottom media={defs.media} isError={defs.status === "error"} />
      </div>
    </Dropdown>
  );
};
