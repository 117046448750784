import { MediaComment } from "@shared/interfaces/media";
import { commentsQuery } from "@shared/state/comments";

import { useObservable } from "@mindspace-io/react";

export const useComments = (): MediaComment[] => {
  const [comments] = useObservable<MediaComment[]>(commentsQuery.selectComments(), commentsQuery.comments);

  return comments;
};
