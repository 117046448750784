import React from "react";

import { Checkbox } from "@shared/primitives/checkbox";
import { useActiveMediaState } from "@shared/state/editor/editor.hooks";
import { hasMovFile } from "@shared/utils/media-functions";

import { FileType } from "@getsubly/common";
import { useDownloadMenu, VideoFormat } from "@media-editor/contexts/download-menu.context";

interface VideoFormatOptionsProps {
  disabled?: boolean;
}
export const VideoFormatOptions: React.FC<VideoFormatOptionsProps> = ({ disabled }) => {
  const media = useActiveMediaState();
  const { videoFormats, toggleVideoFormat } = useDownloadMenu();

  const hasFileFormats = hasMovFile(media);

  const options = React.useMemo(() => {
    const isVideo = media?.mainFile?.type === FileType.Video;
    const isMp4 = !hasMovFile(media);

    if (isMp4) {
      return [VideoFormat.Original];
    }

    if (isVideo) {
      return Object.values(VideoFormat);
    }

    return [VideoFormat.MP4];
  }, [media?.mainFile?.extension, videoFormats.length]);

  const hasSelected = (videoFormat: VideoFormat): boolean => {
    return videoFormats.some((v) => v === videoFormat);
  };

  const renderOptions =
    hasFileFormats &&
    options.map((format) => {
      const isSelected = hasSelected(format);

      return (
        <Checkbox
          key={format}
          label={format}
          checked={isSelected}
          onChange={() => toggleVideoFormat(format)}
          disabled={disabled}
        />
      );
    });

  if (!hasFileFormats) {
    return null;
  }

  return <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">{renderOptions}</div>;
};
