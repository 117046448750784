import React from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";

import { resetPassword, ResetPasswordParams } from "@frontend/api/auth.service";
import { EN } from "@frontend/assets/i18n/en";
import { PasswordRules } from "@frontend/components/password-rules/password-rules";
import { LOGIN_PATH } from "@frontend/routes";

import { Logo } from "@shared/components/logo";
import { Alert } from "@shared/primitives/alert";
import { Button } from "@shared/primitives/button";
import { Input } from "@shared/primitives/input";
import { passwordPattern } from "@shared/utils/regex-patterns";

export const ResetPassword: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [alert, setAlert] = React.useState<JSX.Element>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues
  } = useForm<ResetPasswordParams>();
  const { id = "", code = "" } = useParams();

  const onSubmit = async (form: ResetPasswordParams) => {
    form = { ...form, id, code };

    try {
      setLoading(true);

      await resetPassword(form);

      setSuccess(true);
    } catch (e) {
      console.error(e);
      setAlert(EN.error.defaultMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setAlert(undefined);
  };

  if (success) {
    return (
      <div className="tw-w-full tw-max-w-[400px]">
        <Logo color="black" size="lg" className="tw-mx-auto tw-mb-4 tw-p-2" />
        <h2 className="tw-mb-3 tw-text-center tw-text-h2 tw-font-semibold">Password changed</h2>
        <p className="tw-mb-6 tw-text-center tw-text-md tw-text-neutral-700">Your password was successfully changed.</p>
        <Button variant="primary" type="link" to={LOGIN_PATH + "?withPassword=true"} className="tw-w-full">
          Go to Log in
        </Button>
      </div>
    );
  }

  return (
    <div className="tw-w-full tw-max-w-[400px]">
      <Logo color="black" size="lg" className="tw-mx-auto tw-mb-4 tw-p-2" />
      <h2 className="tw-mb-6 tw-text-center tw-text-h2 tw-font-semibold">Reset your password</h2>
      {alert && (
        <Alert danger title="Something went wrong" closable onClose={handleClose}>
          {alert}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-mb-4">
          <Input
            placeholder="Input your password"
            hasError={!!errors.password}
            type="password"
            {...register("password", {
              required: true,
              pattern: passwordPattern
            })}
          />
        </div>
        <div className="tw-mb-4">
          <Input
            placeholder="Confirm your password"
            hasError={!!errors.passwordConfirm}
            type="password"
            {...register("passwordConfirm", {
              required: true,
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues();
                  return password === value || "Passwords should match";
                }
              }
            })}
          />
          {errors.passwordConfirm && (
            <span style={{ color: "var(--danger, red)" }}>{errors.passwordConfirm.message}</span>
          )}
        </div>

        <PasswordRules value={watch("password")} />

        <Button variant="primary" className="tw-mt-4 tw-w-full" type="submit" loading={loading}>
          Reset your password
        </Button>
      </form>

      <p className="tw-mt-16 tw-text-center tw-text-sm tw-font-semibold tw-text-primary-500 hover:tw-text-primary-700">
        <Link to={LOGIN_PATH + "?withPassword=true"}>Back to Log in</Link>
      </p>
    </div>
  );
};
