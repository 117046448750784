import React from "react";

import { AppId, Integration, listApps } from "@frontend/api/apps.service";
import config from "@frontend/config";

import { DropboxIcon, GoogleDriveIcon, OneDriveIcon, ZapierIcon, ZoomIcon } from "@shared/components/icons";
import { App } from "@shared/interfaces/app";
import { SublyPlan } from "@shared/interfaces/billing";

interface AppIntegrationContext {
  apps: App[];
  integrations: Integration[];
  isLoading: boolean;
  fetchIntegrations: () => void;
}
const AppIntegrationContext = React.createContext<AppIntegrationContext | null>(null);

interface AppIntegrationProviderProps {
  children: React.ReactNode;
}

export const AppIntegrationProvider: React.FC<AppIntegrationProviderProps> = ({ children }) => {
  const zoomUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${
    config.zoom.clientId
  }&redirect_uri=${encodeURIComponent(config.zoom.redirectUri)}`;

  const APPS = [
    {
      appId: AppId.Dropbox,
      icon: <DropboxIcon className="tw-h-10 tw-w-10" />,
      name: "Dropbox",
      description: "Install Dropbox on your computer to have a quick access to your files",
      link: "https://www.dropbox.com/downloading?os=win&ref=edshelf",
      modal: {
        title: "Dropbox Integration",
        description: "By installing Dropbox on your computer you will have a quick access to all your files.",
        link: "https://www.dropbox.com/downloading?os=win&ref=edshelf",
        actionText: "Download Dropbox",
        openNewTab: true
      },
      hidden: !config.features.zapier
    },
    {
      appId: AppId.GoogleDrive,
      icon: <GoogleDriveIcon className="tw-h-10 tw-w-10" />,
      name: "Google Drive",
      description: "Install Google Drive on your computer to have a quick access to your files",
      link: "https://www.google.com/intl/en-GB/drive/download/",
      modal: {
        title: "Google Drive Integration",
        description: "By installing Google Drive on your computer you will have a quick access to all your files.",
        link: "https://www.google.com/intl/en-GB/drive/download/",
        actionText: "Download Google Drive",
        openNewTab: true
      },
      hidden: !config.features.zapier
    },
    {
      appId: AppId.OneDrive,
      icon: <OneDriveIcon className="tw-h-10 tw-w-10" />,
      name: "OneDrive",
      description: "Link your OneDrive account and seamlessly be able to transcribe all your video files.",
      link: "https://www.microsoft.com/en-gb/microsoft-365/onedrive/apps-that-work-with-onedrive",
      modal: {
        title: "OneDrive Integration",
        description: "Link your OneDrive account and seamlessly be able to transcribe all your video files.",
        link: "https://www.microsoft.com/en-gb/microsoft-365/onedrive/apps-that-work-with-onedrive",
        actionText: "Connect",
        openNewTab: true
      },
      hidden: !config.features.zapier
    },
    {
      appId: AppId.Zapier,
      icon: <ZapierIcon className="tw-h-10 tw-w-10" />,
      name: "Zapier",
      description: "Automate your Subly workflow with a Zapier integrations",
      link: "/settings/apps/zapier",
      modal: {
        title: "Zapier Integration",
        description:
          "Add Subly into your automated workflow. Hook up a new or existing Zapier workflow with Subly to upload your content into Subly.",
        link: "/settings/apps/zapier",
        permission: SublyPlan.Premium
      },
      hidden: !config.features.zapier
    },
    {
      appId: AppId.Zoom,
      icon: <ZoomIcon className="tw-h-10 tw-w-10" />,
      name: "Zoom",
      description: "Save time by importing videos from Zoom directly into Subly",
      link: "/settings/apps/zoom",
      showOnSidebar: true,
      modal: {
        title: "Connect With Zoom",
        description:
          "Easily access and transcribe your Zoom cloud meetings from the past 30 days. Select which meetings you’d like to import and ensure you capture every important detail.",
        link: zoomUrl,
        actionText: "Connect",
        openNewTab: true,
        permission: SublyPlan.Premium
      }
    }
  ];

  const [integrations, setIntegrations] = React.useState<Integration[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchIntegrations = async () => {
    setIsLoading(true);
    const fetchResp = await listApps();

    if (fetchResp) {
      setIntegrations(fetchResp);
    }
    setIsLoading(false);
  };

  return (
    <AppIntegrationContext.Provider
      value={{
        apps: APPS,
        integrations,
        isLoading,
        fetchIntegrations
      }}
    >
      {children}
    </AppIntegrationContext.Provider>
  );
};

export const useAppIntegration = (): AppIntegrationContext => {
  const context = React.useContext(AppIntegrationContext);

  if (context === null) {
    throw new Error("useAppIntegration must be used within a AppIntegrationProvider");
  }

  return context;
};
