import React from "react";

import { LetterCase } from "@frontend/api/media.service";

import { ToolTip } from "@shared/primitives/tooltip";

import { ToolbarButton } from "@media-editor/components/bespoke-buttons/toolbar-button";

interface TextCaseInputProp {
  value?: null | LetterCase;
  onChange: (value: null | LetterCase) => void;
}
export const TextCaseInput: React.FC<TextCaseInputProp> = ({ value, onChange }) => {
  return (
    <div className="tw-inline-flex tw-w-fit tw-gap-1 tw-rounded tw-border tw-border-neutral-200 tw-p-1">
      <ToolTip text="Apply none">
        <ToolbarButton active={!value} onClick={() => onChange(null)}>
          -
        </ToolbarButton>
      </ToolTip>
      <ToolTip text="Uppercase">
        <ToolbarButton active={value === LetterCase.uppercase} onClick={() => onChange?.(LetterCase.uppercase)}>
          AG
        </ToolbarButton>
      </ToolTip>
      <ToolTip text="Lowercase">
        <ToolbarButton active={value === LetterCase.lowercase} onClick={() => onChange?.(LetterCase.lowercase)}>
          aa
        </ToolbarButton>
      </ToolTip>
      <ToolTip text="Sentence case">
        <ToolbarButton active={value === LetterCase.capitalise} onClick={() => onChange?.(LetterCase.capitalise)}>
          Ag
        </ToolbarButton>
      </ToolTip>
    </div>
  );
};
