import React from "react";
import classNames from "classnames";

import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "@shared/primitives/dropdown";
import { useActiveMediaConfigState } from "@shared/state/editor/editor.hooks";
import { ratioHelper, ratioLabel } from "@shared/utils/aspect-ratio";

import { AspectRatio } from "@getsubly/common";
import { useDownloadMenu } from "@media-editor/contexts/download-menu.context";
import { RiCheckLine } from "@remixicon/react";

interface DownloadRatiosDropdownProps {
  disabled?: boolean;
}
export const DownloadRatiosDropdown: React.FC<DownloadRatiosDropdownProps> = ({ disabled }) => {
  const mediaConfig = useActiveMediaConfigState();
  const { ratios, toggleRatio } = useDownloadMenu();

  const renderOptions = Object.values(AspectRatio).map((ratio) => {
    const isOriginal = ratio === mediaConfig?.originalRatio;
    const label = ratioLabel(ratio, isOriginal);
    const helper = ratioHelper(ratio);
    const isSelected = ratios.includes(ratio);

    // Hide original item if it has a defined AspectRatio
    if (!isOriginal && ratio === AspectRatio.Original) {
      return null;
    }

    return (
      <DropdownItem
        key={ratio}
        className={classNames("!tw-p-0", { ["tw-bg-primary-50"]: isSelected })}
        keepOpenOnClick
        onClick={() => toggleRatio(ratio)}
      >
        <div className="tw-flex tw-w-full tw-justify-between tw-px-4 tw-py-3">
          <div className="tw-flex tw-w-full tw-flex-col">
            {label}
            {helper && <span className="tw-text-xs tw-text-neutral-500">{helper}</span>}
          </div>
          {isSelected && <RiCheckLine className="tw-ml-2 tw-text-primary-500" />}
        </div>
      </DropdownItem>
    );
  });

  const getLabel = (): string => {
    if (ratios.length === 0) {
      return "Select aspect ratio(s)";
    } else if (ratios.length === 1) {
      return ratioLabel(ratios[0]);
    }

    return `${ratios.length} aspect ratios selected`;
  };

  return (
    <Dropdown>
      <DropdownButton className="tw-w-full" variant="secondary" disabled={disabled}>
        {getLabel()}
      </DropdownButton>
      <DropdownMenu className="tw-w-full tw-min-w-[407px] tw-overflow-y-auto">
        <div className="tw-max-h-[264px] tw-overflow-y-auto">{renderOptions}</div>
      </DropdownMenu>
    </Dropdown>
  );
};
