import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { AudioIcon, CloseIcon, TextIcon } from "@shared/components/icons";
import { Loader } from "@shared/primitives/loader";
import { ISelectOption, Select } from "@shared/primitives/select";
import { UploadErrorType, UploadFile, UploadFileType } from "@shared/state/upload";
import { getExtensionFromMimeType } from "@shared/utils/mime-types";
import { parseCreditToText } from "@shared/utils/plans";
import { formatBytes } from "@shared/utils/storage-size";

import { Language } from "@getsubly/common";
import { RiFilmLine } from "@remixicon/react";

interface FilesListProps {
  files: UploadFileType[];
  className?: string;
  isCheckingFile?: boolean;
  removeFile?: (fileId: string) => void;
  showLanguagePicker?: boolean;
  defaultLanguageCode?: string;
  languagePickerVariant?: "flat" | "outline";
  languages?: Language[];
  onChangeLanguage?: (id: string, languageCode: string) => void;
  onClickPurchaseStorage?: () => void;
}
export const FilesList: React.FC<FilesListProps> = ({
  files,
  className,
  isCheckingFile,
  removeFile,
  showLanguagePicker,
  defaultLanguageCode,
  languagePickerVariant,
  languages = [],
  onChangeLanguage: onChangeLanguage,
  onClickPurchaseStorage
}) => {
  if (!files) {
    return <p className={classNames("tw-text-sm", className)}>No files selected</p>;
  }

  return (
    <div className={classNames("tw-flex tw-flex-col tw-rounded-lg tw-border tw-border-neutral-200", className)}>
      {files.map((file) => (
        <FileItem
          key={file.uploadId}
          file={file}
          remove={Boolean(removeFile) ? () => removeFile?.(file.uploadId) : undefined}
          showLanguagePicker={showLanguagePicker}
          defaultLanguageCode={defaultLanguageCode}
          languagePickerVariant={languagePickerVariant}
          languages={languages}
          onChangeLanguage={onChangeLanguage}
          onClickPurchaseStorage={onClickPurchaseStorage}
        />
      ))}
      {isCheckingFile && (
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <Loader />
          <p className="tw-text-sm">Checking file...</p>
        </div>
      )}
    </div>
  );
};

interface FilesItemProps {
  file: UploadFileType;
  showLanguagePicker?: boolean;
  languagePickerVariant?: "flat" | "outline";
  defaultLanguageCode?: string;
  languages: Language[];
  onChangeLanguage?: (id: string, languageCode: string) => void;
  onClickPurchaseStorage?: () => void;
  remove?: () => void;
}
const FileItem: React.FC<FilesItemProps> = ({
  file,
  showLanguagePicker,
  languagePickerVariant,
  defaultLanguageCode,
  languages,
  onChangeLanguage: onChangeLanguage,
  onClickPurchaseStorage,
  remove
}) => {
  const type = (file as UploadFile)?.file?.type;
  const duration = file.mediaInfo?.duration ?? 0;
  const size = file.mediaInfo?.fileSize ?? 0;
  const warning = file.warning || file.mediaInfo?.warning;
  const error = file.error;

  const fileDuration = parseCreditToText(duration, false, false, true);
  const fileSize = formatBytes(size);
  const fileSizeText = `${fileSize.size} ${fileSize.units}`;
  const languageDropdownVisible = (!error || error === UploadErrorType.Language) && showLanguagePicker;

  const extension = getExtensionFromMimeType(type) ?? "mp4";

  return (
    <div className="tw-flex tw-min-h-10 tw-items-center tw-gap-2 tw-border-b tw-border-neutral-100 tw-p-2.5 last:tw-border-b-0">
      <FileIcon extension={extension} status={error ? "error" : warning ? "warning" : null} />

      <div className="tw-inline-flex tw-flex-1 tw-flex-col tw-justify-center tw-overflow-hidden tw-text-ellipsis">
        <p className="tw-overflow-hidden tw-text-ellipsis tw-text-sm tw-text-neutral-600">{file.mediaName}</p>
        {Boolean(error || warning) && (
          <FileErrorMessage
            type={error ? "error" : "warning"}
            message={error || warning || ""}
            onClickPurchaseStorage={onClickPurchaseStorage}
          />
        )}
      </div>

      <div className="tw-inline-flex tw-items-center tw-gap-2">
        <label className="tw-mb-0 tw-ml-auto tw-flex tw-gap-2 tw-text-neutral-600">
          <span className="tw-text-sm tw-text-neutral-600">{size > 0 && fileSizeText}</span>
          <span className="tw-text-sm">{duration > 0 && fileDuration}</span>
        </label>

        {languageDropdownVisible && (
          <LanguagePicker
            file={file}
            defaultLanguageCode={defaultLanguageCode}
            languages={languages}
            onChangeLanguage={onChangeLanguage}
            variant={languagePickerVariant}
          />
        )}

        {Boolean(remove) && <CloseIcon className="close tw-h-5 tw-w-5 tw-cursor-pointer" onClick={remove} />}
      </div>
    </div>
  );
};

interface FileIconProps {
  extension: string;
  status?: "error" | "warning" | null;
}
const FileIcon: React.FC<FileIconProps> = ({ extension, status }) => {
  const statusStyles = {
    error: "tw-text-destructive-600",
    warning: "tw-text-warning-500",
    default: "tw-text-neutral-500"
  }[status || "default"];

  switch (extension) {
    case "mp4":
      return <RiFilmLine className={classNames("tw-h-5 tw-w-5 tw-min-w-0", statusStyles)} />;
    case "mov":
      return <RiFilmLine className={classNames("tw-h-5 tw-w-5 tw-min-w-0", statusStyles)} />;
    case "mp3":
      return (
        <div className="tw-inline-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center">
          <AudioIcon className={classNames("tw-w-[18px] tw-min-w-0", statusStyles)} />
        </div>
      );
    case "srt":
      return <TextIcon className={classNames("tw-h-5 tw-w-5 tw-min-w-0", statusStyles)} />;

    default:
      return null;
  }
};

interface LanguagePickerProps {
  variant?: "flat" | "outline";
  file: UploadFileType;
  defaultLanguageCode?: string;
  languages: Language[];
  onChangeLanguage?: (id: string, languageCode: string) => void;
}
const LanguagePicker: React.FC<LanguagePickerProps> = ({
  variant = "flat",
  file,
  defaultLanguageCode,
  languages,
  onChangeLanguage
}) => {
  const formatLabel = (option: ISelectOption) => (
    <>
      <span className={`fi fi-${option.extra.flagCode} tw-shrink-0`} />
      {option.label}
    </>
  );

  return (
    <Select
      variant={variant}
      placeholder="Select language"
      options={languages.map((language) => ({
        label: language.language,
        value: language.languageCode,
        extra: { flagCode: language.flagCode }
      }))}
      onChange={(languageCode = "") => onChangeLanguage?.(file.uploadId, languageCode)}
      value={file.languageCode ?? defaultLanguageCode}
      formatOptionLabel={(option) => formatLabel(option)}
      formatInputSelectedLabel={(option) => formatLabel(option)}
      size="28"
    />
  );
};

interface FileErrorMessageProps {
  type: "warning" | "error";
  message: string;
  onClickPurchaseStorage?: () => void;
}
const FileErrorMessage: React.FC<FileErrorMessageProps> = ({ type, message, onClickPurchaseStorage }) => {
  const navigate = useNavigate();

  return (
    <label
      className={classNames("tw-mb-0 tw-text-sm", {
        "tw-text-destructive-600": type === "error",
        "tw-text-warning-600": type === "warning"
      })}
    >
      {message}
      {(message === UploadErrorType.FileSizeMax512MB ||
        message === UploadErrorType.FileSizeMax1GB ||
        message === UploadErrorType.FileSizeMax2GB) && (
        <>
          {" "}
          <a
            href="#"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              navigate(SETTINGS_BILLING_PATH);
              onClickPurchaseStorage?.();
            }}
            className="tw-text-primary-500 hover:tw-text-primary-500 hover:tw-underline"
          >
            Purchase
          </a>{" "}
          to increase the file upload limit.
        </>
      )}
    </label>
  );
};
