import React, { useEffect } from "react";

import { AppId } from "@frontend/api/apps.service";
import { AppList } from "@frontend/components/settings/apps/app-list";
import config from "@frontend/config";
import { useAppIntegration } from "@frontend/contexts/app-integration.context";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";

import { DropboxIcon, GoogleDriveIcon, MicrosoftIcon, ZapierIcon, ZoomIcon } from "@shared/components/icons";
import { App } from "@shared/interfaces/app";
import { SublyPlan } from "@shared/interfaces/billing";

const zoomUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${
  config.zoom.clientId
}&redirect_uri=${encodeURIComponent(config.zoom.redirectUri)}`;

export const APP_LIST: App[] = [
  {
    appId: AppId.Zoom,
    icon: <ZoomIcon className="tw-h-10 tw-w-10" />,
    name: "Zoom",
    description: "Save time by importing videos from Zoom directly into Subly",
    link: "/settings/apps/zoom",
    showOnSidebar: true,
    modal: {
      title: "Connect With Zoom",
      description:
        "Easily access and transcribe your Zoom cloud meetings from the past 30 days. Select which meetings you’d like to import and ensure you capture every important detail.",
      link: zoomUrl,
      actionText: "Connect",
      openNewTab: true,
      permission: SublyPlan.Premium
    }
  },
  {
    appId: AppId.Dropbox,
    icon: <DropboxIcon className="tw-h-10 tw-w-10" />,
    name: "Dropbox",
    description: "Install Dropbox on your computer to have a quick access to your files",
    link: "https://www.dropbox.com/downloading?os=win&ref=edshelf",
    modal: {
      title: "Dropbox Integration",
      description: "By installing Dropbox on your computer you will have a quick access to all your files.",
      link: "https://www.dropbox.com/downloading?os=win&ref=edshelf",
      actionText: "Download Dropbox",
      openNewTab: true
    },
    hidden: !config.features.zapier
  },
  {
    appId: AppId.GoogleDrive,
    icon: <GoogleDriveIcon className="tw-h-10 tw-w-10" />,
    name: "Google Drive",
    description: "Install Google Drive on your computer to have a quick access to your files",
    link: "https://www.google.com/intl/en-GB/drive/download/",
    modal: {
      title: "Google Drive Integration",
      description: "By installing Google Drive on your computer you will have a quick access to all your files.",
      link: "https://www.google.com/intl/en-GB/drive/download/",
      actionText: "Download Google Drive",
      openNewTab: true
    },
    hidden: !config.features.zapier
  },
  {
    appId: AppId.OneDrive,
    // icon: <OneDriveIcon className="tw-h-10 tw-w-10" />,
    icon: <MicrosoftIcon className="tw-h-10 tw-w-10" />,
    name: "Microsoft",
    description:
      "Install SublyClick in your Microsoft environment to access its capabilities across all your files stored in Microsoft",
    link: "https://www.getsubly.com/integrations/microsoft",
    modal: {
      title: "OneDrive Integration",
      description:
        "Install SublyClick in your Microsoft environment to access its capabilities across all your files stored in Microsoft.",
      link: "https://www.getsubly.com/integrations/microsoft",
      actionText: "Download OneDrive",
      openNewTab: true
    },
    hidden: !config.features.zapier
  },
  {
    appId: AppId.Zapier,
    icon: <ZapierIcon className="tw-h-10 tw-w-10" />,
    name: "Zapier",
    description: "Automate your Subly workflow with a Zapier integrations",
    link: "/settings/apps/zapier",
    modal: {
      title: "Zapier Integration",
      description:
        "Add Subly into your automated workflow. Hook up a new or existing Zapier workflow with Subly to upload your content into Subly.",
      link: "/settings/apps/zapier",
      permission: SublyPlan.Premium
    },
    hidden: !config.features.zapier
  }
];

export const AppsPage: React.FC = () => {
  const { integrations, fetchIntegrations, isLoading } = useAppIntegration();

  useEffect(() => {
    fetchIntegrations();
  }, []);

  return (
    <>
      <SettingsPageHeader
        title="Apps"
        description="Manage your apps to have better access to your files"
        className="tw-mb-6"
      />
      <AppList
        apps={APP_LIST}
        integrations={integrations}
        isLoading={isLoading}
        showSuggestionCard
        onReload={() => fetchIntegrations()}
      />
    </>
  );
};
