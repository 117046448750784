import React from "react";
import classNames from "classnames";

import { Dropdown, DropdownButton, DropdownHeader, DropdownItem, DropdownMenu } from "@shared/primitives/dropdown";
import { useActiveMediaConfigState } from "@shared/state/editor/editor.hooks";

import { MediaSnippet } from "@getsubly/common";
import { useDownloadMenu } from "@media-editor/contexts/download-menu.context";
import { RiCheckLine } from "@remixicon/react";

export const SnippetsDropdown: React.FC = () => {
  const { snippets, toggleSnippet } = useDownloadMenu();
  const mediaConfig = useActiveMediaConfigState();

  if (!mediaConfig?.snippets) {
    return null;
  }

  const keepOpenOnClick = mediaConfig.snippets.length > 1;

  const hasSelected = (snippet: MediaSnippet): boolean => {
    return snippets.some((s) => s.id === snippet.id);
  };

  const renderOptions = Object.values(mediaConfig?.snippets).map((snippet) => {
    const isSelected = hasSelected(snippet);

    return (
      <DropdownItem
        key={snippet.id}
        className={classNames("!tw-p-0", { ["tw-bg-primary-50"]: isSelected })}
        keepOpenOnClick={keepOpenOnClick}
        onClick={() => toggleSnippet(snippet)}
      >
        <div className="tw-flex tw-w-full tw-justify-between tw-px-4 tw-py-3">
          {snippet.name}
          {isSelected && <RiCheckLine className="tw-ml-2 tw-text-primary-500" />}
        </div>
      </DropdownItem>
    );
  });

  const getLabel = (): string => {
    if (snippets.length === 0) {
      return "Select snippet(s)";
    } else if (snippets.length === 1) {
      return snippets[0].name;
    }

    return `${snippets.length} snippets selected`;
  };

  return (
    <Dropdown>
      <DropdownButton className="tw-w-full" variant="secondary">
        {getLabel()}
      </DropdownButton>
      <DropdownMenu className="tw-min-w-[407px]" placement="bottom-start">
        {mediaConfig?.snippets.length > 1 && <DropdownHeader>Select one or more snippets</DropdownHeader>}
        <div className="tw-max-h-[200px] tw-overflow-y-auto">{renderOptions}</div>
      </DropdownMenu>
    </Dropdown>
  );
};
