import React from "react";

import { loadFolders } from "@frontend/api/dashboard.service";

import { useAccounts } from "@shared/hooks/use-accounts";
import { useDashboard } from "@shared/hooks/use-dashboard";
import { Folder } from "@shared/state/dashboard/dashboard.store";

import { ResourceAction, ResourceType } from "@getsubly/common";
import { useRolePermissions } from "@getsubly/common/dist/permissions/use-role-permissions";

import { CategoryButton } from "./category-button";
import { FolderTree } from "./folder-tree";

export const SidebarDrives: React.FC = () => {
  const { isAdmin, currentAccount } = useAccounts();
  const { selectedFolderId, favouritesDrive, privateDrive, teamspacesDrive } = useDashboard();

  const { permissions: teamspacePermissions } = useRolePermissions(currentAccount?.role, ResourceType.Teamspace);
  const { permissions: privateFolderPermissions } = useRolePermissions(
    currentAccount?.role,
    ResourceType.PrivateFolder
  );

  const hasFavourites = Boolean(favouritesDrive?.children?.length);
  const canFavouriteTeamspace = teamspacePermissions[ResourceAction.Favourite];
  const canFavouritePrivate = privateFolderPermissions[ResourceAction.Favourite];

  return (
    <ul className="tw-flex tw-flex-col tw-gap-1">
      {hasFavourites && (canFavouritePrivate || canFavouriteTeamspace) && (
        <li>
          <FavouritesSection isAdmin={isAdmin} drive={favouritesDrive} selectedFolderId={selectedFolderId} />
        </li>
      )}

      {teamspacesDrive && (
        <li>
          <PublicSection drive={teamspacesDrive} selectedFolderId={selectedFolderId} />
        </li>
      )}

      {privateDrive && (
        <li>
          <PrivateSection drive={privateDrive} selectedFolderId={selectedFolderId} />
        </li>
      )}
    </ul>
  );
};

type FavouritesSectionProps = {
  isAdmin?: boolean;
  drive?: Folder | null;
  selectedFolderId: string | null;
};

const FavouritesSection: React.FC<FavouritesSectionProps> = ({ drive, selectedFolderId }) => {
  const [showCategory, onToggleCategory] = React.useState(true);

  const handleChange = async () => {
    try {
      await loadFolders();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <CategoryButton description="Folders you have favourited." onClick={() => onToggleCategory(!showCategory)}>
        Favourites
      </CategoryButton>
      {showCategory &&
        drive?.children?.map((folder) => (
          <FolderTree
            key={folder.id}
            folder={folder}
            onChange={handleChange}
            selectedFolderId={selectedFolderId}
            shouldNavigate
            className="tw--mx-2"
          />
        ))}
    </>
  );
};

type PublicProps = {
  drive: Folder;
  selectedFolderId: string | null;
};

const PublicSection: React.FC<PublicProps> = ({ drive, selectedFolderId }) => {
  const [showCategory, onToggleCategory] = React.useState(true);
  const { currentAccount } = useAccounts();
  const { permissions } = useRolePermissions(currentAccount?.role, ResourceType.Teamspace);

  const canCreateFolder = permissions[ResourceAction.Create];
  const canUpdateFolder = permissions[ResourceAction.Update];
  const canDeleteFolder = permissions[ResourceAction.Delete];
  const canFavourite = permissions[ResourceAction.Favourite];
  const canMoveFolder = permissions[ResourceAction.Move];

  const shouldShowOptions = canCreateFolder || canUpdateFolder || canDeleteFolder || canFavourite || canMoveFolder;

  const handleChange = async () => {
    try {
      await loadFolders();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <CategoryButton
        description="Folders and files shared within your team."
        onClick={() => onToggleCategory(!showCategory)}
      >
        Team folders
      </CategoryButton>
      {showCategory && (
        <FolderTree
          folder={drive}
          onChange={handleChange}
          selectedFolderId={selectedFolderId}
          showOptions={shouldShowOptions}
          showCreateFolder={canCreateFolder}
          shouldNavigate
          className="tw--mx-2"
        />
      )}
    </>
  );
};

type PrivateProps = {
  drive: Folder;
  selectedFolderId: string | null;
};

const PrivateSection: React.FC<PrivateProps> = ({ drive, selectedFolderId }) => {
  const [showCategory, onToggleCategory] = React.useState(true);
  const { currentAccount } = useAccounts();
  const { permissions } = useRolePermissions(currentAccount?.role, ResourceType.PrivateFolder);

  const canCreateFolder = permissions[ResourceAction.Create];
  const canUpdateFolder = permissions[ResourceAction.Update];
  const canDeleteFolder = permissions[ResourceAction.Delete];
  const canFavourite = permissions[ResourceAction.Favourite];
  const canMoveFolder = permissions[ResourceAction.Move];

  const shouldShowOptions = canCreateFolder || canUpdateFolder || canDeleteFolder || canFavourite || canMoveFolder;

  const handleChange = async () => {
    try {
      await loadFolders();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <CategoryButton
        description="Folders and files only visible to you."
        onClick={() => onToggleCategory(!showCategory)}
      >
        Personal folders
      </CategoryButton>
      {showCategory && (
        <FolderTree
          folder={drive}
          onChange={handleChange}
          selectedFolderId={selectedFolderId}
          showOptions={shouldShowOptions}
          showCreateFolder={canCreateFolder}
          shouldNavigate
          className="tw--mx-2"
        />
      )}
    </>
  );
};
