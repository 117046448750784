import React from "react";
import classNames from "classnames";

import { Button } from "@shared/primitives/button";
import { TextButton } from "@shared/primitives/text-button";

import { RiCloseLine } from "@remixicon/react";

type BannerType = "neutral" | "warning" | "information" | "success" | "danger";

interface BannerProps {
  title?: string;
  description?: string;
  type?: BannerType;
  icon?: React.ReactNode;
  primaryButtonText?: string;
  onClickPrimary?: () => void;
  secondaryButtonText?: string;
  onClickSecondary?: () => void;
  hideClose?: boolean;
  onClose?: () => void;
}

export const Banner: React.FC<BannerProps> = ({
  title,
  type = "neutral",
  description,
  icon,
  primaryButtonText = "Primary",
  onClickPrimary,
  secondaryButtonText = "Secondary",
  onClickSecondary,
  hideClose = false,
  onClose
}) => {
  description = React.useMemo(() => {
    return description?.replace(/\n/g, "<br>") ?? "";
  }, [description]);

  return (
    <div
      className={classNames("tw-flex tw-w-full tw-rounded-lg tw-border tw-p-2", {
        "tw-border-neutral-200 tw-bg-white": type === "neutral",
        "tw-border-warning-200 tw-bg-warning-50": type === "warning",
        "tw-border-primary-100 tw-bg-primary-50": type === "information",
        "tw-border-success-200 tw-bg-success-50": type === "success",
        "tw-border-destructive-300 tw-bg-destructive-100": type === "danger"
      })}
    >
      {icon ? icon : <BannerIcon type={type} className="tw-mr-1" />}

      <div className="tw-mr-auto">
        {title && <h3 className="tw-text-sm tw-font-semibold">{title}</h3>}
        {description && <p className="tw-text-xs" dangerouslySetInnerHTML={{ __html: description }} />}
      </div>
      {(onClickPrimary || onClickSecondary) && (
        <div className="tw-flex tw-items-center tw-gap-4">
          {onClickSecondary && (
            <TextButton variant="secondary" size="sm" onClick={onClickSecondary}>
              {secondaryButtonText}
            </TextButton>
          )}
          {onClickPrimary && (
            <Button variant="accent" size="32" onClick={onClickPrimary}>
              {primaryButtonText}
            </Button>
          )}
        </div>
      )}
      {!hideClose && (
        <button className="tw-ml-6" onClick={onClose}>
          <RiCloseLine className="tw-h-5 tw-w-5" />
        </button>
      )}
    </div>
  );
};

interface BannerIconProps {
  type: BannerType;
  className?: string;
}

export const BannerIcon: React.FC<BannerIconProps> = ({ type, className }) => {
  if (type === "neutral" || type === "danger") {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1423 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1423 16.5 9 16.5ZM9 15C10.5913 15 12.1174 14.3679 13.2426 13.2426C14.3679 12.1174 15 10.5913 15 9C15 7.4087 14.3679 5.88258 13.2426 4.75736C12.1174 3.63214 10.5913 3 9 3C7.4087 3 5.88258 3.63214 4.75736 4.75736C3.63214 5.88258 3 7.4087 3 9C3 10.5913 3.63214 12.1174 4.75736 13.2426C5.88258 14.3679 7.4087 15 9 15ZM8.25 11.25H9.75V12.75H8.25V11.25ZM9.75 10.0162V10.5H8.25V9.375C8.25 9.17609 8.32902 8.98532 8.46967 8.84467C8.61032 8.70402 8.80109 8.625 9 8.625C9.21306 8.62499 9.42173 8.56447 9.60174 8.4505C9.78175 8.33652 9.9257 8.17377 10.0168 7.98119C10.108 7.7886 10.1425 7.5741 10.1165 7.36263C10.0905 7.15117 10.005 6.95144 9.86987 6.78668C9.73478 6.62193 9.55568 6.49892 9.35342 6.43198C9.15115 6.36503 8.93403 6.3569 8.72732 6.40853C8.52061 6.46016 8.33281 6.56942 8.18577 6.72361C8.03874 6.8778 7.93851 7.07057 7.89675 7.2795L6.42525 6.98475C6.51647 6.52881 6.72713 6.10528 7.03569 5.75744C7.34425 5.4096 7.73964 5.14994 8.18144 5.00499C8.62325 4.86004 9.09561 4.83501 9.55026 4.93246C10.0049 5.02991 10.4255 5.24634 10.7691 5.55962C11.1127 5.8729 11.3669 6.2718 11.5058 6.71555C11.6447 7.15929 11.6633 7.63196 11.5596 8.08523C11.456 8.5385 11.2338 8.95612 10.9159 9.2954C10.5979 9.63467 10.1956 9.88343 9.75 10.0162Z"
          fill="#374151"
        />
      </svg>
    );
  }
  if (type === "information" || type === "warning") {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1423 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1423 16.5 9 16.5ZM9 15C10.5913 15 12.1174 14.3679 13.2426 13.2426C14.3679 12.1174 15 10.5913 15 9C15 7.4087 14.3679 5.88258 13.2426 4.75736C12.1174 3.63214 10.5913 3 9 3C7.4087 3 5.88258 3.63214 4.75736 4.75736C3.63214 5.88258 3 7.4087 3 9C3 10.5913 3.63214 12.1174 4.75736 13.2426C5.88258 14.3679 7.4087 15 9 15ZM8.25 5.25H9.75V6.75H8.25V5.25ZM8.25 8.25H9.75V12.75H8.25V8.25Z"
          fill="currentColor"
        />
      </svg>
    );
  }
  if (type === "success") {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.83323 2.76398C6.06659 1.93987 7.51664 1.5 9 1.5C10.988 1.50381 12.8934 2.29521 14.2991 3.70091C15.7048 5.10661 16.4962 7.01205 16.5 9C16.5 10.4834 16.0601 11.9334 15.236 13.1668C14.4119 14.4001 13.2406 15.3614 11.8701 15.9291C10.4997 16.4967 8.99168 16.6453 7.53682 16.3559C6.08197 16.0665 4.7456 15.3522 3.6967 14.3033C2.64781 13.2544 1.9335 11.918 1.64411 10.4632C1.35472 9.00832 1.50325 7.50032 2.07091 6.12987C2.63856 4.75943 3.59986 3.58809 4.83323 2.76398ZM12.5713 7.68666C12.8018 7.46672 12.8104 7.10154 12.5905 6.871C12.3706 6.64045 12.0054 6.63186 11.7748 6.8518L7.94024 10.51L6.22551 8.87137C5.99516 8.65123 5.62996 8.65952 5.40983 8.88987C5.18969 9.12023 5.19798 9.48542 5.42833 9.70555L7.54131 11.7248C7.76418 11.9378 8.11509 11.9379 8.33814 11.7251L12.5713 7.68666Z"
          fill="#53B483"
        />
      </svg>
    );
  }
  return null;
};
