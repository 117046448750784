import React from "react";
import classNames from "classnames";
import { format } from "date-fns";

import { cancelSchedule, dontCancelSubscription, getCustomer } from "@frontend/api/billing.service";
import { EN } from "@frontend/assets/i18n/en";
import businessIcon from "@frontend/assets/images/plans/legacy/business.png";
import premiumIcon from "@frontend/assets/images/plans/legacy/premium.png";
import proIcon from "@frontend/assets/images/plans/legacy/pro.png";
import { CancelModal } from "@frontend/components/bespoke-modals/cancel-modal";
import { SubscriptionModal } from "@frontend/components/bespoke-modals/subscription-modal";
import { UnpauseModal } from "@frontend/components/bespoke-modals/unpause-modal";
import { SettingsCard } from "@frontend/components/settings-card/settings-card";
import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { InformationCircleIcon, PayAsYouGoTagName } from "@shared/components/icons";
import { useAccountMembers } from "@shared/hooks/use-account-members";
import { useAccounts } from "@shared/hooks/use-accounts";
import { useAnalyticsWithAuth } from "@shared/hooks/use-analytics-with-auth";
import { useModal } from "@shared/hooks/use-modal";
import { usePlan } from "@shared/hooks/use-plan";
import { useQuery } from "@shared/hooks/use-query";
import {
  AdditionalItem,
  Interval,
  PlanProductVersion,
  SublyPlan,
  SubscriptionAdditionalItem,
  SubscriptionPrimaryItem
} from "@shared/interfaces/billing";
import { ModalType } from "@shared/interfaces/modal-type";
import { Alert } from "@shared/primitives/alert";
import { Badge } from "@shared/primitives/badge";
import { Button } from "@shared/primitives/button";
import { Divider } from "@shared/primitives/divider";
import { NewBadge } from "@shared/primitives/new-badge/new-badge";
import { notificationError } from "@shared/primitives/notification";
import { ProgressBar } from "@shared/primitives/progress-bar";
import { ToolTip } from "@shared/primitives/tooltip";
import { formatPrice } from "@shared/utils/strings";

import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

import styles from "./plan-card.module.scss";

interface LegacyPlanCardProps {
  hideActions?: boolean;
  className?: string;
}

export const LegacyPlanCard: React.FC<LegacyPlanCardProps> = ({ hideActions, className }) => {
  const { user, isAdmin, deal, isLoading } = useAccounts();
  const { plan, isPayg, planVersion, credit, storageBytesUsed, totalStorage } = usePlan();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const [showModal, hideModal] = useModal(ModalType.Subscription);
  const { queryParams } = useQuery<{ checkout?: string }>();
  const eventName = "Setting page / legacy plan card upgrade";

  React.useEffect(() => {
    if (!user) {
      return;
    }

    getCustomer(getAccountId(), { force: false });
  }, [user]);

  React.useEffect(() => {
    if (Boolean(queryParams.checkout) && !isLoading && !deal && planVersion !== "2024-01-02") {
      trackEventWithAuth(eventName, { plan });
      showModal(<SubscriptionModal hideModal={hideModal} />);
    }
  }, [queryParams.checkout, isLoading]);

  if (!isAdmin || isLoading) {
    return null;
  }

  if (deal) {
    return (
      <SettingsCard className={className}>
        <SettingsCard.Header>Your Plan</SettingsCard.Header>
        <SettingsCard.Body>
          <div className="tw-flex tw-flex-col tw-text-md tw-font-semibold">
            <p>Currently on an active deal</p>
          </div>
          <div className="tw-flex tw-flex-col">
            <p className="tw-whitespace-nowrap tw-text-sm">Current Balance:</p>
            <p className="tw-whitespace-nowrap tw-text-sm tw-font-medium">
              {Math.floor((credit?.total || 0) / 60)} mins left
            </p>
          </div>
          {!hideActions && (
            <p className="tw-whitespace-nowrap tw-text-xs">
              Please contact support if you are looking to make changes to your plan
            </p>
          )}
        </SettingsCard.Body>
      </SettingsCard>
    );
  }

  const handleUpgrade = () => {
    trackEventWithAuth(eventName, { plan });
    showModal(<SubscriptionModal hideModal={hideModal} />);
  };

  if (isPayg) {
    return (
      <SettingsCard className={className}>
        <SettingsCard.Header className="tw-flex tw-justify-between">
          Your plan
          {!hideActions && (
            <Button variant="primary" size="32" onClick={handleUpgrade} className="tw--my-0.5">
              Upgrade
            </Button>
          )}
        </SettingsCard.Header>
        <SettingsCard.Body className="!tw-pt-0">
          <div className="tw-flex tw-flex-col tw-gap-2">
            <div className="tw-flex tw-flex-row tw-items-center">
              <div className="tw-flex tw-flex-grow tw-flex-col">
                <LegacyPlanIcons plan={SublyPlan.PAYG} />
              </div>
              <NewBadge variant="warning-soft" label="Legacy" size="28" />
            </div>
            <div className="tw-flex tw-flex-col">
              <p className="tw-whitespace-nowrap tw-text-sm">Current Balance:</p>
              <p className="tw-whitespace-nowrap tw-text-sm tw-font-medium">
                {Math.floor((credit?.total || 0) / 60)} mins left
              </p>
            </div>
            <div className="tw-flex tw-flex-col">
              <p className="tw-whitespace-nowrap tw-text-sm">Current Storage:</p>
              <p className="tw-whitespace-nowrap tw-text-sm tw-font-medium">
                {Math.floor((storageBytesUsed / 1024 / 1024 / 1024) * 100) / 100} /{" "}
                {Math.floor(totalStorage / 1024 / 1024 / 1024)} GB
              </p>
            </div>
            {!hideActions && <LegacyPlanAlert onUpgrade={handleUpgrade} />}
            {!hideActions && <LegacyPlan />}
          </div>
        </SettingsCard.Body>
      </SettingsCard>
    );
  }

  if (planVersion === "2024-01-02") {
    return null;
  }

  return (
    <SettingsCard className={className}>
      <SettingsCard.Header className="tw-flex tw-justify-between">
        Your plan
        {!hideActions && (
          <Button variant="primary" size="32" onClick={handleUpgrade}>
            Upgrade
          </Button>
        )}
      </SettingsCard.Header>
      <SettingsCard.Body className="!tw-pt-0">
        <div className="tw-flex tw-flex-col tw-gap-2 ">
          <div className="tw-flex tw-flex-row tw-items-center">
            <div className="tw-flex tw-flex-grow tw-flex-col">
              <LegacyPlanIcons plan={plan} />
            </div>
            <NewBadge variant="warning-soft" label="Legacy" size="28" />
          </div>
          {!hideActions && <LegacyPlanAlert onUpgrade={handleUpgrade} />}
          {!isPayg && <LegacyPlanUsage />}
          {!hideActions && <LegacyPlan />}
          {!isPayg && !hideActions && <LegacyRenewalInfo />}
          {!isPayg && !hideActions && <LegacyPausedStatus />}
          {!isPayg && !hideActions && <LegacyCancelButton />}
        </div>
      </SettingsCard.Body>
    </SettingsCard>
  );
};

export interface SublyPlanLimits {
  minutes: number;
  storage: number; // GB
  upload: number; // GB
  users: number;
}

const getProductLimits = ({
  version,
  price,
  units,
  interval,
  productId
}: {
  version: PlanProductVersion;
  price: string;
  units: number;
  interval: Interval;
  productId: string;
}): SublyPlanLimits | undefined => {
  const yearlyMultiplier = interval === "year" ? 12 : 1;

  switch (version) {
    case "2024-01-02": // Personal, Business, Enterprise
      switch (productId) {
        case config.stripe.products["2024-01-02"].personal:
          return {
            minutes: 0,
            storage: 1,
            upload: 0.5,
            users: 1
          };
        case config.stripe.products["2024-01-02"].business:
          return {
            minutes: 720,
            storage: 5,
            upload: 5,
            users: 1
          };
        case config.stripe.products["2024-01-02"].enterprise:
          return {
            minutes: 1440,
            storage: 10,
            upload: 5,
            users: 1
          };
        default:
          return {
            minutes: 0,
            storage: 1,
            upload: 0.5,
            users: 1
          };
      }
    case "2023-06-01": // (per user) products - custom business
      switch (price) {
        case config.stripe.prices.business.custom20:
          return {
            minutes: 20,
            storage: 20 * units,
            upload: 5,
            users: units
          };
        case config.stripe.prices.businessYearly.custom240:
          return {
            minutes: 240,
            storage: 20 * units,
            upload: 5,
            users: units
          };
        case config.stripe.prices.business.custom40:
          return {
            minutes: 40,
            storage: 20 * units,
            upload: 5,
            users: units
          };
        case config.stripe.prices.businessYearly.custom480:
          return {
            minutes: 480,
            storage: 20 * units,
            upload: 5,
            users: units
          };
        case config.stripe.prices.business.custom60:
          return {
            minutes: 60,
            storage: 30 * units,
            upload: 5,
            users: units
          };
        case config.stripe.prices.businessYearly.custom720:
          return {
            minutes: 720,
            storage: 30 * units,
            upload: 5,
            users: units
          };
        default:
          return {
            minutes: 40,
            storage: 20,
            upload: 5,
            users: 1
          };
      }
    case "2023-01-09": // (per user) products
      switch (productId) {
        case config.stripe.products.perUser.pro:
          return {
            minutes: 40 * units * yearlyMultiplier,
            storage: 10 * units,
            upload: 1,
            users: units
          };
        case config.stripe.products.perUser.premium:
          return {
            minutes: 90 * units * yearlyMultiplier,
            storage: 20 * units,
            upload: 2,
            users: units
          };
        case config.stripe.products.perUser.business:
          return {
            minutes: 100 * units * yearlyMultiplier,
            storage: 50 * units,
            upload: 5,
            users: units
          };
        default:
          return {
            minutes: 0,
            storage: 1,
            upload: 0.5,
            users: 1
          };
      }
    case "2020-09-21":
      switch (productId) {
        case config.stripe.products["2020-09-21"].pro:
        case config.stripe.products["2020-09-21"].proYearly:
          return {
            minutes: 100 * yearlyMultiplier,
            storage: 10,
            upload: 1,
            users: units
          };
        case config.stripe.products["2020-09-21"].premium:
        case config.stripe.products["2020-09-21"].premiumYearly:
          return {
            minutes: 240 * yearlyMultiplier,
            storage: 50,
            upload: 2,
            users: units
          };
        case config.stripe.products["2020-09-21"].businessYearly:
          return {
            minutes: 1000 * yearlyMultiplier,
            storage: 200,
            upload: 5,
            users: units
          };
      }
  }
};

export const LegacyPlanUsage: React.FC = () => {
  const { totalFilledSeats } = useAccountMembers();
  const { subscription } = useAccounts();
  const { availableStorage, storageBytesUsed, credit } = usePlan();

  const primaryItem = React.useMemo(() => {
    if (!subscription) {
      return;
    }

    return subscription.items.find((i) => i.isPrimary) as SubscriptionPrimaryItem | undefined;
  }, [subscription]);

  const additionalItems = React.useMemo(() => {
    if (!subscription) {
      return [];
    }

    return subscription.items?.filter((i) => !i.isPrimary && i.units > 0) as SubscriptionAdditionalItem[];
  }, [subscription]);

  if (!primaryItem) {
    return;
  }

  const primaryLimit = getProductLimits(primaryItem);

  const minutes = primaryLimit?.minutes || 0;
  const seats = primaryLimit?.users || 0;
  // const storage = primaryLimit?.storage || 0;

  // const additionalMinutes = additionalItems?.reduce((acc, item) => {
  //   if (item.product === AdditionalItem.Minutes) {
  //     return acc + item.units;
  //   }
  //   return acc;
  // }, 0);

  const additionalSeats = additionalItems?.reduce((acc, item) => {
    if (item.product === AdditionalItem.Seats) {
      return acc + item.units;
    }
    return acc;
  }, 0);

  // const additionalStorage = additionalItems?.reduce((acc, item) => {
  //   if (item.product === AdditionalItem.Storage) {
  //     return acc + item.units * 50;
  //   }
  //   return acc;
  // }, 0);

  const bytesToGB = (bytes: number) => {
    const gb = bytes / 1024 / 1024 / 1024;
    return Math.round(gb * 100) / 100;
  };

  return (
    <div className="tw-text-sm tw-font-medium tw-text-neutral-500">
      <div className="tw-font-semibold tw-text-neutral-900">Current Usage</div>
      <div className="tw-flex tw-flex-col ">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <ProgressBar progress={100 - ((credit?.total || 0) / 60 / minutes) * 100} />
          <div className="tw-min-w-[90px] tw-shrink-0 tw-whitespace-nowrap">
            {Math.floor((credit?.total || 0) / 60)} mins left
          </div>
        </div>
      </div>
      <div className="tw-flex tw-flex-col">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <ProgressBar progress={((totalFilledSeats || 1) / (seats + additionalSeats)) * 100} />
          <div className="tw-min-w-[90px] tw-shrink-0 tw-whitespace-nowrap">
            {totalFilledSeats || 1} / {seats + additionalSeats} users
          </div>
        </div>
      </div>
      <div className="tw-flex tw-flex-col">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <ProgressBar progress={(storageBytesUsed / (availableStorage + storageBytesUsed)) * 100} />
          <div className="tw-min-w-[90px] tw-shrink-0 tw-whitespace-nowrap">
            {bytesToGB(storageBytesUsed)} / {bytesToGB(availableStorage + storageBytesUsed)} GB
          </div>
        </div>
      </div>
    </div>
  );
};

const getAdditionalItemText = (item: { product: AdditionalItem; units: number; name?: string }) => {
  switch (item.product) {
    case AdditionalItem.Minutes:
      return `${item.units} additional minutes`;
    case AdditionalItem.Storage:
      return `${item.units} additional storage (${item.units * 50} GB)`;
    case AdditionalItem.Seats:
      return `${item.units} additional users`;
    default:
      return `${item.name} (${item.units})`;
  }
};

interface LegacyPlanAlertProps {
  hasClose?: boolean;
  className?: string;
  onUpgrade?: () => void;
}
export const LegacyPlanAlert: React.FC<LegacyPlanAlertProps> = ({ hasClose = false, className, onUpgrade }) => {
  const { isPayg, isTrial } = usePlan();
  const [hidden, setHidden] = React.useState(false);

  if (isPayg || isTrial || hidden) {
    return null;
  }

  return (
    <Alert warning closable={hasClose} className={className} onClose={() => setHidden(true)}>
      <span className="tw-text-sm">
        You are currently subscribed to a legacy plan. In order to adjust your plan, please{" "}
        <span className="tw-cursor-pointer tw-underline" onClick={onUpgrade}>
          upgrade
        </span>{" "}
        to one of our new plans or contact support.
      </span>
    </Alert>
  );
};

export const LegacyPlan: React.FC = () => {
  const { subscription } = useAccounts();
  const { primaryItem } = usePlan();

  if (!primaryItem) {
    return;
  }
  const primaryLimit = getProductLimits(primaryItem);

  const additionalItems = React.useMemo(() => {
    if (!subscription) {
      return [];
    }

    return subscription.items?.filter((i) => !i.isPrimary && i.units > 0) as SubscriptionAdditionalItem[];
  }, [subscription]);

  return (
    <div className="tw-flex tw-flex-col tw-gap-3">
      <div>
        <span className="tw-rounded-3xl tw-bg-neutral-900 tw-px-[10px] tw-py-[2px] tw-font-normal tw-text-white">
          Current
        </span>
      </div>
      <div className="tw-flex tw-flex-col">
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <div className="tw-whitespace-nowrap tw-text-sm tw-font-semibold">{primaryItem.name}</div>
          {primaryLimit && (
            <div className="tw-whitespace-nowrap tw-text-sm tw-font-semibold">
              (includes {primaryLimit.minutes} mins, {primaryLimit.storage} GB & {primaryLimit.users} users)
            </div>
          )}
        </div>
        {additionalItems?.map((item, i) => {
          return (
            <div key={i} className="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <div className="tw-whitespace-nowrap tw-pl-4 tw-text-sm tw-font-semibold">
                + {getAdditionalItemText(item)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const LegacyRenewalInfo: React.FC = () => {
  const { subscription, nextInvoiceDatePhase, upcomingInvoice, currency } = useAccounts();

  const nextPhase = React.useMemo(() => {
    if (!subscription || !subscription.schedule || !subscription.schedule.next[0]) {
      return;
    }

    const primaryItem = subscription.schedule.next[0].items.find((i) =>
      Object.values(SublyPlan).includes(i.product as SublyPlan)
    );

    const additionalItems = subscription.schedule.next[0].items.filter(
      (i) => !Object.values(SublyPlan).includes(i.product as SublyPlan) && i.units && i.units > 0
    );

    return {
      primaryItem,
      additionalItems
    };
  }, [subscription]);

  if (!subscription) {
    return null;
  }

  const invoiceContent = upcomingInvoice && (
    <div className="tw-flex tw-flex-row tw-items-center tw-justify-center">
      <p className="tw-text-lg tw-font-bold">{formatPrice(upcomingInvoice.total / 100, currency)}</p>
      <ToolTip
        text={
          <>
            Price breakdown
            <br />
            {upcomingInvoice.lines
              .filter((l) => l.quantity > 0)
              .map((l) => l.description)
              .join("\n")}
            {Boolean(upcomingInvoice.tax > 0) && `\n${formatPrice(upcomingInvoice.tax / 100, currency)} - Tax`}
          </>
        }
      >
        <InformationCircleIcon className="tw-ml-2" width="20px" />
      </ToolTip>
    </div>
  );

  if (subscription.cancelAt) {
    const subscriptionEndDate = format(new Date(subscription.cancelAt), "dd MMMM yyyy");

    return (
      <div>
        <Badge className={styles["badge"]}>Cancelling soon</Badge>
        <p className="tw-my-2 tw-text-sm">
          Your subscription will be cancelled at the end of your billing cycle on {subscriptionEndDate}.
        </p>
      </div>
    );
  }

  const upcomingInfo = () => {
    if (!nextPhase?.primaryItem) {
      return null;
    }

    const primaryLimit = getProductLimits({
      version: nextPhase.primaryItem.version,
      price: nextPhase.primaryItem.price,
      units: nextPhase.primaryItem.units || 1,
      productId: nextPhase.primaryItem.productId,
      interval: nextPhase.primaryItem.interval
    });

    return (
      <div className="tw-flex tw-flex-col tw-gap-3">
        <div>
          <span className="tw-rounded-3xl tw-bg-neutral-900 tw-px-[10px] tw-py-[2px] tw-font-normal tw-text-white">
            Next renewal
          </span>
        </div>
        <div className="tw-flex tw-flex-col">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
            <div className="tw-whitespace-nowrap tw-text-sm tw-font-semibold">{nextPhase?.primaryItem.name}</div>
            {primaryLimit && (
              <div className="tw-whitespace-nowrap tw-text-sm tw-font-semibold">
                (includes {primaryLimit.minutes} mins, {primaryLimit.storage} GB & {primaryLimit.users} users)
              </div>
            )}
          </div>
          {nextPhase.additionalItems
            ?.filter((item) => (item?.units || 0) > 0)
            .map((item, i) => {
              return (
                <div key={i} className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                  <div className="tw-whitespace-nowrap tw-pl-4 tw-text-sm tw-font-semibold">
                    +{" "}
                    {getAdditionalItemText({
                      product: item.product as AdditionalItem,
                      units: item.units || 1,
                      name: item.name
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <div>
      {upcomingInfo()}
      <Divider />
      <div className="tw-flex tw-flex-col tw-gap-2 tw-text-sm">
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
          <div className="tw-font-semibold">Next invoice date</div>
          <div>{format(new Date(nextInvoiceDatePhase), "dd MMMM yyyy")}</div>
        </div>
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
          <div className="tw-font-semibold">Next invoice amount</div>
          {invoiceContent}
        </div>
      </div>
    </div>
  );
};

export const LegacyPausedStatus: React.FC = () => {
  const { subscription } = useAccounts();
  const { isPaused, pausedAt, pausedUntil } = usePlan();
  const [showUnpauseModal, hideUnpauseModal] = useModal(ModalType.UnpauseModal);

  if (!subscription) {
    return null;
  }

  if (Boolean(subscription.cancelAt)) {
    return null;
  }

  const pausedUntilText = pausedUntil ? format(new Date(pausedUntil), "MMM dd, yyyy") : "";

  const pausedAtText = pausedAt ? format(new Date(pausedAt), "MMM dd, yyyy") : "";
  const handleUnpause = () => {
    showUnpauseModal(<UnpauseModal subscriptionId={subscription.id} onDismiss={hideUnpauseModal} />);
  };

  if (isPaused) {
    return (
      <>
        <Button onClick={handleUnpause} className={styles.pausedUntil} size="36">
          Plan paused until {pausedUntilText}
        </Button>
        <>
          <h6 className="tw-mb-1 tw-mt-5 tw-text-h6 tw-font-bold">Unpause plan</h6>
          <div className="tw-mb-2 tw-text-sm">
            Want to continue transcribing and translating content? Unpause plan to access your minutes.
          </div>
          <div className="tw-flex tw-items-center tw-justify-end">
            <Button variant="primary" className="tw-px-5" onClick={handleUnpause} size="36">
              Unpause plan now
            </Button>
          </div>
        </>
      </>
    );
  }

  if (pausedAt && !isPaused) {
    return (
      <>
        <Badge className={styles.pausedAt}>Plan will pause on {pausedAtText}</Badge>
        <h6 className="tw-mb-1 tw-mt-5 tw-text-h6 tw-font-bold">Cancel scheduled pause</h6>
        <div className="tw-mb-2 tw-text-sm">
          You are scheduled to pause on <b>{pausedAtText}</b> until <b>{pausedUntilText}</b>. You will not be able to
          transcribe and translate content while your subscription is paused.
        </div>
        <div className="tw-flex tw-items-center tw-justify-end">
          <Button variant="destructive" className="tw-px-5" onClick={handleUnpause} size="36">
            Cancel scheduled pause
          </Button>
        </div>
      </>
    );
  }

  return null;
};

export const LegacyCancelButton: React.FC = () => {
  const [isCanceling, setIsCanceling] = React.useState(false);
  const [showCancelModal, hideCancelModal] = useModal(ModalType.CancelModal);
  const { subscription } = useAccounts();
  const { plan, isTrial } = usePlan();
  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const nextPhase = React.useMemo(() => {
    if (!subscription || !subscription.schedule || !subscription.schedule.next[0]) {
      return;
    }

    const primaryItem = subscription.schedule.next[0].items.find((i) =>
      Object.values(SublyPlan).includes(i.product as SublyPlan)
    );

    const additionalItems = subscription.schedule.next[0].items.filter(
      (i) => !Object.values(SublyPlan).includes(i.product as SublyPlan) && i.units && i.units > 0
    );

    return {
      primaryItem,
      additionalItems
    };
  }, [subscription]);

  if (!subscription) {
    return;
  }

  const handleDontCancelSubscription = async () => {
    if (!subscription?.id) {
      notificationError(EN.error.defaultMessage);
      return;
    }

    setIsCanceling(true);
    try {
      await dontCancelSubscription(subscription.id);
    } catch (e) {
      notificationError(EN.error.defaultMessage);
    } finally {
      setIsCanceling(false);
    }
  };

  const handleCancelUpdateSubscription = async () => {
    if (!subscription?.id) {
      notificationError(EN.error.defaultMessage);
      return;
    }

    setIsCanceling(true);
    try {
      await cancelSchedule(subscription.id);
    } catch (e) {
      notificationError(EN.error.defaultMessage);
    } finally {
      setIsCanceling(false);
    }
  };

  const handleOldCancelPlan = () => {
    trackEventWithAuth("Check out - unsubscribe");
    showCancelModal(
      <CancelModal
        subscriptionId={subscription.id}
        cancelPlan={SublyPlan.Free}
        currentPlan={plan}
        onDismiss={hideCancelModal}
        isTrial={isTrial}
      />
    );
  };

  if (subscription.cancelAt) {
    return (
      <div className="tw-rounded-10 tw-border tw-border-neutral-100 tw-bg-neutral-50 tw-p-3 tw-text-sm">
        Don't want to cancel anymore?{" "}
        <span className="tw-cursor-pointer tw-underline" onClick={handleDontCancelSubscription}>
          {isCanceling ? <Icon path={mdiLoading} spin size="0.8rem" /> : "Click here"}
        </span>
        .
      </div>
    );
  }

  if (nextPhase?.primaryItem) {
    return (
      <div className="tw-rounded-10 tw-border tw-border-neutral-100 tw-bg-neutral-50 tw-p-3 tw-text-sm">
        Don't want to change plans anymore?{" "}
        <span className="tw-cursor-pointer tw-underline" onClick={handleCancelUpdateSubscription}>
          {isCanceling ? <Icon path={mdiLoading} spin size="0.8rem" /> : "Click here"}
        </span>
        .
      </div>
    );
  }

  return (
    <div className="tw-rounded-10 tw-border tw-border-neutral-100 tw-bg-neutral-50 tw-p-3 tw-text-sm">
      Don't want your subscription anymore?{" "}
      <span className="tw-cursor-pointer tw-underline" onClick={handleOldCancelPlan}>
        {isCanceling ? <Icon path={mdiLoading} spin size="0.8rem" /> : "Click here"}
      </span>
      .
    </div>
  );
};

interface LegacyPlanIconsProps {
  className?: string;
  plan: SublyPlan;
}
export const LegacyPlanIcons: React.FC<LegacyPlanIconsProps> = ({ className, plan }) => {
  switch (plan) {
    case SublyPlan.PAYG:
      return (
        <div className="tw-flex tw-flex-col tw-gap-1">
          <h5 className="tw-text-h5 tw-font-semibold">Subly</h5>
          <PayAsYouGoTagName />
        </div>
      );
    case SublyPlan.Pro:
      return <img src={proIcon} alt={plan} className={classNames("tw-w-1/3", className)} />;
    case SublyPlan.Premium:
      return <img src={premiumIcon} alt={plan} className={classNames("tw-w-1/3", className)} />;
    case SublyPlan.Business:
      return <img src={businessIcon} alt={plan} className={classNames("tw-w-1/3", className)} />;
    default:
      return <img src={proIcon} alt={plan} className={classNames("tw-w-1/3", className)} />;
  }
};
