import React from "react";
import classNames from "classnames";
import { PlacesType } from "react-tooltip";

import { loadFolders } from "@frontend/api/dashboard.service";
import { createFolder } from "@frontend/api/folders.service";

import { HeroPlusIcon as PlusIcon } from "@shared/components/icons";
import { Loader } from "@shared/primitives/loader";
import { ToolTip } from "@shared/primitives/tooltip";

interface AddFolderButtonProps {
  folderCategory: string;
  toolTipPlace: PlacesType | undefined;
  className?: string;
}

export const AddFolderButton: React.FC<AddFolderButtonProps> = ({ folderCategory, toolTipPlace, className }) => {
  const [loading, setLoading] = React.useState(false);

  const handleClickAddFolder = async () => {
    try {
      setLoading(true);
      await createFolder({ isPublic: folderCategory !== "Private" });
      await loadFolders();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader className="tw-w-4 tw-p-1" />;
  }

  return (
    <ToolTip text="New folder" place={toolTipPlace}>
      <div
        className={classNames("tw-flex tw-cursor-pointer tw-items-center tw-p-1", className)}
        onClick={(e) => {
          e.stopPropagation();
          handleClickAddFolder();
        }}
      >
        <PlusIcon className="tw-w-4" />
      </div>
    </ToolTip>
  );
};
