import React from "react";
import classNames from "classnames";

import { ToolTip } from "@shared/primitives/tooltip";

interface ContainerProps {
  className?: string;
  children: React.ReactNode;
}
const Container: React.FC<ContainerProps> = ({ className, children }) => {
  return (
    <div
      className={classNames(
        "tw-flex tw-flex-row tw-items-center tw-gap-1 tw-rounded-lg tw-bg-neutral-100 tw-p-1",
        className
      )}
    >
      {children}
    </div>
  );
};
interface OptionProps {
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
  tooltipText?: string;
  tooltipDisabled?: boolean;
  children: React.ReactNode;
}
const Option: React.FC<OptionProps> = ({
  onClick,
  selected,
  disabled = false,
  tooltipText = "",
  tooltipDisabled = true,
  children
}) => {
  return (
    <ToolTip text={tooltipText} tipClassName="tw-flex-1" hide={Boolean(tooltipDisabled || !tooltipText)}>
      <button
        className={classNames(
          "tw-m-0 tw-inline-flex tw-h-10 tw-flex-1 tw-items-center tw-justify-center tw-rounded-md tw-border-black tw-text-center tw-text-sm tw-font-medium",
          {
            "tw-text-neutral-400": !selected,
            "tw-bg-white tw-text-neutral-600 tw-drop-shadow": selected,
            "tw-cursor-not-allowed": disabled,
            "tw-cursor-pointer hover:tw-text-neutral-600": !disabled
          }
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </ToolTip>
  );
};

export const SegmentedControl = Object.assign(Container, { Option });
