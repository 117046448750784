import React from "react";
import classNames from "classnames";
import { best } from "wcag-color";

import { TrashCanIcon } from "@shared/components/icons";

interface ColorElementProps {
  color?: string;
  selected?: boolean;
  trash?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
}
export const ColorElement: React.FC<ColorElementProps> = ({ color, selected, trash, onClick, onDelete }) => {
  const contrastColor = color?.match(/#(([0-9a-fA-F]{2}){3,4}|([0-9a-fA-F]){3,4})/g)
    ? best("#000000", "#FFFFFF", color.slice(0, 7))
    : "#000000";

  return (
    <div
      className={classNames("tw-relative tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full", {
        "tw-ring-2 tw-ring-[#0472E7] tw-ring-offset-2": selected,
        "tw-border tw-border-neutral-200": !selected,
        "tw-cursor-pointer hover:tw-border-2 hover:tw-border-neutral-900": Boolean(onClick || onDelete)
      })}
      style={{
        backgroundColor: color
      }}
      onClick={() => {
        if (trash && onDelete) {
          onDelete();
        } else {
          onClick?.();
        }
      }}
    >
      {!color && (
        <div className="tw-absolute -tw-left-[1px] tw-h-full tw-w-full tw-translate-x-1/2">
          <div className="tw-h-full tw-w-[2px] -tw-rotate-45 tw-bg-destructive-600" />
        </div>
      )}
      {/* {selected && (
        <SelectedCheckIcon
          className="tw-h-[10px] tw-w-[10px]"
          stroke={contrastColor?.slice(0, 7)}
        />
      )} */}
      {trash && <TrashCanIcon className="tw-h-[10px] tw-w-[10px]" stroke={contrastColor?.slice(0, 7)} />}
    </div>
  );
};
