import React from "react";
import classNames from "classnames";

import { InformationCircleIcon } from "@shared/components/icons";
import { ToolTip } from "@shared/primitives/tooltip";

interface NewFieldsetProps {
  title?: string | JSX.Element;
  helper?: string | JSX.Element;
  tooltipText?: string;
  className?: string;
  children: React.ReactNode;
}
export const NewFieldset: React.FC<NewFieldsetProps> = ({ title, helper, tooltipText, children, className }) => {
  return (
    <div className={classNames("tw-flex tw-flex-col tw-gap-2 tw-text-neutral-900", className)}>
      {title && (
        <div
          className={classNames(
            "tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-sm tw-font-medium tw-tracking-wide"
          )}
        >
          {title}
          {tooltipText && (
            <ToolTip text={tooltipText} place="top">
              <InformationCircleIcon className="tw-h-4 tw-w-4 tw-cursor-pointer tw-stroke-neutral-500" />
            </ToolTip>
          )}
        </div>
      )}
      {helper && <p className="caption">{helper}</p>}

      {children}
    </div>
  );
};
