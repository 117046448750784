import React from "react";

import { EditProfileForm } from "@frontend/components/settings/profile-card/edit-profile-form";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";
import { SETTINGS_PROFILE_PATH } from "@frontend/routes";

export const AccountEditPage: React.FC = () => {
  return (
    <>
      <SettingsPageHeader title="Change profile details" backUrl={SETTINGS_PROFILE_PATH} className="tw-mb-6" />
      <EditProfileForm />
    </>
  );
};
