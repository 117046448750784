import React, { useEffect, useState } from "react";
import { Navigate } from "react-router";

import { authorizeZoom } from "@frontend/api/apps.service";
import { SETTINGS_APPS_PATH } from "@frontend/routes";

import { useQuery } from "@shared/hooks/use-query";

import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

interface Params {
  code: string;
  state?: string;
  error_description?: string;
}

export const ZoomProcessPage: React.FC = () => {
  const { queryParams } = useQuery<Params>();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const getAuth = async () => {
      if (!queryParams.code) {
        return;
      }

      // Send code to backend
      await authorizeZoom(queryParams.code);

      // TODO: Handle any error here
      setIsAuthorized(true);
    };

    getAuth();
  }, [queryParams.code]);

  if (isAuthorized) {
    const pathname = `${SETTINGS_APPS_PATH}/zoom`;

    return <Navigate to={{ pathname }} />;
  }

  return (
    <p className="tw-text-center">
      <Icon path={mdiLoading} spin size="1.2rem" className="tw-mr-2" /> Please wait a moment...
    </p>
  );
};
