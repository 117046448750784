import React from "react";
import classNames from "classnames";

import { useModal } from "@shared/hooks/use-modal";
import { usePlanPermissions } from "@shared/hooks/use-plan-permissions";
import { SublyPlan } from "@shared/interfaces/billing";
import { ModalType } from "@shared/interfaces/modal-type";
import { Badge } from "@shared/primitives/badge";

import { PlanUpgradeModal } from "../bespoke-modals/upgrade-modal/plan-upgrade-modal";

import styles from "./premium-badge.module.scss";

interface PremiumBadgeProps {
  className?: string;
  hideLink?: boolean;
  inverted?: boolean;
}
export const PremiumBadge: React.FC<PremiumBadgeProps> = ({ className, hideLink = false, inverted = false }) => {
  const [showModal, , hideAll] = useModal(ModalType.UpgradePlan);
  const { hasPermission } = usePlanPermissions(SublyPlan.Premium);

  if (hasPermission) {
    return null;
  }

  if (hideLink) {
    return (
      <Badge className={className} inverted={inverted}>
        Upgrade
      </Badge>
    );
  }

  const handleClick = () => {
    showModal(<PlanUpgradeModal upgradePlan={SublyPlan.Premium} closeModal={hideAll} />);
  };

  return (
    <Badge
      className={classNames(styles.badgePremium, "tw-cursor-pointer", className)}
      inverted={inverted}
      onClick={() => handleClick()}
    >
      Upgrade
    </Badge>
  );
};
