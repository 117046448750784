import React from "react";

import { ManageMembers } from "@frontend/components/settings/team/members/manage-members";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";

export const UsersPage: React.FC = () => {
  return (
    <>
      <SettingsPageHeader title="Users" description="Manage your users and permissions" className="tw-mb-6" />
      <ManageMembers />
    </>
  );
};
