import React from "react";

import { deleteFolder } from "@frontend/api/folders.service";

import { Button } from "@shared/primitives/button";
import { Modal } from "@shared/primitives/modal";
import { Folder } from "@shared/state/dashboard/dashboard.store";

interface DeleteFolderModalProps {
  folder: Folder;
  closeModal: () => void;
  folderHasFilesCreatedByAnother?: boolean;
  onBeforeDelete?: () => void;
  onCompleteDelete?: () => void;
}

export const DeleteFolderModal: React.FC<DeleteFolderModalProps> = ({
  folder,
  closeModal,
  folderHasFilesCreatedByAnother,
  onBeforeDelete,
  onCompleteDelete
}) => {
  return (
    <Modal onDismiss={closeModal}>
      <DeleteFolderModalContainer
        closeModal={closeModal}
        folder={folder}
        folderHasFilesCreatedByAnother={folderHasFilesCreatedByAnother}
        onBeforeDelete={onBeforeDelete}
        onCompleteDelete={onCompleteDelete}
      />
    </Modal>
  );
};

export const DeleteFolderModalContainer: React.FC<DeleteFolderModalProps> = ({
  closeModal,
  folder,
  folderHasFilesCreatedByAnother,
  onBeforeDelete,
  onCompleteDelete
}) => {
  const [loading, setLoading] = React.useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      onBeforeDelete?.();
      await deleteFolder(folder.id);
      closeModal();
    } catch (e) {
      closeModal();
      setLoading(false);
    } finally {
      onCompleteDelete?.();
    }
  };

  const warningMessage = folderHasFilesCreatedByAnother
    ? "This action will permantly delete all media files in this folder - these include files created by your teammates."
    : "This action will permantly delete all media files in this folder.";

  return (
    <>
      <h5 className="tw-text-h5">Are you sure you want to delete {folder.name}?</h5>

      <p className="warning-message">{warningMessage}</p>
      <div className="tw-flex">
        <Button variant="secondary" onClick={closeModal} className="tw-ml-auto tw-mr-2" size="36">
          Cancel
        </Button>
        <Button variant="destructive" onClick={handleDelete} loading={loading} size="36">
          Delete
        </Button>
      </div>
    </>
  );
};
