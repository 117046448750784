import React from "react";
import classNames from "classnames";
import { ITooltip, PlacesType, Tooltip as ReactTooltip } from "react-tooltip";

import { flip, offset, shift } from "@floating-ui/dom";
import { ShortcutItem, ShortcutItemProps } from "@media-editor/components/editor-shortcuts/shortcuts-modal";

import "react-tooltip/dist/react-tooltip.css";
import styles from "./tooltip.module.scss";

interface ToolTipProps extends ITooltip {
  text: string | JSX.Element;
  hide?: boolean;
  place?: PlacesType;
  className?: string;
  tipClassName?: string;
  offset?: number;
  crossOffset?: number;
  disable?: boolean;
  children?: React.ReactNode;
  anchorId?: string;
  title?: string;
  hotkey?: ShortcutItemProps;
}
export const ToolTip: React.FC<ToolTipProps> = ({
  text,
  hide,
  children,
  place = "bottom",
  className,
  tipClassName,
  disable = false,
  anchorId,
  ...props
}) => {
  const isAnchoredExternally = Boolean(anchorId);
  const [id] = React.useState(anchorId ?? String(Math.random()));

  if (isAnchoredExternally && (hide || disable)) {
    return null;
  } else if (!isAnchoredExternally && hide) {
    return <>{children}</>;
  }

  return (
    <>
      {!isAnchoredExternally && (
        <div data-tooltip-id={id} className={classNames(styles.parent, tipClassName)}>
          {children}
        </div>
      )}

      <ToolTipContent id={id} place={place} text={text} className={className} {...props} />
    </>
  );
};

interface ToolTipContentProps extends ITooltip {
  id: string;
  title?: string;
  text: string | JSX.Element;
  place?: PlacesType;
  className?: string;
  offset?: number;
  crossOffset?: number;
  disable?: boolean;
  anchor?: string;
  clickable?: boolean;
  hotkey?: ShortcutItemProps;
}
export const ToolTipContent: React.FC<ToolTipContentProps> = ({
  id,
  title,
  text,
  place = "bottom",
  className,
  clickable = false,
  hotkey,
  ...props
}) => {
  return (
    <ReactTooltip
      id={id}
      className={classNames(styles.tooltip, className)}
      place={place}
      middlewares={[
        offset({
          mainAxis: props.offset ?? 8,
          crossAxis: props.crossOffset ?? 0
        }),
        flip(),
        shift({ padding: 5 })
      ]}
      clickable={clickable}
      {...props}
    >
      <div className="tw-flex tw-flex-col tw-gap-2">
        {title && <h6 className="tw-text-sm tw-font-medium">{title}</h6>}
        {text}
        {hotkey && <ShortcutItem key={hotkey.title} {...hotkey} />}
      </div>
    </ReactTooltip>
  );
};
