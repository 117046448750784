import { isUploadFile, UploadFile, UploadFileType, UploadingStatus, uploadStore } from "@shared/state/upload";

import { useMediaInfo } from "./use-media-info";

/**
 * This hook handles the process of adding files from the the file system or dropzone to the upload queue. It:
 * 1. Takes each file.
 * 2. Prepares for upload, setting the status to 'Analyzing'.
 * 3. Immediately adds to the queue for UI to display.
 * 4. Asynchronously gathers more info and checks for errors.
 * 5. Updates the file in the queue with the new info and errors, and changes the status to 'Staged' or
 *    'AnalysisError' based on whether errors exist.
 */
export const useFileUploadQueue = () => {
  const { getMediaFileInfo, checkFileErrors, convertFileToUploadFile } = useMediaInfo();

  const prepareFilesForUpload = async (droppedFiles: File[]): Promise<UploadFileType[]> => {
    const preparedFiles: UploadFileType[] = [];
    for await (const droppedFile of droppedFiles) {
      const preparedFile = await convertFileToUploadFile(droppedFile);
      if (preparedFile) {
        preparedFiles.push(preparedFile);
      }
    }
    return preparedFiles;
  };

  const handleAddFilesToUploadQueue = async (droppedFiles: File[] = [], folderId?: string) => {
    const preparedFiles = await prepareFilesForUpload(droppedFiles);

    const { queue } = await uploadStore.addFilesToQueue(preparedFiles, folderId);

    const filesInAnalysis = queue.filter(
      (file) => isUploadFile(file) && file.uploadingStatus === UploadingStatus.Analyzing
    ) as UploadFile[];

    for await (const file of filesInAnalysis) {
      const mediaInfo = await getMediaFileInfo(file.file);

      const error = checkFileErrors({
        duration: mediaInfo.duration,
        size: mediaInfo.fileSize,
        skipDurationCheck: true
      });

      uploadStore.updateFileInQueue(file.uploadId, {
        mediaInfo,
        error,
        uploadingStatus: error ? UploadingStatus.AnalysisError : UploadingStatus.Staged
      });
    }
  };

  return { handleAddFilesToUploadQueue };
};
