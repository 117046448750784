import React from "react";

import { WorkspaceCard } from "@frontend/components/settings/workspace/workspace-card";
import { WorkspaceDiscoveryCard } from "@frontend/components/settings/workspace/workspace-discovery-card";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";

import { useAccounts } from "@shared/hooks/use-accounts";

import { RoleName } from "@getsubly/common";

export const WorkspacePage: React.FC = () => {
  const { currentAccount } = useAccounts();

  return (
    <div className="tw-flex tw-flex-col">
      <SettingsPageHeader title="Workspace" description="Manage your workspace" className="tw-mb-6" />
      <div className="tw-flex tw-max-w-lg tw-flex-col tw-gap-6">
        <WorkspaceCard />
        {currentAccount?.role === RoleName.Admin && <WorkspaceDiscoveryCard />}
      </div>
    </div>
  );
};
