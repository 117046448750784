import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import countries from "typed-countries";

import { SettingsCard } from "@frontend/components/settings-card/settings-card";
import { SETTINGS_BILLING_DETAILS_PATH } from "@frontend/routes";

import { BillingDetails } from "@shared/interfaces/billing";
import { Button } from "@shared/primitives/button";
import { accountQuery } from "@shared/state/account";

interface BillingDetailsCardProps {
  className?: string;
}

export const BillingDetailsCard: React.FC<BillingDetailsCardProps> = ({ className }) => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<BillingDetails>();

  useEffect(() => {
    const account$ = accountQuery.select(["billing", "loading"]).subscribe(({ billing, loading }) => {
      if (!billing) {
        setDetails(undefined);
        setLoading(loading);
        return;
      }

      setLoading(loading || billing.loading);
      setDetails(billing.details);
    });

    return () => account$.unsubscribe();
  }, []);

  if (loading) {
    return (
      <SettingsCard className={className}>
        <SettingsCard.Header>Billing details</SettingsCard.Header>
        <SettingsCard.Body className="!tw-pt-0">
          <Skeleton height={150} />
        </SettingsCard.Body>
      </SettingsCard>
    );
  }

  if (!details || !details.address) {
    return (
      <div className={classNames("tw-flex tw-min-w-[258px] tw-max-w-[540px]", className)}>
        <Link to={SETTINGS_BILLING_DETAILS_PATH}>
          <div className="account-card blank-billing-card card tw-box-border tw-rounded-8 tw-border tw-border-dashed tw-border-neutral-900">
            <div className="tw-mx-[46px] tw-my-[78px] tw-text-center">
              <p className="tw-m-0">
                <b>Add billing details</b> so you can receive tax invoices for your Subly subscription.
              </p>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  const country = countries.find((c) => c.iso === details?.address?.country)?.name || details.address.country;

  return (
    <SettingsCard className={className}>
      <SettingsCard.Header>Billing details</SettingsCard.Header>
      <SettingsCard.Body className="!tw-pt-0">
        <div>{details.name}</div>
        <div>{details.address.line1}</div>
        {details.address.line2 && <div>{details.address.line2}</div>}
        <div>
          {details.address.city} {details.address.state} {details.address.postalCode}
        </div>
        <div>{country}</div>
      </SettingsCard.Body>
      <SettingsCard.Footer>
        <Button variant="secondary" type="link" to={SETTINGS_BILLING_DETAILS_PATH} size="32">
          Update
        </Button>
      </SettingsCard.Footer>
    </SettingsCard>
  );
};
