// We have an editor component specifically for the time as we want to
// be able to track the intermediary "editing" state, and then only callback
// with changes to the time when it is in a "valid" format.

import React from "react";

import { Input, InputSize } from "@shared/primitives/input";
import { format, isValidFormat, parse } from "@shared/utils/time";

import { TimeRifm } from "./time-editor.rifm";

export interface TimeEditorInputProps {
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (time: number) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  value: number;
  isOverHourLong?: boolean;
  disabled?: boolean;
  sizeH?: InputSize;
  line?: boolean;
  label?: string;
  helper?: string;
  className?: string;
  labelClassName?: string;
  hasError?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  after?: React.ReactNode;
}

export const TimeEditorInput: React.FC<TimeEditorInputProps> = ({
  value,
  onChange,
  isOverHourLong = false,
  ...props
}) => {
  const [formattedTime, setFormattedTime] = React.useState<string>(format(value, isOverHourLong));
  const maxLength = isOverHourLong ? 11 : 8;

  // We need a handle to current formatted time value that won't trigger
  // the effect to react to changes in the incoming value, therefore
  // we use a ref. Hooks aren't beautiful always. 😅
  const formattedTimeRef = React.useRef(formattedTime);
  React.useEffect(() => {
    formattedTimeRef.current = formattedTime;
  }, [formattedTime]);

  // Handle incoming changes to the time
  React.useEffect(() => {
    const formattedTimeForLatest = format(value, isOverHourLong);
    if (formattedTimeForLatest !== formattedTimeRef.current) {
      setFormattedTime(formattedTimeForLatest);
    }
  }, [formattedTimeRef, setFormattedTime, value]);

  const onFormattedTimeChange = (formattedTime: string) => {
    if (isValidFormat(formattedTime, isOverHourLong)) {
      onChange(parse(formattedTime, isOverHourLong));
    }
    setFormattedTime(formattedTime);
  };

  return (
    <TimeRifm value={formattedTime} onChange={onFormattedTimeChange} maxLength={maxLength}>
      {({ value, onChange }) => <Input {...props} maxLength={maxLength} value={value} onChange={onChange} />}
    </TimeRifm>
  );
};
