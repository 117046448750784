import axios from "axios";

import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { ICustomVocabularyWord } from "@shared/interfaces/custom-vocabulary-words";
import { accountStore } from "@shared/state/account";

import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

const sortChronologically = (customVocabularyWords: ICustomVocabularyWord[]) => {
  const byDate = (a: ICustomVocabularyWord, b: ICustomVocabularyWord) => {
    return a.createdAt > b.createdAt ? 1 : -1;
  };
  return customVocabularyWords.sort(byDate);
};

interface CustomVocabularyWordsResponse {
  customVocabularyWords: ICustomVocabularyWord[];
}

export const getCustomVocabularyWords = async (): Promise<void> => {
  try {
    accountStore.update({ loading: true });

    const { data } = await axios.get<CustomVocabularyWordsResponse>(`${getAccountId()}/custom-vocabulary`, {
      baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const { customVocabularyWords } = data;

    accountStore.update((s) => ({
      ...s,
      customVocabularyWords: sortChronologically(customVocabularyWords),
      loading: false
    }));
  } catch (error) {
    handleError(error);
  }
};

export const addWordToVocabulary = async (word: string): Promise<void> => {
  try {
    accountStore.update({ loading: true });

    const { data } = await axios.post<CustomVocabularyWordsResponse>(
      `${getAccountId()}/custom-vocabulary/add`,
      { word },
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const { customVocabularyWords } = data;

    accountStore.update((s) => ({
      ...s,
      customVocabularyWords: sortChronologically(customVocabularyWords),
      loading: false
    }));
  } catch (error) {
    handleError(error);
  }
};

export const deleteWordFromVocabulary = async (wordId: string): Promise<void> => {
  try {
    accountStore.update({ loading: true });

    const { data } = await axios.delete<CustomVocabularyWordsResponse>(
      `${getAccountId()}/custom-vocabulary/${wordId}`,
      {
        baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const { customVocabularyWords } = data;

    accountStore.update((s) => ({
      ...s,
      customVocabularyWords: sortChronologically(customVocabularyWords),
      loading: false
    }));
  } catch (error) {
    handleError(error);
  }
};
