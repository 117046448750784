import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { getCustomer } from "@frontend/api/billing.service";
import { getAccountId } from "@frontend/config/settings/settings.service";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { CREATE_OR_JOIN_PATH, LOGIN_PATH } from "@frontend/routes";

import { useQuery } from "@shared/hooks/use-query";
import { authQuery } from "@shared/state/auth/auth.query";

import { Pusher } from "../pusher/pusher";

interface LoggedInProps {
  children?: React.ReactNode;
}
export const LoggedInRoute: React.FC<LoggedInProps> = ({ children }) => {
  const { isAuthenticated, getUser } = useAuthProvider();
  const { queryParams } = useQuery<{ redirect?: string }>();

  const navigate = useNavigate();

  useEffect(() => {
    const accountId = getAccountId();
    if (isAuthenticated === undefined) getUser(); // Load auth info.
    else if (isAuthenticated && !accountId)
      navigate(CREATE_OR_JOIN_PATH); // Redirect to /create-or-join if zero accounts.
    else if (isAuthenticated) getCustomer(accountId, { getSettings: true }); // Make sure it always has the latest customer info.
  }, [isAuthenticated]);

  // only proceed after the auth check has run.
  if (isAuthenticated === undefined) return;

  // redirect to the login page if not authenticated.
  if (!isAuthenticated) {
    if (location.pathname.startsWith(LOGIN_PATH)) return <>{children}</>;
    return (
      <Navigate
        to={LOGIN_PATH + (location.pathname === "/" ? "" : "?redirect=" + location.pathname + location.search)}
      />
    );
  }

  // only proceed if the user exists.
  if (!Boolean(authQuery.userId)) return;

  const isCreateOrJoinWorkspacePage = location.pathname.startsWith(CREATE_OR_JOIN_PATH);

  // redirect to `/create-or-join` if the user has no accounts.
  if (!authQuery.accountId && !isCreateOrJoinWorkspacePage) return <Navigate to={CREATE_OR_JOIN_PATH} />;

  // redirect to url if the url contains a redirect param and is not on the `create-or-join` page.
  if (queryParams.redirect && !isCreateOrJoinWorkspacePage) return <Navigate to={queryParams.redirect} />;

  // show the page
  return (
    <>
      <Pusher accountId={authQuery.accountId} userId={authQuery.userId} />
      <Outlet />
      {children}
    </>
  );
};
