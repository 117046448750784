import React from "react";

import { SUMMARY_SERVICES_LEARN_MORE_URL } from "@frontend/routes";

import { useModal } from "@shared/hooks/use-modal";
import { ModalType } from "@shared/interfaces/modal-type";
import { Button } from "@shared/primitives/button";
import { Dialog, DialogTransition } from "@shared/primitives/dialog";

import { DashboardUploadModal } from "../dashboard/dashboard-upload-modal/dashboard-upload-modal";

interface HumanServicesAnnouncementProps {
  onCancel: () => void;
}
export const SummaryServicesAnnouncement: React.FC<HumanServicesAnnouncementProps> = ({ onCancel }) => {
  const [showModal, hideModal] = useModal(ModalType.Upload);

  const handleClickUpload = () => {
    showModal(<DashboardUploadModal closeModal={() => hideModal()} />);
    onCancel();
  };
  return (
    <Dialog onDismiss={onCancel} size="930" transition={DialogTransition.SlideUp} closeOnClickOutside showCloseButton>
      <div className="tw-flex tw-flex-col-reverse tw-items-center tw-gap-4 md:tw-flex-row md:tw-gap-6">
        <div className="tw-flex-1">
          <img src="/img/summary-service-announcement-image.png" />
        </div>
        <div className="tw-flex-1">
          <h2 className="tw-mb-3 tw-text-[30px] tw-font-semibold tw-leading-[36px] tw-tracking-[-0.5px] tw-text-neutral-900">
            Summarise Meetings, Videos, and Webinars
          </h2>
          <p className="tw-mb-6 tw-text-md tw-tracking-[-0.2px] tw-text-neutral-500">
            You don't need to have people listen to long recordings again. Upload your videos or meeting recordings to
            get automatic summaries or bullet points for all your meetings, calls, videos, and webinars, including those
            on Zoom, Teams, and Meet.
          </p>
          <p className="tw-mb-6 tw-text-md tw-tracking-[-0.2px] tw-text-neutral-500">
            Find it in the 'Summary' tab when you visit the editor.
          </p>
          <div className="tw-flex tw-gap-2">
            <Button variant="ghost" size="32" type="link" to={SUMMARY_SERVICES_LEARN_MORE_URL} onClick={onCancel}>
              Learn more
            </Button>
            <Button variant="primary" size="32" onClick={handleClickUpload}>
              Upload now
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
