import React from "react";

import { TextButton } from "@shared/primitives/text-button";
import { pluralize } from "@shared/utils/strings";

import envelope from "../../../assets/images/envelope.png";

type EmailSentViewProps = {
  email: string;
  onClickResend?: () => Promise<void>;
};

export const EmailSentView: React.FC<EmailSentViewProps> = ({ email, onClickResend }) => {
  const [time, setTime] = React.useState(onClickResend ? 60 : 0);

  // Handle countdown timer
  React.useEffect(() => {
    if (time <= 0) return;
    const timeout = setTimeout(() => setTime(time - 1), 1000);
    return () => clearTimeout(timeout);
  }, [time]);

  // Handle resend email
  const handleResendEmail = () => {
    onClickResend?.();
    setTime(60);
  };

  return (
    <div className="tw-my-auto tw-flex tw-h-screen tw-flex-col tw-items-center tw-justify-center">
      <div className="tw-flex tw-max-w-[400px] tw-flex-col tw-items-center tw-justify-center">
        <img src={envelope} alt="Letter sent" className="tw-h-[98px] tw-w-[98px]" />
        <h3 className="tw-my-3 tw-text-h3 tw-font-semibold">Check email</h3>
        <p className="tw-text-centre tw-mb-0 tw-text-md tw-text-neutral-600">We have sent a link to</p>
        <p className="tw-text-centre tw-my-0 tw-text-md tw-text-neutral-600">{email} </p>
        <p className="tw-text-centre tw-mt-0 tw-text-md tw-text-neutral-600">
          Click on the link in the email to log in.
        </p>
        {onClickResend &&
          (time ? (
            <p className="tw-text-centre tw-mt-6 tw-text-neutral-600">
              You can resend the email in {time} {pluralize(time, "second")}
            </p>
          ) : (
            <TextButton className="tw-mt-6 tw-text-sm !tw-text-primary-500" type="link" onClick={handleResendEmail}>
              Resend email
            </TextButton>
          ))}
      </div>
    </div>
  );
};
