import React from "react";
import { useNavigate } from "react-router-dom";

import { OrderModal } from "@frontend/components/bespoke-modals/order-modal";
import { DashboardHeader } from "@frontend/components/dashboard/dashboard-explorer/dashboard-header";
import { OrdersView } from "@frontend/components/dashboard/dashboard-orders/orders-view";
import { MEDIA_PATH } from "@frontend/routes";

import { useModal } from "@shared/hooks/use-modal";
import { useOrders } from "@shared/hooks/use-orders";
import { useQuery } from "@shared/hooks/use-query";
import { ModalType } from "@shared/interfaces/modal-type";
import { Folder } from "@shared/state/dashboard/dashboard.store";

export const OrdersPage = () => {
  const { orders, canLoadMore, hasLoaded, cancelOrderRequest, loadMore, reset } = useOrders();
  const [showOrderModal, hideOrderModal] = useModal(ModalType.Order);
  const navigate = useNavigate();
  const { queryParams } = useQuery();

  const handleLoadMore = (
    options: Partial<{
      offset: number;
      order: "asc" | "desc";
      sort: "filename" | "status" | "status_date";
      mediaId: string;
    }> = {}
  ) => {
    return loadMore({
      order: queryParams.order,
      sort: queryParams.sort,
      mediaId: queryParams.mediaId,
      q: queryParams.q,
      ...options
    });
  };

  React.useEffect(() => {
    const cancel = handleLoadMore({ offset: 0 });
    return () => {
      cancel?.();
      setTimeout(() => reset(), 1);
    };
  }, []);

  React.useEffect(() => {
    if (hasLoaded) {
      handleLoadMore({ offset: 0 });
    }
  }, [queryParams.order, queryParams.sort, queryParams.mediaId, queryParams.q]);

  const handleSelectOrder = (orderId: string) => {
    const order = orders.find((order) => order.id === orderId);
    if (!order) return;
    return showOrderModal(
      <OrderModal orderId={order.id} onClose={hideOrderModal} showCancelButton showOpenMediaButton />
    );
  };

  const handleCancelOrder = async (orderId: string) => {
    return cancelOrderRequest(orderId);
  };

  const handleClickOpenMedia = (orderId: string) => {
    const order = orders.find((order) => order.id === orderId);
    if (!order) return;
    const mediaLink = `${MEDIA_PATH}/${order.media.id}`;
    navigate(mediaLink);
  };

  return (
    <div className="tw-flex tw-min-h-full tw-shrink-0 tw-flex-col tw-p-8">
      <DashboardHeader crumbs={[{ id: "Orders", name: "Orders" } as Folder]} title="Orders" includeSearch />
      {!hasLoaded && <LoaderView />}
      {hasLoaded && (
        <OrdersView
          ordersList={orders}
          canLoadMore={canLoadMore}
          onLoadMoreOrders={handleLoadMore}
          onSelectOrder={handleSelectOrder}
          onCancelOrder={handleCancelOrder}
          onClickViewMedia={handleClickOpenMedia}
        />
      )}
    </div>
  );
};

const LoaderView = () => {
  return (
    <div className="tw-flex tw-min-h-[240px] tw-cursor-default tw-items-center tw-justify-center tw-space-y-5 tw-rounded-[8px] tw-border tw-border-neutral-200 tw-p-5">
      <div className="tw-h-12 tw-w-12 tw-animate-spin tw-rounded-full tw-border-2 tw-border-solid tw-border-neutral-500 tw-border-t-transparent"></div>
    </div>
  );
};
