import React from "react";
import classNames from "classnames";

import { useIntersection } from "@shared/hooks/use-intersection";
import { Loader } from "@shared/primitives/loader";

import { Order } from "@getsubly/common";

import { OrdersTable } from "./orders-table";

type OrdersViewProps = {
  ordersList: Order[];
  canLoadMore: boolean;
  onLoadMoreOrders: () => void;
  onSelectOrder: (orderId: string) => void;
  onCancelOrder: (orderId: string) => Promise<unknown>;
  onClickViewMedia: (orderId: string) => void;
};

export const OrdersView: React.FC<OrdersViewProps> = ({
  ordersList,
  canLoadMore,
  onLoadMoreOrders,
  onSelectOrder,
  onClickViewMedia,
  onCancelOrder
}) => {
  const [loadMoreRef, setLoadMoreRef] = React.useState<HTMLDivElement | null>(null);

  useIntersection(loadMoreRef, "100%", () => onLoadMoreOrders());

  const hasItems = ordersList?.length > 0;

  return (
    <>
      <div className={classNames("tw-max-w-full tw-overflow-x-auto")}>
        {hasItems && (
          <OrdersTable
            ordersList={ordersList}
            onSelect={onSelectOrder}
            onClickViewMedia={onClickViewMedia}
            onCancel={onCancelOrder}
          />
        )}
        {!hasItems && <div className="tw-text-sm tw-italic">No orders</div>}
      </div>
      {canLoadMore && (
        <div ref={setLoadMoreRef} className="tw-flex tw-w-full tw-justify-center">
          <Loader className="tw-mt-4" />
        </div>
      )}
    </>
  );
};
