import { combineLatest, map } from "rxjs";

import { dashboardRepository, DashboardStore, dashboardStore, Folder } from "@shared/state/dashboard/dashboard.store";

import { useObservable } from "@mindspace-io/react";
import { select, setProps } from "@ngneat/elf";

const useDashboardState = <T>(selector: (state: DashboardStore) => T, defaultValue: T) => {
  return useObservable(dashboardStore.pipe(select(selector)), defaultValue);
};

export const useDashboard = () => {
  const [folders] = useDashboardState((state) => state.folders, []);
  const [media] = useDashboardState((state) => state.media, []);
  const [totalMediaCount] = useDashboardState((state) => state.totalMediaCount, 0);
  const [isLoadingFolders] = useDashboardState((state) => state.isLoadingFolders, false);
  const [hasLoadedFolders] = useDashboardState((state) => state.hasLoadedFolders, false);
  const [selectedFolderId] = useDashboardState((state) => state.selectedFolderId, null);
  const [lastPath] = useDashboardState((state) => state.lastPath, null);
  const [isLoadingFolderMedia] = useDashboardState((state) => state.isLoadingFolderMedia, null);
  const [hasLoadedFolderMedia] = useDashboardState((state) => state.hasLoadedFolderMedia, false);
  const [refreshMediaListKey] = useDashboardState((state) => state.refreshMediaListKey, 0);

  const selectFolder = (folderId: string | null = null) => {
    dashboardStore.update(setProps({ selectedFolderId: folderId ?? null }));
  };

  const setLastPath = (path: string | null = null) => {
    dashboardStore.update(setProps({ lastPath: path }));
  };

  const refreshMediaList = () => {
    dashboardStore.update(setProps({ refreshMediaListKey: Math.random() }));
  };

  function findNodePathById(nodes: Folder[], targetId: string): number[] | undefined {
    let path: number[] = [];

    function findNode(pathSoFar: number[], currentNode: Folder, currentIndex: number): boolean {
      if (currentNode.depth === 0 && currentNode.id === "favourites") {
        return false;
      }
      if (currentNode.id === targetId) {
        path = pathSoFar.concat(currentIndex);
        return true;
      }
      return (
        Array.isArray(currentNode.children) &&
        currentNode.children.some((child, index) => findNode(pathSoFar.concat(currentIndex), child, index))
      );
    }

    return nodes.some((node, index) => findNode([], node, index)) ? path : undefined;
  }

  const path$ = combineLatest([
    dashboardRepository.teamspacesDrive$,
    dashboardRepository.privateDrive$,
    dashboardStore.pipe(select((state) => state.selectedFolderId))
  ]).pipe(
    map(([teamspacesDrive, privateDrive, selectedFolderId]) => {
      const drives = [teamspacesDrive, privateDrive].filter((drive) => drive !== null) as Folder[];
      const path = selectedFolderId ? findNodePathById(drives, selectedFolderId) ?? [] : [];
      return path;
    })
  );

  const activeFolder$ = combineLatest([
    dashboardRepository.folderTree$,
    dashboardStore.pipe(select((state) => state.selectedFolderId))
  ]).pipe(
    map(([folders, selectedFolderId]) => {
      return folders.find((folder) => folder.id === selectedFolderId) ?? null;
    })
  );

  function findNodesByPath(nodes: Folder[], path: number[]): Folder[] {
    let currentNodes = nodes;
    const result: Folder[] = [];

    for (const index of path) {
      const node = currentNodes[index];
      result.push(node);
      currentNodes = node.children || [];
    }

    return result;
  }

  const breadcrumbs$ = combineLatest([
    dashboardRepository.teamspacesDrive$,
    dashboardRepository.privateDrive$,
    path$
  ]).pipe(
    map(([teamspacesDrive, privateDrive, path]) => {
      const drives = [teamspacesDrive, privateDrive].filter((drive) => drive !== null) as Folder[];
      return findNodesByPath(drives, path);
    })
  );

  const [breadcrumbs] = useObservable(breadcrumbs$);
  const [path] = useObservable(path$);
  const [activeFolder] = useObservable(activeFolder$);

  const [favouritesDrive] = useObservable(dashboardRepository.favouritesDrive$);
  const [privateDrive] = useObservable(dashboardRepository.privateDrive$);
  const [teamspacesDrive] = useObservable(dashboardRepository.teamspacesDrive$);

  const privateFolders$ = dashboardRepository.folders$.pipe(
    map((folders) => folders.filter((folder) => folder.isPublic === false))
  );

  const teamspaces$ = dashboardRepository.folders$.pipe(
    map((folders) => folders.filter((folder) => folder.isPublic === true))
  );

  const [privateFolders = []] = useObservable(privateFolders$);
  const [teamspaces = []] = useObservable(teamspaces$);

  const getFolderById = (id?: string): Folder | undefined => {
    if (!id) {
      return;
    }

    return folders?.find((folder) => folder.id === id);
  };

  return {
    favouritesDrive,
    privateDrive,
    teamspacesDrive,
    privateFolders,
    teamspaces,
    folders: folders ?? [],
    isLoadingFolders: isLoadingFolders ?? false,
    hasLoadedFolders: hasLoadedFolders ?? false,
    activeFolder,
    selectedFolderId,
    lastPath,
    breadcrumbs,
    path,
    selectFolder,
    setLastPath,
    isLoadingFolderMedia,
    hasLoadedFolderMedia,
    getFolderById,
    refreshMediaList,
    refreshMediaListKey,
    media,
    totalMediaCount
  };
};
